import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
  EntryType,
  MembershipTier,
  MembershipTierState,
  SharedContentBoard,
  SharedContentBoardEntry,
  SharedContentBoardLane,
  SharedContentEntry,
  SmorgStudioMembership,
  Space,
  SpacePrivateConfig,
  UserProfile,
} from '../API';
import { currentCreatorSpaceSelector } from './user_reducer';
import {
  sharedContentBoardsWithSharedContentBoardUpdated,
  spaceContentBoardEntryByEntryID,
} from '../services/spaces';

type RootState = {
  userProfile: UserProfile;
  spaces: Array<Space>;
  spacePrivateConfig: SpacePrivateConfig | null;
  sharedContentBoards: Array<SharedContentBoard>;
  sharedContentEntries: Record<string, SharedContentEntry>;
  membershipTiers: Array<MembershipTier>;
  smorgStudioMemberships: Array<SmorgStudioMembership>;
};

// eslint-disable-next-line import/prefer-default-export
export const spacesReducer = (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case 'OWNED_SPACES_AVAILABLE': {
      const { spaces, sharedContentBoards } = action;
      return {
        ...state,
        spaces,
        sharedContentBoards,
      };
    }

    case 'CREATOR_ONBOARDING_SHOWN': {
      const { space } = action;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          showOnboarding: false,
        },
        spaces: [space],
      };
    }

    case 'SPACE_UPDATED': {
      const { space } = action;
      return {
        ...state,
        spaces: state.spaces.map((s) => {
          if (s.id !== space.id) {
            return s;
          }
          return space;
        }),
      };
    }

    case 'SPACE_PRIVATE_CONFIG_AVAILABLE': {
      const { spacePrivateConfig } = action;
      return {
        ...state,
        spacePrivateConfig,
      };
    }

    case 'SPACE_PRIVATE_CONFIG_UPDATED': {
      const { spacePrivateConfig } = action;
      return {
        ...state,
        spacePrivateConfig,
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_ADDED': {
      const { parentID, entryID, entryType, objectID, laneID, object } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const updatedBoard = {
        ...spaceContentBoard,
        lanes: spaceContentBoard.lanes.map((lane: SharedContentBoardLane) => {
          if (lane.id !== laneID) {
            return lane;
          }
          return {
            ...lane,
            entries: [
              ...lane.entries,
              {
                id: entryID,
                entryType,
                objectID,
              },
            ],
          };
        }),
      };

      const newSharedContentEntries = { ...state.sharedContentEntries };
      if (entryType === EntryType.CONTENT_ENTRY) {
        newSharedContentEntries[objectID] = object;
      }

      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          updatedBoard,
        ),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_ADDED': {
      const { parentID, laneID, title } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const updatedBoard = {
        ...spaceContentBoard,
        lanes: [
          ...spaceContentBoard.lanes,
          {
            id: laneID,
            title,
            entries: [],
          },
        ],
      };
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          updatedBoard,
        ),
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_QUICK_DUPLICATE': {
      const { parentID, entryID, entryType, newObject } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const newSharedContentEntries = { ...state.sharedContentEntries };
      if (entryType === EntryType.CONTENT_ENTRY) {
        newSharedContentEntries[newObject.id] = newObject;
      }
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SharedContentBoardLane) => {
          const entryIndexToDuplicate = lane.entries.findIndex(
            (e) => e.id === entryID,
          );
          if (entryIndexToDuplicate === -1) {
            return lane;
          }
          const newEntry = {
            id: uuidv4(),
            entryType,
            objectID: newObject
              ? newObject.id
              : lane.entries[entryIndexToDuplicate].objectID,
          } as unknown as SharedContentBoardEntry;
          const updatedEntries = [...lane.entries];
          updatedEntries.splice(entryIndexToDuplicate, 0, newEntry);
          return {
            ...lane,
            entries: updatedEntries,
          };
        },
      );
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          {
            ...spaceContentBoard,
            lanes: updatedLanes,
          },
        ),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_DELETED': {
      const { parentID, laneID, entryID, objectID } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const newSharedContentEntries = { ...state.sharedContentEntries };
      delete newSharedContentEntries[objectID];
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SharedContentBoardLane) => {
          if (lane.id !== laneID) {
            return lane;
          }
          const updatedEntries = lane.entries.filter((e) => e.id !== entryID);
          return {
            ...lane,
            entries: updatedEntries,
          };
        },
      );
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          {
            ...spaceContentBoard,
            lanes: updatedLanes,
          },
        ),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_MOVED': {
      const { parentID, entryID, sourceLaneID, targetLaneID, toPosition } =
        action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const entry = spaceContentBoardEntryByEntryID(spaceContentBoard, entryID);
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SharedContentBoardLane) => {
          let updatedLane = { ...lane };

          if (updatedLane.id === sourceLaneID) {
            updatedLane = {
              ...updatedLane,
              entries: updatedLane.entries.filter((e) => e.id !== entryID),
            };
          }

          if (updatedLane.id === targetLaneID) {
            const updatedEntries = [...updatedLane.entries];
            updatedEntries.splice(toPosition, 0, entry);
            updatedLane = {
              ...updatedLane,
              entries: updatedEntries,
            };
          }

          return updatedLane;
        },
      );
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          {
            ...spaceContentBoard,
            lanes: updatedLanes,
          },
        ),
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_RENAMED': {
      const { parentID, laneID, updatedTitle } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SharedContentBoardLane) => {
          if (lane.id === laneID) {
            return {
              ...lane,
              title: updatedTitle,
            };
          }

          return lane;
        },
      );
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          {
            ...spaceContentBoard,
            lanes: updatedLanes,
          },
        ),
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_MOVED': {
      const { parentID, removedIndex, addedIndex } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const updatedLanes = [...spaceContentBoard.lanes];
      const [lane] = updatedLanes.splice(removedIndex, 1);
      updatedLanes.splice(addedIndex, 0, lane);
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          {
            ...spaceContentBoard,
            lanes: updatedLanes,
          },
        ),
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_DELETED': {
      const { parentID, laneID } = action;
      const spaceContentBoard = state.sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      if (!spaceContentBoard) {
        return state;
      }
      const objectIDs = (
        spaceContentBoard.lanes.find(
          (l: SharedContentBoardLane) => l.id === laneID,
        )?.entries || []
      ).map((e: SharedContentBoardEntry) => e.objectID);
      const updatedLanes = spaceContentBoard.lanes.filter(
        (l: SharedContentBoardLane) => l.id !== laneID,
      );
      const newSharedContentEntries = { ...state.sharedContentEntries };
      objectIDs.forEach(
        (objectID: string) => delete newSharedContentEntries[objectID],
      );
      return {
        ...state,
        sharedContentBoards: sharedContentBoardsWithSharedContentBoardUpdated(
          state.sharedContentBoards,
          {
            ...spaceContentBoard,
            lanes: updatedLanes,
          },
        ),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SHARED_CONTENT_BOARD_BOARD_CREATED': {
      const { sharedContentBoard } = action;
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        spaceSharedContentBoardIDs: [
          ...(space.spaceSharedContentBoardIDs || []),
          sharedContentBoard.id,
        ],
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
        sharedContentBoards: [...state.sharedContentBoards, sharedContentBoard],
      };
    }

    case 'SHARED_CONTENT_BOARD_BOARD_DELETED': {
      const { sharedContentBoardID } = action;
      const sharedContentBoard = state.sharedContentBoards.find(
        (b) => b.id === sharedContentBoardID,
      );
      if (!sharedContentBoard) {
        return state;
      }
      const objectIDs = sharedContentBoard.lanes
        .flatMap((l) => l.entries)
        .map((e) => e.objectID);
      const newSharedContentEntries = { ...state.sharedContentEntries };
      objectIDs.forEach(
        (objectID: string) => delete newSharedContentEntries[objectID],
      );
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        spaceSharedContentBoardIDs: (
          space.spaceSharedContentBoardIDs || []
        ).filter((cbid: string) => cbid !== sharedContentBoardID),
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
        sharedContentBoards: state.sharedContentBoards.filter(
          (b) => b.id !== sharedContentBoardID,
        ),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_MY_DAY_SCREEN_SECTIONS_UPDATED': {
      const { myDayScreenSections } = action;
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        myDayScreenSections: myDayScreenSections || [],
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
      };
    }

    case 'SPACE_MY_DAY_WELCOME_VIDEO_UPDATED': {
      const { videoPlaylistItem } = action;
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        welcomeVideo: videoPlaylistItem,
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
      };
    }

    case 'SPACE_PUBLISHED_SEARCH_TAGS_UPDATED': {
      const { publishedSearchTags } = action;
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        publishedSearchTags,
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
      };
    }

    case 'IAP_SUBSCRIPTIONS_AVAILABLE': {
      const { iapSubscriptions } = action;
      return {
        ...state,
        iapSubscriptions,
      };
    }

    case 'SMORG_STUDIO_MEMBERSHIPS_AVAILABLE': {
      const { memberships } = action;
      return {
        ...state,
        smorgStudioMemberships: memberships,
      };
    }

    case 'SMORG_STUDIO_MEMBERSHIP_UPDATED': {
      const { smorgStudioMembership } = action;
      const updatedSmorgStudioMemberships = state.smorgStudioMemberships.map(
        (existingMembership) => {
          if (existingMembership.id === smorgStudioMembership.id) {
            return smorgStudioMembership;
          }
          return existingMembership;
        },
      );
      return {
        ...state,
        smorgStudioMemberships: updatedSmorgStudioMemberships,
      };
    }

    case 'SMORG_STUDIO_MEMBERSHIP_DELETED': {
      const { spaceMembershipID } = action;
      const updatedSmorgStudioMemberships = state.smorgStudioMemberships.filter(
        (membership) => membership.id !== spaceMembershipID,
      );
      return {
        ...state,
        smorgStudioMemberships: updatedSmorgStudioMemberships,
      };
    }

    default:
      return state;
  }
};

export const isStripeConfiguredSelector = (state: RootState) =>
  !!state?.spacePrivateConfig?.stripeConfig?.connectedAccountID;

export const spaceHasMembershipTiersAvailableSelector = (state: RootState) =>
  (state.membershipTiers || []).some(
    (membershipTier) => membershipTier.state === MembershipTierState.ACTIVE,
  );

export const anySmorgStudioMembershipAvailableSelector = (state: RootState) =>
  state.smorgStudioMemberships && state.smorgStudioMemberships.length > 0;
