import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { myFavouritesRecipesBoardIdSelector } from '../../reducers/recipes_reducer';
import TopNavBar from '../user/top_nav_bar';
import PopupTitle from '../common/popup_title';
import MealBasketWidget from '../meal/meal_basket_widget';
import RecipeCollectionsAddMealItem from './recipe_collections_add_meal_item';
import { useMultiMenuMeals } from '../common/multi_menu_meals_hook';

const RecipeCollectionsAddMealsFlattenedBoardView = ({
  recipesBoardId,
  menusWithParents,
  destinationBoardID,
  destinationMenuID,
  onClick,
}) => {
  const navigate = useNavigate();

  const ownRecipesBoard = useSelector((state) =>
    state.recipesBoards.find((b) => b.id === recipesBoardId),
  );

  const favouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const { dedupedMergedEntriesFromMultipleMenus, multiMenusTitle } =
    useMultiMenuMeals(menusWithParents);

  const flattenedFavouriteMealIDs = (ownRecipesBoard?.menus || []).flatMap(
    (menu) =>
      (menu.mealIDs || menu.sharedMealIDs).map((mealID) => ({
        mealID,
        menuID: menu.id,
        parentID: ownRecipesBoard.id,
        isDeleteEnabled: true,
      })),
  );

  const shouldShowEmptyBoardHint =
    ownRecipesBoard &&
    favouritesRecipesBoardID === recipesBoardId &&
    flattenedFavouriteMealIDs.length === 0;

  const title =
    menusWithParents && menusWithParents.length > 0
      ? multiMenusTitle
      : ownRecipesBoard.title;

  // const menu = (theBoard?.menus || []).find((m) => m.id === menuId);

  const objects =
    menusWithParents && menusWithParents.length > 0
      ? dedupedMergedEntriesFromMultipleMenus
      : flattenedFavouriteMealIDs;

  // const flattenedObjectIDs = [
  //   ...(embeddedRecipesBoard?.menus || []).flatMap((menu) => menu.mealIDs),
  //   ...(myFavouritesRecipesBoard?.menus || []).flatMap((menu) => menu.mealIDs),
  // ];

  // useEffect(() => {
  //   dispatch(ensureObjectsLoadedAction(EntryType.MEAL, flattenedObjectIDs));
  // }, [dispatch, JSON.stringify(flattenedObjectIDs)]);

  const onClickBack = () => navigate(-1);

  return (
    <div className="recipes-search">
      <TopNavBar />
      <MealBasketWidget />
      <div
        className="topNavBarMobile topNavBarMobileLight"
        style={{
          color: 'var(--app-primary-font-color)',
          height: '70px',
          flexDirection: 'column',
        }}>
        <PopupTitle
          titleText={title}
          backButtonEnabled
          onClickBack={onClickBack}
        />
      </div>
      <div>
        <Grid container>
          {objects.map(({ mealID, menuID, parentID, isDeleteEnabled }) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={mealID}
              style={{ flexShrink: 0 }}>
              <RecipeCollectionsAddMealItem
                mealID={mealID}
                destinationBoardID={destinationBoardID}
                destinationMenuID={destinationMenuID}
                onClick={onClick}
              />
            </Grid>
          ))}
        </Grid>
        {shouldShowEmptyBoardHint && (
          <div style={{ height: '100%', display: 'flex' }}>
            <div
              style={{
                margin: 'auto',
                color: 'var(--app-primary-font-color)',
              }}>
              Favourite a meal for it to appear here, or copy meals from
              Collections
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

RecipeCollectionsAddMealsFlattenedBoardView.propTypes = {
  recipesBoardId: PropTypes.string,
  menusWithParents: PropTypes.objectOf({
    parentID: PropTypes.string.isRequired,
    menuID: PropTypes.string.isRequired,
  }),
  destinationBoardID: PropTypes.string.isRequired,
  destinationMenuID: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

RecipeCollectionsAddMealsFlattenedBoardView.defaultProps = {
  recipesBoardId: null,
  menusWithParents: [],
};

export default RecipeCollectionsAddMealsFlattenedBoardView;
