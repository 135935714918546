import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';

const NarrowEndUserContentEntryCard = ({ title, coverImageUrl, onClick }) => {
  const hasTitle = !!title;

  const theme = useTheme();

  const imgClassNames = classNames('narrowEndUserContentEntryCardImage', {
    cardWithNoTitle: !hasTitle,
  });

  return (
    <div>
      <Card
        onClick={(ev) => {
          ev.stopPropagation();
          onClick();
        }}
        sx={{
          width: 166,
          height: 136,
          minWidth: 166,
          borderRadius: '15px',
          margin: '6px',
          flexShrink: 0,
        }}
        style={{ backgroundColor: theme.palette.primary.cardBackground }}>
        <Grid item xs={12}>
          {coverImageUrl ? (
            <CardMedia
              component="img"
              className={imgClassNames}
              image={coverImageUrl}
              alt=""
            />
          ) : (
            <div className={imgClassNames} />
          )}
        </Grid>
        {hasTitle && (
          <Grid item xs={12}>
            <CardContent
              sx={{
                flex: '1 0 auto',
                margin: '8px',
                padding: '0 !important',
                fontSize: '14px',
                whiteSpace: 'break-spaces',
              }}
              style={{
                color: 'var(--app-primary-font-color)',
                lineClamp: 3,
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}>
              {title}
            </CardContent>
          </Grid>
        )}
      </Card>
    </div>
  );
};

NarrowEndUserContentEntryCard.propTypes = {
  title: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

NarrowEndUserContentEntryCard.defaultProps = {
  coverImageUrl: null,
};

export default NarrowEndUserContentEntryCard;
