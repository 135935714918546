import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import {
  mdiCheckOutline,
  mdiSwapHorizontalCircle,
  mdiUndoVariant,
} from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import MealTypesChips from '../meal/meal_types_chips';
import {
  myDayMarkMealCompletedAction,
  myDaySkipMealAction,
  myDayUnskipMealAction,
} from '../../action_creators/my_day_action_creators';
import {
  currentProgrammeEnrollmentSelector,
  isMealCompletedSelector,
  isMealSkippedSelector,
} from '../../reducers/programmes_reducer';
import {
  END_USER_FIND_ALTERNATIVES_ROUTE,
  MY_DAY_MEAL_ROUTE,
} from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import {
  dayIndexForCalendarDate,
  viewWeekStartDateStr,
} from '../../services/planner';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';
import { plannerEntryDeletedAction } from '../../action_creators/planner_action_creators';

const MyDayCompactMealCard = ({ mealID, parentID, calendarDay }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const meal = useSelector((state) => state.meals[mealID]);
  const title = meal?.recipes[0]?.title;
  const mealType = ((meal?.recipes || [])[0]?.mealTypes || [])[0];

  const plannerViewWeekStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );

  const plannerViewWeekStartDate = viewWeekStartDateStr(
    calendarDay,
    plannerViewWeekStartDay,
  );

  const dayIndex = dayIndexForCalendarDate(calendarDay);

  const [deleteMealAnchorEl, setDeleteMealAnchorEl] = useState(null);

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const isCompleted = useSelector(
    (state) =>
      currentProgrammeEnrollment &&
      isMealCompletedSelector(state, currentProgrammeEnrollment?.id, mealID),
  );

  const isSkipped = useSelector(
    (state) =>
      currentProgrammeEnrollment &&
      isMealSkippedSelector(state, currentProgrammeEnrollment?.id, mealID),
  );

  const onClickDelete = useCallback((ev) => {
    setDeleteMealAnchorEl(ev.target);
    ev.stopPropagation();
  }, []);

  const onDelete = useCallback(() => {
    setDeleteMealAnchorEl(null);
    dispatch(
      plannerEntryDeletedAction(plannerViewWeekStartDate, mealID, dayIndex),
    );
  }, [dispatch, plannerViewWeekStartDate, mealID, dayIndex]);

  if (!meal) {
    return null;
  }

  return (
    <>
      <Card
        className="my-day-compact-card"
        onClick={() => {
          navigate(
            formatRoute(MY_DAY_MEAL_ROUTE, {
              parentID,
              mealID,
            }),
          );
        }}>
        <CardMedia
          sx={{ height: 160 }}
          image={meal.recipes[0].imageUrl || imgPlaceholder}
        />
        <CardContent
          style={{ padding: '12px 12px', borderBottom: '1px solid #7B7B7B' }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ flexGrow: 1 }}
            style={{
              lineClamp: 1,
              WebkitLineClamp: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <MealTypesChips mealTypes={meal.recipes[0].mealTypes} />
          </Box>
        </CardContent>
        <CardActions
          sx={{ justifyContent: 'space-around' }}
          style={{ paddingTop: '0' }}>
          {isCompleted && (
            <div
              className="myDayActionCardCompleted"
              style={{ paddingTop: '8px' }}>
              COMPLETED - GOOD JOB!
            </div>
          )}
          {currentProgrammeEnrollment && !isCompleted && isSkipped && (
            <Tooltip title="Unskip this meal" placement="top-start">
              <IconButton
                aria-label="unskip"
                size="small"
                onClick={(ev) => {
                  dispatch(
                    myDayUnskipMealAction(mealID, parentID, calendarDay),
                  );
                  ev.stopPropagation();
                }}>
                <Icon
                  path={mdiUndoVariant}
                  style={{ width: '32px', height: '32px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {!isCompleted && !isSkipped && (
            <Tooltip title="Find alternatives" placement="top-start">
              <IconButton
                aria-label="find alternatives"
                size="small"
                disabled={isSkipped}
                onClick={(ev) => {
                  ev.stopPropagation();
                  navigate(
                    linkWithQueryParams(
                      formatRoute(END_USER_FIND_ALTERNATIVES_ROUTE, {
                        calendarDay,
                      }),
                      { mealType, mealId: meal?.id },
                    ),
                  );
                }}>
                <Icon
                  path={mdiSwapHorizontalCircle}
                  style={{ width: '32px', height: '32px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {currentProgrammeEnrollment && !isCompleted && !isSkipped && (
            <>
              <Tooltip title="Complete this meal" placement="top-start">
                <IconButton
                  aria-label="complete"
                  size="small"
                  disabled={isSkipped}
                  onClick={(ev) => {
                    dispatch(
                      myDayMarkMealCompletedAction(
                        mealID,
                        parentID,
                        calendarDay,
                      ),
                    );
                    ev.stopPropagation();
                  }}>
                  <Icon
                    path={mdiCheckOutline}
                    style={{ width: '24px', height: '24px' }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Skip this meal" placement="top-start">
                <IconButton
                  aria-label="skip"
                  size="small"
                  style={{ width: '32px', height: '32px' }}
                  onClick={(ev) => {
                    dispatch(
                      myDaySkipMealAction(mealID, parentID, calendarDay),
                    );
                    ev.stopPropagation();
                  }}>
                  <DeleteIcon style={{ width: '32px', height: '32px' }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {!currentProgrammeEnrollment && (
            <Tooltip title="Delete this meal" placement="top-start">
              <IconButton
                aria-label="delete"
                size="small"
                style={{ width: '32px', height: '32px' }}
                onClick={(ev) => {
                  onClickDelete(ev);
                  ev.stopPropagation();
                }}>
                <DeleteIcon style={{ width: '32px', height: '32px' }} />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
      <DeleteCardConfirmPopover
        visible={!!deleteMealAnchorEl}
        anchorEl={deleteMealAnchorEl}
        title="Delete meal"
        message="The meal will be deleted permanently. Are you sure?"
        height={140}
        onConfirmDelete={onDelete}
        onDismiss={() => setDeleteMealAnchorEl(null)}
      />
    </>
  );
};

MyDayCompactMealCard.propTypes = {
  mealID: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  calendarDay: PropTypes.string.isRequired,
};

export default MyDayCompactMealCard;
