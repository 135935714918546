import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  locallyScaleSharedMealAction,
  mealAddedToMealBasketAction,
  mealRemovedFromMealBasketAction,
  scaleMealAction,
} from '../../action_creators/meal_action_creators';
import {
  END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE,
  END_USER_RECIPE_SINGLE_COLLECTION_ROUTE,
  END_USER_RECIPES_BOARD_ROUTE,
} from '../../services/routes';
import FullWidthMealCard from '../meal/full_width_meal_card';
import {
  mealCopiedToPlannerAction,
  sharedMealCopiedToPlannerAction,
} from '../../action_creators/planner_action_creators';
import {
  mealDeletedAction,
  mealMovedBetweenBoardsAction,
  mealOrSharedMealCopiedToRecipesBoardAction,
  mealUnfavouritedAction,
} from '../../action_creators/recipes_action_creators';
import AddToPlannerModal from '../meal/add_to_planner_modal';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import {
  endUserCopyDestinationsSelector,
  isMealFavouritedSelector,
  isSharedMealSelector,
  mealIDInFavouritesSelector,
  myFavouritesRecipesBoardDefaultMenuIdSelector,
  myFavouritesRecipesBoardIdSelector,
} from '../../reducers/recipes_reducer';
import EndUserOrganiseRecipeModal from '../meal/end_user_organise_recipe_modal';
import { recipeCollectionsCardDOMID } from '../../services/meals';
import { isMealBackgroundProcessingSelector } from '../../reducers/meal_reducer';

const RecipeCollectionFullWidthMealCard = ({ mealID, parentID, menuID }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const [copyToBoardAnchorEl, setCopyToBoardAnchorEl] = useState(null);

  const [deleteMealAnchorEl, setDeleteMealAnchorEl] = useState(null);

  const [scalingInProgress, setScalingInProgress] = useState(false);

  const meal = useSelector(
    (state) => state.meals[mealID] || state.sharedMeals[mealID],
  );

  const isBackgroundProcessing = useSelector((state) =>
    isMealBackgroundProcessingSelector(state, mealID),
  );

  const title = meal?.recipes[0]?.title;
  const servings = meal?.recipes[0]?.servings;

  const isFavourite = useSelector((state) =>
    isMealFavouritedSelector(state, meal),
  );

  const mealIDInFavourites = useSelector((state) =>
    mealIDInFavouritesSelector(state, meal),
  );

  const isSharedMeal = useSelector((state) =>
    isSharedMealSelector(state, mealID),
  );

  const anyCopyDestinationsAvailable = useSelector(
    (state) => endUserCopyDestinationsSelector(state, parentID).length > 0,
  );

  const isInMealBasket = useSelector(
    (state) => state.mealBasket && state.mealBasket.mealIDs.includes(mealID),
  );

  const calories = useSelector(
    (state) =>
      state.meals[mealID]?.derivedNutrition?.totalNutritionPerServing?.calories,
  );

  const onAddToMealBasket = useCallback(
    () => dispatch(mealAddedToMealBasketAction(mealID, 'recipeCollections')),
    [dispatch, mealID],
  );
  const onRemoveFromMealBasket = useCallback(
    () => dispatch(mealRemovedFromMealBasketAction(mealID)),
    [dispatch, mealID],
  );
  const onCopyToPlanner = useCallback(
    (plannerWeekStartDate, dayIndexes) => {
      if (isSharedMeal) {
        dispatch(
          sharedMealCopiedToPlannerAction(
            mealID,
            plannerWeekStartDate,
            dayIndexes,
          ),
        );
      } else {
        dispatch(
          mealCopiedToPlannerAction(mealID, plannerWeekStartDate, dayIndexes),
        );
      }
    },
    [dispatch, mealID, isSharedMeal],
  );
  const onCopyToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealOrSharedMealCopiedToRecipesBoardAction(
          mealID,
          toRecipesBoardId,
          toMenuId,
          (copiedMeal) => {
            window.setTimeout(() => {
              const el = document.getElementById(
                recipeCollectionsCardDOMID(copiedMeal.id),
              );
              if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
              }
            }, 200);
          },
        ),
      );
    },
    [dispatch, mealID],
  );

  const onMoveToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealMovedBetweenBoardsAction(
          mealID,
          parentID,
          menuID,
          toRecipesBoardId,
          toMenuId,
        ),
      );
    },
    [dispatch, mealID, menuID, parentID],
  );

  const onDelete = useCallback(() => {
    dispatch(mealDeletedAction(parentID, mealID, menuID));
  }, [dispatch, parentID, mealID, menuID]);

  const onClickCopyToPlanner = useCallback((target) => {
    setAddToPlannerAnchorEl(target);
  }, []);

  const onClickCopyToRecipesBoard = useCallback((target) => {
    setCopyToBoardAnchorEl(target);
  }, []);

  const onClickDelete = useCallback((ev) => {
    setDeleteMealAnchorEl(ev.target);
    ev.stopPropagation();
  }, []);

  const onChangeScaleToServings = async (scaleToServings) => {
    const scaleToServingsNumber = Number(scaleToServings) || 1;
    if (scaleToServingsNumber !== servings) {
      const scaleFactor = scaleToServingsNumber / (servings || 1);
      setScalingInProgress(true);
      try {
        if (isSharedMeal) {
          await dispatch(
            locallyScaleSharedMealAction(
              mealID,
              scaleFactor,
              scaleToServingsNumber,
            ),
          );
        } else {
          await dispatch(
            scaleMealAction(mealID, scaleFactor, scaleToServingsNumber),
          );
        }
      } finally {
        setScalingInProgress(false);
      }
    }
  };

  const myFavouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const myFavouritesMenuID = useSelector(
    myFavouritesRecipesBoardDefaultMenuIdSelector,
  );

  const onClickFavourite = useCallback(() => {
    dispatch(
      mealOrSharedMealCopiedToRecipesBoardAction(
        mealID,
        myFavouritesRecipesBoardID,
        myFavouritesMenuID,
        (copiedMeal) => {
          window.setTimeout(() => {
            const el = document.getElementById(
              recipeCollectionsCardDOMID(copiedMeal.id),
            );
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        },
      ),
    );
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'Your meal has been added to your favourites',
      linkText: 'View',
      linkTarget: formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
        boardID: myFavouritesRecipesBoardID,
      }),
    });
  }, [dispatch, mealID, myFavouritesMenuID, myFavouritesRecipesBoardID]);

  const onClickUnfavourite = useCallback(() => {
    if (mealIDInFavourites) {
      dispatch(mealUnfavouritedAction(mealIDInFavourites)).then(() => {
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your meal has been removed from your favourites',
        });
      });
    }
  }, [dispatch, mealIDInFavourites]);

  if (!meal) {
    return null;
  }

  return (
    <>
      <FullWidthMealCard
        addToPlannerEnabled
        copyEnabled={!isSharedMeal && anyCopyDestinationsAvailable}
        shoppingEnabled
        deleteEnabled={!isSharedMeal}
        title={title}
        imageUrl={meal.recipes[0].imageUrl}
        mealTypes={meal.recipes[0].mealTypes}
        calories={calories}
        servingsEnabled
        servings={servings}
        onClick={() => {
          navigate(
            formatRoute(END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE, {
              boardID: parentID,
              mealID,
            }),
          );
        }}
        onChangeScaleToServings={onChangeScaleToServings}
        isInMealBasket={isInMealBasket}
        onClickCopyToPlanner={onClickCopyToPlanner}
        onClickCopyToRecipesBoard={onClickCopyToRecipesBoard}
        onClickAddToMealBasket={onAddToMealBasket}
        onClickRemoveFromMealBasket={onRemoveFromMealBasket}
        onClickDelete={onClickDelete}
        scalingInProgress={scalingInProgress}
        favouriteEnabled={isSharedMeal}
        isFavourite={isFavourite}
        onClickFavourite={onClickFavourite}
        onClickUnfavourite={onClickUnfavourite}
        isBackgroundProcessing={isBackgroundProcessing}
      />
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToPlannerEnabled
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
          titleText="Schedule"
        />
      )}
      {copyToBoardAnchorEl && (
        <EndUserOrganiseRecipeModal
          recipesBoardId={parentID}
          menuSelectionEnabled
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId, toMenuId) => {
            onCopyToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been copied to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          isMoveEnabled={!isSharedMeal}
          onMove={(toRecipesBoardId, toMenuId) => {
            onMoveToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been moved to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
          boardTitle="collection"
        />
      )}
      <DeleteCardConfirmPopover
        visible={!!deleteMealAnchorEl}
        anchorEl={deleteMealAnchorEl}
        title="Delete meal"
        message="The meal will be deleted permanently. Are you sure?"
        height={140}
        onConfirmDelete={onDelete}
        onDismiss={() => setDeleteMealAnchorEl(null)}
      />
    </>
  );
};

RecipeCollectionFullWidthMealCard.propTypes = {
  mealID: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,
};

export default RecipeCollectionFullWidthMealCard;
