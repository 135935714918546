import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Grid } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Icon from '@mdi/react';
import {
  mdiAccount,
  /* mdiInformationOutline, */ mdiTuneVariant,
} from '@mdi/js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
  END_USER_PREFERENCES_PERSONAL_INFORMATION_MOBILE_ROUTE,
} from '../../services/routes';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';
import { linkWithQueryParams } from '../../services/urls';

const UserPreferencesMobileView = () => {
  const navigate = useNavigate();

  const [givenName, setGivenName] = useState('');

  const [searchParams] = useSearchParams();

  const hideMobileNavigation = searchParams.get('hideMobileNavigation');

  useEffect(() => {
    console.log('Loading profile data');
    Auth.currentUserInfo().then((currentUserInfo) => {
      console.log({ currentUserInfo });
      setGivenName(currentUserInfo.attributes.given_name);
    });
  }, []);

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  return (
    <div className="user-preferences-page">
      <div
        className="topNavBarMobile"
        style={{ margin: 0, paddingTop: '10px' }}>
        <div className="screen-title" style={{ flexGrow: 1 }}>
          {givenName || 'Your profile'}
        </div>
      </div>
      <Grid
        container
        rowSpacing={3}
        style={{ textAlign: 'left', color: 'var(--app-primary-font-color)' }}>
        {!!givenName && (
          <Grid item xs={12}>
            <h2
              style={{
                fontSize: '24px',
                color: 'var(--app-primary-font-color)',
                margin: '23px 23px 10px',
              }}>
              Your profile
            </h2>
          </Grid>
        )}
        {!givenName && (
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        )}
        <Grid
          item
          xs={11}
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() =>
            navigate(
              linkWithQueryParams(
                END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
                { hideMobileNavigation },
              ),
            )
          }>
          <Icon
            path={mdiAccount}
            style={{
              width: '24px',
              height: '24px',
              margin: 'auto 4px auto 20px',
            }}
          />
          Account information
        </Grid>
        <Grid item xs={1}>
          <Link
            to={linkWithQueryParams(
              END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
              { hideMobileNavigation },
            )}>
            <FontAwesomeIcon icon="chevron-right" />
          </Link>
        </Grid>
        {!isCommunityUser && (
          <>
            <Grid
              item
              xs={11}
              style={{ display: 'flex', cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  linkWithQueryParams(
                    END_USER_PREFERENCES_PERSONAL_INFORMATION_MOBILE_ROUTE,
                    { hideMobileNavigation },
                  ),
                )
              }>
              <Icon
                path={mdiTuneVariant}
                style={{
                  width: '24px',
                  height: '24px',
                  margin: 'auto 4px auto 20px',
                }}
              />
              Personal information
            </Grid>
            <Grid item xs={1}>
              <Link
                to={linkWithQueryParams(
                  END_USER_PREFERENCES_PERSONAL_INFORMATION_MOBILE_ROUTE,
                  { hideMobileNavigation },
                )}>
                <FontAwesomeIcon icon="chevron-right" />
              </Link>
            </Grid>
          </>
        )}
        {/* <Grid item xs={11} style={{ display: 'flex' }}>
          <Icon
            path={mdiInformationOutline}
            style={{
              width: '24px',
              height: '24px',
              margin: 'auto 4px auto 20px',
            }}
          />
          About
        </Grid> */}
        {/* <Grid item xs={1}>
          <Link to={END_USER_PREFERENCES_ABOUT_MOBILE_ROUTE}>
            <FontAwesomeIcon icon="chevron-right" />
          </Link>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default UserPreferencesMobileView;
