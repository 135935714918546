import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import {
  ContentEntry,
  ContentEntryByParentQuery,
  CreateContentEntryInput,
  CreateContentEntryMutation,
  CreateSharedContentBoardMutation,
  CreateSharedContentEntryInput,
  CreateSharedContentEntryMutation,
  DeleteContentEntryMutation,
  DeleteSharedContentEntryMutation,
  GetContentEntryQuery,
  GetSharedContentBoardQuery,
  SharedContentBoard,
  SharedContentEntry,
  UpdateContentEntryMutation,
  UpdateSharedContentBoardMutation,
  UpdateSharedContentEntryMutation,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getInputForUpdate } from './utils';

export const getContentEntryOperation = async (contentEntryID: string) => {
  const response = (await API.graphql(
    graphqlOperation(queries.getContentEntry, { id: contentEntryID }),
  )) as GraphQLResult<GetContentEntryQuery>;
  return response.data?.getContentEntry as ContentEntry;
};

export const getContentEntriesByContentEntryIDsOperation = async (
  contentEntryIDs: Array<string>,
) => {
  const contentEntries: Record<string, ContentEntry> = {};
  const loadContentEntryPromises = contentEntryIDs
    .filter((id) => !!id)
    .map(getContentEntryOperation);
  if (loadContentEntryPromises) {
    const loadedContentEntries = await Promise.all(loadContentEntryPromises);
    loadedContentEntries.forEach((contentEntry) => {
      if (contentEntry) {
        contentEntries[contentEntry.id] = contentEntry;
      }
    });
  }
  return contentEntries;
};

export const getContentEntriesByParentIDOperation = async (
  parentID: string,
) => {
  const contentEntries: Record<string, ContentEntry> = {};
  const fetchPage = async (nextToken: string | null | undefined) => {
    const allContentEntriesResponse = (await API.graphql(
      graphqlOperation(queries.contentEntryByParent, { parentID, nextToken }),
    )) as GraphQLResult<ContentEntryByParentQuery>;
    const allContentEntries =
      allContentEntriesResponse.data?.contentEntryByParent?.items || [];
    allContentEntries.forEach((contentEntry) => {
      if (contentEntry) {
        contentEntries[contentEntry.id] = contentEntry;
      }
    });
    return allContentEntriesResponse.data?.contentEntryByParent?.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return contentEntries;
};

export const removeContentEntryOperation = async (contentEntryID: string) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.deleteContentEntry, {
      input: { id: contentEntryID },
    }),
  )) as GraphQLResult<DeleteContentEntryMutation>;
  return response.data?.deleteContentEntry;
};

export const addContentEntryOperation = async (
  parentID: string,
  contentEntry: CreateContentEntryInput,
  currentHealthProGroup: string | null,
) => {
  const input = { ...contentEntry };
  input.parentID = parentID;
  input.groups = currentHealthProGroup ? [currentHealthProGroup] : null;
  const response = (await API.graphql(
    graphqlOperation(mutations.createContentEntry, { input }),
  )) as GraphQLResult<CreateContentEntryMutation>;
  return response.data?.createContentEntry;
};

export const addSharedContentEntryOperation = async (
  parentID: string,
  sharedContentEntry: CreateSharedContentEntryInput,
) => {
  const input = { ...sharedContentEntry };
  input.parentID = parentID;
  const response = (await API.graphql(
    graphqlOperation(mutations.createSharedContentEntry, { input }),
  )) as GraphQLResult<CreateSharedContentEntryMutation>;
  return response.data?.createSharedContentEntry;
};

export const updateContentEntryOperation = async (
  contentEntry: ContentEntry,
) => {
  const input = getInputForUpdate(contentEntry);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateContentEntry, { input }),
  )) as GraphQLResult<UpdateContentEntryMutation>;
  return response.data?.updateContentEntry;
};

export const updateSharedContentEntryOperation = async (
  sharedContentEntry: SharedContentEntry,
) => {
  const input = getInputForUpdate(sharedContentEntry);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateSharedContentEntry, { input }),
  )) as GraphQLResult<UpdateSharedContentEntryMutation>;
  return response.data?.updateSharedContentEntry;
};

export const createSharedContentEntryOperation = async (
  input: CreateSharedContentEntryInput,
) => {
  const response = (await API.graphql({
    ...graphqlOperation(mutations.createSharedContentEntry, { input }),
    // authMode: 'AWS_IAM',
  })) as GraphQLResult<CreateSharedContentEntryMutation>;
  return response.data?.createSharedContentEntry;
};

export const removeSharedContentEntryOperation = async (
  sharedContentEntryID: string,
) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.deleteSharedContentEntry, {
      input: { id: sharedContentEntryID },
    }),
  )) as GraphQLResult<DeleteSharedContentEntryMutation>;
  return response.data?.deleteSharedContentEntry;
};

export const removeSharedContentEntriesOperation = async (
  sharedContentEntryIDs: string[],
) => {
  const promises = sharedContentEntryIDs.map((sharedContentEntryID) =>
    API.graphql(
      graphqlOperation(mutations.deleteSharedContentEntry, {
        input: { id: sharedContentEntryID },
      }),
    ),
  );
  const results = await Promise.allSettled(promises);
  return results;
};

export const createSharedContentBoardOperation = async (
  input: SharedContentBoard,
) => {
  const response = (await API.graphql({
    ...graphqlOperation(mutations.createSharedContentBoard, { input }),
  })) as GraphQLResult<CreateSharedContentBoardMutation>;
  return response.data?.createSharedContentBoard;
};

/** For the owner (health pro account) */
export const getSharedContentBoardOperation = async (
  sharedContentBoardID: string,
) => {
  const response = (await API.graphql(
    graphqlOperation(queries.getSharedContentBoard, {
      id: sharedContentBoardID,
    }),
  )) as GraphQLResult<GetSharedContentBoardQuery>;
  return response.data?.getSharedContentBoard as SharedContentBoard;
};

/** For the end-user */
export const getSharedContentBoardIAMOperation = async (
  sharedContentBoardID: string,
) => {
  const response = (await API.graphql({
    ...graphqlOperation(queries.getSharedContentBoard, {
      id: sharedContentBoardID,
    }),
    authMode: 'AWS_IAM',
  })) as GraphQLResult<GetSharedContentBoardQuery>;
  return response.data?.getSharedContentBoard as SharedContentBoard;
};

export const updateSharedContentBoardOperation = async (
  sharedContentBoard: SharedContentBoard,
) => {
  const input = getInputForUpdate(sharedContentBoard);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateSharedContentBoard, { input }),
  )) as GraphQLResult<UpdateSharedContentBoardMutation>;
  return response.data?.updateSharedContentBoard;
};
