/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getZapierRestHook = /* GraphQL */ `query GetZapierRestHook($id: ID!) {
  getZapierRestHook(id: $id) {
    spaceID
    hookUrl
    hookKey
    enabled
    id
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetZapierRestHookQueryVariables,
  APITypes.GetZapierRestHookQuery
>;
export const listZapierRestHooks = /* GraphQL */ `query ListZapierRestHooks(
  $filter: ModelZapierRestHookFilterInput
  $limit: Int
  $nextToken: String
) {
  listZapierRestHooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      spaceID
      hookUrl
      hookKey
      enabled
      id
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListZapierRestHooksQueryVariables,
  APITypes.ListZapierRestHooksQuery
>;
export const getUserProfile = /* GraphQL */ `query GetUserProfile($id: ID!) {
  getUserProfile(id: $id) {
    id
    userID
    showOnboarding
    consentToProductComms
    plannerViewWeekStartDay
    geoRegion
    countryCode
    mealPlanReminderSchedule
    showMealDetailGuide
    recentlyUsedTags
    activitySummary {
      numMealsViewed
      numMealsViewedMobile
      numShoppingListsViewed
      numShoppingListsViewedMobile
      numMealsEdited
      numNutritionClicks
      numMealsPlanned
      numMealsAdded
      numMealsImported
      numMealsAddedManually
      numPlansViewed
      numShoppingListsCopiedToClipboard
      numShoppingListsEmailed
      numShoppingListsCreated
      numShops
      numNotesAdded
      numNotesViewed
      numNotesViewedMobile
      numRecipesBoardsCreated
      numRecipesBoardsShared
    }
    enabledFeatures
    requestedFeatures
    seenMessages
    recentlyUsedRecommenderMealTypes
    recentlyUsedRecommenderCalorieSplits {
      mealType
      fraction
    }
    recentlyUsedSearchCriteria {
      searchString
      mealTypes
      cookTimeMin
      cookTimeMax
      excludeIngredients
      includeIngredients
      dietaryPreferences
      perMealNutritionConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      tags
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileQueryVariables,
  APITypes.GetUserProfileQuery
>;
export const listUserProfiles = /* GraphQL */ `query ListUserProfiles(
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      showOnboarding
      consentToProductComms
      plannerViewWeekStartDay
      geoRegion
      countryCode
      mealPlanReminderSchedule
      showMealDetailGuide
      recentlyUsedTags
      activitySummary {
        numMealsViewed
        numMealsViewedMobile
        numShoppingListsViewed
        numShoppingListsViewedMobile
        numMealsEdited
        numNutritionClicks
        numMealsPlanned
        numMealsAdded
        numMealsImported
        numMealsAddedManually
        numPlansViewed
        numShoppingListsCopiedToClipboard
        numShoppingListsEmailed
        numShoppingListsCreated
        numShops
        numNotesAdded
        numNotesViewed
        numNotesViewedMobile
        numRecipesBoardsCreated
        numRecipesBoardsShared
      }
      enabledFeatures
      requestedFeatures
      seenMessages
      recentlyUsedRecommenderMealTypes
      recentlyUsedRecommenderCalorieSplits {
        mealType
        fraction
      }
      recentlyUsedSearchCriteria {
        searchString
        mealTypes
        cookTimeMin
        cookTimeMax
        excludeIngredients
        includeIngredients
        dietaryPreferences
        perMealNutritionConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        tags
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfilesQueryVariables,
  APITypes.ListUserProfilesQuery
>;
export const userProfileByUserID = /* GraphQL */ `query UserProfileByUserID(
  $userID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  userProfileByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      showOnboarding
      consentToProductComms
      plannerViewWeekStartDay
      geoRegion
      countryCode
      mealPlanReminderSchedule
      showMealDetailGuide
      recentlyUsedTags
      activitySummary {
        numMealsViewed
        numMealsViewedMobile
        numShoppingListsViewed
        numShoppingListsViewedMobile
        numMealsEdited
        numNutritionClicks
        numMealsPlanned
        numMealsAdded
        numMealsImported
        numMealsAddedManually
        numPlansViewed
        numShoppingListsCopiedToClipboard
        numShoppingListsEmailed
        numShoppingListsCreated
        numShops
        numNotesAdded
        numNotesViewed
        numNotesViewedMobile
        numRecipesBoardsCreated
        numRecipesBoardsShared
      }
      enabledFeatures
      requestedFeatures
      seenMessages
      recentlyUsedRecommenderMealTypes
      recentlyUsedRecommenderCalorieSplits {
        mealType
        fraction
      }
      recentlyUsedSearchCriteria {
        searchString
        mealTypes
        cookTimeMin
        cookTimeMax
        excludeIngredients
        includeIngredients
        dietaryPreferences
        perMealNutritionConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        tags
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.UserProfileByUserIDQueryVariables,
  APITypes.UserProfileByUserIDQuery
>;
export const getObjectEditEvent = /* GraphQL */ `query GetObjectEditEvent($id: ID!) {
  getObjectEditEvent(id: $id) {
    id
    objectID
    objectType
    section
    itemID
    oldValue
    createdAt
    groups
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetObjectEditEventQueryVariables,
  APITypes.GetObjectEditEventQuery
>;
export const listObjectEditEvents = /* GraphQL */ `query ListObjectEditEvents(
  $filter: ModelObjectEditEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listObjectEditEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      objectID
      objectType
      section
      itemID
      oldValue
      createdAt
      groups
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListObjectEditEventsQueryVariables,
  APITypes.ListObjectEditEventsQuery
>;
export const objectEditEventByObjectID = /* GraphQL */ `query ObjectEditEventByObjectID(
  $objectID: ID!
  $itemIDSectionCreatedAt: ModelObjectEditEventByObjectIDCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelObjectEditEventFilterInput
  $limit: Int
  $nextToken: String
) {
  objectEditEventByObjectID(
    objectID: $objectID
    itemIDSectionCreatedAt: $itemIDSectionCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      objectID
      objectType
      section
      itemID
      oldValue
      createdAt
      groups
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ObjectEditEventByObjectIDQueryVariables,
  APITypes.ObjectEditEventByObjectIDQuery
>;
export const getMeal = /* GraphQL */ `query GetMeal($id: ID!) {
  getMeal(id: $id) {
    id
    schemaVersion
    smorgBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    addons {
      name
    }
    additionallyReferredToBy {
      sourceType
      sourceID
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<APITypes.GetMealQueryVariables, APITypes.GetMealQuery>;
export const listMeals = /* GraphQL */ `query ListMeals(
  $filter: ModelMealFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      smorgBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      addons {
        name
      }
      additionallyReferredToBy {
        sourceType
        sourceID
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMealsQueryVariables, APITypes.ListMealsQuery>;
export const mealBySmorgBoard = /* GraphQL */ `query MealBySmorgBoard(
  $smorgBoardID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMealFilterInput
  $limit: Int
  $nextToken: String
) {
  mealBySmorgBoard(
    smorgBoardID: $smorgBoardID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      smorgBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      addons {
        name
      }
      additionallyReferredToBy {
        sourceType
        sourceID
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MealBySmorgBoardQueryVariables,
  APITypes.MealBySmorgBoardQuery
>;
export const mealBySpaceMembership = /* GraphQL */ `query MealBySpaceMembership(
  $spaceMembershipID: ID!
  $smorgBoardID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMealFilterInput
  $limit: Int
  $nextToken: String
) {
  mealBySpaceMembership(
    spaceMembershipID: $spaceMembershipID
    smorgBoardID: $smorgBoardID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      smorgBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      addons {
        name
      }
      additionallyReferredToBy {
        sourceType
        sourceID
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MealBySpaceMembershipQueryVariables,
  APITypes.MealBySpaceMembershipQuery
>;
export const getSmorgBoard = /* GraphQL */ `query GetSmorgBoard($id: ID!) {
  getSmorgBoard(id: $id) {
    id
    schemaVersion
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      mealIDs
    }
    shareRecords {
      sharedBoardID
      version
      updatedOn
    }
    spaceMembershipID
    embeddedInContainerType
    embeddedInContainerID
    groups
    availableInMembershipTierIDs
    isMyFavouritesBoard
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetSmorgBoardQueryVariables,
  APITypes.GetSmorgBoardQuery
>;
export const listSmorgBoards = /* GraphQL */ `query ListSmorgBoards(
  $filter: ModelSmorgBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listSmorgBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        mealIDs
      }
      shareRecords {
        sharedBoardID
        version
        updatedOn
      }
      spaceMembershipID
      embeddedInContainerType
      embeddedInContainerID
      groups
      availableInMembershipTierIDs
      isMyFavouritesBoard
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSmorgBoardsQueryVariables,
  APITypes.ListSmorgBoardsQuery
>;
export const smorgBoardBySpaceMembership = /* GraphQL */ `query SmorgBoardBySpaceMembership(
  $spaceMembershipID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSmorgBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  smorgBoardBySpaceMembership(
    spaceMembershipID: $spaceMembershipID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        mealIDs
      }
      shareRecords {
        sharedBoardID
        version
        updatedOn
      }
      spaceMembershipID
      embeddedInContainerType
      embeddedInContainerID
      groups
      availableInMembershipTierIDs
      isMyFavouritesBoard
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SmorgBoardBySpaceMembershipQueryVariables,
  APITypes.SmorgBoardBySpaceMembershipQuery
>;
export const getSharedMeal = /* GraphQL */ `query GetSharedMeal($id: ID!) {
  getSharedMeal(id: $id) {
    id
    schemaVersion
    sharedBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSharedMealQueryVariables,
  APITypes.GetSharedMealQuery
>;
export const listSharedMeals = /* GraphQL */ `query ListSharedMeals(
  $filter: ModelSharedMealFilterInput
  $limit: Int
  $nextToken: String
) {
  listSharedMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      sharedBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSharedMealsQueryVariables,
  APITypes.ListSharedMealsQuery
>;
export const sharedMealBySharedBoard = /* GraphQL */ `query SharedMealBySharedBoard(
  $sharedBoardID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSharedMealFilterInput
  $limit: Int
  $nextToken: String
) {
  sharedMealBySharedBoard(
    sharedBoardID: $sharedBoardID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      sharedBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SharedMealBySharedBoardQueryVariables,
  APITypes.SharedMealBySharedBoardQuery
>;
export const getSharedBoard = /* GraphQL */ `query GetSharedBoard($id: ID!) {
  getSharedBoard(id: $id) {
    id
    schemaVersion
    version
    accessLevel
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      sharedMealIDs
    }
    availableInMembershipTierIDs
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSharedBoardQueryVariables,
  APITypes.GetSharedBoardQuery
>;
export const listSharedBoards = /* GraphQL */ `query ListSharedBoards(
  $filter: ModelSharedBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listSharedBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      version
      accessLevel
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        sharedMealIDs
      }
      availableInMembershipTierIDs
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSharedBoardsQueryVariables,
  APITypes.ListSharedBoardsQuery
>;
export const getNote = /* GraphQL */ `query GetNote($id: ID!) {
  getNote(id: $id) {
    id
    parentID
    title
    description
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const listNotes = /* GraphQL */ `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      parentID
      title
      description
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
export const noteByParent = /* GraphQL */ `query NoteByParent(
  $parentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  noteByParent(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parentID
      title
      description
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.NoteByParentQueryVariables,
  APITypes.NoteByParentQuery
>;
export const noteBySpaceMembership = /* GraphQL */ `query NoteBySpaceMembership(
  $spaceMembershipID: ID!
  $parentID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  noteBySpaceMembership(
    spaceMembershipID: $spaceMembershipID
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parentID
      title
      description
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.NoteBySpaceMembershipQueryVariables,
  APITypes.NoteBySpaceMembershipQuery
>;
export const getContentEntry = /* GraphQL */ `query GetContentEntry($id: ID!) {
  getContentEntry(id: $id) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetContentEntryQueryVariables,
  APITypes.GetContentEntryQuery
>;
export const listContentEntries = /* GraphQL */ `query ListContentEntries(
  $filter: ModelContentEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  listContentEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      parentID
      title
      body
      coverImageUrl
      isCoverImageShownInDetailView
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListContentEntriesQueryVariables,
  APITypes.ListContentEntriesQuery
>;
export const contentEntryByParent = /* GraphQL */ `query ContentEntryByParent(
  $parentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContentEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  contentEntryByParent(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parentID
      title
      body
      coverImageUrl
      isCoverImageShownInDetailView
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ContentEntryByParentQueryVariables,
  APITypes.ContentEntryByParentQuery
>;
export const getSharedContentEntry = /* GraphQL */ `query GetSharedContentEntry($id: ID!) {
  getSharedContentEntry(id: $id) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSharedContentEntryQueryVariables,
  APITypes.GetSharedContentEntryQuery
>;
export const listSharedContentEntries = /* GraphQL */ `query ListSharedContentEntries(
  $filter: ModelSharedContentEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSharedContentEntries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parentID
      title
      body
      coverImageUrl
      isCoverImageShownInDetailView
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSharedContentEntriesQueryVariables,
  APITypes.ListSharedContentEntriesQuery
>;
export const sharedContentEntryByParent = /* GraphQL */ `query SharedContentEntryByParent(
  $parentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSharedContentEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  sharedContentEntryByParent(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parentID
      title
      body
      coverImageUrl
      isCoverImageShownInDetailView
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SharedContentEntryByParentQueryVariables,
  APITypes.SharedContentEntryByParentQuery
>;
export const getPlannerWeek = /* GraphQL */ `query GetPlannerWeek($id: ID!) {
  getPlannerWeek(id: $id) {
    id
    schemaVersion
    spaceMembershipID
    startDate
    days {
      entries {
        plannerEntryType
        mealID
        objectID
      }
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetPlannerWeekQueryVariables,
  APITypes.GetPlannerWeekQuery
>;
export const listPlannerWeeks = /* GraphQL */ `query ListPlannerWeeks(
  $filter: ModelPlannerWeekFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlannerWeeks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      spaceMembershipID
      startDate
      days {
        entries {
          plannerEntryType
          mealID
          objectID
        }
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListPlannerWeeksQueryVariables,
  APITypes.ListPlannerWeeksQuery
>;
export const plannerWeekBySpaceMembership = /* GraphQL */ `query PlannerWeekBySpaceMembership(
  $spaceMembershipID: ID!
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlannerWeekFilterInput
  $limit: Int
  $nextToken: String
) {
  plannerWeekBySpaceMembership(
    spaceMembershipID: $spaceMembershipID
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      spaceMembershipID
      startDate
      days {
        entries {
          plannerEntryType
          mealID
          objectID
        }
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PlannerWeekBySpaceMembershipQueryVariables,
  APITypes.PlannerWeekBySpaceMembershipQuery
>;
export const plannerWeekByStartDate = /* GraphQL */ `query PlannerWeekByStartDate(
  $startDate: String!
  $sortDirection: ModelSortDirection
  $filter: ModelPlannerWeekFilterInput
  $limit: Int
  $nextToken: String
) {
  plannerWeekByStartDate(
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      spaceMembershipID
      startDate
      days {
        entries {
          plannerEntryType
          mealID
          objectID
        }
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PlannerWeekByStartDateQueryVariables,
  APITypes.PlannerWeekByStartDateQuery
>;
export const getShoppingList = /* GraphQL */ `query GetShoppingList($id: ID!) {
  getShoppingList(id: $id) {
    id
    schemaVersion
    spaceMembershipID
    productsBoardID
    title
    shortDescription
    items {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    mealIDs
    mealImageUrls
    meals {
      mealID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
    }
    activityRecords {
      activityType
      occurredOn
      vendorName
      transactionID
      shoppingListItemIDs
    }
    userItems {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetShoppingListQueryVariables,
  APITypes.GetShoppingListQuery
>;
export const listShoppingLists = /* GraphQL */ `query ListShoppingLists(
  $filter: ModelShoppingListFilterInput
  $limit: Int
  $nextToken: String
) {
  listShoppingLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      spaceMembershipID
      productsBoardID
      title
      shortDescription
      items {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      mealIDs
      mealImageUrls
      meals {
        mealID
        recipes {
          title
          shortDescription
          imageUrl
          recipeUrl
          publisher
          chef
          mealiqId
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          mealTypes
          tags
          supportedDiets
          ingredients {
            id
            fullText
            structuredIngredient {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            tokFullText
            tokens {
              fromChar
              toChar
              type
              label
            }
            scalingRules
            checkedInIngredientsList
          }
          preparations {
            id
            fullText
          }
          utensils {
            id
            fullText
          }
          servings
          cookTime
          prepTime
          totalTime
          recipeNotes
        }
      }
      activityRecords {
        activityType
        occurredOn
        vendorName
        transactionID
        shoppingListItemIDs
      }
      userItems {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListShoppingListsQueryVariables,
  APITypes.ListShoppingListsQuery
>;
export const shoppingListBySpaceMembership = /* GraphQL */ `query ShoppingListBySpaceMembership(
  $spaceMembershipID: ID!
  $productsBoardID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelShoppingListFilterInput
  $limit: Int
  $nextToken: String
) {
  shoppingListBySpaceMembership(
    spaceMembershipID: $spaceMembershipID
    productsBoardID: $productsBoardID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      spaceMembershipID
      productsBoardID
      title
      shortDescription
      items {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      mealIDs
      mealImageUrls
      meals {
        mealID
        recipes {
          title
          shortDescription
          imageUrl
          recipeUrl
          publisher
          chef
          mealiqId
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          mealTypes
          tags
          supportedDiets
          ingredients {
            id
            fullText
            structuredIngredient {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            tokFullText
            tokens {
              fromChar
              toChar
              type
              label
            }
            scalingRules
            checkedInIngredientsList
          }
          preparations {
            id
            fullText
          }
          utensils {
            id
            fullText
          }
          servings
          cookTime
          prepTime
          totalTime
          recipeNotes
        }
      }
      activityRecords {
        activityType
        occurredOn
        vendorName
        transactionID
        shoppingListItemIDs
      }
      userItems {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ShoppingListBySpaceMembershipQueryVariables,
  APITypes.ShoppingListBySpaceMembershipQuery
>;
export const shoppingListByProductsBoard = /* GraphQL */ `query ShoppingListByProductsBoard(
  $productsBoardID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelShoppingListFilterInput
  $limit: Int
  $nextToken: String
) {
  shoppingListByProductsBoard(
    productsBoardID: $productsBoardID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      spaceMembershipID
      productsBoardID
      title
      shortDescription
      items {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      mealIDs
      mealImageUrls
      meals {
        mealID
        recipes {
          title
          shortDescription
          imageUrl
          recipeUrl
          publisher
          chef
          mealiqId
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          mealTypes
          tags
          supportedDiets
          ingredients {
            id
            fullText
            structuredIngredient {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            tokFullText
            tokens {
              fromChar
              toChar
              type
              label
            }
            scalingRules
            checkedInIngredientsList
          }
          preparations {
            id
            fullText
          }
          utensils {
            id
            fullText
          }
          servings
          cookTime
          prepTime
          totalTime
          recipeNotes
        }
      }
      activityRecords {
        activityType
        occurredOn
        vendorName
        transactionID
        shoppingListItemIDs
      }
      userItems {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ShoppingListByProductsBoardQueryVariables,
  APITypes.ShoppingListByProductsBoardQuery
>;
export const getProductsBoard = /* GraphQL */ `query GetProductsBoard($id: ID!) {
  getProductsBoard(id: $id) {
    id
    schemaVersion
    title
    spaceMembershipID
    shoppingListGroups {
      id
      title
      shoppingListIDs
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetProductsBoardQueryVariables,
  APITypes.GetProductsBoardQuery
>;
export const listProductsBoards = /* GraphQL */ `query ListProductsBoards(
  $filter: ModelProductsBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductsBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schemaVersion
      title
      spaceMembershipID
      shoppingListGroups {
        id
        title
        shoppingListIDs
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProductsBoardsQueryVariables,
  APITypes.ListProductsBoardsQuery
>;
export const productsBoardBySpaceMembership = /* GraphQL */ `query ProductsBoardBySpaceMembership(
  $spaceMembershipID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductsBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  productsBoardBySpaceMembership(
    spaceMembershipID: $spaceMembershipID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schemaVersion
      title
      spaceMembershipID
      shoppingListGroups {
        id
        title
        shoppingListIDs
      }
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProductsBoardBySpaceMembershipQueryVariables,
  APITypes.ProductsBoardBySpaceMembershipQuery
>;
export const getGlobalTag = /* GraphQL */ `query GetGlobalTag($id: ID!, $tag: String!) {
  getGlobalTag(id: $id, tag: $tag) {
    id
    tag
    count
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetGlobalTagQueryVariables,
  APITypes.GetGlobalTagQuery
>;
export const listGlobalTags = /* GraphQL */ `query ListGlobalTags(
  $id: ID
  $tag: ModelStringKeyConditionInput
  $filter: ModelGlobalTagFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGlobalTags(
    id: $id
    tag: $tag
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tag
      count
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListGlobalTagsQueryVariables,
  APITypes.ListGlobalTagsQuery
>;
export const getIngredientEditEvent = /* GraphQL */ `query GetIngredientEditEvent($id: ID!) {
  getIngredientEditEvent(id: $id) {
    mealID
    recipeID
    editMethod
    ingredientID
    fullTextBefore
    fullTextAfter
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetIngredientEditEventQueryVariables,
  APITypes.GetIngredientEditEventQuery
>;
export const listIngredientEditEvents = /* GraphQL */ `query ListIngredientEditEvents(
  $filter: ModelIngredientEditEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listIngredientEditEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      mealID
      recipeID
      editMethod
      ingredientID
      fullTextBefore
      fullTextAfter
      groups
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListIngredientEditEventsQueryVariables,
  APITypes.ListIngredientEditEventsQuery
>;
export const getIngredientEditSuggestion = /* GraphQL */ `query GetIngredientEditSuggestion($id: ID!, $inputFullText: String!) {
  getIngredientEditSuggestion(id: $id, inputFullText: $inputFullText) {
    id
    inputFullText
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetIngredientEditSuggestionQueryVariables,
  APITypes.GetIngredientEditSuggestionQuery
>;
export const listIngredientEditSuggestions = /* GraphQL */ `query ListIngredientEditSuggestions(
  $id: ID
  $inputFullText: ModelStringKeyConditionInput
  $filter: ModelIngredientEditSuggestionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIngredientEditSuggestions(
    id: $id
    inputFullText: $inputFullText
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      inputFullText
      suggestion
      weight
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListIngredientEditSuggestionsQueryVariables,
  APITypes.ListIngredientEditSuggestionsQuery
>;
export const ingredientEditSuggestionByInputFullText = /* GraphQL */ `query IngredientEditSuggestionByInputFullText(
  $inputFullText: String!
  $weight: ModelFloatKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIngredientEditSuggestionFilterInput
  $limit: Int
  $nextToken: String
) {
  ingredientEditSuggestionByInputFullText(
    inputFullText: $inputFullText
    weight: $weight
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      inputFullText
      suggestion
      weight
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IngredientEditSuggestionByInputFullTextQueryVariables,
  APITypes.IngredientEditSuggestionByInputFullTextQuery
>;
export const getIngredientEditSuggestionByIngredientID = /* GraphQL */ `query GetIngredientEditSuggestionByIngredientID(
  $id: ID!
  $ingredientID: String!
) {
  getIngredientEditSuggestionByIngredientID(
    id: $id
    ingredientID: $ingredientID
  ) {
    id
    ingredientID
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetIngredientEditSuggestionByIngredientIDQueryVariables,
  APITypes.GetIngredientEditSuggestionByIngredientIDQuery
>;
export const listIngredientEditSuggestionByIngredientIDS = /* GraphQL */ `query ListIngredientEditSuggestionByIngredientIDS(
  $id: ID
  $ingredientID: ModelStringKeyConditionInput
  $filter: ModelIngredientEditSuggestionByIngredientIDFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIngredientEditSuggestionByIngredientIDS(
    id: $id
    ingredientID: $ingredientID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      ingredientID
      suggestion
      weight
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListIngredientEditSuggestionByIngredientIDSQueryVariables,
  APITypes.ListIngredientEditSuggestionByIngredientIDSQuery
>;
export const ingredientEditSuggestionByIngredientID = /* GraphQL */ `query IngredientEditSuggestionByIngredientID(
  $ingredientID: String!
  $weight: ModelFloatKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIngredientEditSuggestionByIngredientIDFilterInput
  $limit: Int
  $nextToken: String
) {
  ingredientEditSuggestionByIngredientID(
    ingredientID: $ingredientID
    weight: $weight
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ingredientID
      suggestion
      weight
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IngredientEditSuggestionByIngredientIDQueryVariables,
  APITypes.IngredientEditSuggestionByIngredientIDQuery
>;
export const getProgramme = /* GraphQL */ `query GetProgramme($id: ID!) {
  getProgramme(id: $id) {
    id
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    recipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    shareRecords {
      sharedProgrammeID
      version
      updatedOn
    }
    availableInMembershipTierIDs
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetProgrammeQueryVariables,
  APITypes.GetProgrammeQuery
>;
export const listProgrammes = /* GraphQL */ `query ListProgrammes(
  $filter: ModelProgrammeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProgrammes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      copyMealsExactly
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      recipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      shareRecords {
        sharedProgrammeID
        version
        updatedOn
      }
      availableInMembershipTierIDs
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProgrammesQueryVariables,
  APITypes.ListProgrammesQuery
>;
export const getSharedProgramme = /* GraphQL */ `query GetSharedProgramme($id: ID!) {
  getSharedProgramme(id: $id) {
    spaceID
    programmeID
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    sharedRecipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    version
    availableInMembershipTierIDs
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetSharedProgrammeQueryVariables,
  APITypes.GetSharedProgrammeQuery
>;
export const listSharedProgrammes = /* GraphQL */ `query ListSharedProgrammes(
  $filter: ModelSharedProgrammeFilterInput
  $limit: Int
  $nextToken: String
) {
  listSharedProgrammes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      spaceID
      programmeID
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      copyMealsExactly
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      sharedRecipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      version
      availableInMembershipTierIDs
      groups
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSharedProgrammesQueryVariables,
  APITypes.ListSharedProgrammesQuery
>;
export const sharedProgrammeBySpace = /* GraphQL */ `query SharedProgrammeBySpace(
  $spaceID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSharedProgrammeFilterInput
  $limit: Int
  $nextToken: String
) {
  sharedProgrammeBySpace(
    spaceID: $spaceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      programmeID
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      copyMealsExactly
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      sharedRecipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      version
      availableInMembershipTierIDs
      groups
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SharedProgrammeBySpaceQueryVariables,
  APITypes.SharedProgrammeBySpaceQuery
>;
export const getUserProgrammeEnrollment = /* GraphQL */ `query GetUserProgrammeEnrollment($id: ID!) {
  getUserProgrammeEnrollment(id: $id) {
    spaceMembershipID
    sharedProgrammeID
    enrolledAt
    onboardingAnswers {
      questionID
      answer
    }
    endedAt
    eventRecords {
      calendarDay
      eventType
      updatedOn
    }
    customTargetCalories
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetUserProgrammeEnrollmentQueryVariables,
  APITypes.GetUserProgrammeEnrollmentQuery
>;
export const listUserProgrammeEnrollments = /* GraphQL */ `query ListUserProgrammeEnrollments(
  $filter: ModelUserProgrammeEnrollmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProgrammeEnrollments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceMembershipID
      sharedProgrammeID
      enrolledAt
      onboardingAnswers {
        questionID
        answer
      }
      endedAt
      eventRecords {
        calendarDay
        eventType
        updatedOn
      }
      customTargetCalories
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserProgrammeEnrollmentsQueryVariables,
  APITypes.ListUserProgrammeEnrollmentsQuery
>;
export const getGRCRecipe = /* GraphQL */ `query GetGRCRecipe($grcRecipeID: ID!) {
  getGRCRecipe(grcRecipeID: $grcRecipeID) {
    grcRecipeID
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetGRCRecipeQueryVariables,
  APITypes.GetGRCRecipeQuery
>;
export const listGRCRecipes = /* GraphQL */ `query ListGRCRecipes(
  $grcRecipeID: ID
  $filter: ModelGRCRecipeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGRCRecipes(
    grcRecipeID: $grcRecipeID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      grcRecipeID
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListGRCRecipesQueryVariables,
  APITypes.ListGRCRecipesQuery
>;
export const getSharedContentBoard = /* GraphQL */ `query GetSharedContentBoard($id: ID!) {
  getSharedContentBoard(id: $id) {
    id
    title
    lanes {
      id
      title
      entries {
        id
        entryType
        objectID
      }
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetSharedContentBoardQueryVariables,
  APITypes.GetSharedContentBoardQuery
>;
export const listSharedContentBoards = /* GraphQL */ `query ListSharedContentBoards(
  $filter: ModelSharedContentBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listSharedContentBoards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      lanes {
        id
        title
        entries {
          id
          entryType
          objectID
        }
      }
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSharedContentBoardsQueryVariables,
  APITypes.ListSharedContentBoardsQuery
>;
export const getSpaceIAPSubscription = /* GraphQL */ `query GetSpaceIAPSubscription($id: ID!) {
  getSpaceIAPSubscription(id: $id) {
    spaceID
    productID
    title
    shortDescription
    currencyCode
    priceStr
    bundleID
    duration
    signUpButtonLabelOverride
    appStoreID
    rank
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetSpaceIAPSubscriptionQueryVariables,
  APITypes.GetSpaceIAPSubscriptionQuery
>;
export const listSpaceIAPSubscriptions = /* GraphQL */ `query ListSpaceIAPSubscriptions(
  $filter: ModelSpaceIAPSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpaceIAPSubscriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      productID
      title
      shortDescription
      currencyCode
      priceStr
      bundleID
      duration
      signUpButtonLabelOverride
      appStoreID
      rank
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSpaceIAPSubscriptionsQueryVariables,
  APITypes.ListSpaceIAPSubscriptionsQuery
>;
export const getSpace = /* GraphQL */ `query GetSpace($id: ID!) {
  getSpace(id: $id) {
    title
    shortDescription
    whiteLabelElements {
      primaryMainColor
      backgroundStyle
      backgroundColor
      backgroundGradientColor
      cardBackgroundColor
      iconUrl
      splashImageUrl
      myDayBackgroundImageUrl
      desktopBannerImageUrl
      termsUrl
      privacyPolicyUrl
      primaryFontColor
      navBarFontColor
    }
    adminUserIDs
    landingPageContent
    landingPageStyle {
      backgroundColor
    }
    landingPageCoverImageUrl
    isDefaultSpace
    sharedProgrammeConfiguration {
      sharedProgrammeCategories {
        categoryTag
        orderedProgrammeIDs
      }
    }
    productPreviewSections {
      title
      productPreviews {
        productType
        productObjectID
        title
        shortDescription
        coverImageUrl
        availableInMembershipTierIDs
      }
    }
    videoPlaylists {
      title
      playlistID
      items {
        videoID
        title
        description
        thumbnailImageUrl
      }
    }
    spaceContentBoard {
      id
      title
      lanes {
        id
        title
        entries {
          id
          entryType
          objectID
        }
      }
    }
    spaceSharedContentBoardIDs
    welcomeVideo {
      videoID
      title
      description
      thumbnailImageUrl
    }
    myDayScreenSections {
      publishedSectionType
      spaceContentLaneID
    }
    subscriptionBenefitsContent
    subscriptionPageStyle {
      backgroundColor
    }
    groups
    sharedRecipesBoardIDs
    defaultSharedProgrammeIDForNewUsers
    appStoreLinks {
      appStoreID
      appUrl
    }
    chatConfig {
      appID
      appRegion
    }
    publishedSearchTags {
      id
      tag
    }
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<APITypes.GetSpaceQueryVariables, APITypes.GetSpaceQuery>;
export const listSpaces = /* GraphQL */ `query ListSpaces(
  $filter: ModelSpaceFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      title
      shortDescription
      whiteLabelElements {
        primaryMainColor
        backgroundStyle
        backgroundColor
        backgroundGradientColor
        cardBackgroundColor
        iconUrl
        splashImageUrl
        myDayBackgroundImageUrl
        desktopBannerImageUrl
        termsUrl
        privacyPolicyUrl
        primaryFontColor
        navBarFontColor
      }
      adminUserIDs
      landingPageContent
      landingPageStyle {
        backgroundColor
      }
      landingPageCoverImageUrl
      isDefaultSpace
      sharedProgrammeConfiguration {
        sharedProgrammeCategories {
          categoryTag
          orderedProgrammeIDs
        }
      }
      productPreviewSections {
        title
        productPreviews {
          productType
          productObjectID
          title
          shortDescription
          coverImageUrl
          availableInMembershipTierIDs
        }
      }
      videoPlaylists {
        title
        playlistID
        items {
          videoID
          title
          description
          thumbnailImageUrl
        }
      }
      spaceContentBoard {
        id
        title
        lanes {
          id
          title
          entries {
            id
            entryType
            objectID
          }
        }
      }
      spaceSharedContentBoardIDs
      welcomeVideo {
        videoID
        title
        description
        thumbnailImageUrl
      }
      myDayScreenSections {
        publishedSectionType
        spaceContentLaneID
      }
      subscriptionBenefitsContent
      subscriptionPageStyle {
        backgroundColor
      }
      groups
      sharedRecipesBoardIDs
      defaultSharedProgrammeIDForNewUsers
      appStoreLinks {
        appStoreID
        appUrl
      }
      chatConfig {
        appID
        appRegion
      }
      publishedSearchTags {
        id
        tag
      }
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSpacesQueryVariables,
  APITypes.ListSpacesQuery
>;
export const getSpaceMembership = /* GraphQL */ `query GetSpaceMembership($id: ID!) {
  getSpaceMembership(id: $id) {
    spaceID
    memberID
    state
    correlatedUserID
    expiresAt
    visibleProgrammeIDsOverride
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
    groups
    createdAt
    id
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSpaceMembershipQueryVariables,
  APITypes.GetSpaceMembershipQuery
>;
export const listSpaceMemberships = /* GraphQL */ `query ListSpaceMemberships(
  $filter: ModelSpaceMembershipFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpaceMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSpaceMembershipsQueryVariables,
  APITypes.ListSpaceMembershipsQuery
>;
export const spaceMembershipBySpaceID = /* GraphQL */ `query SpaceMembershipBySpaceID(
  $spaceID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSpaceMembershipFilterInput
  $limit: Int
  $nextToken: String
) {
  spaceMembershipBySpaceID(
    spaceID: $spaceID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SpaceMembershipBySpaceIDQueryVariables,
  APITypes.SpaceMembershipBySpaceIDQuery
>;
export const spaceMembershipByMemberID = /* GraphQL */ `query SpaceMembershipByMemberID(
  $memberID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSpaceMembershipFilterInput
  $limit: Int
  $nextToken: String
) {
  spaceMembershipByMemberID(
    memberID: $memberID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SpaceMembershipByMemberIDQueryVariables,
  APITypes.SpaceMembershipByMemberIDQuery
>;
export const spaceMembershipByStripeCustomerID = /* GraphQL */ `query SpaceMembershipByStripeCustomerID(
  $stripeCustomerID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSpaceMembershipFilterInput
  $limit: Int
  $nextToken: String
) {
  spaceMembershipByStripeCustomerID(
    stripeCustomerID: $stripeCustomerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SpaceMembershipByStripeCustomerIDQueryVariables,
  APITypes.SpaceMembershipByStripeCustomerIDQuery
>;
export const spaceMembershipByStripeSubscriptionID = /* GraphQL */ `query SpaceMembershipByStripeSubscriptionID(
  $stripeSubscriptionID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSpaceMembershipFilterInput
  $limit: Int
  $nextToken: String
) {
  spaceMembershipByStripeSubscriptionID(
    stripeSubscriptionID: $stripeSubscriptionID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SpaceMembershipByStripeSubscriptionIDQueryVariables,
  APITypes.SpaceMembershipByStripeSubscriptionIDQuery
>;
export const getSpaceMembershipEvent = /* GraphQL */ `query GetSpaceMembershipEvent($id: ID!) {
  getSpaceMembershipEvent(id: $id) {
    spaceMembershipID
    memberID
    eventType
    transactionSource
    transactionID
    details
    createdAt
    id
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSpaceMembershipEventQueryVariables,
  APITypes.GetSpaceMembershipEventQuery
>;
export const listSpaceMembershipEvents = /* GraphQL */ `query ListSpaceMembershipEvents(
  $filter: ModelSpaceMembershipEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpaceMembershipEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceMembershipID
      memberID
      eventType
      transactionSource
      transactionID
      details
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSpaceMembershipEventsQueryVariables,
  APITypes.ListSpaceMembershipEventsQuery
>;
export const spaceMembershipEventBySpaceMembershipID = /* GraphQL */ `query SpaceMembershipEventBySpaceMembershipID(
  $spaceMembershipID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSpaceMembershipEventFilterInput
  $limit: Int
  $nextToken: String
) {
  spaceMembershipEventBySpaceMembershipID(
    spaceMembershipID: $spaceMembershipID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceMembershipID
      memberID
      eventType
      transactionSource
      transactionID
      details
      createdAt
      id
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SpaceMembershipEventBySpaceMembershipIDQueryVariables,
  APITypes.SpaceMembershipEventBySpaceMembershipIDQuery
>;
export const getUserMyDayActionRecord = /* GraphQL */ `query GetUserMyDayActionRecord($id: ID!) {
  getUserMyDayActionRecord(id: $id) {
    userProgrammeEnrollmentID
    programmeEntryType
    objectID
    parentID
    calendarDay
    actionType
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetUserMyDayActionRecordQueryVariables,
  APITypes.GetUserMyDayActionRecordQuery
>;
export const listUserMyDayActionRecords = /* GraphQL */ `query ListUserMyDayActionRecords(
  $filter: ModelUserMyDayActionRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserMyDayActionRecords(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userProgrammeEnrollmentID
      programmeEntryType
      objectID
      parentID
      calendarDay
      actionType
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserMyDayActionRecordsQueryVariables,
  APITypes.ListUserMyDayActionRecordsQuery
>;
export const userMyDayActionRecordByUserProgrammeEnrollment = /* GraphQL */ `query UserMyDayActionRecordByUserProgrammeEnrollment(
  $userProgrammeEnrollmentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserMyDayActionRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  userMyDayActionRecordByUserProgrammeEnrollment(
    userProgrammeEnrollmentID: $userProgrammeEnrollmentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userProgrammeEnrollmentID
      programmeEntryType
      objectID
      parentID
      calendarDay
      actionType
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.UserMyDayActionRecordByUserProgrammeEnrollmentQueryVariables,
  APITypes.UserMyDayActionRecordByUserProgrammeEnrollmentQuery
>;
export const getMembershipTier = /* GraphQL */ `query GetMembershipTier($id: ID!) {
  getMembershipTier(id: $id) {
    spaceID
    title
    shortDescription
    monthlyPrice
    quarterlyPrice
    annualPrice
    freeTrialDurationDays
    rank
    currencyCode
    state
    stripeProductID
    stripePriceID
    stripePriceIDQuarterly
    stripePriceIDAnnual
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetMembershipTierQueryVariables,
  APITypes.GetMembershipTierQuery
>;
export const listMembershipTiers = /* GraphQL */ `query ListMembershipTiers(
  $filter: ModelMembershipTierFilterInput
  $limit: Int
  $nextToken: String
) {
  listMembershipTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      spaceID
      title
      shortDescription
      monthlyPrice
      quarterlyPrice
      annualPrice
      freeTrialDurationDays
      rank
      currencyCode
      state
      stripeProductID
      stripePriceID
      stripePriceIDQuarterly
      stripePriceIDAnnual
      groups
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMembershipTiersQueryVariables,
  APITypes.ListMembershipTiersQuery
>;
export const membershipTierBySpace = /* GraphQL */ `query MembershipTierBySpace(
  $spaceID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMembershipTierFilterInput
  $limit: Int
  $nextToken: String
) {
  membershipTierBySpace(
    spaceID: $spaceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      spaceID
      title
      shortDescription
      monthlyPrice
      quarterlyPrice
      annualPrice
      freeTrialDurationDays
      rank
      currencyCode
      state
      stripeProductID
      stripePriceID
      stripePriceIDQuarterly
      stripePriceIDAnnual
      groups
      id
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MembershipTierBySpaceQueryVariables,
  APITypes.MembershipTierBySpaceQuery
>;
export const getSpacePrivateConfig = /* GraphQL */ `query GetSpacePrivateConfig($id: ID!) {
  getSpacePrivateConfig(id: $id) {
    id
    spaceID
    stripeConfig {
      environment
      connectedAccountID
      chargesEnabled
      stripeProductID
      billingPortalConfigurationID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetSpacePrivateConfigQueryVariables,
  APITypes.GetSpacePrivateConfigQuery
>;
export const listSpacePrivateConfigs = /* GraphQL */ `query ListSpacePrivateConfigs(
  $filter: ModelSpacePrivateConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpacePrivateConfigs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      spaceID
      stripeConfig {
        environment
        connectedAccountID
        chargesEnabled
        stripeProductID
        billingPortalConfigurationID
      }
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSpacePrivateConfigsQueryVariables,
  APITypes.ListSpacePrivateConfigsQuery
>;
export const spacePrivateConfigBySpaceID = /* GraphQL */ `query SpacePrivateConfigBySpaceID(
  $spaceID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSpacePrivateConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  spacePrivateConfigBySpaceID(
    spaceID: $spaceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      spaceID
      stripeConfig {
        environment
        connectedAccountID
        chargesEnabled
        stripeProductID
        billingPortalConfigurationID
      }
      groups
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SpacePrivateConfigBySpaceIDQueryVariables,
  APITypes.SpacePrivateConfigBySpaceIDQuery
>;
export const getAccountActivitySignal = /* GraphQL */ `query GetAccountActivitySignal($id: ID!) {
  getAccountActivitySignal(id: $id) {
    id
    spaceID
    groups
    lastActivityAt
    friendlyUsername
    lastActivityObjectType
    lastActivityObjectID
    sourceIp
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedQuery<
  APITypes.GetAccountActivitySignalQueryVariables,
  APITypes.GetAccountActivitySignalQuery
>;
export const listAccountActivitySignals = /* GraphQL */ `query ListAccountActivitySignals(
  $filter: ModelAccountActivitySignalFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountActivitySignals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      spaceID
      groups
      lastActivityAt
      friendlyUsername
      lastActivityObjectType
      lastActivityObjectID
      sourceIp
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountActivitySignalsQueryVariables,
  APITypes.ListAccountActivitySignalsQuery
>;
export const accountActivitySignalBySpaceID = /* GraphQL */ `query AccountActivitySignalBySpaceID(
  $spaceID: ID!
  $lastActivityAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAccountActivitySignalFilterInput
  $limit: Int
  $nextToken: String
) {
  accountActivitySignalBySpaceID(
    spaceID: $spaceID
    lastActivityAt: $lastActivityAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      spaceID
      groups
      lastActivityAt
      friendlyUsername
      lastActivityObjectType
      lastActivityObjectID
      sourceIp
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountActivitySignalBySpaceIDQueryVariables,
  APITypes.AccountActivitySignalBySpaceIDQuery
>;
