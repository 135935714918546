import React, { useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { formatRoute } from 'react-router-named-routes';
import SearchIcon from '@mui/icons-material/Search';
import { myFavouritesRecipesBoardSelector } from '../../reducers/recipes_reducer';
import {
  END_USER_FIND_ALTERNATIVES_BOARD_ROUTE,
  END_USER_FIND_ALTERNATIVES_GRC_RECIPE_CATEGORY_ROUTE,
  END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE,
  END_USER_FIND_ALTERNATIVES_MULTI_MENUS_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_ROUTE,
  END_USER_FIND_ALTERNATIVES_WITH_MEAL_DETAIL_ROUTE,
} from '../../services/routes';
import TopNavBar from '../user/top_nav_bar';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import { ensureSharedProgrammeLatestVersionLoadedAction } from '../../action_creators/programmes_action_creators';
import MealBasketWidget from '../meal/meal_basket_widget';
import PopupTitle from '../common/popup_title';
import MealDetailModal from '../meal/meal_detail_modal';
import EndUserFindAlternativesItem from './end_user_find_alternatives_item';
import { linkWithQueryParams } from '../../services/urls';
import { useAllMultiMenuMeals } from '../common/multi_menu_meals_hook';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';
import EndUserFindAlternativesGRCRecipeItem from './end_user_find_alternatives_grc_recipe_item';
import { ensureCategoryGRCRecipesLoadedAction } from '../../action_creators/recipes_action_creators';

const CAROUSEL_LIMIT = 10;

const showNutritionMetrics = [
  'calories',
  'fat',
  'carbohydrate',
  'protein',
  'fibre',
];

const EndUserFindAlternativesHomePage = () => {
  const navigate = useNavigate();

  const { calendarDay, mealDetailMealID: showDetailForMealID } = useParams();

  const [searchParams] = useSearchParams();

  const mealIDToReplace = searchParams.get('mealId');
  const mealType = searchParams.get('mealType');
  const scrollCookie = searchParams.get('scrollCookie');

  const dispatch = useDispatch();

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment?.sharedProgrammeID &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const sharedProgrammeID = sharedProgramme?.id;

  const myFavouritesRecipesBoard = useSelector(
    myFavouritesRecipesBoardSelector,
  );

  const flattenedFavouriteMealIDs = (
    myFavouritesRecipesBoard?.menus || []
  ).flatMap((menu) =>
    (menu.mealIDs || menu.sharedMealIDs).map((mealID) => ({
      mealID,
      menuID: menu.id,
    })),
  );

  useEffect(() => {
    if (sharedProgrammeID) {
      dispatch(
        ensureSharedProgrammeLatestVersionLoadedAction(sharedProgrammeID),
      );
    }
  }, [dispatch, sharedProgrammeID]);

  const { flattenedSharedBoardsTitleMap } = useAllMultiMenuMeals();

  const onClickResultDetail = (mealID) => {
    navigate(
      linkWithQueryParams(
        formatRoute(END_USER_FIND_ALTERNATIVES_WITH_MEAL_DETAIL_ROUTE, {
          calendarDay,
          mealDetailMealID: mealID,
        }),
        { mealType, mealId: mealIDToReplace },
      ),
    );
  };

  const onClickGRCResultDetail = (grcRecipeID) => {
    navigate(
      linkWithQueryParams(
        formatRoute(END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE, {
          calendarDay,
          grcRecipeId: grcRecipeID,
        }),
        { mealType, mealId: mealIDToReplace },
      ),
    );
  };

  const onDismissDetail = () => {
    navigate(-1);
  };

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const alwaysShowNutrition = !isCommunityUser;

  const grcRecipeCategories = useSelector(
    (state) => state.grcRecipeCategories || [],
  );

  useEffect(() => {
    dispatch(ensureCategoryGRCRecipesLoadedAction());
  }, [dispatch]);

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }
  return (
    <div className="recipes-search">
      <div style={{ color: 'var(--app-primary-font-color)' }}>
        <PopupTitle
          titleText="Search for a meal"
          backButtonEnabled
          onClickBack={() => navigate(-1)}
        />
      </div>
      <MealBasketWidget />
      <div
        style={{
          marginTop: '20px',
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            onClick={() =>
              navigate(
                linkWithQueryParams(
                  formatRoute(END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_ROUTE, {
                    calendarDay,
                  }),
                  { mealType, mealId: mealIDToReplace, scrollCookie },
                ),
              )
            }
            style={{
              padding: '12px',
              paddingLeft: '12px !important',
              textAlign: 'left',
              fontSize: '14px',
              color: 'var(--app-primary-font-color)',
              opacity: 0.5,
              border: '1px solid',
              borderRadius: '8px',
              margin: '8px 16px',
              cursor: 'pointer',
              display: 'flex',
            }}>
            <SearchIcon style={{ margin: 'auto 0' }} />
            <span style={{ paddingTop: '2px' }}>Search recipes</span>
          </Grid>
        </Grid>
        {myFavouritesRecipesBoard && flattenedFavouriteMealIDs.length > 0 && (
          <>
            <Grid container>
              <Grid item xs={8}>
                <h2
                  style={{
                    margin: '23px 23px 5px',
                    color: 'var(--app-primary-font-color)',
                    textAlign: 'left',
                  }}>
                  {myFavouritesRecipesBoard.title}
                </h2>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Box
                  sx={{
                    margin: 'auto 10px 7px',
                    color: 'var(--app-primary-font-color)',
                  }}>
                  <Link
                    to={linkWithQueryParams(
                      formatRoute(END_USER_FIND_ALTERNATIVES_BOARD_ROUTE, {
                        calendarDay,
                        recipesBoardId: myFavouritesRecipesBoard.id,
                      }),
                      { mealType, mealId: mealIDToReplace },
                    )}>
                    View all
                  </Link>
                </Box>
              </Grid>
            </Grid>

            <div style={{ overflowX: 'auto', display: 'flex' }}>
              {flattenedFavouriteMealIDs
                .slice(0, CAROUSEL_LIMIT)
                .map(({ mealID }) => (
                  <div key={mealID} style={{ flexShrink: 0 }}>
                    <EndUserFindAlternativesItem
                      mealID={mealID}
                      calendarDay={calendarDay}
                      mealType={mealType}
                      mealIDToReplace={mealIDToReplace}
                      onClick={onClickResultDetail}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        {Object.values(flattenedSharedBoardsTitleMap).map(
          ({ menus, menuTitle }) => {
            if (!menus || menus.length === 0) {
              return null;
            }
            const searchParamsArgs = {
              mealType,
              mealId: mealIDToReplace,
              menuIDs: menus.map((m) => m.menuID),
              parentIDs: menus.map((m) => m.parentID),
            };
            return (
              <>
                <Grid container>
                  <Grid item xs={8}>
                    <h2
                      style={{
                        margin: '23px 23px 5px',
                        color: 'var(--app-primary-font-color)',
                        textAlign: 'left',
                      }}>
                      {menuTitle}
                    </h2>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                    <Box
                      sx={{
                        margin: 'auto 10px 7px',
                        color: 'var(--app-primary-font-color)',
                      }}>
                      <Link
                        to={linkWithQueryParams(
                          formatRoute(
                            END_USER_FIND_ALTERNATIVES_MULTI_MENUS_ROUTE,
                            {
                              calendarDay,
                            },
                          ),
                          searchParamsArgs,
                        )}>
                        View all
                      </Link>
                    </Box>
                  </Grid>
                </Grid>

                <div style={{ overflowX: 'auto', display: 'flex' }}>
                  {menus.flatMap((menu) =>
                    (menu.mealIDs || menu.sharedMealIDs)
                      .slice(0, CAROUSEL_LIMIT)
                      .map((mealID) => (
                        <div key={mealID} style={{ flexShrink: 0 }}>
                          <EndUserFindAlternativesItem
                            mealID={mealID}
                            calendarDay={calendarDay}
                            mealType={mealType}
                            mealIDToReplace={mealIDToReplace}
                            onClick={onClickResultDetail}
                          />
                        </div>
                      )),
                  )}
                </div>
              </>
            );
          },
        )}
        {grcRecipeCategories.map((category) => {
          const searchParamsArgs = {
            mealType,
            mealId: mealIDToReplace,
          };
          return (
            <>
              <Grid container>
                <Grid item xs={8}>
                  <h2
                    style={{
                      margin: '23px 23px 5px',
                      color: 'var(--app-primary-font-color)',
                      textAlign: 'left',
                    }}>
                    {category.title}
                  </h2>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <Box
                    sx={{
                      margin: 'auto 10px 7px',
                      color: 'var(--app-primary-font-color)',
                    }}>
                    <Link
                      to={linkWithQueryParams(
                        formatRoute(
                          END_USER_FIND_ALTERNATIVES_GRC_RECIPE_CATEGORY_ROUTE,
                          {
                            calendarDay,
                            categoryID: category.id,
                          },
                        ),
                        searchParamsArgs,
                      )}>
                      View all
                    </Link>
                  </Box>
                </Grid>
              </Grid>

              <div style={{ overflowX: 'auto', display: 'flex' }}>
                {category.result.data.map((item) => (
                  <EndUserFindAlternativesGRCRecipeItem
                    key={item.grcRecipeID}
                    calendarDay={calendarDay}
                    grcRecipeID={item.grcRecipeID}
                    mealType={mealType}
                    mealIDToReplace={mealIDToReplace}
                    onClick={onClickGRCResultDetail}
                  />
                ))}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default EndUserFindAlternativesHomePage;
