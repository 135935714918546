import React from 'react';
import { PropTypes } from 'prop-types';
import { Autocomplete, Chip, TextField, useTheme } from '@mui/material';
import BackNextButtons from './back_next_buttons';

const SpaceOnboardingStepExcludeIngredients = ({
  // sharedProgrammeTitle,
  excludeIngredients,
  setExcludeIngredients,
  completionPercentage,
  allIngredients,
  onNext,
  onBack,
}) => {
  const theme = useTheme();

  const availableIngredientsForExclude = allIngredients.filter(
    (ing) => !(excludeIngredients || []).includes(ing),
  );

  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">Any ingredients you don’t like?</div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <Autocomplete
            multiple
            id="tags-standard"
            freeSolo
            disableClearable
            filterSelectedOptions
            options={availableIngredientsForExclude}
            onChange={(e, newValues, reason) => {
              setExcludeIngredients(newValues);
            }}
            renderTags={() => {}}
            value={excludeIngredients || []}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                placeholder="Type an ingredient"
                margin="normal"
                sx={{
                  '& input::placeholder': {
                    fontSize: '14px',
                  },
                }}
              />
            )}
          />
          <div className="selectedTags">
            {(excludeIngredients || []).map((ing) => {
              // This is to handle new options added by the user (allowed by freeSolo prop).
              return (
                <Chip
                  key={ing}
                  tabIndex={-1}
                  label={ing}
                  sx={{ margin: theme.spacing(0.5, 0.25) }}
                  style={{
                    backgroundColor: 'var(--app-primary-color)',
                    color: 'var(--app-primary-font-color)',
                  }}
                  onDelete={() =>
                    setExcludeIngredients(
                      excludeIngredients.filter((i) => i !== ing),
                    )
                  }
                />
              );
            })}
          </div>
        </div>
        <BackNextButtons
          onNext={() => onNext(excludeIngredients)}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepExcludeIngredients.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  excludeIngredients: PropTypes.arrayOf(PropTypes.string),
  setExcludeIngredients: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  allIngredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepExcludeIngredients.defaultProps = {
  excludeIngredients: [],
};

export default SpaceOnboardingStepExcludeIngredients;
