import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';

const SmorgStudioMembershipsActionMenu = ({
  anchorEl,
  subscribeEnabled,
  deleteEnabled,
  onClickSubscribe,
  onClickChangePassword,
  onClickDelete,
  onDismiss,
}) => {
  return (
    <Menu
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      PaperProps={{
        style: { minWidth: '250px' },
      }}
      anchorEl={anchorEl}
      open
      onClose={onDismiss}>
      <PopupTitle
        titleText="Member actions"
        closeButtonEnabled
        onClickClose={onDismiss}
      />
      {subscribeEnabled && (
        <MenuItem onClick={onClickSubscribe}>Subscribe</MenuItem>
      )}
      <MenuItem onClick={onClickChangePassword}>Change password</MenuItem>
      <MenuItem onClick={onClickDelete} disabled={!deleteEnabled}>
        Delete user
      </MenuItem>
    </Menu>
  );
};

SmorgStudioMembershipsActionMenu.propTypes = {
  anchorEl: PropTypes.bool.isRequired,
  subscribeEnabled: PropTypes.bool.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
  onClickSubscribe: PropTypes.func.isRequired,
  onClickChangePassword: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default SmorgStudioMembershipsActionMenu;
