/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "assetapi",
            "endpoint": "https://vax52l1ia3.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "smorgPublicApi",
            "endpoint": "https://opif7lwn0f.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "stripeWebhooks",
            "endpoint": "https://fig4tlsfh2.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "zapierRestHooks",
            "endpoint": "https://m91vvvrnt7.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://772hgvtqtvhk7ptfehm7wzcjwu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:4dfa66aa-5805-4f74-a527-e96307deab8f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_2QKCwjGJA",
    "aws_user_pools_web_client_id": "5t56if2h5qndn6m4nevv54djhp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
