import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mdiCalendarToday } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import WeekNavigator from '../common/week_navigator';
import {
  currentViewWeekStartDateStr,
  dateMinusDays,
} from '../../services/planner';
import PlannerWeekActionsPopupMenu from './planner_week_actions_popup_menu';
import {
  plannerWeekAddedToMealBasketAction,
  plannerWeekMealsCopiedAction,
  plannerWeekMealsDeletedAction,
} from '../../action_creators/planner_action_creators';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';

const TopNavBarMobilePlanner = ({ plannerViewWeekStartDate }) => {
  const navigate = useNavigate();
  const plannerViewWeekStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );
  const thisWeekStartDate = currentViewWeekStartDateStr(
    plannerViewWeekStartDay,
  );
  const dispatch = useDispatch();

  return (
    <div className="topNavBarMobile">
      <div style={{ margin: 'auto 10px auto 20px' }}>
        <Icon
          path={mdiCalendarToday}
          onClick={() => navigate(`/planner/${thisWeekStartDate}`)}
          style={{
            width: '36px',
            height: '36px',
            color: 'var(--app-primary-font-color)',
            padding: '0',
            marginBottom: -5,
          }}
        />
      </div>
      <div
        style={{
          margin: 'auto',
          color: 'var(--app-primary-font-color)',
          fontSize: '16px',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            fontSize: 18,
            fontWeight: 'bold',
            margin: 'auto 0',
          }}>
          <WeekNavigator
            startDate={plannerViewWeekStartDate}
            onChangeStartDate={(startDate) => navigate(`/planner/${startDate}`)}
            innerWeekWidth={190}
            arrowStyle={{ color: 'var(--app-primary-font-color)' }}
          />
        </div>
      </div>
      <div style={{ margin: 'auto 10px' }}>
        <PlannerWeekActionsPopupMenu
          onDeleteAllMeals={() =>
            dispatch(plannerWeekMealsDeletedAction(plannerViewWeekStartDate))
          }
          onAddWeekMealsToMealsBasket={() =>
            dispatch(
              plannerWeekAddedToMealBasketAction(plannerViewWeekStartDate),
            )
          }
          onCopyPreviousWeekMeals={() =>
            dispatch(
              plannerWeekMealsCopiedAction(
                plannerViewWeekStartDate,
                dateMinusDays(plannerViewWeekStartDate, 7),
              ),
            )
          }
        />
      </div>
    </div>
  );
};

TopNavBarMobilePlanner.propTypes = {
  plannerViewWeekStartDate: PropTypes.string.isRequired,
};

export default TopNavBarMobilePlanner;
