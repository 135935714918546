/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Chip, TextField, useTheme } from '@mui/material';
import { normalizeTag } from '../../services/tags';
import { addedItems } from '../../services/arrays';
import { trackEvents } from '../../operations/tracking_operations';

// https://codesandbox.io/s/material-ui-input-with-chips-0s2j4?from-embed=&file=/src/TagsInput.js:0-3323
// https://codesandbox.io/s/autocomplete-with-chips-85rqq?file=/demo.js

const TagsInput = ({
  onTagsChange,
  tagsSorted,
  placeholder,
  tags,
  skipNormalization,
}) => {
  const theme = useTheme();

  const [values, setValues] = useState(tags);

  useEffect(() => {
    setValues(tags);
  }, [tags]);

  const handleChange = (newValues, isNewValueSelected) => {
    const tagsNormalized = skipNormalization
      ? [...newValues]
      : newValues.map(normalizeTag);
    const addedTags = addedItems(newValues, tags);
    if (addedTags.length > 0) {
      const addedTag = addedTags[0];
      if (isNewValueSelected) {
        console.log(
          `User added tag ${addedTag} by selecting from the autocomplete`,
        );
        trackEvents([
          { name: 'Category tag selected', args: { tag: addedTag } },
        ]);
      } else {
        console.log(`User added tag ${addedTag} by typing it in`);
        trackEvents([
          { name: 'Category tag created', args: { tag: addedTag } },
        ]);
      }
      if (addedTags.length > 1) {
        console.warn(
          'Too many added tags, this should never happen: ',
          addedTags,
        );
      }
    }
    setValues(tagsNormalized);
    console.log(`Will set tags values to ${tagsNormalized}`);
    onTagsChange(tagsNormalized);
  };

  const handleDelete = (item) => {
    const newValues = [...values];
    newValues.splice(newValues.indexOf(item), 1);
    setValues(newValues);
    console.log(`Will set tags values to ${newValues}`);
    onTagsChange(newValues);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="tags-standard"
        freeSolo
        disableClearable
        filterSelectedOptions
        options={tagsSorted}
        onChange={(e, newValue, reason) => {
          handleChange(newValue, reason === 'selectOption');
        }}
        renderTags={() => {}}
        value={values}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={placeholder}
            margin="normal"
          />
        )}
      />
      <div className="selectedTags">
        {values.map((option) => {
          // This is to handle new options added by the user (allowed by freeSolo prop).
          const label = option.title || option;
          return (
            <Chip
              key={label}
              tabIndex={-1}
              label={label}
              sx={{ margin: theme.spacing(0.5, 0.25) }}
              style={{
                backgroundColor: 'var(--app-primary-color)',
                color: 'var(--app-primary-font-color)',
              }}
              onDelete={() => handleDelete(option)}
            />
          );
        })}
      </div>
    </div>
  );
};

TagsInput.propTypes = {
  onTagsChange: PropTypes.func.isRequired,
  tagsSorted: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  skipNormalization: PropTypes.bool,
};

TagsInput.defaultProps = {
  tags: [],
  placeholder: '',
  skipNormalization: false,
};

export default TagsInput;
