import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MY_DAY_TODAYS_LESSONS_ROUTE } from '../../services/routes';
import { myDayCardsSelector } from '../../reducers/my_day_reducer';
import MyDayContentCard from './my_day_content_card';

const MyDayTodaysLessonsSection = ({ today }) => {
  const todaysContentCards = useSelector((state) =>
    myDayCardsSelector(state, today).filter(
      (card) => card.cardType === 'CONTENT_ENTRY',
    ),
  );

  console.log(`${todaysContentCards.length} todays content cards`);

  if (todaysContentCards.length === 0) {
    return null;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h2
            style={{
              margin: '23px 23px 5px',
              color: 'var(--app-primary-font-color)',
            }}>
            Today&apos;s lessons
          </h2>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box sx={{ margin: '28px 10px 5px' }}>
            <Link to={MY_DAY_TODAYS_LESSONS_ROUTE}>View all</Link>
          </Box>
        </Grid>
      </Grid>

      <div style={{ overflowX: 'auto', display: 'flex' }}>
        {todaysContentCards.map((card) => (
          <div key={card.contentEntryID} style={{ flexShrink: 0 }}>
            <MyDayContentCard
              contentEntryID={card.contentEntryID}
              parentID={card.parentID}
              calendarDay={card.calendarDay}
            />
          </div>
        ))}
      </div>
    </>
  );
};

MyDayTodaysLessonsSection.propTypes = {
  today: PropTypes.string.isRequired,
};

export default MyDayTodaysLessonsSection;
