import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import SharedProgrammeWithUserEnrolledPopupMenu from './shared_programme_with_user_enrolled_popup_menu';
import { endProgrammeEnrollmentAction } from '../../action_creators/programmes_action_creators';
import enrolled_programme_card_background from '../../assets/images/enrolled_programme_card_background.png';
import { programmeStartCalendarDay } from '../../reducers/my_day_reducer';
import { userCountryCodeSelector } from '../../reducers/user_reducer';
import { formatDayAndMonthInLocale } from '../../services/dates';
import { END_USER_SHARED_PROGRAMMES_ROUTE } from '../../services/routes';

const ProgrammeProgressNotYetStarted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const { sharedProgrammeID } = currentProgrammeEnrollment;

  const countryCode = useSelector(userCountryCodeSelector);

  const sharedProgrammeTitle = useSelector(
    (state) =>
      state.sharedProgrammes.find((spr) => spr.id === sharedProgrammeID)?.title,
  );

  const programmeStartDate = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );

  const onStopProgram = async () => {
    console.log('Before navigate');
    navigate(END_USER_SHARED_PROGRAMMES_ROUTE);
    await dispatch(endProgrammeEnrollmentAction(currentProgrammeEnrollment.id));
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'You have stopped the program',
    });
  };

  return (
    <div className="alternatives-page">
      <div className="topNavBarMobile">
        <div style={{ paddingLeft: '23px', margin: 'auto 0' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            style={{ textDecoration: 'none' }}
            onClick={(ev) => {
              ev.preventDefault();
              navigate(-1);
            }}>
            <FontAwesomeIcon icon="chevron-left" />
            Back
          </a>
        </div>
        <div style={{ margin: 'auto', marginRight: 10 }}>
          <SharedProgrammeWithUserEnrolledPopupMenu
            onStopProgram={onStopProgram}
          />
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <h2
            style={{
              fontSize: '24px',
              color: 'var(--app-primary-font-color)',
              margin: '23px 23px 10px',
            }}>
            {sharedProgrammeTitle}
          </h2>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <h3
            style={{
              fontSize: '16px',
              color: 'var(--app-primary-font-color)',
              fontWeight: 'normal',
              margin: '10px 23px',
            }}>
            Due to start{' '}
            {formatDayAndMonthInLocale(programmeStartDate, countryCode)}
          </h3>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
      </Grid>
      <div
        style={{
          width: 316,
          height: 194,
          backgroundColor: 'white',
          color: 'black',
          margin: '10px 23px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '30px',
          overflow: 'hidden',
        }}>
        <div style={{ display: 'flex' }}>
          <div style={{ padding: 16, width: 166 }}>
            <div
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: 24,
              }}>
              Your plan is starting soon!
            </div>
            <div>
              {formatDayAndMonthInLocale(programmeStartDate, countryCode)}
            </div>
          </div>
          <div
            style={{
              width: 150,
              height: 194,
              backgroundImage: `url("${enrolled_programme_card_background}")`,
              backgroundSize: 'cover',
            }}>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammeProgressNotYetStarted;
