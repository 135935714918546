import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
  PopoverProps,
} from '@mui/material';
import PopupTitle from '../common/popup_title';

interface StopProgramConfirmPopoverProps {
  anchorEl: PopoverProps['anchorEl'];
  visible: boolean;
  onConfirm: (shouldClearPlanner: boolean) => void;
  onDismiss: () => void;
}

const StopProgramConfirmPopover = ({
  anchorEl = null,
  visible,
  onConfirm,
  onDismiss,
}: StopProgramConfirmPopoverProps) => {
  const [shouldClearPlanner, setShouldClearPlanner] = useState(true);

  if (!visible) {
    return null;
  }

  return (
    <Popover
      open
      PaperProps={{
        style: { width: `300px`, height: `270px`, padding: '10px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Stop program"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px', fontSize: '14px' }}>
          Your about to stop this program, any progress recorded on this program
          will be removed.
          <br />
          <br />
          Are you ok to proceed?
        </Grid>
        <Grid item xs={12} style={{ padding: '15px 5px 20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldClearPlanner}
                onChange={(ev) => setShouldClearPlanner(ev.target.checked)}
              />
            }
            label="Clear program calendar entries in your planner"
          />
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onConfirm(shouldClearPlanner);
              ev.stopPropagation();
            }}
            variant="contained"
            color="primary"
            size="medium"
            disableElevation
            style={{ justifyContent: 'flex-start' }}>
            Stop program
          </Button>
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={(ev) => {
                onDismiss();
                ev.stopPropagation();
              }}
              variant="contained"
              color="secondary"
              size="medium"
              disableElevation
              style={{ justifyContent: 'flex-end' }}>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default StopProgramConfirmPopover;
