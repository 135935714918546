import React from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import MyDayShoppingListCard from './my_day_shopping_list_card';

const MyDayShoppingListsSection = () => {
  const shoppingLists = useSelector((state) =>
    Object.values(state.shoppingLists),
  );

  if (shoppingLists.length === 0) {
    return null;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h2 style={{ margin: '23px 23px 5px', color: 'var(--app-primary-font-color)' }}>
            Shopping lists
          </h2>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box sx={{ margin: 'auto 10px' }}>&nbsp;</Box>
        </Grid>
      </Grid>

      <div style={{ overflowX: 'auto', display: 'flex' }}>
        {shoppingLists.map((shoppingList) => (
          <div key={shoppingList.id} style={{ flexShrink: 0 }}>
            <MyDayShoppingListCard shoppingListID={shoppingList.id} />
          </div>
        ))}
      </div>
    </>
  );
};

export default MyDayShoppingListsSection;
