import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from '@mui/icons-material/Warning';
import { v4 as uuidv4 } from 'uuid';
import ProgrammesPicker from './programmes_picker';
import PlanNavigator from './plan_navigator';
import ProgrammesActionsPopupMenu from './programmes_actions_popup_menu';
import {
  createPlanAction,
  exportProgrammeAndObjectsAction,
  importProgrammeAndObjectsAction,
  programmePlanRecommendMealsAction,
  publishLatestVersionOfProgrammeAction,
  removeMealsAndGRCRecipesFromProgrammePlanAction,
  removePlanAction,
  removeProgrammeAction,
} from '../../action_creators/programmes_action_creators';
import {
  getPaginatedProgrammePlanSelector,
  programmeSelector,
  programmesSelector,
} from '../../reducers/programmes_reducer';
import RecommenderWizardContainer from '../common/recommender_wizard_container';
import {
  PROGRAMME_PLANS_TITLE_PREFIX,
  emptyProgrammePlan,
} from '../../services/programmes';
import { otherUserActiveOnResourceSelector } from '../../reducers/mealboard_reducer';
import { ActivityObjectType } from '../../API';
// import { exportPlannerBasedProgrammeAction } from '../../action_creators/planner_action_creators';

const ProgrammesNavigationBar = ({ selectedProgrammeId, selectedPlanId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const programmes = useSelector(programmesSelector);
  const programme = useSelector((state) =>
    programmeSelector(state, selectedProgrammeId),
  );
  const { prevPlanId, nextPlanId } = useSelector((state) =>
    getPaginatedProgrammePlanSelector(
      state,
      selectedProgrammeId,
      selectedPlanId,
    ),
  );

  const currentPLan = programme.plans.find((pl) => pl.id === selectedPlanId);
  const currentPlanIndex = programme.plans.findIndex(
    (pl) => pl.id === selectedPlanId,
  );
  const nextPlanIdAfterRemoval = nextPlanId || prevPlanId;

  const onCreateProgramme = () => {
    navigate(`/programmes/create`);
  };

  const onCreateNewWeek = () => {
    dispatch(
      createPlanAction(
        selectedProgrammeId,
        selectedPlanId,
        {
          title: `${PROGRAMME_PLANS_TITLE_PREFIX} ${currentPlanIndex + 2}`,
          id: uuidv4(),
          shortDescription: '',
          description: null,
          coverImageUrl: null,
          days: emptyProgrammePlan(),
          nutritionConstraints: null,
          showNutritionToUsers: null,
        },
        (planId) =>
          navigate(`/programmes/${selectedProgrammeId}/plans/${planId}`),
      ),
    );
  };

  const onRemoveCurrentWeek = () => {
    dispatch(removePlanAction(selectedProgrammeId, selectedPlanId));
    navigate(
      `/programmes/${selectedProgrammeId}/plans/${nextPlanIdAfterRemoval}`,
    );
  };

  const onEditProgramme = () => {
    navigate(`/programmes/${selectedProgrammeId}/edit`);
  };

  const onEditNutritionConstraints = () => {
    navigate(`/programmes/${selectedProgrammeId}/nutritionConstraints/edit`);
  };

  const onEditPlan = () => {
    navigate(`/programmes/${selectedProgrammeId}/plans/${selectedPlanId}/edit`);
  };

  /** TODO duplicate code with TopNavBarMobileProgrammes */
  const onRemoveProgramme = () => {
    const firstRemainingProgramme = programmes.find(
      (b) => b.id !== selectedProgrammeId,
    );
    dispatch(removeProgrammeAction(selectedProgrammeId));
    navigate(`/programmes/${firstRemainingProgramme.id}`);
  };

  const onRemoveMealsAndGRCRecipesFromPlan = () => {
    dispatch(removeMealsAndGRCRecipesFromProgrammePlanAction(selectedPlanId));
  };

  const onPublish = () => {
    dispatch(
      publishLatestVersionOfProgrammeAction(selectedProgrammeId, () =>
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your program has been published successfully',
        }),
      ),
    );
  };

  const onPreview = () => {
    navigate(`/programmes/${programme.id}/plans/${selectedPlanId}/preview`);
  };

  const onChangePlan = (newPlanId) => {
    navigate(`/programmes/${programme.id}/plans/${newPlanId}`);
  };

  const [recommenderWizardVisible, setRecommenderWizardVisible] =
    useState(false);

  const onRecommendMeals = async (numDaysToPlan, startDayIndexOneBased) =>
    dispatch(
      programmePlanRecommendMealsAction(
        selectedProgrammeId,
        selectedPlanId,
        numDaysToPlan,
        startDayIndexOneBased,
      ),
    );

  const onExport = () => {
    dispatch(exportProgrammeAndObjectsAction(programme.id));
    // dispatch(exportPlannerBasedProgrammeAction('247-high-cholesterol'));
  };

  const importFileRef = useRef();

  const onImport = () => {
    if (importFileRef.current) {
      importFileRef.current.click();
    } else {
      console.warn('importFileRef.current is null or undefined');
    }
  };

  const onImportFileChosen = (fileList) => {
    const blob = fileList[0];
    dispatch(
      importProgrammeAndObjectsAction(blob, (newProgramme) => {
        if (newProgramme) {
          navigate(`/programmes/${newProgramme.id}`);
        }
      }),
    );
  };

  const otherActiveUser = useSelector((state) =>
    otherUserActiveOnResourceSelector(
      state,
      ActivityObjectType.PROGRAMMES,
      selectedProgrammeId,
    ),
  );

  return (
    <>
      <Box
        display="flex"
        className="plannerNavigationBar"
        justifyContent="center"
        style={{ color: 'var(--app-primary-font-color)', position: 'relative', height: 40 }}>
        <div
          style={{
            padding: '0 10px',
            display: 'flex',
            position: 'absolute',
            left: 0,
            top: 0,
            height: 40,
          }}>
          <div style={{ margin: 'auto 0' }}>
            <ProgrammesPicker selectedProgrammeId={selectedProgrammeId} />
          </div>
        </div>
        <div style={{ margin: 'auto' }}>
          <PlanNavigator
            currentPlanId={selectedPlanId}
            plans={programme.plans}
            onChangePlan={onChangePlan}
            arrowStyle={{ color: 'var(--app-primary-font-color)' }}
          />
        </div>
        {otherActiveUser && (
          <div style={{ marginRight: '90px', display: 'flex' }}>
            <Tooltip title="Modifying this program whilst another user is active may cause data loss">
              <WarningIcon
                style={{
                  margin: 'auto 10px auto 0',
                  color: 'orange',
                }}
              />
            </Tooltip>
            <div style={{ margin: 'auto 0', fontSize: '14px' }}>
              {otherActiveUser} is currently editing this program!
            </div>
          </div>
        )}
        <div
          style={{
            padding: '0 10px',
            position: 'absolute',
            right: 0,
            top: 0,
            height: 40,
          }}>
          <ProgrammesActionsPopupMenu
            fabStyle={{ color: 'var(--app-primary-font-color)' }}
            isRemoveCurrentWeekDisabled={programme.plans.length === 1}
            onCreateProgramme={onCreateProgramme}
            onImport={onImport}
            onCreateNewWeek={onCreateNewWeek}
            currentWeekTitle={currentPLan.title}
            onRemoveCurrentWeek={onRemoveCurrentWeek}
            onEditProgramme={onEditProgramme}
            onEditPlan={onEditPlan}
            onEditNutritionConstraints={onEditNutritionConstraints}
            onRecommendMeals={() => setRecommenderWizardVisible(true)}
            onRemoveMealsAndGRCRecipesFromPlan={
              onRemoveMealsAndGRCRecipesFromPlan
            }
            onPreview={onPreview}
            onPublish={onPublish}
            onExport={onExport}
            onRemoveProgramme={onRemoveProgramme}
          />
        </div>
      </Box>
      {recommenderWizardVisible && (
        <RecommenderWizardContainer
          onRecommendMeals={onRecommendMeals}
          onDismiss={() => setRecommenderWizardVisible(false)}
        />
      )}
      <input
        ref={importFileRef}
        onChange={() => {
          onImportFileChosen(importFileRef.current.files);
          importFileRef.current.value = '';
        }}
        multiple={false}
        type="file"
        hidden
      />
    </>
  );
};

ProgrammesNavigationBar.propTypes = {
  selectedProgrammeId: PropTypes.string.isRequired,
  selectedPlanId: PropTypes.string.isRequired,
};

export default ProgrammesNavigationBar;
