import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import TopNavBar from '../user/top_nav_bar';
import { formatDateInLocale } from '../../services/dates';
import { userCountryCodeSelector } from '../../reducers/user_reducer';
import {
  exportMembershipsAsCsv,
  formatReferrer,
  formatStatus,
  hasActiveSubscription,
} from '../../services/smorg_studio_memberships';
import SmorgStudioMembershipsSetExpirationPopup from './smorg_studio_memberships_set_expiration_popup';
import {
  membershipAdminChangePasswordAction,
  membershipAdminDeleteUserAction,
  membershipAdminSetExpiresAtAction,
} from '../../action_creators/spaces_action_creators';
import SmorgStudioMembershipsChangePasswordPopup from './smorg_studio_memberships_change_password_popup';
import SmorgStudioMembershipsActionMenu from './smorg_studio_memberships_action_menu';
import SmorgStudioMembershipsDeleteUserPopup from './smorg_studio_memberships_delete_user_popup';

const POPUP_TYPE_SUBSCRIBE = 'Subscribe';
const POPUP_TYPE_CHANGE_PASSWORD = 'Change password';
const POPUP_TYPE_DELETE_USER = 'Delete user';

const SmorgStudioMembershipsView = () => {
  const dispatch = useDispatch();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);

  const [
    currentlyUpdatingSpaceMembershipID,
    setCurrentlyUpdatingSpaceMembershipID,
  ] = useState(null);

  const [subscriptionPopupAnchorEl, setSubscriptionPopupAnchorEl] =
    useState(null);

  const smorgStudioMemberships = useSelector(
    (state) => state.smorgStudioMemberships,
  );

  const handleClickActionsMenu = (ev, membershipID) => {
    setSubscriptionPopupOpenForSpaceMembershipID(membershipID);
    setActionsMenuAnchorEl(ev.target);
  };

  const onDismissActionsMenu = () => {
    setActionsMenuAnchorEl(null);
  };

  const countryCode = useSelector(userCountryCodeSelector);

  const [
    subscriptionPopupOpenForSpaceMembershipID,
    setSubscriptionPopupOpenForSpaceMembershipID,
  ] = useState(null);

  const [subscriptionPopupType, setSubscriptionPopupType] = useState(null);

  const subscriptionPopupOpenForSpaceMembership =
    subscriptionPopupOpenForSpaceMembershipID &&
    smorgStudioMemberships.find(
      (sm) => sm.id === subscriptionPopupOpenForSpaceMembershipID,
    );

  const subscriptionPopupOpenForEmailAddress =
    subscriptionPopupOpenForSpaceMembership &&
    subscriptionPopupOpenForSpaceMembership.email;

  const onClickExport = (ev) => {
    ev.preventDefault();
    exportMembershipsAsCsv(smorgStudioMemberships, countryCode);
  };

  const onClickSubscribe = (ev, spaceMembershipID) => {
    ev.preventDefault();
    setSubscriptionPopupOpenForSpaceMembershipID(spaceMembershipID);
    setSubscriptionPopupAnchorEl(ev.target);
    setSubscriptionPopupType(POPUP_TYPE_SUBSCRIBE);
  };

  const onClickChangePassword = (ev, spaceMembershipID) => {
    ev.preventDefault();
    setSubscriptionPopupOpenForSpaceMembershipID(spaceMembershipID);
    setSubscriptionPopupAnchorEl(ev.target);
    setSubscriptionPopupType(POPUP_TYPE_CHANGE_PASSWORD);
  };

  const onClickDeleteUser = (ev, spaceMembershipID) => {
    ev.preventDefault();
    setSubscriptionPopupOpenForSpaceMembershipID(spaceMembershipID);
    setSubscriptionPopupAnchorEl(ev.target);
    setSubscriptionPopupType(POPUP_TYPE_DELETE_USER);
  };

  const onSetSubscriptionExpiration = async (newExpiresAt) => {
    onDismissActionsMenu();
    setSubscriptionPopupAnchorEl(null);
    setCurrentlyUpdatingSpaceMembershipID(
      subscriptionPopupOpenForSpaceMembershipID,
    );
    try {
      await dispatch(
        membershipAdminSetExpiresAtAction(
          subscriptionPopupOpenForSpaceMembershipID,
          newExpiresAt,
        ),
      );
    } finally {
      setSubscriptionPopupOpenForSpaceMembershipID(null);
      setCurrentlyUpdatingSpaceMembershipID(null);
      setSubscriptionPopupType(null);
    }
  };

  const onChangePassword = async (newPassword) => {
    onDismissActionsMenu();
    setSubscriptionPopupAnchorEl(null);
    setCurrentlyUpdatingSpaceMembershipID(
      subscriptionPopupOpenForSpaceMembershipID,
    );
    try {
      await dispatch(
        membershipAdminChangePasswordAction(
          subscriptionPopupOpenForSpaceMembershipID,
          newPassword,
        ),
      );
    } finally {
      setSubscriptionPopupOpenForSpaceMembershipID(null);
      setCurrentlyUpdatingSpaceMembershipID(null);
      setSubscriptionPopupType(null);
    }
  };

  const onDeleteUser = async () => {
    onDismissActionsMenu();
    setSubscriptionPopupAnchorEl(null);
    setCurrentlyUpdatingSpaceMembershipID(
      subscriptionPopupOpenForSpaceMembershipID,
    );
    try {
      await dispatch(
        membershipAdminDeleteUserAction(
          subscriptionPopupOpenForSpaceMembershipID,
        ),
      );
    } finally {
      setSubscriptionPopupOpenForSpaceMembershipID(null);
      setCurrentlyUpdatingSpaceMembershipID(null);
      setSubscriptionPopupType(null);
    }
  };

  const onDismissSubscriptionPopup = () => {
    setSubscriptionPopupAnchorEl(null);
    setSubscriptionPopupOpenForSpaceMembershipID(null);
    setSubscriptionPopupType(null);
  };

  return (
    <>
      <TopNavBar />
      <div style={{ textAlign: 'left' }}>
        <Grid
          container
          style={{ marginTop: '20px', color: 'var(--app-primary-font-color)' }}>
          <Grid
            item
            xs={6}
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              marginLeft: '23px',
              color: 'var(--app-primary-font-color)',
            }}>
            Your members
          </Grid>
          <Grid item xs={3} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto' }}>
              {smorgStudioMemberships &&
                smorgStudioMemberships.length &&
                `${smorgStudioMemberships.length} members`}
            </div>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={onClickExport}
                style={{ color: 'var(--app-primary-font-color)' }}>
                Export
              </a>
            </div>
          </Grid>
        </Grid>

        <div className="smorgStudioMemberships">
          <table>
            <thead>
              <tr className="heading">
                <th>Email Address</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Created Date</th>
                <th>Expiry Date</th>
                <th>Channel</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {smorgStudioMemberships.map((membership) => {
                return (
                  <tr key={membership.id}>
                    <div style={{ display: 'none' }}>{membership.id}</div>
                    <td>{membership.email}</td>
                    <td>{membership.givenName}</td>
                    <td>{membership.familyName}</td>
                    <td>
                      {formatDateInLocale(membership.createdAt, countryCode)}
                    </td>
                    <td>
                      {membership.expiresAt &&
                        formatDateInLocale(membership.expiresAt, countryCode)}
                    </td>
                    <td>{formatReferrer(membership.referrer)}</td>
                    <td>{formatStatus(membership)}</td>
                    <td>
                      {currentlyUpdatingSpaceMembershipID !== membership.id && (
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls="long-menu"
                          aria-expanded="true"
                          aria-haspopup="true"
                          size="small"
                          className="actionsPopupMenu"
                          onClick={(ev) =>
                            handleClickActionsMenu(ev, membership.id)
                          }>
                          <Icon
                            path={mdiDotsHorizontal}
                            style={{ color: 'black' }}
                          />
                        </IconButton>
                      )}
                      {currentlyUpdatingSpaceMembershipID === membership.id && (
                        <div style={{ height: '34px', display: 'flex' }}>
                          <div style={{ margin: 'auto 0' }}>Updating...</div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {actionsMenuAnchorEl && (
        <SmorgStudioMembershipsActionMenu
          anchorEl={actionsMenuAnchorEl}
          subscribeEnabled={
            !hasActiveSubscription(subscriptionPopupOpenForSpaceMembership)
          }
          deleteEnabled={
            !hasActiveSubscription(subscriptionPopupOpenForSpaceMembership)
          }
          onClickSubscribe={(ev) => {
            onClickSubscribe(ev, subscriptionPopupOpenForSpaceMembershipID);
          }}
          onClickChangePassword={(ev) => {
            onClickChangePassword(
              ev,
              subscriptionPopupOpenForSpaceMembershipID,
            );
          }}
          onClickDelete={(ev) => {
            onClickDeleteUser(ev, subscriptionPopupOpenForSpaceMembershipID);
          }}
          onDismiss={onDismissActionsMenu}
        />
      )}
      {subscriptionPopupAnchorEl &&
        subscriptionPopupType === POPUP_TYPE_SUBSCRIBE && (
          <SmorgStudioMembershipsSetExpirationPopup
            anchorEl={subscriptionPopupAnchorEl}
            title={subscriptionPopupOpenForEmailAddress}
            onSetSubscriptionExpiration={onSetSubscriptionExpiration}
            onDismiss={onDismissSubscriptionPopup}
          />
        )}
      {subscriptionPopupAnchorEl &&
        subscriptionPopupType === POPUP_TYPE_CHANGE_PASSWORD && (
          <SmorgStudioMembershipsChangePasswordPopup
            anchorEl={subscriptionPopupAnchorEl}
            title={subscriptionPopupOpenForEmailAddress}
            onChangePassword={onChangePassword}
            onDismiss={onDismissSubscriptionPopup}
          />
        )}
      {subscriptionPopupAnchorEl &&
        subscriptionPopupType === POPUP_TYPE_DELETE_USER && (
          <SmorgStudioMembershipsDeleteUserPopup
            anchorEl={subscriptionPopupAnchorEl}
            onDeleteUser={onDeleteUser}
            onDismiss={onDismissSubscriptionPopup}
          />
        )}
    </>
  );
};

export default SmorgStudioMembershipsView;
