import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../meal/meal_detail.css';
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { mdiLinkVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import BoardIcon from '../svg_icons/board_icon';
import ContentEditableField from '../meal/content_editable_field';
import { useIsMobile } from '../common/layout_hooks';
import contentEntryPlaceholder from '../../assets/images/content_entry_placeholder.png';
import ImageUploadButton from '../meal/image_upload_button';
import ContentMobilePreview from './content_mobile_preview';
import ContentReadonlyView from './content_readony_view';
import HtmlEditor from '../meal/html_editor';
import AppLinkEditModal from './app_link_edit_modal';
import { contentEntryPreviewHtml } from '../../services/content_entries';

const ContentEntryDetail = ({
  contentEntry,
  readOnly,
  currentlyEditingInline,
  currentTextRef,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  onNewCoverImageChosen,
  onDialogClose,
  appLinkEnabled,
  onAppLinkChange,
  onCoverImageShownInDetailViewChange,
}) => {
  const isMobile = useIsMobile();

  const theme = useTheme();

  const editorRef = useRef(null);

  const isEditable = !readOnly && !isMobile;

  const [previewHtml, setPreviewHtml] = useState(
    contentEntryPreviewHtml(
      contentEntry.body,
      contentEntry.isCoverImageShownInDetailView && contentEntry.coverImageUrl,
    ),
  );

  const previewPhoneHeight = 812;

  const previewPhoneZoomFactor = 0.75;

  const [appLinkEditModalVisible, setAppLinkEditModalVisible] = useState(false);

  const handleAppLinkChange = (newAppLink) => {
    setAppLinkEditModalVisible(false);
    onAppLinkChange(newAppLink);
  };

  const handleCoverImageShownInDetailViewChange = (newValue) => {
    setPreviewHtml(
      contentEntryPreviewHtml(
        editorRef.current.getContent(),
        newValue && contentEntry.coverImageUrl,
      ),
    );
    onCoverImageShownInDetailViewChange(newValue);
  };

  return (
    <>
      <DialogTitle className="content" style={{ height: 10 }}>
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          {/* <MealDetailMobileActionsMenu */}
          {/*  addonsEnabled={addonsEnabled} */}
          {/*  onAddonChange={onAddonChange} */}
          {/* /> */}
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDialogClose} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isEditable && (
              <Grid container>
                <Grid item xs={1} style={{ maxWidth: 40 }}>
                  <Typography variant="h2" style={{ paddingTop: '15px' }}>
                    <BoardIcon />
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    variant="h2"
                    style={{
                      lineHeight: 1.4,
                      color: 'var(--app-primary-color)',
                    }}>
                    <ContentEditableField
                      section="title"
                      placeholderText="Set title"
                      recipe={contentEntry}
                      currentlyEditingInline={currentlyEditingInline}
                      currentTextRef={currentTextRef}
                      disabled={false}
                      onInlineEditFocus={onInlineEditFocus}
                      onInlineEditChange={onInlineEditChange}
                      onInlineEditBlur={onInlineEditBlur}
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
            {isEditable && (
              <Grid container sx={{ marginTop: '20px' }}>
                <Grid item xs={1} sm={1} style={{ maxWidth: 40 }}>
                  <Typography variant="h3">
                    <FontAwesomeIcon icon="align-left" />
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography variant="h3">Cover image</Typography>
                </Grid>
              </Grid>
            )}
            {isEditable && (
              <Grid container>
                <Grid item xs={1} sm={1} style={{ maxWidth: 40 }}>
                  &nbsp;
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className="mealDetailImage"
                      style={{
                        width: 614,
                        maxWidth: 614,
                        height: 347,
                        position: 'relative',
                        backgroundImage: `url("${
                          contentEntry.coverImageUrl || contentEntryPlaceholder
                        }")`,
                      }}>
                      {isEditable && (
                        <ImageUploadButton
                          style={{
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                          }}
                          onChange={onNewCoverImageChosen}
                        />
                      )}
                      {isEditable && appLinkEnabled && (
                        <IconButton
                          sx={{
                            backgroundColor: 'rgba(225, 225, 225, 0.6)',
                            '&:hover': {
                              backgroundColor: 'rgba(225, 225, 225, 1)',
                            },
                          }}
                          style={{
                            position: 'absolute',
                            bottom: 60,
                            right: 10,
                          }}
                          onClick={() => {
                            setAppLinkEditModalVisible(true);
                          }}
                          title="Link image">
                          <Icon
                            path={mdiLinkVariant}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1} style={{ maxWidth: 40 }}>
                  &nbsp;
                </Grid>
                <Grid item xs={11} sm={11} style={{ padding: '5px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contentEntry.isCoverImageShownInDetailView}
                        onChange={(ev) =>
                          handleCoverImageShownInDetailViewChange(
                            ev.target.checked,
                          )
                        }
                      />
                    }
                    label="Show as header"
                  />
                </Grid>
              </Grid>
            )}
            {isEditable && (
              <Grid container sx={{ marginTop: '20px' }}>
                <Grid item xs={1} sm={1} style={{ maxWidth: 40 }}>
                  <Typography variant="h3">
                    <FontAwesomeIcon icon="align-left" />
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography variant="h3">Body</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              {!isEditable && <ContentReadonlyView html={contentEntry.body} />}
              {isEditable && (
                <>
                  <Grid item xs={1} sm={1} style={{ maxWidth: 40 }}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <HtmlEditor
                      initialHtml={contentEntry.body}
                      height={previewPhoneHeight * previewPhoneZoomFactor}
                      width="100%"
                      editorRef={editorRef}
                      onChange={(newValue) =>
                        onInlineEditChange('body', null, newValue)
                      }
                      onBlur={() => onInlineEditBlur('body', null)}
                    />
                    <div
                      style={{
                        fontWeight: 'bold',
                        marginTop: 5,
                      }}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href="#"
                        style={{ textDecoration: 'none' }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPreviewHtml(
                            contentEntryPreviewHtml(
                              editorRef.current.getContent(),
                              contentEntry.isCoverImageShownInDetailView &&
                                contentEntry.coverImageUrl,
                            ),
                          );
                        }}>
                        Preview in emulator
                      </a>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {isEditable && (
            <Grid item xs={12} sm={6}>
              <ContentMobilePreview html={previewHtml} zoomFactor={0.75} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {appLinkEditModalVisible && (
        <AppLinkEditModal
          initialAppLink={contentEntry.appLink}
          onChange={handleAppLinkChange}
          onDismiss={() => setAppLinkEditModalVisible(false)}
        />
      )}
    </>
  );
};

ContentEntryDetail.propTypes = {
  contentEntry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    coverImageUrl: PropTypes.string,
    isCoverImageShownInDetailView: PropTypes.bool,
    body: PropTypes.string,
    appLink: PropTypes.shape({}),
  }).isRequired,
  readOnly: PropTypes.bool,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  onNewCoverImageChosen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  appLinkEnabled: PropTypes.bool.isRequired,
  onAppLinkChange: PropTypes.func.isRequired,
  onCoverImageShownInDetailViewChange: PropTypes.func.isRequired,
};

ContentEntryDetail.defaultProps = {
  readOnly: false,
};

export default ContentEntryDetail;
