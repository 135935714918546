import React from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';

const MyDayWelcomeCard = ({
  spaceTitle,
  primaryMainColor,
  cardBackgroundColor,
}) => {
  // const navigate = useNavigate();

  return (
    <div
      className="my-day-card"
      style={{
        backgroundColor: cardBackgroundColor || primaryMainColor,
        color: 'var(--app-primary-font-color)',
        textAlign: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 32px',
        }}>
        <div
          style={{
            marginBottom: 24,
          }}>
          Welcome to
        </div>
        <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: 24 }}>
          {spaceTitle}
        </div>
        <div
          style={{
            fontWeight: 'bold',
            backgroundColor: primaryMainColor,
            padding: '16px 8px',
            borderRadius: 15,
            cursor: 'pointer',
          }}>
          How it works
        </div>
      </div>
    </div>
  );
};

MyDayWelcomeCard.propTypes = {
  spaceTitle: PropTypes.string.isRequired,
  primaryMainColor: PropTypes.string.isRequired,
  cardBackgroundColor: PropTypes.string,
};

MyDayWelcomeCard.defaultProps = {
  cardBackgroundColor: null,
};

export default MyDayWelcomeCard;
