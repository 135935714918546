import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { MovableCardWrapper } from '../common/board/styles/Base';
import { usePlannerViewWeekStartDate } from './planner_hooks';
import notePlaceholder from '../../assets/images/notePlaceholder.png';
import { plannerEntryQuickDuplicateAction } from '../../action_creators/planner_action_creators';
import { useIsMobile } from '../common/layout_hooks';

const noteChipStyle = {
  color: 'var(--app-primary-font-color)',
  backgroundColor: '#FF9900',
  borderColor: '#FF9900',
};

const PlannerEntryNoteCard = ({
  id,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  const isMobile = useIsMobile();

  // const classes = useStyles();
  const dispatch = useDispatch();
  const note = useSelector((state) => state.notes[id]);

  const [deleteNoteAnchorEl, setDeleteNoteAnchorEl] = useState(null);

  const plannerViewWeekStartDate = usePlannerViewWeekStartDate();

  const quickDuplicateEnabled = !isMobile;

  if (!note) {
    return null;
  }

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => onClick(id)}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardMedia
            component="img"
            className="plannerCardImage"
            image={notePlaceholder}
            alt=""
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '2px 0 4px',
            }}>
            <Chip
              label="NOTE"
              className="meal-type-chip"
              variant="outlined"
              size="small"
              style={noteChipStyle}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              margin: '8px',
              padding: 0,
              fontSize: '14px',
              whiteSpace: 'break-spaces',
            }}
            style={{
              lineClamp: 3,
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '4px',
            }}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(ev) => {
                setDeleteNoteAnchorEl(ev.target);
                ev.stopPropagation();
              }}>
              <DeleteIcon />
            </IconButton>
            <DeleteCardConfirmPopover
              visible={!!deleteNoteAnchorEl}
              anchorEl={deleteNoteAnchorEl}
              title="Delete note"
              message="The note will be deleted permanently. Are you sure?"
              height={140}
              onConfirmDelete={() => onDelete(id)}
              onDismiss={() => setDeleteNoteAnchorEl(null)}
            />
            {quickDuplicateEnabled && (
              <Tooltip title="Duplicate this note" placement="top-start">
                <IconButton
                  aria-label="quick duplicate"
                  size="small"
                  onClick={(ev) => {
                    dispatch(
                      plannerEntryQuickDuplicateAction(
                        plannerViewWeekStartDate,
                        id,
                      ),
                    );
                    ev.stopPropagation();
                  }}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Card>
    </MovableCardWrapper>
  );
};

PlannerEntryNoteCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PlannerEntryNoteCard;
