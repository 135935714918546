import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import TopNavBar from '../user/top_nav_bar';
import PopupTitle from '../common/popup_title';
import MealBasketWidget from '../meal/meal_basket_widget';
import EndUserFindAlternativesItem from './end_user_find_alternatives_item';
import { useMultiMenuMeals } from '../common/multi_menu_meals_hook';

const EndUserFindAlternativesFlattenedBoardView = ({
  recipesBoardId,
  menusWithParents,
  calendarDay,
  mealType,
  mealIDToReplace,
  onClick,
}) => {
  const navigate = useNavigate();

  const ownRecipesBoard = useSelector((state) =>
    state.recipesBoards.find((b) => b.id === recipesBoardId),
  );

  const { dedupedMergedEntriesFromMultipleMenus, multiMenusTitle } =
    useMultiMenuMeals(menusWithParents);

  const flattenedFavouriteMealIDs = (ownRecipesBoard?.menus || []).flatMap(
    (menu) =>
      (menu.mealIDs || menu.sharedMealIDs).map((mealID) => ({
        mealID,
        menuID: menu.id,
        parentID: ownRecipesBoard.id,
        isDeleteEnabled: true,
      })),
  );

  const title =
    menusWithParents && menusWithParents.length > 0
      ? multiMenusTitle
      : ownRecipesBoard.title;

  // const menu = (theBoard?.menus || []).find((m) => m.id === menuId);

  const objects =
    menusWithParents && menusWithParents.length > 0
      ? dedupedMergedEntriesFromMultipleMenus
      : flattenedFavouriteMealIDs;

  const onClickBack = () => navigate(-1);

  return (
    <div className="recipes-search">
      <TopNavBar />
      <MealBasketWidget />
      <div>
        <div
          className="topNavBarMobile topNavBarMobileLight"
          style={{
            color: 'var(--app-primary-font-color)',
            height: '70px',
            flexDirection: 'column',
          }}>
          <PopupTitle
            titleText={title}
            backButtonEnabled
            onClickBack={onClickBack}
          />
        </div>
        <Grid container>
          {objects.map(({ mealID, menuID, parentID, isDeleteEnabled }) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={mealID}
              style={{ flexShrink: 0 }}>
              <EndUserFindAlternativesItem
                mealID={mealID}
                calendarDay={calendarDay}
                mealType={mealType}
                mealIDToReplace={mealIDToReplace}
                onClick={onClick}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

EndUserFindAlternativesFlattenedBoardView.propTypes = {
  recipesBoardId: PropTypes.string,
  menusWithParents: PropTypes.objectOf({
    parentID: PropTypes.string.isRequired,
    menuID: PropTypes.string.isRequired,
  }),
  calendarDay: PropTypes.string.isRequired,
  mealType: PropTypes.string,
  mealIDToReplace: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

EndUserFindAlternativesFlattenedBoardView.defaultProps = {
  recipesBoardId: null,
  mealIDToReplace: null,
  menusWithParents: [],
  mealType: null,
};

export default EndUserFindAlternativesFlattenedBoardView;
