import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { MovableCardWrapper } from '../common/board/styles/Base';
import contentEntryPlaceholder from '../../assets/images/content_entry_placeholder.png';

const contentEntryChipStyle = {
  color: 'white',
  backgroundColor: '#FF0000',
  borderColor: '#FF0000',
};

const PlannerEntryContentEntryCard = ({
  id,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  const contentEntryID = style._objectID;
  const contentEntry = useSelector(
    (state) => state.contentEntries[contentEntryID],
  );

  const [deleteContentEntryAnchorEl, setDeleteContentEntryAnchorEl] =
    useState(null);

  const coverImageUrl = useSelector(
    (state) => state.contentEntries[contentEntryID]?.coverImageUrl,
  );

  if (!contentEntry) {
    console.warn(
      `Rendering PlannerEntryContentEntry card: content entry not found`,
    );
    return null;
  }

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => onClick(id)}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            className="plannerCardImage"
            image={coverImageUrl || contentEntryPlaceholder}
            title={title}
          />
          <CardContent
            title={title}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid #7B7B7B',
            }}>
            <Typography variant="body2" component="p" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Chip
                label="LESSON"
                className="meal-type-chip"
                variant="outlined"
                size="small"
                style={contentEntryChipStyle}
              />
            </Box>
          </CardContent>
        </Box>
        <CardActions sx={{ justifyContent: 'space-around' }}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(ev) => {
              setDeleteContentEntryAnchorEl(ev.target);
              ev.stopPropagation();
            }}>
            <DeleteIcon />
          </IconButton>
          <DeleteCardConfirmPopover
            visible={!!deleteContentEntryAnchorEl}
            anchorEl={deleteContentEntryAnchorEl}
            title="Delete content entry"
            message="The content entry will be deleted permanently. Are you sure?"
            height={155}
            onConfirmDelete={() => onDelete(id)}
            onDismiss={() => setDeleteContentEntryAnchorEl(null)}
          />
        </CardActions>
      </Card>
    </MovableCardWrapper>
  );
};

PlannerEntryContentEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PlannerEntryContentEntryCard;
