import React from 'react';
import PropTypes from 'prop-types';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';

const SmorgStudioMembershipsDeleteUserPopup = ({
  anchorEl,
  onDeleteUser,
  onDismiss,
}) => {
  return (
    <DeleteCardConfirmPopover
      visible
      anchorEl={anchorEl}
      title="Delete user"
      message="This will permanently delete this user. Are you sure?"
      height={160}
      width={280}
      onConfirmDelete={() => {
        onDeleteUser();
      }}
      onDismiss={onDismiss}
      actionButtonLabel="Delete user"
    />
  );
};

SmorgStudioMembershipsDeleteUserPopup.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default SmorgStudioMembershipsDeleteUserPopup;
