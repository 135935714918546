import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import Icon from '@mdi/react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
  mdiCheckOutline,
  mdiSwapHorizontalCircle,
  mdiUndoVariant,
  mdiCalendarMonth,
  mdiArrowAll,
} from '@mdi/js';
import DeleteIcon from '@mui/icons-material/Delete';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import MealTypesChips from './meal_types_chips';
import Nutrition from './nutrition';
import StepInput from '../common/step_input';

const FullWidthMealCard = ({
  title,
  imageUrl,
  mealTypes,
  calories,
  servingsEnabled,
  servings,
  onClick,
  onClickSkip,
  onClickUnskip,
  onClickFindAlternatives,
  onClickComplete,
  onChangeScaleToServings,
  isSkipped,
  isCompleted,
  addToPlannerEnabled,
  copyEnabled,
  quickDuplicateEnabled,
  shoppingEnabled,
  skipEnabled,
  completeEnabled,
  alternativesEnabled,
  deleteEnabled,
  isInMealBasket,
  onClickCopyToPlanner,
  onClickCopyToRecipesBoard,
  onClickQuickDuplicate,
  onClickAddToMealBasket,
  onClickRemoveFromMealBasket,
  onClickDelete,
  scalingInProgress,
  favouriteEnabled,
  onClickFavourite,
  isFavourite,
  onClickUnfavourite,
  isBackgroundProcessing,
}) => {
  return (
    <Card
      className="my-day-card"
      onClick={onClick}
      sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{ display: 'flex' }}
        className={classNames({ skippedCard: isSkipped })}>
        {isSkipped && (
          <div className="skippedCardOverlay">
            <div className="skippedLabel">SKIPPED</div>
          </div>
        )}
        <CardMedia
          component="img"
          className="plannerCardImage"
          style={{ height: '140px' }}
          image={imageUrl || imgPlaceholder}
          title={title}
        />
        <CardContent
          title={title}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '124px',
            width: '198px',
            padding: '8px',
            paddingBottom: '8px !important',
            borderBottom: '1px solid #7B7B7B',
          }}>
          <Typography
            variant="body2"
            component="p"
            style={{
              lineClamp: 3,
              WebkitLineClamp: 3,
              height: '140px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </Typography>
          <Grid container>
            <Grid item xs={6} className="mealCardNutrition">
              {calories && <Nutrition metric="calories" quantity={calories} />}
            </Grid>
            <Grid item xs={6} style={{ display: 'flex' }}>
              {servingsEnabled && !isBackgroundProcessing && (
                <div
                  className="mealCardServingsStepInput"
                  style={{ margin: 'auto' }}>
                  <StepInput
                    value={servings}
                    onChange={(ev) => {
                      // ev.preventDefault();
                      console.log(ev.target.value);
                      onChangeScaleToServings(ev.target.value);
                    }}
                    isLoading={scalingInProgress}
                  />
                </div>
              )}
              {servingsEnabled && isBackgroundProcessing && (
                <CircularProgress size={20} style={{ margin: 'auto' }} />
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <MealTypesChips mealTypes={mealTypes} />
          </Box>
        </CardContent>
      </Box>
      <CardActions sx={{ justifyContent: 'space-around' }}>
        {completeEnabled && isCompleted && (
          <div className="myDayActionCardCompleted">COMPLETED - GOOD JOB!</div>
        )}
        {completeEnabled && !isCompleted && isSkipped && (
          <Tooltip title="Unskip this meal" placement="top-start">
            <IconButton
              aria-label="unskip"
              size="small"
              onClick={(ev) => {
                onClickUnskip();
                ev.stopPropagation();
              }}>
              <Icon
                path={mdiUndoVariant}
                style={{ width: '32px', height: '32px' }}
              />
            </IconButton>
          </Tooltip>
        )}
        {(!completeEnabled || !isCompleted) && (!skipEnabled || !isSkipped) && (
          <>
            {addToPlannerEnabled && (
              <Tooltip title="Copy to plan" placement="top-start">
                <IconButton
                  aria-label="copy to plan"
                  size="small"
                  onClick={(ev) => {
                    onClickCopyToPlanner(ev.target);
                    ev.stopPropagation();
                  }}>
                  <Icon
                    path={mdiCalendarMonth}
                    style={{ width: '24px', height: '24px' }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {favouriteEnabled && (
              <Tooltip
                title="Add meal to your favourites"
                placement="top-start">
                {isFavourite ? (
                  <IconButton
                    aria-label="favourite"
                    size="small"
                    onClick={(ev) => {
                      onClickUnfavourite(ev.target);
                      ev.stopPropagation();
                    }}>
                    <FavoriteIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="favourite"
                    size="small"
                    onClick={(ev) => {
                      onClickFavourite(ev.target);
                      ev.stopPropagation();
                    }}>
                    <FavoriteBorderIcon />
                  </IconButton>
                )}
              </Tooltip>
            )}
            {quickDuplicateEnabled && (
              <Tooltip title="Duplicate this meal" placement="top-start">
                <IconButton
                  aria-label="quick duplicate"
                  size="small"
                  onClick={onClickQuickDuplicate}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {copyEnabled && (
              <Tooltip title="Copy to board" placement="top-start">
                <IconButton
                  aria-label="Copy to board"
                  size="small"
                  onClick={(ev) => {
                    onClickCopyToRecipesBoard(ev.target);
                    ev.stopPropagation();
                  }}>
                  <Icon
                    path={mdiArrowAll}
                    style={{ width: '24px', height: '24px' }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {shoppingEnabled && isInMealBasket && (
              <Tooltip
                title="Don't add this meal to shopping list"
                placement="top-start">
                <IconButton
                  aria-label="remove from shopping list"
                  size="small"
                  onClick={(ev) => {
                    onClickRemoveFromMealBasket();
                    ev.stopPropagation();
                  }}>
                  <ShoppingCartIcon />
                </IconButton>
              </Tooltip>
            )}
            {shoppingEnabled && !isInMealBasket && (
              <Tooltip
                title="Add this meal to a shopping list"
                placement="top-start">
                <IconButton
                  aria-label="add to shopping list"
                  size="small"
                  onClick={(ev) => {
                    onClickAddToMealBasket();
                    ev.stopPropagation();
                  }}>
                  <AddShoppingCartIcon />
                </IconButton>
              </Tooltip>
            )}
            {alternativesEnabled && (
              <Tooltip title="Find alternatives" placement="top-start">
                <IconButton
                  aria-label="find alternatives"
                  size="small"
                  disabled={isSkipped}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onClickFindAlternatives();
                  }}>
                  <Icon
                    path={mdiSwapHorizontalCircle}
                    style={{ width: '32px', height: '32px' }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {deleteEnabled && (
              <Tooltip title="Delete meal" placement="top-start">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={onClickDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {completeEnabled && (
              <Tooltip title="Complete this meal" placement="top-start">
                <IconButton
                  aria-label="complete"
                  size="small"
                  disabled={isSkipped}
                  onClick={(ev) => {
                    onClickComplete();
                    ev.stopPropagation();
                  }}>
                  <Icon
                    path={mdiCheckOutline}
                    style={{ width: '24px', height: '24px' }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {skipEnabled && (
              <Tooltip title="Skip this meal" placement="top-start">
                <IconButton
                  aria-label="skip"
                  size="small"
                  style={{ width: '32px', height: '32px' }}
                  onClick={(ev) => {
                    onClickSkip();
                    ev.stopPropagation();
                  }}>
                  <DeleteIcon style={{ width: '32px', height: '32px' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

FullWidthMealCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  mealTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  calories: PropTypes.number.isRequired,
  servingsEnabled: PropTypes.bool,
  servings: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onClickSkip: PropTypes.func,
  onClickUnskip: PropTypes.func,
  onClickFindAlternatives: PropTypes.func,
  onClickComplete: PropTypes.func,
  onChangeScaleToServings: PropTypes.func.isRequired,
  isSkipped: PropTypes.bool,
  isCompleted: PropTypes.bool,
  addToPlannerEnabled: PropTypes.bool,
  quickDuplicateEnabled: PropTypes.bool,
  copyEnabled: PropTypes.bool,
  shoppingEnabled: PropTypes.bool,
  skipEnabled: PropTypes.bool,
  completeEnabled: PropTypes.bool,
  alternativesEnabled: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  isInMealBasket: PropTypes.bool,
  onClickCopyToPlanner: PropTypes.func,
  onClickCopyToRecipesBoard: PropTypes.func,
  onClickQuickDuplicate: PropTypes.func,
  onClickAddToMealBasket: PropTypes.func,
  onClickRemoveFromMealBasket: PropTypes.func,
  onClickDelete: PropTypes.func,
  scalingInProgress: PropTypes.bool,
  favouriteEnabled: PropTypes.bool,
  isFavourite: PropTypes.bool,
  onClickFavourite: PropTypes.func,
  onClickUnfavourite: PropTypes.func,
  isBackgroundProcessing: PropTypes.bool.isRequired,
};

FullWidthMealCard.defaultProps = {
  servingsEnabled: false,
  servings: null,
  imageUrl: null,
  onClickSkip: () => {},
  onClickUnskip: () => {},
  onClickFindAlternatives: () => {},
  onClickComplete: () => {},
  isSkipped: false,
  isCompleted: false,
  addToPlannerEnabled: false,
  quickDuplicateEnabled: false,
  copyEnabled: false,
  shoppingEnabled: false,
  skipEnabled: false,
  completeEnabled: false,
  alternativesEnabled: false,
  deleteEnabled: false,
  isInMealBasket: false,
  onClickCopyToPlanner: () => {},
  onClickCopyToRecipesBoard: () => {},
  onClickQuickDuplicate: () => {},
  onClickAddToMealBasket: () => {},
  onClickRemoveFromMealBasket: () => {},
  onClickDelete: () => {},
  scalingInProgress: false,
  favouriteEnabled: false,
  onClickFavourite: () => {},
  isFavourite: false,
  onClickUnfavourite: () => {},
};

export default FullWidthMealCard;
