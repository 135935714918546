import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch, useSelector } from 'react-redux';
import { scaleMealAction } from '../../action_creators/meal_action_creators';
import {
  myDayMarkMealCompletedAction,
  myDaySkipMealAction,
  myDayUnskipMealAction,
} from '../../action_creators/my_day_action_creators';
import {
  currentProgrammeEnrollmentSelector,
  isMealCompletedSelector,
  isMealSkippedSelector,
} from '../../reducers/programmes_reducer';
import {
  END_USER_FIND_ALTERNATIVES_ROUTE,
  MY_DAY_MEAL_ROUTE,
} from '../../services/routes';
import FullWidthMealCard from '../meal/full_width_meal_card';
import { linkWithQueryParams } from '../../services/urls';
import { isMealBackgroundProcessingSelector } from '../../reducers/meal_reducer';

const MyDayMealCard = ({ mealID, parentID, calendarDay }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isBackgroundProcessing = useSelector((state) =>
    isMealBackgroundProcessingSelector(state, mealID),
  );

  const meal = useSelector((state) => state.meals[mealID]);
  const title = meal?.recipes[0]?.title;
  const servings = meal?.recipes[0]?.servings;
  const mealType = ((meal?.recipes || [])[0]?.mealTypes || [])[0];

  const calories = useSelector(
    (state) =>
      state.meals[mealID]?.derivedNutrition?.totalNutritionPerServing?.calories,
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const isCompleted = useSelector((state) =>
    isMealCompletedSelector(state, currentProgrammeEnrollment?.id, mealID),
  );

  const isSkipped = useSelector((state) =>
    isMealSkippedSelector(state, currentProgrammeEnrollment?.id, mealID),
  );

  const [scalingInProgress, setScalingInProgress] = useState(false);

  if (!meal) {
    return null;
  }

  const onChangeScaleToServings = async (scaleToServings) => {
    const scaleToServingsNumber = Number(scaleToServings) || 1;
    if (scaleToServingsNumber !== servings) {
      const scaleFactor = scaleToServingsNumber / (servings || 1);
      setScalingInProgress(true);
      try {
        await dispatch(
          scaleMealAction(mealID, scaleFactor, scaleToServingsNumber),
        );
      } finally {
        setScalingInProgress(false);
      }
    }
  };

  return (
    <FullWidthMealCard
      skipEnabled={!!currentProgrammeEnrollment}
      completeEnabled={!!currentProgrammeEnrollment}
      alternativesEnabled
      title={title}
      imageUrl={meal.recipes[0].imageUrl}
      mealTypes={meal.recipes[0].mealTypes}
      calories={calories}
      servings={servings}
      onClick={() => {
        navigate(
          formatRoute(MY_DAY_MEAL_ROUTE, {
            parentID,
            mealID,
          }),
        );
      }}
      onClickSkip={() =>
        dispatch(myDaySkipMealAction(mealID, parentID, calendarDay))
      }
      onClickUnskip={() =>
        dispatch(myDayUnskipMealAction(mealID, parentID, calendarDay))
      }
      onClickFindAlternatives={() =>
        navigate(
          linkWithQueryParams(
            formatRoute(END_USER_FIND_ALTERNATIVES_ROUTE, {
              calendarDay,
            }),
            { mealType, mealId: meal?.id },
          ),
        )
      }
      onClickComplete={() =>
        dispatch(myDayMarkMealCompletedAction(mealID, parentID, calendarDay))
      }
      servingsEnabled
      onChangeScaleToServings={onChangeScaleToServings}
      isSkipped={isSkipped}
      isCompleted={isCompleted}
      scalingInProgress={scalingInProgress}
      isBackgroundProcessing={isBackgroundProcessing}
    />
  );
};

MyDayMealCard.propTypes = {
  mealID: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  calendarDay: PropTypes.string.isRequired,
};

export default MyDayMealCard;
