import {
  getContentEntryOperation,
  updateContentEntryOperation,
  updateSharedContentEntryOperation,
} from '../operations/content_entry_operations';
import { getSharedContentEntryOperation } from '../operations/meal_operations';

export const contentEntrySectionUpdatedAction = (objectID, section, text) => {
  return async (dispatch) => {
    dispatch({
      type: 'CONTENT_ENTRY_SECTION_UPDATED',
      objectID,
      section,
      text,
    });
    await dispatch(syncContentEntryAction(objectID));
  };
};

export const sharedContentEntrySectionUpdatedAction = (
  objectID,
  section,
  text,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_CONTENT_ENTRY_SECTION_UPDATED',
      objectID,
      section,
      text,
    });
    await dispatch(syncSharedContentEntryAction(objectID));
  };
};

export const contentEntryCoverImageUpdatedAction = (objectID, newImageUrl) => {
  return async (dispatch) => {
    dispatch({
      type: 'CONTENT_ENTRY_COVER_IMAGE_UPDATED',
      objectID,
      newImageUrl,
    });
    await dispatch(syncContentEntryAction(objectID));
  };
};

export const sharedContentEntryCoverImageUpdatedAction = (
  objectID,
  newImageUrl,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_CONTENT_ENTRY_COVER_IMAGE_UPDATED',
      objectID,
      newImageUrl,
    });
    await dispatch(syncSharedContentEntryAction(objectID));
  };
};

export const contentEntryAppLinkUpdatedAction = (objectID, newAppLink) => {
  return async (dispatch) => {
    dispatch({
      type: 'CONTENT_ENTRY_APP_LINK_UPDATED',
      objectID,
      newAppLink,
    });
    await dispatch(syncContentEntryAction(objectID));
  };
};

export const contentEntryCoverImageShownInDetailViewChangedAction = (
  objectID,
  newCoverImageShownInDetailView,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'CONTENT_ENTRY_COVER_IMAGE_SHOWN_IN_DETAIL_VIEW_CHANGED',
      objectID,
      newCoverImageShownInDetailView,
    });
    await dispatch(syncContentEntryAction(objectID));
  };
};

export const sharedContentEntryAppLinkUpdatedAction = (
  objectID,
  newAppLink,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_CONTENT_ENTRY_APP_LINK_UPDATED',
      objectID,
      newAppLink,
    });
    await dispatch(syncSharedContentEntryAction(objectID));
  };
};

export const sharedContentEntryCoverImageShownInDetailViewChangedAction = (
  objectID,
  newCoverImageShownInDetailView,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_CONTENT_ENTRY_COVER_IMAGE_SHOWN_IN_DETAIL_VIEW_CHANGED',
      objectID,
      newCoverImageShownInDetailView,
    });
    await dispatch(syncSharedContentEntryAction(objectID));
  };
};

export const syncContentEntryAction = (objectID) => {
  return async (dispatch, getState) => {
    try {
      const updatedContentEntry = await updateContentEntryOperation(
        getState().contentEntries[objectID],
      );
      dispatch({
        type: 'CONTENT_ENTRY_UPDATED_FROM_BACKEND',
        contentEntry: updatedContentEntry,
      });
    } catch (e) {
      console.log(e);
      const contentEntryFromBackend = await getContentEntryOperation(objectID);
      dispatch({
        type: 'CONTENT_ENTRY_UPDATED_FROM_BACKEND',
        contentEntry: contentEntryFromBackend,
      });
    }
  };
};

const syncSharedContentEntryAction = (objectID) => {
  return async (dispatch, getState) => {
    try {
      const updatedContentEntry = await updateSharedContentEntryOperation(
        getState().sharedContentEntries[objectID],
      );
      dispatch({
        type: 'SHARED_CONTENT_ENTRY_UPDATED_FROM_BACKEND',
        sharedContentEntry: updatedContentEntry,
      });
    } catch (e) {
      console.log(e);
      const sharedContentEntryFromBackend =
        await getSharedContentEntryOperation(objectID);
      dispatch({
        type: 'SHARED_CONTENT_ENTRY_UPDATED_FROM_BACKEND',
        sharedContentEntry: sharedContentEntryFromBackend,
      });
    }
  };
};
