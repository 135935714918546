export const getVimeoVideoID = (vimeoURL) =>
  new URL(vimeoURL).pathname.substring(1);

export const isValidVimeoUrl = (vimeoURL) => {
  try {
    return new URL(vimeoURL).hostname.endsWith('vimeo.com');
  } catch (e) {
    return false;
  }
};

export const buildVimeoURL = (videoID) => `https://vimeo.com/${videoID}`;

const VIMEO_OEMBED_API_URL = 'https://vimeo.com/api/oembed.json';

export const getVimeoVideoPlaylistItem = async (videoID) => {
  const queryString = `?${new URLSearchParams({
    url: buildVimeoURL(videoID),
  })}`;
  const fullServiceUrl = `${VIMEO_OEMBED_API_URL}${queryString}`;
  const response = await fetch(fullServiceUrl);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `Vimeo oembed service returned ${response.status} ${errorText}`,
    );
  }
  const jsonBody = await response.json();
  console.log(jsonBody);
  const { title, description, thumbnail_url: thumbnailImageUrl } = jsonBody;
  return {
    videoID,
    title,
    description,
    thumbnailImageUrl,
  };
};

export const getVimeoVideoEmbedCode = (vimeoURL) => {
  const videoID = getVimeoVideoID(vimeoURL);
  return `<iframe title="vimeo-player" src="https://player.vimeo.com/video/${videoID}" width="100%" frameborder="0"    allowfullscreen style="aspect-ratio: 16/9"></iframe>`;
};
