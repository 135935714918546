import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../action_creators/user_action_creators';
import { LOGIN_ROUTE } from '../../services/routes';
import { currentSpaceMembershipSelector } from '../../reducers/user_reducer';
import { SpaceMembershipState, StripeCheckoutSessionState } from '../../API';
import { checkStripeCheckoutSessionOperation } from '../../operations/user_profile_operations';
import { linkWithQueryParams } from '../../services/urls';
import { trackEvents } from '../../operations/tracking_operations';

const StripeCheckoutProcessingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const spaceMembership = useSelector(currentSpaceMembershipSelector);

  const onSignOut = () => {
    dispatch(signOutAction());
    navigate(
      linkWithQueryParams(LOGIN_ROUTE, {
        intoSpace: spaceMembership?.spaceID,
      }),
    );
  };

  useEffect(() => {
    let interval;

    const goToMainScreen = () => {
      if (interval) {
        window.clearInterval(interval);
      }
      navigate('/');
    };

    const doCheck = () =>
      checkStripeCheckoutSessionOperation(spaceMembership.id).then(
        (stripeCheckoutSessionState) => {
          console.log({ stripeCheckoutSessionState });
          if (
            stripeCheckoutSessionState === StripeCheckoutSessionState.PENDING
          ) {
            // Keep running
          } else {
            goToMainScreen();
          }
        },
      );

    if (spaceMembership?.id) {
      if (spaceMembership?.state === SpaceMembershipState.ACTIVE) {
        trackEvents([
          {
            name: 'Subscription purchased',
            args: {
              channel: 'stripe',
            },
          },
        ]).finally(() => goToMainScreen());
      }

      interval = window.setInterval(doCheck, 2000);
    }
  }, [navigate, spaceMembership?.id, spaceMembership?.state]);

  return (
    <div className="subscription-screen">
      <div className="screen-title">Subscribing</div>
      <div>Processing your subscription, please wait...</div>
      <div className="spacer">&nbsp;</div>
      <div className="footer">
        <div>
          <Button
            onClick={onSignOut}
            style={{ color: 'var(--app-primary-font-color)' }}>
            Log out
          </Button>
        </div>
        <div>
          <Button
            onClick={() => navigate('/user?hideMobileNavigation=true')}
            style={{ color: 'var(--app-primary-font-color)' }}>
            My Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StripeCheckoutProcessingScreen;
