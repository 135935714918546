import { MutableRefObject, createRef, useEffect } from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { MessageListManager } from "./controller";
import { CometChatUIKitLoginListener } from "@cometchat/uikit-shared";
/**
 * This Hooks function is a custom React hook designed to manage  functionalities of CometChatMessageList component. It fetches the logged-in user, handles event subscriptions and tracks message IDs for new message retrieval. It plays a key role in maintaining real-time functionality and user interactions in the chat interface.
 **/
function Hooks(
	loggedInUserRef: MutableRefObject<CometChat.User | null>,
	messageListManagerRef: MutableRefObject<any>,
	fetchPreviousMessages: () => void,
	updateMessage: (key: string, mesage: CometChat.BaseMessage, group?: CometChat.Group) => void,
	messagesRequestBuilder: CometChat.MessagesRequestBuilder | undefined,
	user: CometChat.User | undefined,
	group: CometChat.Group | undefined,
	subscribeToEvents: () => (() => void) | undefined,
	messageIdRef: MutableRefObject<any>,
	totalMessagesCountRef: MutableRefObject<any>,
	messageList: CometChat.BaseMessage[],
	onErrorCallback: (error: CometChat.CometChatException) => void,
	setMessageList: (messages: CometChat.BaseMessage[]) => void,
	setScrollListToBottom: (scrollToBottom: boolean) => void,
	smartReplyViewRef: MutableRefObject<any>,
	isOnBottomRef: MutableRefObject<boolean>,
	isFirstReloadRef: MutableRefObject<boolean>,
): void {
	/**
	 * useEffect hook to fetch the logged-in user when we first launch the user/group chat and set isFirstReloadRef to true. This state variable is used to add a connection listener when the chat is launched for the first time.
	 * **/
	useEffect(() => {
		CometChat.getLoggedinUser()
			.then(
				(userObject: CometChat.User | null) => {
					isFirstReloadRef.current = true;
					if (userObject) {
						loggedInUserRef.current = userObject;
					}
				}, (error: CometChat.CometChatException) => {
					onErrorCallback(error);
				}
			);
	}, [onErrorCallback]);
	/**
	* useEffect hook to subscribe to SDK and UI events when the component launches for the first time, or when changing from one chat to another.
	**/

	useEffect(() => {
		let unsubscribeEvents: (() => void) | undefined;
		if (CometChatUIKitLoginListener.getLoggedInUser() && (user || group)) {
			messageListManagerRef.current = {
				previous: new MessageListManager(
					messagesRequestBuilder,
					user,
					group
				)
			}
			MessageListManager.attachListeners(updateMessage);
			unsubscribeEvents = subscribeToEvents();
			setMessageList([]);
			setScrollListToBottom(true);
			isOnBottomRef.current = true;
			fetchPreviousMessages();
			smartReplyViewRef.current = null;
		}
		return () => {
			MessageListManager?.removeListeners?.();
			unsubscribeEvents?.();
		}
	}, [user, group]);
	/**
	 * useEffect hook to store the first and last message ID in the messageList array. These are used to fetch new messages after a particular message when the connection gets reestablished after being interrupted.
	**/
	useEffect(() => {
		totalMessagesCountRef.current = messageList.length;
		if (messageList?.length > 0) {
			messageIdRef.current.prevMessageId = messageList[0].getId();
			messageIdRef.current.nextMessageId = messageList[messageList.length - 1].getId();
		}
	}, [messageList]);

}

export { Hooks };
