import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ProgrammesPicker from './programmes_picker';
import ProgrammesActionsPopupMenu from './programmes_actions_popup_menu';
import {
  createPlanAction,
  exportProgrammeAndObjectsAction,
  programmePlanRecommendMealsAction,
  publishLatestVersionOfProgrammeAction,
  removeMealsAndGRCRecipesFromProgrammePlanAction,
  removePlanAction,
  removeProgrammeAction,
} from '../../action_creators/programmes_action_creators';
import PlanNavigator from './plan_navigator';
import {
  getPaginatedProgrammePlanSelector,
  programmeSelector,
  programmesSelector,
} from '../../reducers/programmes_reducer';
import RecommenderWizardContainer from '../common/recommender_wizard_container';
import {
  PROGRAMME_PLANS_TITLE_PREFIX,
  emptyProgrammePlan,
} from '../../services/programmes';

const TopNavBarMobileProgrammes = ({ selectedProgrammeId, selectedPlanId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const programmes = useSelector(programmesSelector);
  const programme = useSelector((state) =>
    programmeSelector(state, selectedProgrammeId),
  );
  const { prevPlanId, nextPlanId } = useSelector((state) =>
    getPaginatedProgrammePlanSelector(
      state,
      selectedProgrammeId,
      selectedPlanId,
    ),
  );

  const [recommenderWizardVisible, setRecommenderWizardVisible] =
    useState(false);

  const currentPLan = programme.plans.find((pl) => pl.id === selectedPlanId);
  const currentPlanIndex = programme.plans.findIndex(
    (pl) => pl.id === selectedPlanId,
  );
  const nextPlanIdAfterRemoval = nextPlanId || prevPlanId;

  if (!programme) {
    return null;
  }

  const actualPlanId = selectedPlanId || programme.plans[0].id;

  const onCreateProgramme = () => {
    navigate(`/programmes/create`);
  };

  const onEditProgramme = () => {
    navigate(`/programmes/${selectedProgrammeId}/edit`);
  };

  const onEditNutritionConstraints = () => {
    navigate(`/programmes/${selectedProgrammeId}/nutritionConstraints/edit`);
  };

  const onEditPlan = () => {
    navigate(`/programmes/${selectedProgrammeId}/plans/${actualPlanId}/edit`);
  };

  /** TODO duplicate code with TopNavBarMobileProgrammes */
  const onRemoveProgramme = () => {
    const firstRemainingProgramme = programmes.find(
      (b) => b.id !== selectedProgrammeId,
    );
    dispatch(removeProgrammeAction(selectedProgrammeId));
    navigate(`/programmes/${firstRemainingProgramme.id}`);
  };

  const onRemoveCurrentWeek = () => {
    dispatch(removePlanAction(selectedProgrammeId, selectedPlanId));
    navigate(
      `/programmes/${selectedProgrammeId}/plans/${nextPlanIdAfterRemoval}`,
    );
  };

  const onCreateNewWeek = () => {
    dispatch(
      createPlanAction(
        selectedProgrammeId,
        selectedPlanId,
        {
          title: `${PROGRAMME_PLANS_TITLE_PREFIX} ${currentPlanIndex + 2}`,
          id: uuidv4(),
          shortDescription: '',
          description: null,
          coverImageUrl: null,
          days: emptyProgrammePlan(),
          nutritionConstraints: null,
          showNutritionToUsers: null,
        },
        (planId) =>
          navigate(`/programmes/${selectedProgrammeId}/plans/${planId}`),
      ),
    );
  };

  const onRemoveMealsAndGRCRecipesFromPlan = () => {
    dispatch(removeMealsAndGRCRecipesFromProgrammePlanAction(actualPlanId));
  };

  const onPublish = () => {
    dispatch(
      publishLatestVersionOfProgrammeAction(selectedProgrammeId, () =>
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your program has been published successfully',
        }),
      ),
    );
  };

  const onChangePlan = (newPlanId) => {
    navigate(`/programmes/${programme.id}/plans/${newPlanId}`);
  };

  const onRecommendMeals = async (numDaysToPlan, startDayIndexOneBased) =>
    dispatch(
      programmePlanRecommendMealsAction(
        selectedProgrammeId,
        selectedPlanId,
        numDaysToPlan,
        startDayIndexOneBased,
      ),
    );

  const onExport = () => {
    dispatch(exportProgrammeAndObjectsAction(programme.id));
  };

  const onImport = () => {
    // TODO
  };

  return (
    <>
      <div className="topNavBarMobile">
        <div
          style={{
            textAlign: 'left',
            margin: 'auto 0 auto 10px',
            color: 'var(--app-primary-font-color)',
          }}>
          <ProgrammesPicker selectedProgrammeId={selectedProgrammeId} />
        </div>
        <div style={{ color: 'var(--app-primary-font-color)', margin: 'auto' }}>
          <PlanNavigator
            currentPlanId={actualPlanId}
            plans={programme.plans}
            onChangePlan={onChangePlan}
            arrowStyle={{ color: 'var(--app-primary-font-color)' }}
          />
        </div>
        <ProgrammesActionsPopupMenu
          fabStyle={{
            position: 'fixed',
            bottom: '67px',
            right: '10px',
            color: 'var(--app-primary-font-color)',
          }}
          isRemoveCurrentWeekDisabled={programme.plans.length === 1}
          onRemoveCurrentWeek={onRemoveCurrentWeek}
          currentWeekTitle={currentPLan.title}
          onCreateNewWeek={onCreateNewWeek}
          onCreateProgramme={onCreateProgramme}
          onImport={onImport}
          onEditProgramme={onEditProgramme}
          onEditPlan={onEditPlan}
          onEditNutritionConstraints={onEditNutritionConstraints}
          onRecommendMeals={() => setRecommenderWizardVisible(true)}
          onRemoveMealsAndGRCRecipesFromPlan={
            onRemoveMealsAndGRCRecipesFromPlan
          }
          onPublish={onPublish}
          onExport={onExport}
          onRemoveProgramme={onRemoveProgramme}
        />
      </div>
      {recommenderWizardVisible && (
        <RecommenderWizardContainer
          onRecommendMeals={onRecommendMeals}
          onDismiss={() => setRecommenderWizardVisible(false)}
        />
      )}
    </>
  );
};

TopNavBarMobileProgrammes.propTypes = {
  selectedProgrammeId: PropTypes.string.isRequired,
  selectedPlanId: PropTypes.string,
};

TopNavBarMobileProgrammes.defaultProps = {
  selectedPlanId: null,
};

export default TopNavBarMobileProgrammes;
