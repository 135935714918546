import dayjs from 'dayjs';
import {
  EntryType,
  MyDayActionType,
  PlannerEntryType,
  UserProgrammeEnrollmentEventType,
} from '../API';
import {
  arrayIndexWithinDbWeekStartDate,
  dbWeekStartDateStr,
} from '../services/planner';
import {
  currentProgrammeEnrollmentSelector,
  isContentEntryWatchedSelector,
  sharedProgrammeDurationDaysSelector,
} from './programmes_reducer';
import { formatDayAndMonthInLocale } from '../services/dates';
import { daysSince } from '../services/programmes';

export const calendarDayFromJSDate = (jsDate) =>
  jsDate.toISOString().substring(0, 10);

export const programmeStartCalendarDay = (userProgrammeEnrollment) => {
  if (!userProgrammeEnrollment) {
    return null;
  }
  const startEvent = (userProgrammeEnrollment.eventRecords || []).find(
    (event) =>
      event.eventType.includes(UserProgrammeEnrollmentEventType.USER_STARTED),
  );
  if (startEvent) {
    return startEvent.calendarDay;
  }
  const enrollmentEvent = (userProgrammeEnrollment.eventRecords || []).find(
    (event) =>
      event.eventType.includes(UserProgrammeEnrollmentEventType.USER_ENROLLED),
  );
  if (enrollmentEvent) {
    return enrollmentEvent.calendarDay;
  }
  return calendarDayFromJSDate(new Date(userProgrammeEnrollment.enrolledAt));
};

export const programmeTotalDays = (sharedProgramme) =>
  sharedProgramme.plans.flatMap((plan) => plan.days).length;

const getContentEntriesForCalendarDay = (
  sharedProgramme,
  programmeStartDay,
  today,
) => {
  const flattenedProgrammeDays = sharedProgramme.plans.flatMap(
    (plan) => plan.days,
  );
  const dayArrayIndex = arrayIndexWithinDbWeekStartDate(
    programmeStartDay,
    today,
  );
  const programmeDay = flattenedProgrammeDays[dayArrayIndex];
  if (!programmeDay) {
    console.warn(
      `Could not find programme day with index ${dayArrayIndex} in programme with ID ${sharedProgramme.id}`,
    );
    return [];
  }
  return programmeDay.entries.filter(
    (entry) => entry.programmeEntryType === EntryType.CONTENT_ENTRY,
  );
};

const myDayContentCardsSelector = (state, today) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  if (!currentProgrammeEnrollment) {
    console.warn('Programme enrollment not found, this should not happen');
    return [];
  }
  const programmeStartDay = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );
  const { sharedProgrammeID } = currentProgrammeEnrollment;
  const sharedProgramme = state.sharedProgrammes.find(
    (sp) => sp.id === sharedProgrammeID,
  );
  if (!sharedProgramme) {
    console.warn(
      `Could not find shared programme with ID ${sharedProgrammeID}`,
    );
    return [];
  }

  return getContentEntriesForCalendarDay(
    sharedProgramme,
    programmeStartDay,
    today,
  ).map((entry) => ({
    cardType: 'CONTENT_ENTRY',
    calendarDay: today,
    contentEntryID: entry.objectID,
    parentID: sharedProgrammeID,
  }));
};

const myDayMealCardsSelector = (state, today) => {
  const dbWeekStartDate = dbWeekStartDateStr(today);
  const arrayIndex = arrayIndexWithinDbWeekStartDate(dbWeekStartDate, today);
  console.log({ dbWeekStartDate, arrayIndex });
  const dbPlannerWeek = state.plannerWeeks[dbWeekStartDate];
  if (!dbPlannerWeek) {
    console.warn(`Could not find planner week with date ${dbWeekStartDate}`);
    return [];
  }
  const plannerDay = dbPlannerWeek.days[arrayIndex];
  if (!plannerDay) {
    console.warn(`Could not find planner day at index ${arrayIndex}`);
    return [];
  }
  console.log(`${plannerDay.entries.length} planner entries today`);
  return plannerDay.entries
    .filter(
      (entry) =>
        !entry.plannerEntryType ||
        entry.plannerEntryType === PlannerEntryType.MEAL,
    )
    .map((entry) => ({
      cardType: 'MEAL',
      calendarDay: today,
      mealID: entry.mealID || entry.objectID,
      parentID: dbPlannerWeek.id,
    }));
};

export const myDayCardsSelector = (state, today) => {
  const contentCards = myDayContentCardsSelector(state, today);
  const mealCards = myDayMealCardsSelector(state, today);
  const cards = [...contentCards, ...mealCards];
  return cards;
};

const myDayActionRecordsFor = (state, userProgrammeEnrollmentID, calendarDay) =>
  state.myDayActionRecords.filter(
    (r) =>
      r.userProgrammeEnrollmentID === userProgrammeEnrollmentID &&
      r.calendarDay === calendarDay,
  );

const friendlyDayNodeLabel = (calendarDay, today) => {
  if (calendarDay.localeCompare(today) === 0) {
    return 'Today';
  }
  return formatDayAndMonthInLocale(calendarDay, 'US');
};

export const myDayTimelineItemsSelector = (state, today) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  if (!currentProgrammeEnrollment || !!currentProgrammeEnrollment.endedAt) {
    return [];
  }
  const { sharedProgrammeID } = currentProgrammeEnrollment;
  const sharedProgramme = state.sharedProgrammes.find(
    (sp) => sp.id === sharedProgrammeID,
  );
  if (!sharedProgramme) {
    console.warn(
      `Could not find shared programme with ID ${sharedProgrammeID}`,
    );
    return [];
  }
  const programmeStartDate = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );
  let currentDay = today;
  const programmeEndsBefore = dayjs(programmeStartDate)
    .add(programmeTotalDays(sharedProgramme), 'day')
    .format('YYYY-MM-DD');
  console.log({ programmeEndsBefore });
  const isProgrammeEnded = today.localeCompare(programmeEndsBefore) >= 0;
  const nodes = [];
  if (isProgrammeEnded) {
    nodes.push({
      nodeLabel: 'Congratulations, you have completed the program!',
    });
  }
  do {
    // console.log({ currentDay });
    const dayItems = [];
    const contentEntries = getContentEntriesForCalendarDay(
      sharedProgramme,
      programmeStartDate,
      currentDay,
    );
    dayItems.push(
      ...contentEntries.map((entry) => ({
        itemType: 'CONTENT_ENTRY',
        itemID: entry.objectID,
        contentEntry: state.sharedContentEntries[entry.objectID],
      })),
    );
    const myDayActionRecords = myDayActionRecordsFor(
      state,
      currentProgrammeEnrollment.id,
      currentDay,
    );
    dayItems.push(
      ...myDayActionRecords
        .filter((r) => r.actionType === MyDayActionType.COMPLETED)
        .map((r) => ({ itemType: 'MEAL', itemID: r.objectID })),
    );
    if (dayItems.length > 0) {
      nodes.push({
        nodeLabel: friendlyDayNodeLabel(currentDay, today),
        items: dayItems,
      });
    }
    currentDay = dayjs(currentDay).add(-1, 'day').format('YYYY-MM-DD');
  } while (currentDay.localeCompare(programmeStartDate) >= 0);
  nodes.push({ nodeLabel: 'You are at the beginning of the program' });
  return nodes;
};

export const myDayFeaturedContentCardSelector = (state, today) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  const myDayCards = myDayCardsSelector(state, today);
  const todaysContentCards = myDayCards.filter(
    (card) => card.cardType === 'CONTENT_ENTRY',
  );
  return (todaysContentCards || []).find(
    (card) =>
      !isContentEntryWatchedSelector(
        state,
        currentProgrammeEnrollment?.id,
        card.contentEntryID,
      ),
  );
};

export const isDateWithinEnrollmentPeriodSelector = (state, todaysDate) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  if (!currentProgrammeEnrollment) {
    return false;
  }
  const programmeStartDay = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );
  const daysSinceStart = daysSince(todaysDate, programmeStartDay);
  const programmeDuration = sharedProgrammeDurationDaysSelector(
    state,
    currentProgrammeEnrollment.sharedProgrammeID,
  );
  // console.log({ daysSinceStart, programmeDuration });
  return daysSinceStart >= 0 && daysSinceStart < programmeDuration;
};
