import React from 'react';
import PropTypes from 'prop-types';
import NarrowEndUserMealCard from '../meal/narrow_end_user_meal_card';

/** Meal or SharedMeal */
const RecipesEndUserMealCard = ({
  title,
  imageUrl,
  isInMealBasket,
  onClick,
  favouriteEnabled,
  deleteEnabled,
  onAddToPlanner,
  onAddToMealBasket,
  onRemoveFromMealBasket,
  isFavourite,
  onFavourite,
  onClickUnfavourite,
  onDelete,
  isBackgroundProcessing,
}) => {
  return (
    <NarrowEndUserMealCard
      title={title}
      imageUrl={imageUrl}
      onClick={onClick}
      isInMealBasket={isInMealBasket}
      favouriteEnabled={favouriteEnabled}
      isFavourite={isFavourite}
      onFavourite={onFavourite}
      onClickUnfavourite={onClickUnfavourite}
      addToPlannerEnabled
      onAddToPlanner={onAddToPlanner}
      shoppingEnabled
      onAddToMealBasket={onAddToMealBasket}
      onRemoveFromMealBasket={onRemoveFromMealBasket}
      deleteEnabled={deleteEnabled}
      onDelete={onDelete}
      isBackgroundProcessing={isBackgroundProcessing}
    />
  );
};

RecipesEndUserMealCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isInMealBasket: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  favouriteEnabled: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  onAddToPlanner: PropTypes.func.isRequired,
  onAddToMealBasket: PropTypes.func.isRequired,
  onRemoveFromMealBasket: PropTypes.func.isRequired,
  onFavourite: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  onClickUnfavourite: PropTypes.func.isRequired,
  isBackgroundProcessing: PropTypes.bool.isRequired,
};

RecipesEndUserMealCard.defaultProps = {
  imageUrl: null,
  favouriteEnabled: false,
  deleteEnabled: false,
};

export default RecipesEndUserMealCard;
