import { API, graphqlOperation } from 'aws-amplify';
// import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export const listSpaceMembershipsOperation = async () => {
  const response = await API.graphql(
    graphqlOperation(mutations.listSmorgStudioMemberships),
  );
  return response.data.listSmorgStudioMemberships;
};

export const membershipAdminSetExpiresAtOperation = async (
  spaceMembershipID,
  expiresAt,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.smorgStudioMembershipAdminSetExpiresAt, {
      spaceMembershipID,
      expiresAt,
    }),
  );
  return response.data.smorgStudioMembershipAdminSetExpiresAt;
};

export const membershipAdminChangePasswordOperation = async (
  spaceMembershipID,
  newPassword,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.smorgStudioMembershipAdminSetPassword, {
      spaceMembershipID,
      cleartextPassword: newPassword,
    }),
  );
  return response.data.smorgStudioMembershipAdminSetPassword;
};

export const membershipAdminDeleteUserOperation = async (spaceMembershipID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.smorgStudioMembershipAdminDeleteUser, {
      spaceMembershipID,
    }),
  );
  return response.data.smorgStudioMembershipAdminDeleteUser;
};
