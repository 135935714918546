import React, { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import TopNavBar from '../user/top_nav_bar';
import { END_USER_RECIPE_SINGLE_COLLECTION_ROUTE } from '../../services/routes';
import RecipeCollectionsList from './recipe_collections_list';
import { recipeCollectionsBoardsSelector } from '../../reducers/recipes_reducer';
import MealBasketWidget from '../meal/meal_basket_widget';
import { ensureRecipeCollectionsUpToDateAction } from '../../action_creators/shared_entities_action_creators';
import RecipeCollectionsNewBoardModal from './recipe_collections_new_board_modal';
import { mealsExistInBasketSelector } from '../../reducers/meal_reducer';

const RecipeCollectionsView = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const boards = useSelector(recipeCollectionsBoardsSelector);

  useEffect(() => {
    dispatch(ensureRecipeCollectionsUpToDateAction());
  }, [dispatch]);

  const [newBoardModalVisible, setNewBoardModalVisible] = useState(false);

  const onClickAddBoard = () => {
    setNewBoardModalVisible(true);
  };

  const onDismissNewBoardModal = () => {
    setNewBoardModalVisible(false);
  };

  const mealsExistInBasket = useSelector(mealsExistInBasketSelector);

  let newCollectionFabBottomPx = 67;
  if (mealsExistInBasket) {
    newCollectionFabBottomPx += 50;
  }

  return (
    <>
      <TopNavBar />
      <MealBasketWidget />
      <div className="shared-programmes-view">
        <div className="alternatives-page">
          <div className="topNavBarMobile">
            <div className="screen-title" style={{ flexGrow: 1 }}>
              Collections
            </div>
          </div>
          <div
            style={{
              marginTop: '20px',
            }}>
            <div style={{ paddingLeft: '23px' }}>
              <RecipeCollectionsList
                boards={boards}
                onClickBoard={(boardID) =>
                  navigate(
                    formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
                      boardID,
                    }),
                  )
                }
              />
            </div>
          </div>
          {boards.length === 0 && (
            <div
              style={{
                height: 'var(--app-height)',
                width: '100%',
                display: 'flex',
              }}>
              <div style={{ margin: 'auto' }}>Nothing here yet</div>
            </div>
          )}
        </div>
      </div>
      <Fab
        color="warning"
        aria-label="add"
        size="small"
        style={{
          position: 'fixed',
          bottom: `${newCollectionFabBottomPx}px`,
          right: '10px',
          color: 'var(--app-primary-font-color)',
        }}
        onClick={onClickAddBoard}>
        <AddIcon />
      </Fab>
      {newBoardModalVisible && (
        <RecipeCollectionsNewBoardModal onDismiss={onDismissNewBoardModal} />
      )}
    </>
  );
};

export default RecipeCollectionsView;
