import { ActionCreator, AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';
import {
  ActivityObjectType,
  ContainerType,
  ContentEntry,
  CreateContentEntryInput,
  CreateMealInput,
  CreateNoteInput,
  EntryType,
  GRCRecipe,
  Meal,
  Note,
  OriginObjectType,
  Programme,
  ProgrammeEntry,
  ProgrammePlan,
  RecommendationSource,
  RecommendedMenu,
  SharedMeal,
  SharedProgramme,
  SmorgBoard,
  Space,
  UserMyDayActionRecord,
  UserProgrammeEnrollment,
} from '../API';
import {
  addNoteOperation,
  removeNoteOperation,
} from '../operations/note_operations';
import {
  createProgrammeOperation,
  createUserProgrammeEnrollmentOperation,
  getProgrammeOperation,
  removeProgrammeOperation,
  shareProgrammeOperation,
  unshareProgrammeOperation,
  updateProgrammeOperation,
  updateUserProgrammeEnrollmentOperation,
} from '../operations/programmes_operations';
import { cloneObject } from '../operations/utils';
import {
  downloadProgrammeAndObjects,
  mealsMissingInProgrammes,
  planEntryByEntryID,
  prepareNewProgrammeFromImport,
  programmeEntryByEntryID,
  programmeEntryByObjectID,
  programmeObjectIDsOfType,
  programmeObjectIDsOfTypeInPlan,
  programmePlanDayIDFromDayIndex,
  PROGRAMMES_DEFAULT_TARGET_CALORIES,
  readProgrammeAndObjects,
  UpdatePlanInput,
  UpdateProgrammeInput,
} from '../services/programmes';
import {
  addMealOperation,
  getMealsByMealIDsOperation,
  getSharedContentEntriesOperation,
  getSharedMeals,
  removeMeals,
  updateMeal,
} from '../operations/meal_operations';
import {
  addContentEntryOperation,
  removeContentEntryOperation,
} from '../operations/content_entry_operations';
import {
  currentCreatorSpaceIDSelector,
  currentHealthProGroupSelector,
  currentSpaceMembershipSelector,
  userLocaleSelector,
} from '../reducers/user_reducer';
import { recommendMealsOperation } from '../operations/recommender_operations';
import {
  mealsForRecipesBoardSelector,
  recipesBoardByIdSelector,
  smorgStudioSharedBoardIDFromRecipesBoardSelector,
  standaloneRecipesBoardByIdSelector,
} from '../reducers/recipes_reducer';
import { contentEntriesForParentSelector } from '../reducers/content_entry_reducer';
import {
  currentProgrammeEnrollmentSelector,
  getPaginatedProgrammePlanSelector,
  programmeLocalesSelector,
  programmeRecipesBoardIDsSelector,
} from '../reducers/programmes_reducer';
import { syncSpaceAction } from './spaces_action_creators';
import {
  DEFAULT_PROGRAMME_CALORIE_SPLITS,
  DEFAULT_PROGRAMME_MEAL_TYPES,
  calorieSplitsAreValid,
} from '../services/meal_types';
import { getSharedProgrammeOperation } from '../operations/user_profile_operations';
import { dateIsAfter } from '../services/dates';
import { plannerClearedAction } from './planner_action_creators';
import { deduplicate } from '../services/arrays';
import { mealIDsReferencedInBoard } from '../services/smorg_board';
import { reportActivitySignalOnObjectAction } from './user_action_creators';
import { shareSmorgBoardOperation } from '../operations/recipes_operations';
import { endUserEnsureSharedRecipesBoardsAvailableAction } from './shared_entities_action_creators';
import { RECOMMENDER_ENTRY_TYPE_MEAL, RECOMMENDER_MEAL_SOURCE_SMORG } from '../services/recommender';
// import { addStandaloneRecipesBoardShareRecordAction, syncRecipesBoardAction } from './recipes_action_creators';

// eslint-disable-next-line no-unused-vars
type ProgrammeCallback = (_: Programme) => void;

export const createProgrammeAction: ActionCreator<
  ThunkAction<void, void, void, AnyAction>
> = (programmeInput: Programme, callback: ProgrammeCallback) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const input = {
      ...programmeInput,
      groups: currentHealthProGroup && [currentHealthProGroup],
    };
    const programme = await createProgrammeOperation(input);
    dispatch({
      type: 'PROGRAMME_CREATED',
      programme,
    });
    if (callback) {
      callback(programme);
    }
  };
};

export interface IProgrammesState {
  programmes: Array<Programme>;
  meals: Record<string, Meal>;
  notes: Record<string, Note>;
  contentEntries: Record<string, ContentEntry>;
  recipesBoards: Array<SmorgBoard>;
  grcRecipes: Record<string, GRCRecipe>;
  spaces: Array<Space>;
  sharedProgrammes: Array<SharedProgramme>;
  sharedMeals: Record<string, SharedMeal>;
  programmeEnrollments: Array<UserProgrammeEnrollment>;
  myDayActionRecords: Array<UserMyDayActionRecord>;
}

export const syncProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeId: string) => {
  return async (dispatch, getState) => {
    const programme = getState().programmes.find((pr) => pr.id === programmeId);

    if (!programme) {
      console.warn(`Could not find programme with ID ${programmeId}`);
      return;
    }
    try {
      const updatedProgramme = await updateProgrammeOperation(programme);
      dispatch({
        type: 'PROGRAMME_UPDATED_FROM_BACKEND',
        programme: updatedProgramme,
      });
    } catch (e) {
      console.log(e);
      const programmeFromBackend = await getProgrammeOperation(programmeId);
      dispatch({
        type: 'PROGRAMME_UPDATED_FROM_BACKEND',
        programme: programmeFromBackend,
      });
    }
  };
};

export const programmeEntryMovedAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  programmePlanId: string,
  entryID: string,
  fromDayID: string,
  toDayID: string,
  toPosition: number,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'PROGRAMME_ENTRY_MOVED',
      programmeId,
      programmePlanId,
      entryID,
      fromDayID,
      toDayID,
      toPosition,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        programmeId,
      ),
    );
    dispatch(syncProgrammeAction(programmeId));
  };
};

export const mealLinkedToProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  mealID: string,
  programmeId: string,
  programmePlanId: string,
  dayIndexes: Array<number>,
  positions: Array<number> | null | undefined,
  cb: ProgrammeEntryCallback | null | undefined,
) => {
  return async (dispatch, getState) => {
    const programme = getState().programmes.find((pr) => pr.id === programmeId);
    if (!programme) {
      return;
    }
    const plan = programme.plans.find((pl) => pl.id === programmePlanId);
    if (!plan) {
      return;
    }
    const dayIDs = dayIndexes.map((dayIndex) =>
      programmePlanDayIDFromDayIndex(dayIndex, plan.days),
    );
    const entryID = uuidv4();
    dispatch({
      type: 'PROGRAMME_ENTRY_ADDED',
      entryID,
      programmeId,
      programmePlanId,
      entryType: EntryType.MEAL,
      objectID: mealID,
      dayIDs,
      positions,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        programmeId,
      ),
    );
    dispatch(syncProgrammeAction(programmeId));
    const missingMealIDs = mealsMissingInProgrammes(
      getState().programmes,
      getState().meals,
    );
    if (cb) {
      cb(entryID);
    }
    const addedMeals = await getMealsByMealIDsOperation(missingMealIDs);
    dispatch({
      type: 'PROGRAMMES_RELATED_OBJECTS_AVAILABLE',
      referencedMeals: addedMeals,
      referencedGrcRecipes: {},
      ownedMeals: {},
      notes: {},
      contentEntries: {},
    });
  };
};

export const programmeEntryDeletedAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  programmePlanId: string,
  dayID: string,
  entryID: string,
) => {
  return async (dispatch, getState) => {
    try {
      const programme = getState().programmes.find(
        (pr) => pr.id === programmeId,
      );
      if (!programme) {
        return;
      }
      const entry = programmeEntryByEntryID(programme, entryID);
      if (!entry) {
        return;
      }
      if (entry.programmeEntryType === EntryType.NOTE) {
        await removeNoteOperation(entry.objectID);
      }
      if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
        await removeContentEntryOperation(entry.objectID);
      }
    } finally {
      dispatch({
        type: 'PROGRAMME_ENTRY_DELETED',
        programmeId,
        programmePlanId,
        dayID,
        entryID,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      dispatch(syncProgrammeAction(programmeId));
    }
  };
};

export const updateProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  editInput: UpdateProgrammeInput,
  callback: CallableFunction,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'PROGRAMME_UPDATED',
      programmeId,
      editInput,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        programmeId,
      ),
    );
    dispatch(syncProgrammeAction(programmeId));
    if (callback) {
      callback();
    }
  };
};

export const updatePlanAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  planId: string,
  editInput: UpdatePlanInput,
  callback: CallableFunction,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'PROGRAMME_PLAN_UPDATED',
      programmeId,
      planId,
      editInput,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        programmeId,
      ),
    );
    dispatch(syncProgrammeAction(programmeId));
    if (callback) {
      callback();
    }
  };
};

export const createPlanAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  addAfterProgrammePlanId: string,
  programmePlan: ProgrammePlan,
  callback: CallableFunction,
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'PROGRAMME_PLAN_ADDED',
      programmeId,
      programmePlan,
      addAfterProgrammePlanId,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        programmeId,
      ),
    );
    dispatch(syncProgrammeAction(programmeId));
    if (callback) {
      const { prevPlanId, nextPlanId } = getPaginatedProgrammePlanSelector(
        getState(),
        programmeId,
        addAfterProgrammePlanId,
      );
      callback(nextPlanId || prevPlanId);
    }
  };
};

export const removePlanAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeId: string, planId: string) => {
  return async (dispatch, getState) => {
    const { programmes } = getState();
    const programmeIndex = programmes.findIndex((pr) => pr.id === programmeId);
    const programme = programmeIndex !== -1 && programmes[programmeIndex];
    if (!programme) {
      return;
    }
    const noteIDs = programmeObjectIDsOfTypeInPlan(
      programme,
      programmeIndex,
      EntryType.NOTE,
    );
    const contentEntryIDs = programmeObjectIDsOfTypeInPlan(
      programme,
      programmeIndex,
      EntryType.CONTENT_ENTRY,
    );

    try {
      await Promise.all([
        ...noteIDs.map(removeNoteOperation),
        ...contentEntryIDs.map(removeContentEntryOperation),
      ]);
    } finally {
      dispatch({
        type: 'PROGRAMME_PLAN_REMOVED',
        programmeId,
        planId,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      dispatch(syncProgrammeAction(programmeId));
    }
  };
};

export const removeProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeId: string) => {
  return async (dispatch, getState) => {
    const programme = getState().programmes.find((pr) => pr.id === programmeId);
    if (!programme) {
      return;
    }
    const noteIDs = programmeObjectIDsOfType(programme, EntryType.NOTE);
    const contentEntryIDs = programmeObjectIDsOfType(
      programme,
      EntryType.CONTENT_ENTRY,
    );
    const mealIDs = (programme.recipesBoard?.menus || []).flatMap(
      (menu) => menu.mealIDs,
    );
    dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: mealIDs });
    try {
      await Promise.all([
        ...noteIDs.map(removeNoteOperation),
        ...contentEntryIDs.map(removeContentEntryOperation),
        removeMeals(mealIDs),
      ]);
    } finally {
      dispatch({
        type: 'PROGRAMME_REMOVED',
        programmeId,
      });
      await removeProgrammeOperation(programmeId);
    }
  };
};

export const removeMealsAndGRCRecipesFromProgrammePlanAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmePlanId: string) => {
  return async (dispatch, getState) => {
    const programmes = getState().programmes.filter((pr) =>
      pr.plans.some((pl) => pl.id === programmePlanId),
    );
    if (programmes.length !== 1) {
      return;
    }
    const programme = programmes[0];
    try {
      dispatch({
        type: 'PROGRAMME_MEALS_AND_GRC_RECIPES_REMOVED_FROM_PLAN',
        programmeId: programme.id,
        programmePlanId,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programme.id,
        ),
      );
      dispatch(syncProgrammeAction(programme.id));
    }
  };
};

export const removeMealsAndGRCRecipesFromProgrammePlanDayAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmePlanId: string, dayId: string) => {
  return async (dispatch, getState) => {
    const programmes = getState().programmes.filter((pr) =>
      pr.plans.some((pl) => pl.id === programmePlanId),
    );
    if (programmes.length !== 1) {
      return;
    }
    const programme = programmes[0];
    try {
      dispatch({
        type: 'PROGRAMME_MEALS_AND_GRC_RECIPES_REMOVED_FROM_DAY',
        programmeId: programme.id,
        programmePlanId,
        dayId,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programme.id,
        ),
      );
      dispatch(syncProgrammeAction(programme.id));
    }
  };
};

export const programmePlanEntryQuickDuplicateAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmePlanId: string, entryID: string) => {
  return async (dispatch, getState) => {
    const programme = getState().programmes.find((pr) =>
      pr.plans.some((pl) => pl.id === programmePlanId),
    );
    if (!programme) {
      return;
    }
    const currentHealthProGroup = currentHealthProGroupSelector(getState());

    const entry = programmeEntryByEntryID(programme, entryID);
    if (!entry) {
      return;
    }
    let newObject = null;
    if (entry.programmeEntryType === EntryType.MEAL) {
      const clonedMeal = cloneObject(
        getState().meals[entry.objectID],
      ) as unknown as CreateMealInput;
      clonedMeal.recipes = [
        {
          ...clonedMeal.recipes[0],
          title: `Copy of ${clonedMeal.recipes[0].title}`,
        },
      ];
      const insertedMeal = await addMealOperation(
        clonedMeal.smorgBoardID,
        clonedMeal,
        null,
        currentHealthProGroup,
      );
      const recipesBoard = recipesBoardByIdSelector(
        getState(),
        clonedMeal.smorgBoardID,
      );
      const isRecipesBoardEmbeddedInProgramme =
        recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
        !!recipesBoard?.embeddedInContainerID;
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId: programme.id,
          laneId: null,
          meal: insertedMeal,
          position: null,
        });
      } else {
        dispatch({
          type: 'NEW_MEAL_ADDED',
          recipesBoardId: clonedMeal.smorgBoardID,
          laneId: null,
          meal: insertedMeal,
          position: null,
        });
      }
      newObject = insertedMeal;
    }
    if (entry.programmeEntryType === EntryType.NOTE) {
      const clonedNote = cloneObject(
        getState().notes[entry.objectID],
      ) as unknown as CreateNoteInput;
      console.log({ clonedNote });
      const insertedNote = await addNoteOperation(
        programme.id,
        clonedNote,
        null,
        currentHealthProGroup,
      );
      newObject = insertedNote;
    }
    if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
      const clonedContentEntry = cloneObject(
        getState().contentEntries[entry.objectID],
      ) as unknown as CreateContentEntryInput;
      console.log({ clonedContentEntry });
      const insertedContentEntry = await addContentEntryOperation(
        programme.id,
        clonedContentEntry,
        currentHealthProGroup,
      );
      newObject = insertedContentEntry;
    }
    dispatch({
      type: 'PROGRAMME_ENTRY_QUICK_DUPLICATE',
      programmeId: programme.id,
      programmePlanId,
      entryID,
      entryType: entry.programmeEntryType,
      newObject,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        programme.id,
      ),
    );
    dispatch(syncProgrammeAction(programme.id));
  };
};

type ProgrammeEntryCallback = (entryID: string) => void;

/**
 * Takes a programme and creates a duplicate,
 * with meal references _changed_ to point to the
 * new board.
 * WARNING call this only after all meals from the linked recipes board have been loaded
 *   into the Redux store.
 * WARNING only programme entries of type MEAL will be copied.
 */
// TODO needs rewrite to account for embedded recipes board
// export const programmeDeepDuplicateAction: ActionCreator<
//   ThunkAction<void, IProgrammesState, void, AnyAction>
// > = (
//   sourceProgrammeId: string,
//   newTitle: string,
//   mealsLinkedToBoardId: string,
// ) => {
//   return async (dispatch, getState) => {
//     const { meals, programmes, recipesBoards } = getState();
//     const programme = programmes.find((pr) => pr.id === sourceProgrammeId);
//     if (!programme) {
//       console.warn(`Programme ${sourceProgrammeId} not found`);
//       return;
//     }
//     const linkedRecipesBoard = recipesBoards.find(
//       (b) => b.id === mealsLinkedToBoardId,
//     );
//     if (!linkedRecipesBoard) {
//       console.warn(`Recipes board ${mealsLinkedToBoardId} not found`);
//       return;
//     }

//     const lookupMealEntry = (mealID: string) => {
//       const originalMeal = meals[mealID];
//       if (!originalMeal) {
//         console.warn(`Original meal ${mealID} not found`);
//         return null;
//       }
//       const matchingMeal = Object.values(meals).find(
//         (m) =>
//           m.smorgBoardID === mealsLinkedToBoardId &&
//           m.recipes[0].title === originalMeal.recipes[0].title,
//       );
//       if (!matchingMeal) {
//         console.warn(
//           `No matching meal found in board ${mealsLinkedToBoardId} for meal ${mealID}`,
//         );
//         return null;
//       }
//       return matchingMeal.id;
//     };

//     const newProgramme = {
//       ...cloneObject(programme),
//       title: newTitle,
//       plans: programme.plans.map((pl) => ({
//         ...pl,
//         id: uuidv4(),
//         days: pl.days.map((day) => ({
//           ...day,
//           id: uuidv4(),
//           entries: day.entries
//             .filter((entry) => entry.programmeEntryType === EntryType.MEAL)
//             .map((entry) => ({
//               ...entry,
//               id: uuidv4(),
//               objectID: lookupMealEntry(entry.objectID),
//             }))
//             .filter((entry) => !!entry.objectID),
//         })),
//       })),
//     };
//     console.log(JSON.stringify(newProgramme));
//     dispatch(createProgrammeAction(newProgramme, () => {}));
//   };
// };

// const cloneMealWithScaling = async (
//   meal: Meal,
//   parentID: string,
//   spaceMembershipID: string,
//   mealType: string,
//   scaledMealNumServings: number | null | undefined,
//   scaledIngredientsFullText: Array<string> | null | undefined,
//   scaledNutrition: Nutrition | null | undefined,
//   scaledStructuredIngredients: Array<StructuredIngredient> | null | undefined,
//   scaledDerivedIngredientNutrition:
//     | Array<DerivedIngredientNutrition>
//     | null
//     | undefined,
// ) => {
//   const input = mealWithScaledIngredients(
//     cloneObject(meal),
//     scaledMealNumServings,
//     scaledIngredientsFullText,
//     scaledNutrition,
//     scaledStructuredIngredients,
//     scaledDerivedIngredientNutrition,
//   );
//   input.smorgBoardID = parentID;
//   input.spaceMembershipID = spaceMembershipID;
//   if (mealType && input.recipes[0]) {
//     console.log(`Overriding meal type to ${mealType}`);
//     input.recipes[0].mealTypes = [mealType];
//   }
//   const response = (await API.graphql(
//     graphqlOperation(mutations.createMeal, { input }),
//   )) as GraphQLResult<CreateMealMutation>;
//   return response.data?.createMeal as Meal;
// };

const updateMealOverridingMealType = async (
  meal: Meal,
  mealTypeOverride: string,
) => {
  const updatedMeal = {
    ...meal,
    recipes: [{ ...meal.recipes[0], mealTypes: [mealTypeOverride] }],
  };
  return updateMeal(updatedMeal);
};

export const programmePlanDayCopiedAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  fromProgrammePlanId: string,
  fromDayId: string,
  toProgrammeId: string,
  toProgrammePlanId: string,
  dayIndexes: Array<number>,
) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const fromProgrammePlans = getState().programmes.flatMap((pr) =>
      pr.plans.filter((pl) => pl.id === fromProgrammePlanId),
    );
    if (fromProgrammePlans.length !== 1) {
      return;
    }
    const fromPlan = fromProgrammePlans[0];
    const fromDay = fromPlan.days.find((d) => d.id === fromDayId);
    if (!fromDay) {
      return;
    }
    const entriesToCopy = fromDay.entries.filter((entry) =>
      [EntryType.MEAL, EntryType.GRC_RECIPE, EntryType.CONTENT_ENTRY].includes(
        entry.programmeEntryType,
      ),
    );
    const toProgramme = getState().programmes.find(
      (pr) => pr.id === toProgrammeId,
    );
    if (!toProgramme) {
      return;
    }
    const toPlan = toProgramme.plans.find((pl) => pl.id === toProgrammePlanId);
    if (!toPlan) {
      return;
    }
    const toDayIDs = dayIndexes.map((dayIndex) =>
      programmePlanDayIDFromDayIndex(dayIndex, toPlan.days),
    );

    const newEntryPromises = entriesToCopy
      .filter((entry) =>
        [EntryType.MEAL, EntryType.CONTENT_ENTRY].includes(
          entry.programmeEntryType,
        ),
      )
      .map(async (entry) => {
        if (entry.programmeEntryType === EntryType.MEAL) {
          const newEntryID = uuidv4();
          dispatch({
            type: 'PROGRAMME_ENTRY_ADDED',
            entryID: newEntryID,
            programmeId: toProgrammeId,
            programmePlanId: toProgrammePlanId,
            entryType: entry.programmeEntryType,
            objectID: entry.objectID,
            dayIDs: toDayIDs,
          });
          return {
            id: newEntryID,
            programmeEntryType: entry.programmeEntryType,
            objectID: entry.objectID,
          } as ProgrammeEntry;
        }
        if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
          const newContentEntry = cloneObject(
            getState().contentEntries[entry.objectID],
          );
          const copiedContentEntry = await addContentEntryOperation(
            toProgramme.id,
            newContentEntry,
            currentHealthProGroup,
          );
          if (!copiedContentEntry) {
            return null;
          }
          const newEntryID = uuidv4();
          dispatch({
            type: 'PROGRAMME_ENTRY_ADDED',
            entryID: newEntryID,
            programmeId: toProgrammeId,
            programmePlanId: toProgrammePlanId,
            entryType: entry.programmeEntryType,
            objectID: copiedContentEntry.id,
            dayIDs: toDayIDs,
            object: copiedContentEntry,
          });

          return {
            id: newEntryID,
            programmeEntryType: entry.programmeEntryType,
            objectID: copiedContentEntry.id,
          } as ProgrammeEntry;
        }
        // Should never be reached
        return null;
      });

    await Promise.all(newEntryPromises);

    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        toProgrammeId,
      ),
    );

    dispatch(syncProgrammeAction(toProgrammeId));
  };
};

export const programmePlanEntryCopiedToProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  fromProgrammePlanId: string,
  entryID: string,
  toProgrammeId: string,
  toProgrammePlanId: string,
  dayIndexes: Array<number>,
) => {
  // console.log({
  //   fromProgrammePlanId,
  //   entryID,
  //   toProgrammeId,
  //   toProgrammePlanId,
  //   dayIndexes,
  // });
  return async (dispatch, getState) => {
    const fromProgrammePlans = getState().programmes.flatMap((pr) =>
      pr.plans.filter((pl) => pl.id === fromProgrammePlanId),
    );
    if (fromProgrammePlans.length !== 1) {
      return;
    }
    const toProgrammePlans = getState().programmes.flatMap((pr) =>
      pr.plans.filter((pl) => pl.id === toProgrammePlanId),
    );
    const fromPlan = fromProgrammePlans[0];
    const toPlan = toProgrammePlans[0];
    const dayIDs = dayIndexes.map((dayIndex) =>
      programmePlanDayIDFromDayIndex(dayIndex, toPlan.days),
    );
    const entry = planEntryByEntryID(fromPlan, entryID);
    if (!entry) {
      return;
    }
    dispatch({
      type: 'PROGRAMME_ENTRY_ADDED',
      entryID: uuidv4(),
      programmeId: toProgrammeId,
      programmePlanId: toProgrammePlanId,
      entryType: entry.programmeEntryType,
      objectID: entry.objectID,
      dayIDs,
    });

    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.PROGRAMMES,
        toProgrammeId,
      ),
    );

    dispatch(syncProgrammeAction(toProgrammeId));
  };
};

export const programmePlanNewContentEntryAddedAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  programmePlanId: string,
  dayID: string,
  contentEntryWithoutId: ContentEntry,
  cb: ProgrammeEntryCallback | null,
) => {
  return async (dispatch, getState) => {
    try {
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const contentEntry = await addContentEntryOperation(
        programmeId,
        contentEntryWithoutId,
        currentHealthProGroup,
      );
      if (!contentEntry) {
        return;
      }
      dispatch({
        type: 'PROGRAMME_ENTRY_ADDED',
        entryID: uuidv4(),
        programmeId,
        programmePlanId,
        entryType: EntryType.CONTENT_ENTRY,
        objectID: contentEntry.id,
        dayIDs: [dayID],
        object: contentEntry,
      });
      if (cb) {
        const programme = getState().programmes.find(
          (pr) => pr.id === programmeId,
        );
        if (programme) {
          const entry = programmeEntryByObjectID(programme, contentEntry.id);
          if (entry) {
            cb(entry.id);
          }
        }
      }
      // dispatch(
      //   trackAction(
      //     [
      //       {
      //         name: 'Add new content entry',
      //         args: {
      //           title: contentEntryWithoutId.title,
      //           section: 'programmes',
      //         },
      //       },
      //     ],
      //     ['numContentEntriesAdded'],
      //   ),
      // );
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      dispatch(syncProgrammeAction(programmeId));
    }
  };
};

export const programmePlanRecommendMealsAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (
  programmeId: string,
  programmePlanId: string,
  numDaysToPlan,
  startDayIndexOneBased,
) => {
  return async (dispatch, getState) => {
    const { meals, programmes } = getState();
    const programme = programmes.find((p) => p.id === programmeId);
    if (!programme) {
      return;
    }
    const plan = programme.plans.find((p) => p.id === programmePlanId);
    if (!plan) {
      return;
    }
    const { personalisedMealScaling } = programme;
    const recipesBoardsIDs = programmeRecipesBoardIDsSelector(
      getState(),
      programmeId,
    );
    const existingDays = plan.days.map((day, indexZeroBased) => ({
      dayIndex: indexZeroBased + 1,
      entries: day.entries
        .filter((entry) => [EntryType.MEAL].includes(entry.programmeEntryType))
        .filter(
          (entry) =>
            entry.programmeEntryType === EntryType.MEAL &&
            !!(meals[entry.objectID]?.recipes || [])[0],
        )
        .map((entry) => ({
          entryType: RECOMMENDER_ENTRY_TYPE_MEAL,
          mealType: ((meals[entry.objectID]?.recipes || [])[0].mealTypes ||
            [])[0],
          meals: [
            {
              mealSource: RECOMMENDER_MEAL_SOURCE_SMORG,
              id: entry.objectID,
              servings: meals[entry.objectID].recipes[0]?.servings || 1,
              categoryTags: [],
              scaleFactor: 1.0,
            },
          ],
        })),
    }));

    const programmeHasValidMealTypesAndCalorieSplits =
      (programme.mealTypes || []).length > 0 &&
      calorieSplitsAreValid(programme.mealTypes, programme.calorieSplits);

    const mealTypes = programmeHasValidMealTypesAndCalorieSplits
      ? programme.mealTypes
      : DEFAULT_PROGRAMME_MEAL_TYPES;

    const calorieSplits = programmeHasValidMealTypesAndCalorieSplits
      ? programme.calorieSplits
      : DEFAULT_PROGRAMME_CALORIE_SPLITS;

    try {
      const recommendedMenu = (await recommendMealsOperation(
        mealTypes,
        numDaysToPlan,
        existingDays,
        startDayIndexOneBased,
        programme.nutritionConstraints,
        {},
        recipesBoardsIDs,
        calorieSplits,
        1.0,
        personalisedMealScaling,
        [RecommendationSource.SMORG],
        PROGRAMMES_DEFAULT_TARGET_CALORIES,
        programmeLocalesSelector(getState(), programmeId),
      )) as RecommendedMenu;
      console.log(JSON.stringify(recommendedMenu));

      if (recommendedMenu.errorMessage) {
        console.warn(recommendedMenu.errorMessage);
        const notificationText =
          recipesBoardsIDs.length === 0
            ? 'Not enough recipes are available to fit the plan. Check if enough recipes are associated with this program, or try relaxing the program constraints'
            : 'Something went wrong, please try again';
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText,
        });
        return;
      }

      // If personalised meal scaling, we don't reference the Smorg meal, instead we make
      // a copy of the Smorg meal and apply the scaled ingredients.
      const mealUpdateJobs = personalisedMealScaling
        ? []
        : recommendedMenu.days.flatMap((recommendedDay) =>
            recommendedDay.entries
              .filter((e) => e.entryType === RECOMMENDER_ENTRY_TYPE_MEAL)
              .flatMap((recommendedEntry) => {
                const mealTypeSlot = recommendedEntry.mealType;
                return recommendedEntry.meals
                  .filter((m) => m.mealSource === RECOMMENDER_MEAL_SOURCE_SMORG)
                  .map((m) => ({
                    mealID: m.id,
                    mealTypeSlot,
                    scaledMealNumServings: m.servings,
                    scaledIngredientsFullText: m.scaledIngredientsFullText,
                    scaledNutrition: m.scaledNutrition,
                    scaledStructuredIngredients: m.scaledStructuredIngredients,
                    scaledDerivedIngredientNutrition:
                      m.scaledDerivedIngredientNutrition,
                  }));
              }),
          );

      // const smorgMealImportJobs = personalisedMealScaling
      //   ? recommendedMenu.days.flatMap((recommendedDay) =>
      //       recommendedDay.entries
      //         .filter((e) => e.entryType === RECOMMENDER_ENTRY_TYPE_MEAL)
      //         .flatMap((recommendedEntry) => {
      //           const mealTypeSlot = recommendedEntry.mealType;
      //           return recommendedEntry.meals
      //             .filter((m) => m.mealSource === RECOMMENDER_MEAL_SOURCE_SMORG)
      //             .map((m) => ({
      //               mealID: m.id,
      //               mealTypeSlot,
      //               scaledMealNumServings: m.servings,
      //               scaledIngredientsFullText: m.scaledIngredientsFullText,
      //               scaledNutrition: m.scaledNutrition,
      //               scaledStructuredIngredients: m.scaledStructuredIngredients,
      //               scaledDerivedIngredientNutrition:
      //                 m.scaledDerivedIngredientNutrition,
      //             }));
      //         }),
      //     )
      //   : [];

      // const smorgMealIDsToImport = smorgMealImportJobs.map((job) => job.mealID);

      // const smorgImportedMeals = await Promise.all(
      //   smorgMealImportJobs.map((job) => {
      //     const meal = getState().meals[job.mealID];
      //     return cloneMealWithScaling(
      //       meal,
      //       databaseRecipesBoardID,  // WARNING, need to put these scaled meals into the embedded recipesBoard but it will create duplicates!
      //       currentSpaceMembershipID,
      //       job.mealTypeSlot,
      //       job.scaledMealNumServings,
      //       job.scaledIngredientsFullText,
      //       job.scaledNutrition,
      //       job.scaledStructuredIngredients,
      //       job.scaledDerivedIngredientNutrition,
      //     );
      //   }),
      // );

      await Promise.all(
        mealUpdateJobs.map((job) => {
          const meal = getState().meals[job.mealID];
          if (!meal) {
            console.warn(`Could not find meal with ID ${job.mealID} to update`);
            return null;
          }
          return updateMealOverridingMealType(meal, job.mealTypeSlot);
        }),
      );

      const recommendedMenuWithProgrammeEntries = {
        ...recommendedMenu,
        days: recommendedMenu.days.map((recommendedDay) => {
          const newEntries = recommendedDay.entries
            .flatMap((e) => e.meals)
            .map((recommendedMeal) => {
              // recommendedMeal.mealSource === RECOMMENDER_MEAL_SOURCE_SMORG
              // if (personalisedMealScaling) {
              //   const mealIDToReplace = recommendedMeal.id;
              //   const index = smorgMealIDsToImport.indexOf(mealIDToReplace);
              //   if (index === -1) {
              //     console.warn(
              //       `Could not find imported meal for meal ID ${mealIDToReplace}`,
              //     );
              //     return null;
              //   }
              //   return smorgImportedMeals[index].id;
              // }
              return recommendedMeal.id;
            })
            .filter((mealID) => mealID)
            .map((mealID) => ({
              id: uuidv4(),
              programmeEntryType: EntryType.MEAL,
              objectID: mealID,
            }));
          return { ...recommendedDay, entries: newEntries };
        }),
      };
      dispatch({
        type: 'PROGRAMME_PLAN_EXTENDED_WITH_RECOMMENDED_ENTRIES',
        programmeId,
        programmePlanId,
        recommendedMenuWithProgrammeEntries,
        startDayIndexOneBased,
      });
      const referencedMealsObj: Record<string, Meal> = {};
      // smorgImportedMeals.forEach((smorgImportedMeal) => {
      //   referencedMealsObj[smorgImportedMeal.id] = smorgImportedMeal;
      // });
      dispatch({
        type: 'PROGRAMMES_RELATED_OBJECTS_AVAILABLE',
        referencedMeals: referencedMealsObj,
        referencedGrcRecipes: {},
        ownedMeals: {},
        notes: {},
        contentEntries: {},
      });
    } catch (e) {
      console.warn(e);
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Something went wrong, please try again',
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      dispatch(syncProgrammeAction(programmeId));
    }
  };
};

export const addProgrammeShareRecordAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeID, sharedProgrammeID, version, updatedOn) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ADD_PROGRAMME_SHARE_RECORD',
        programmeID,
        sharedProgrammeID,
        version,
        updatedOn,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeID,
        ),
      );
      await dispatch(syncProgrammeAction(programmeID));
    }
  };
};

const updateProgrammePreviewsAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (ownedSpaceID) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'COPY_SHARED_PROGRAMME_PREVIEWS_TO_SPACE',
        spaceID: ownedSpaceID,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(ActivityObjectType.SPACE, null),
      );
      await dispatch(syncSpaceAction(ownedSpaceID));
    }
  };
};

const publishVersionOfProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeID, ownedSpaceID, versionStr, cb, sharedProgrammePrevVersion) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const programme = getState().programmes.find((p) => p.id === programmeID);
    if (!programme) {
      return;
    }
    const programmeRecipesBoardID = programme.recipesBoard?.id;
    const linkedMeals = mealsForRecipesBoardSelector(
      getState(),
      programmeRecipesBoardID,
    );
    const linkedDatabaseMeals = programme.databaseRecipesBoard?.id
      ? mealsForRecipesBoardSelector(
          getState(),
          programme.databaseRecipesBoard?.id,
        )
      : {};
    const linkedContentEntries = contentEntriesForParentSelector(
      getState(),
      programmeID,
    ) as Record<string, ContentEntry>;
    const sharedProgrammeRecipesBoard = programme.recipesBoard
      ? {
          ...programme.recipesBoard,
          id: sharedProgrammePrevVersion?.recipesBoard?.id || uuidv4(),
        }
      : null;
    const sharedDatabaseRecipesBoard = programme.databaseRecipesBoard
      ? {
          ...programme.databaseRecipesBoard,
          id: sharedProgrammePrevVersion?.databaseRecipesBoard?.id || uuidv4(),
        }
      : null;

    const associatedRecipesBoardIDs = programme.recipesBoardIDs || [];
    const publishAssociatedRecipesBoardPromises = associatedRecipesBoardIDs.map(
      async (recipesBoardID) => {
        const existingSharedBoardID =
          smorgStudioSharedBoardIDFromRecipesBoardSelector(
            getState(),
            recipesBoardID,
          );
        const recipesBoard = standaloneRecipesBoardByIdSelector(
          getState(),
          recipesBoardID,
        );
        const mealsForSmorgBoard = mealsForRecipesBoardSelector(
          getState(),
          recipesBoardID,
        );
        const sb = await shareSmorgBoardOperation(
          recipesBoard,
          mealsForSmorgBoard,
          existingSharedBoardID,
        );
        // TODO
        // dispatch(
        //   addStandaloneRecipesBoardShareRecordAction(
        //     recipesBoardID,
        //     sb.id,
        //     '1',
        //     sb.updatedAt,
        //   ),
        // );
        // dispatch(syncRecipesBoardAction(recipesBoardID));
        return sb;
      },
    );
    const sharedAssociatedRecipesBoards = await Promise.all(
      publishAssociatedRecipesBoardPromises,
    );
    const sharedAssociatedRecipesBoardIDs = sharedAssociatedRecipesBoards.map(
      (sb) => sb.id,
    );
    await dispatch(
      endUserEnsureSharedRecipesBoardsAvailableAction(
        sharedAssociatedRecipesBoardIDs,
      ),
    );
    const mealIDToSharedMealIDMapping = {} as Record<string, string>;
    // eslint-disable-next-line no-restricted-syntax
    for (const sharedMeal of Object.values(getState().sharedMeals)) {
      if (
        sharedMeal.origin &&
        sharedMeal.origin.originObjectType === OriginObjectType.MEAL
      ) {
        mealIDToSharedMealIDMapping[sharedMeal.origin.originObjectID] =
          sharedMeal.id;
      }
    }
    console.log(
      `mealIDToSharedMealIDMapping has ${
        Object.keys(mealIDToSharedMealIDMapping).length
      } mappings`,
    );
    const scratchPlans = programme.plans.map(
      (plan) =>
        ({
          ...plan,
          days: plan.days.map((day) => ({
            ...day,
            entries: day.entries
              .map((entry) => {
                if (entry.programmeEntryType !== EntryType.MEAL) {
                  // GRC recipe entries pass through unchanged
                  return entry;
                }

                const sourceMealID = entry.objectID;
                const sharedMealID = mealIDToSharedMealIDMapping[sourceMealID];
                if (!sharedMealID) {
                  console.warn(
                    `Cannot find mapping from meal ID ${sourceMealID} to shared meal`,
                  );
                  // HACK. For meal IDs pointing to the embedded recipes boards,
                  // `shareProgrammeOperation` will perform the ID rewrite.
                  return entry;
                }
                return {
                  ...entry,
                  objectID: sharedMealID,
                };
              })
              .filter((entry) => !!entry),
          })),
        } as ProgrammePlan),
    );
    /*
        Publishing a shared programme must preserve the following from the previous version:
        - the ID of the SharedProgramme object
        - the IDs of the embedded recipes board and embedded database recipes board
        And must update the updatedAt timestamp.
        Ideally in the future we should also run a difference, only replace changed content (meals etc),
        and preserve the unchanged content objects.
        The call to shareProgrammeOperation must preserve the shared meal IDs from associated recipes boards,
        set from here.
     */
    const newSharedProgramme = await shareProgrammeOperation(
      {
        id: sharedProgrammePrevVersion?.id,
        spaceID: ownedSpaceID,
        programmeID: programme.id,
        title: programme.title,
        shortDescription: programme.shortDescription,
        description: programme.description,
        coverImageUrl: programme.coverImageUrl,
        plans: scratchPlans,
        nutritionConstraints: programme.nutritionConstraints,
        copyMealsExactly: programme.copyMealsExactly,
        showNutritionToUsers: programme.showNutritionToUsers,
        personalisedMealScaling: programme.personalisedMealScaling,
        recipesBoard: sharedProgrammeRecipesBoard,
        databaseRecipesBoard: sharedDatabaseRecipesBoard,
        sharedRecipesBoardIDs: sharedAssociatedRecipesBoardIDs,
        onboardingConfiguration: programme.onboardingConfiguration,
        locales: programme.locales || [userLocaleSelector(getState())],
        mealTypes: programme.mealTypes,
        calorieSplits: programme.calorieSplits,
        categoryTags: programme.categoryTags,
        version: versionStr,
        availableInMembershipTierIDs: programme.availableInMembershipTierIDs,
        groups: currentHealthProGroup && [currentHealthProGroup],
      },
      linkedMeals,
      linkedContentEntries,
      linkedDatabaseMeals,
    );
    dispatch({
      type: 'SHARED_PROGRAMME_AVAILABLE',
      sharedProgramme: newSharedProgramme,
    });
    dispatch(
      addProgrammeShareRecordAction(
        programmeID,
        newSharedProgramme.id,
        versionStr,
        newSharedProgramme.updatedAt,
      ),
    );
    cb();
  };
};

const unpublishSharedProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (sharedProgramme, oldSharedMealIDs) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_PROGRAMME_DELETED',
      sharedProgrammeID: sharedProgramme.id,
      oldSharedMealIDs,
    });
    await unshareProgrammeOperation(sharedProgramme, oldSharedMealIDs);
  };
};

export const publishLatestVersionOfProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeID, cb) => {
  return async (dispatch, getState) => {
    const ownedSpaceID = currentCreatorSpaceIDSelector(getState());
    if (!ownedSpaceID) {
      return;
    }
    const sharedProgramme = getState().sharedProgrammes.find(
      (sp) => sp.programmeID === programmeID,
    );
    if (!sharedProgramme) {
      // Publish for the first time
      console.log(`Publishing shared programme`);
      await dispatch(
        publishVersionOfProgrammeAction(programmeID, ownedSpaceID, '1', cb),
      );
    } else {
      const currentVersionInt = parseInt(sharedProgramme.version, 10);
      const nextVersionStr = (currentVersionInt + 1).toString();
      const existingSharedProgrammeID = sharedProgramme.id;
      const linkedSharedMeals = {} as Record<string, Meal>;
      const sharedProgrammeRecipesBoardID = sharedProgramme.recipesBoard?.id;
      if (sharedProgrammeRecipesBoardID) {
        const sharedMealsLinkedToProgrammeRecipesBoard = (await getSharedMeals(
          sharedProgrammeRecipesBoardID,
        )) as Record<string, Meal>;
        // eslint-disable-next-line no-restricted-syntax
        for (const sharedMealID of Object.keys(
          sharedMealsLinkedToProgrammeRecipesBoard,
        )) {
          linkedSharedMeals[sharedMealID] =
            sharedMealsLinkedToProgrammeRecipesBoard[sharedMealID];
        }
      }
      const sharedProgrammeDatabaseRecipesBoardID =
        sharedProgramme.databaseRecipesBoard?.id;
      if (sharedProgrammeDatabaseRecipesBoardID) {
        const sharedMealsLinkedToDatabaseRecipesBoard = (await getSharedMeals(
          sharedProgrammeDatabaseRecipesBoardID,
        )) as Record<string, Meal>;
        // eslint-disable-next-line no-restricted-syntax
        for (const sharedMealID of Object.keys(
          sharedMealsLinkedToDatabaseRecipesBoard,
        )) {
          linkedSharedMeals[sharedMealID] =
            sharedMealsLinkedToDatabaseRecipesBoard[sharedMealID];
        }
      }
      console.log(`Unpublishing shared programme ${existingSharedProgrammeID}`);
      const oldSharedMealIDs = Object.keys(linkedSharedMeals);
      await dispatch(
        unpublishSharedProgrammeAction(sharedProgramme, oldSharedMealIDs),
      );
      console.log(`Publishing shared programme ${existingSharedProgrammeID}`);
      await dispatch(
        publishVersionOfProgrammeAction(
          programmeID,
          ownedSpaceID,
          nextVersionStr,
          cb,
          sharedProgramme,
        ),
      );
    }
    dispatch(updateProgrammePreviewsAction(ownedSpaceID));
  };
};

export const enrollIntoProgrammeAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (sharedProgrammeID, programmeStartDate, shouldDeleteExistingMeals) => {
  return async (dispatch, getState) => {
    const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(
      getState(),
    );
    if (currentProgrammeEnrollment) {
      console.log(
        `Ending programme enrollment ${currentProgrammeEnrollment.id}`,
      );
      if (shouldDeleteExistingMeals) {
        await dispatch(
          plannerClearedAction(
            dayjs(programmeStartDate).format('YYYY-MM-DD'),
            dayjs(programmeStartDate).add(7, 'day').format('YYYY-MM-DD'),
          ),
        );
      }
      await dispatch(
        endProgrammeEnrollmentAction(currentProgrammeEnrollment.id),
      );
    }
    console.log(`Creating enrollment into programme ${sharedProgrammeID}`);
    const currentSpaceMembership = currentSpaceMembershipSelector(getState());
    const spaceOnboardingAnswers =
      currentSpaceMembership?.onboardingAnswers || [];
    const spaceCustomTargetCalories =
      currentSpaceMembership?.customTargetCalories;
    console.log(`Before createUserProgrammeEnrollmentOperation`);
    const programmeEnrollment = await createUserProgrammeEnrollmentOperation(
      currentSpaceMembership.id,
      sharedProgrammeID,
      spaceOnboardingAnswers,
      programmeStartDate,
      spaceCustomTargetCalories,
    );
    console.log(`After createUserProgrammeEnrollmentOperation`);
    dispatch({ type: 'USER_ENROLLED_INTO_PROGRAMME', programmeEnrollment });

    const sharedProgrammeRecipesBoardId = getState().sharedProgrammes.find(
      (spr) => spr.id === sharedProgrammeID,
    )?.recipesBoard?.id;
    if (sharedProgrammeRecipesBoardId) {
      dispatch({
        type: 'RECIPES_BOARD_SELECTED',
        recipesBoardId: sharedProgrammeRecipesBoardId,
      });
    }
  };
};

export const endProgrammeEnrollmentAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeEnrollmentID) => {
  return async (dispatch, getState) => {
    const programmeEnrollment = getState().programmeEnrollments.find(
      (pe) => pe.id === programmeEnrollmentID,
    );
    if (!programmeEnrollment) {
      return;
    }
    programmeEnrollment.endedAt = new Date().toISOString();
    await updateUserProgrammeEnrollmentOperation(programmeEnrollment);
    dispatch({
      type: 'USER_ENDED_PROGRAMME_ENROLLMENT',
      programmeEnrollmentID,
    });
  };
};

export const ensureSharedProgrammeLatestVersionLoadedAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (sharedProgrammeID) => {
  return async (dispatch, getState) => {
    const localSharedProgrammeUpdatedAt = getState().sharedProgrammes.find(
      (sp) => sp.id === sharedProgrammeID,
    )?.updatedAt;
    const latestSharedProgramme = await getSharedProgrammeOperation(
      sharedProgrammeID,
    );
    if (!latestSharedProgramme) {
      console.warn(`Failed to retrieve shared programme ${sharedProgrammeID}`);
      return;
    }
    if (
      localSharedProgrammeUpdatedAt !== null &&
      localSharedProgrammeUpdatedAt !== undefined &&
      !dateIsAfter(
        latestSharedProgramme.updatedAt,
        localSharedProgrammeUpdatedAt,
      )
    ) {
      console.log(`Shared programme ${sharedProgrammeID} is up to date`);
      return;
    }

    console.log(
      `Downloading latest version of shared programme ${sharedProgrammeID}`,
    );

    if (
      latestSharedProgramme.sharedRecipesBoardIDs &&
      latestSharedProgramme.sharedRecipesBoardIDs.length > 0
    ) {
      await dispatch(
        endUserEnsureSharedRecipesBoardsAvailableAction(
          latestSharedProgramme.sharedRecipesBoardIDs,
        ),
      );
    }

    const linkedResourcesPromises = [
      getSharedContentEntriesOperation(latestSharedProgramme.id).then(
        (sharedContentEntriesObj) => {
          dispatch({
            type: 'SHARED_CONTENT_ENTRIES_AVAILABLE',
            sharedContentEntries: sharedContentEntriesObj,
          });
        },
      ),
    ];
    if (latestSharedProgramme?.recipesBoard?.id) {
      linkedResourcesPromises.push(
        getSharedMeals(latestSharedProgramme.recipesBoard.id).then(
          (sharedMealsObj) => {
            dispatch({
              type: 'SHARED_MEALS_AVAILABLE',
              sharedMeals: sharedMealsObj,
            });
          },
        ),
      );
    }
    if (latestSharedProgramme?.databaseRecipesBoard?.id) {
      linkedResourcesPromises.push(
        getSharedMeals(latestSharedProgramme.databaseRecipesBoard.id).then(
          (sharedMealsObj) => {
            dispatch({
              type: 'SHARED_MEALS_AVAILABLE',
              sharedMeals: sharedMealsObj,
            });
          },
        ),
      );
    }

    await Promise.all(linkedResourcesPromises);

    dispatch({
      type: 'SHARED_PROGRAMMES_AVAILABLE',
      sharedProgrammes: [latestSharedProgramme],
    });
  };
};

export const exportProgrammeAndObjectsAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (programmeID) => {
  return async (_, getState) => {
    const programme = getState().programmes.find((p) => p.id === programmeID);
    if (!programme) {
      return;
    }
    const referencedMealIDs = deduplicate([
      ...mealIDsReferencedInBoard(programme.recipesBoard),
      ...mealIDsReferencedInBoard(programme.databaseRecipesBoard),
      ...programmeObjectIDsOfType(programme, EntryType.MEAL),
    ]);
    const referencedContentEntryIDs = programmeObjectIDsOfType(
      programme,
      EntryType.CONTENT_ENTRY,
    );
    const referencedMeals = referencedMealIDs
      .map((mealID) => getState().meals[mealID])
      .filter((meal) => !!meal);
    const referencedContentEntries = referencedContentEntryIDs
      .map((contentEntryID) => getState().contentEntries[contentEntryID])
      .filter((contentEntry) => !!contentEntry);
    await downloadProgrammeAndObjects(
      programme,
      referencedMeals,
      referencedContentEntries,
    );
  };
};

/* eslint-disable no-restricted-syntax */
export const importProgrammeAndObjectsAction: ActionCreator<
  ThunkAction<void, IProgrammesState, void, AnyAction>
> = (blob, cb) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const { programme, meals, contentEntries } = await readProgrammeAndObjects(
      blob,
    );
    if (!programme) {
      console.warn('Import failed');
      return;
    }
    console.log(
      `Importing programme ${programme.id} with ${meals.length} meals`,
    );
    const {
      programme: newProgramme,
      meals: newMeals,
      contentEntries: newContentEntries,
    } = prepareNewProgrammeFromImport(programme, meals, contentEntries);
    const cleanProgramme = { ...newProgramme } as any;
    delete cleanProgramme.createdAt;
    delete cleanProgramme.updatedAt;
    cleanProgramme.groups = currentHealthProGroup && [currentHealthProGroup];
    await createProgrammeOperation(cleanProgramme);
    const embeddedRecipesBoardMealIDs = mealIDsReferencedInBoard(
      newProgramme.recipesBoard,
    );
    const parentID = newProgramme.recipesBoard?.id;
    const addEmbeddedRecipesBoardMealsPromises = newMeals
      .filter((m) => embeddedRecipesBoardMealIDs.includes(m.id))
      .map((meal) => {
        const cleanMeal = { ...meal } as any;
        delete cleanMeal.createdAt;
        delete cleanMeal.updatedAt;
        return addMealOperation(
          parentID,
          cleanMeal,
          null,
          currentHealthProGroup,
        );
      });
    await Promise.all(addEmbeddedRecipesBoardMealsPromises);
    if (newProgramme.databaseRecipesBoard) {
      const databaseRecipesBoardMealIDs = mealIDsReferencedInBoard(
        newProgramme.databaseRecipesBoard,
      );
      const dbRecipesBoardID = newProgramme.databaseRecipesBoard.id;
      const addDatabaseRecipesBoardMealsPromises = newMeals
        .filter((m) => databaseRecipesBoardMealIDs.includes(m.id))
        .map((meal) => {
          const cleanMeal = { ...meal } as any;
          delete cleanMeal.createdAt;
          delete cleanMeal.updatedAt;
          return addMealOperation(
            dbRecipesBoardID,
            cleanMeal,
            null,
            currentHealthProGroup,
          );
        });
      await Promise.all(addDatabaseRecipesBoardMealsPromises);
    }
    await Promise.all(
      newContentEntries.map((ce) => {
        const cleanContentEntry = { ...ce } as any;
        cleanContentEntry.tags ||= [];
        delete cleanContentEntry.createdAt;
        delete cleanContentEntry.updatedAt;
        return addContentEntryOperation(
          newProgramme.id,
          cleanContentEntry,
          currentHealthProGroup,
        );
      }),
    );
    const mealsObj = {} as Record<string, Meal>;
    for (const meal of newMeals) {
      mealsObj[meal.id] = meal;
    }
    const contentEntriesObj = {} as Record<string, ContentEntry>;
    for (const contentEntry of newContentEntries) {
      contentEntriesObj[contentEntry.id] = contentEntry;
    }
    dispatch({
      type: 'PROGRAMMES_AVAILABLE',
      programmes: [newProgramme],
    });
    dispatch({
      type: 'PROGRAMMES_RELATED_OBJECTS_AVAILABLE',
      referencedMeals: mealsObj,
      contentEntries: contentEntriesObj,
    });
    cb(newProgramme);
  };
};
