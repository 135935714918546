/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const advancedSearchGRC = /* GraphQL */ `mutation AdvancedSearchGRC($searchCriteria: SearchCriteria!, $offset: Int) {
  advancedSearchGRC(searchCriteria: $searchCriteria, offset: $offset) {
    data {
      grcRecipeID
      title
      imageUrl
      mealTypes
      score
    }
    nextOffset
    moreAvailable
    estimatedTotalResults
  }
}
` as GeneratedMutation<
  APITypes.AdvancedSearchGRCMutationVariables,
  APITypes.AdvancedSearchGRCMutation
>;
export const advancedSearchOwnMeals = /* GraphQL */ `mutation AdvancedSearchOwnMeals(
  $searchCriteria: SearchCriteria!
  $offset: Int
) {
  advancedSearchOwnMeals(searchCriteria: $searchCriteria, offset: $offset) {
    data {
      mealID
      parentID
      title
      imageUrl
      mealTypes
      score
    }
    nextOffset
    moreAvailable
    estimatedTotalResults
  }
}
` as GeneratedMutation<
  APITypes.AdvancedSearchOwnMealsMutationVariables,
  APITypes.AdvancedSearchOwnMealsMutation
>;
export const getGRCRecipeCategories = /* GraphQL */ `mutation GetGRCRecipeCategories($spaceID: String!) {
  getGRCRecipeCategories(spaceID: $spaceID) {
    id
    title
  }
}
` as GeneratedMutation<
  APITypes.GetGRCRecipeCategoriesMutationVariables,
  APITypes.GetGRCRecipeCategoriesMutation
>;
export const getGRCResults = /* GraphQL */ `mutation GetGRCResults(
  $spaceID: String!
  $grcRecipeCategoryID: String!
  $offset: Int
) {
  getGRCResults(
    spaceID: $spaceID
    grcRecipeCategoryID: $grcRecipeCategoryID
    offset: $offset
  ) {
    data {
      grcRecipeID
      title
      imageUrl
      mealTypes
      score
    }
    nextOffset
    moreAvailable
    estimatedTotalResults
  }
}
` as GeneratedMutation<
  APITypes.GetGRCResultsMutationVariables,
  APITypes.GetGRCResultsMutation
>;
export const addRecipe = /* GraphQL */ `mutation AddRecipe($url: String!) {
  addRecipe(url: $url) {
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    error
  }
}
` as GeneratedMutation<
  APITypes.AddRecipeMutationVariables,
  APITypes.AddRecipeMutation
>;
export const addRecipesBulk = /* GraphQL */ `mutation AddRecipesBulk($ldjsonListStr: String!) {
  addRecipesBulk(ldjsonListStr: $ldjsonListStr) {
    title
    shortDescription
    imageUrl
    recipeUrl
    publisher
    chef
    mealiqId
    nutrition {
      calories
      protein
      carbohydrate
      fat
      fibre
      sugar
      saturates
      salt
      veg_weight
      cholesterol
      calcium
      iron
      magnesium
      potassium
      vitamin_a
      vitamin_b1
      vitamin_b2
      vitamin_b3
      vitamin_b5
      vitamin_b6
      vitamin_b12
      vitamin_c
      vitamin_d
      vitamin_e
      vitamin_k
      phosphorous
      zinc
      copper
      manganese
      selenium
      folate
      omega3_dha
      omega3_dpa
      omega3_epa
      sucrose
      glucose
      fructose
      lactose
      maltose
    }
    mealTypes
    tags
    supportedDiets
    ingredients {
      id
      fullText
      structuredIngredient {
        name
        quantity
        unitOfMeasure
        normalisedQuantity
        linkedIngredient {
          status
          linkedIngredientName
        }
        resolvedNutrition
        resolvedNutritionError
      }
      tokFullText
      tokens {
        fromChar
        toChar
        type
        label
      }
      scalingRules
      checkedInIngredientsList
    }
    preparations {
      id
      fullText
    }
    utensils {
      id
      fullText
    }
    servings
    cookTime
    prepTime
    totalTime
    recipeNotes
  }
}
` as GeneratedMutation<
  APITypes.AddRecipesBulkMutationVariables,
  APITypes.AddRecipesBulkMutation
>;
export const analyzeIngredients = /* GraphQL */ `mutation AnalyzeIngredients(
  $ingsFullText: [String!]!
  $customerFoodDataID: String
) {
  analyzeIngredients(
    ingsFullText: $ingsFullText
    customerFoodDataID: $customerFoodDataID
  ) {
    id
    fullText
    structuredIngredient {
      name
      quantity
      unitOfMeasure
      normalisedQuantity
      linkedIngredient {
        status
        linkedIngredientName
      }
      resolvedNutrition
      resolvedNutritionError
    }
    tokFullText
    tokens {
      fromChar
      toChar
      type
      label
    }
    scalingRules
    checkedInIngredientsList
  }
}
` as GeneratedMutation<
  APITypes.AnalyzeIngredientsMutationVariables,
  APITypes.AnalyzeIngredientsMutation
>;
export const deriveNutrition = /* GraphQL */ `mutation DeriveNutrition(
  $servings: Float!
  $structuredIngredients: [DerivedNutritionStructuredIngredientInput!]!
  $publisher: String
  $chef: String
  $customerFoodDataID: String
) {
  deriveNutrition(
    servings: $servings
    structuredIngredients: $structuredIngredients
    publisher: $publisher
    chef: $chef
    customerFoodDataID: $customerFoodDataID
  ) {
    totalNutritionPerServing {
      calories
      protein
      carbohydrate
      fat
      fibre
      sugar
      saturates
      salt
      veg_weight
      cholesterol
      calcium
      iron
      magnesium
      potassium
      vitamin_a
      vitamin_b1
      vitamin_b2
      vitamin_b3
      vitamin_b5
      vitamin_b6
      vitamin_b12
      vitamin_c
      vitamin_d
      vitamin_e
      vitamin_k
      phosphorous
      zinc
      copper
      manganese
      selenium
      folate
      omega3_dha
      omega3_dpa
      omega3_epa
      sucrose
      glucose
      fructose
      lactose
      maltose
    }
    ingredientNutrition {
      ingredientID
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      resolvedNutrition
      error
      resolvedIngredientWeight
    }
    foodAllergens
    foodPreferences
    status
    macroRatio {
      carbohydrate
      protein
      fat
    }
  }
}
` as GeneratedMutation<
  APITypes.DeriveNutritionMutationVariables,
  APITypes.DeriveNutritionMutation
>;
export const deriveNutritionMeals = /* GraphQL */ `mutation DeriveNutritionMeals(
  $meals: [DerivedNutritionMealInput!]!
  $customerFoodDataID: String
) {
  deriveNutritionMeals(meals: $meals, customerFoodDataID: $customerFoodDataID) {
    mealID
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.DeriveNutritionMealsMutationVariables,
  APITypes.DeriveNutritionMealsMutation
>;
export const compileIngredientCatalog = /* GraphQL */ `mutation CompileIngredientCatalog(
  $menu: [RecommenderMealInput!]!
  $previousIngredientCatalog: [PreviousShoppingListItemInput!]
  $locale: String
) {
  compileIngredientCatalog(
    menu: $menu
    previousIngredientCatalog: $previousIngredientCatalog
    locale: $locale
  ) {
    id
    name
    total {
      quantity
      prettyQuantity
      unitOfMeasure
    }
    quantities {
      quantity
      prettyQuantity
      unitOfMeasure
    }
    linkedIngredient {
      status
      linkedIngredientName
    }
    aisleLocation
    forMealIds
    checked
  }
}
` as GeneratedMutation<
  APITypes.CompileIngredientCatalogMutationVariables,
  APITypes.CompileIngredientCatalogMutation
>;
export const getSuggestions = /* GraphQL */ `mutation GetSuggestions(
  $searches: [IngredientSuggestionSearchInput!]!
  $locale: String
) {
  getSuggestions(searches: $searches, locale: $locale) {
    suggestions {
      suggestionText
      suggestionType
      highlightTokens {
        fromChar
        toChar
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.GetSuggestionsMutationVariables,
  APITypes.GetSuggestionsMutation
>;
export const scaleIngredients = /* GraphQL */ `mutation ScaleIngredients(
  $scaleFactor: Float!
  $ingredients: [IngredientScaleInput!]!
) {
  scaleIngredients(scaleFactor: $scaleFactor, ingredients: $ingredients) {
    id
    fullText
    structuredIngredient {
      name
      quantity
      unitOfMeasure
      normalisedQuantity
      linkedIngredient {
        status
        linkedIngredientName
      }
      resolvedNutrition
      resolvedNutritionError
    }
    tokFullText
    tokens {
      fromChar
      toChar
      type
      label
    }
    scalingRules
    checkedInIngredientsList
  }
}
` as GeneratedMutation<
  APITypes.ScaleIngredientsMutationVariables,
  APITypes.ScaleIngredientsMutation
>;
export const localiseIngredients = /* GraphQL */ `mutation LocaliseIngredients(
  $ingredients: [IngredientSuggestionSearchInput!]!
  $localiseWhat: String!
  $targetLocale: String!
) {
  localiseIngredients(
    ingredients: $ingredients
    localiseWhat: $localiseWhat
    targetLocale: $targetLocale
  ) {
    id
    fullText
    structuredIngredient {
      name
      quantity
      unitOfMeasure
      normalisedQuantity
      linkedIngredient {
        status
        linkedIngredientName
      }
      resolvedNutrition
      resolvedNutritionError
    }
    tokFullText
    tokens {
      fromChar
      toChar
      type
      label
    }
    scalingRules
    checkedInIngredientsList
  }
}
` as GeneratedMutation<
  APITypes.LocaliseIngredientsMutationVariables,
  APITypes.LocaliseIngredientsMutation
>;
export const updateContactAttribute = /* GraphQL */ `mutation UpdateContactAttribute(
  $email: AWSEmail!
  $attributeName: String!
  $attributeValue: String
) {
  updateContactAttribute(
    email: $email
    attributeName: $attributeName
    attributeValue: $attributeValue
  )
}
` as GeneratedMutation<
  APITypes.UpdateContactAttributeMutationVariables,
  APITypes.UpdateContactAttributeMutation
>;
export const emailShoppingList = /* GraphQL */ `mutation EmailShoppingList(
  $shoppingList: EmailShoppingListInput!
  $recipient: AWSEmail!
  $senderName: String
) {
  emailShoppingList(
    shoppingList: $shoppingList
    recipient: $recipient
    senderName: $senderName
  )
}
` as GeneratedMutation<
  APITypes.EmailShoppingListMutationVariables,
  APITypes.EmailShoppingListMutation
>;
export const emailSpaceUrlToSelf = /* GraphQL */ `mutation EmailSpaceUrlToSelf($spaceUrl: String!) {
  emailSpaceUrlToSelf(spaceUrl: $spaceUrl)
}
` as GeneratedMutation<
  APITypes.EmailSpaceUrlToSelfMutationVariables,
  APITypes.EmailSpaceUrlToSelfMutation
>;
export const adminEmailUserRequestedFeature = /* GraphQL */ `mutation AdminEmailUserRequestedFeature(
  $userId: String!
  $userEmail: AWSEmail!
  $featureCode: String!
) {
  adminEmailUserRequestedFeature(
    userId: $userId
    userEmail: $userEmail
    featureCode: $featureCode
  )
}
` as GeneratedMutation<
  APITypes.AdminEmailUserRequestedFeatureMutationVariables,
  APITypes.AdminEmailUserRequestedFeatureMutation
>;
export const adminEmailUserDeleted = /* GraphQL */ `mutation AdminEmailUserDeleted($userId: String!, $userEmail: AWSEmail!) {
  adminEmailUserDeleted(userId: $userId, userEmail: $userEmail)
}
` as GeneratedMutation<
  APITypes.AdminEmailUserDeletedMutationVariables,
  APITypes.AdminEmailUserDeletedMutation
>;
export const getMixpanelUserGeolocation = /* GraphQL */ `mutation GetMixpanelUserGeolocation($mixpanelDistinctId: String!) {
  getMixpanelUserGeolocation(mixpanelDistinctId: $mixpanelDistinctId) {
    countryCode
  }
}
` as GeneratedMutation<
  APITypes.GetMixpanelUserGeolocationMutationVariables,
  APITypes.GetMixpanelUserGeolocationMutation
>;
export const generateRecipeAI = /* GraphQL */ `mutation GenerateRecipeAI($prompt: String!) {
  generateRecipeAI(prompt: $prompt) {
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    error
  }
}
` as GeneratedMutation<
  APITypes.GenerateRecipeAIMutationVariables,
  APITypes.GenerateRecipeAIMutation
>;
export const beginGenerateRecipeImageAI = /* GraphQL */ `mutation BeginGenerateRecipeImageAI($prompt: String!, $size: String!) {
  beginGenerateRecipeImageAI(prompt: $prompt, size: $size)
}
` as GeneratedMutation<
  APITypes.BeginGenerateRecipeImageAIMutationVariables,
  APITypes.BeginGenerateRecipeImageAIMutation
>;
export const checkGenerateRecipeImageAI = /* GraphQL */ `mutation CheckGenerateRecipeImageAI($jobID: ID!) {
  checkGenerateRecipeImageAI(jobID: $jobID) {
    jobID
    jobStatus
    imageUrl
  }
}
` as GeneratedMutation<
  APITypes.CheckGenerateRecipeImageAIMutationVariables,
  APITypes.CheckGenerateRecipeImageAIMutation
>;
export const beginGenerateRecipeAI = /* GraphQL */ `mutation BeginGenerateRecipeAI($prompt: String!) {
  beginGenerateRecipeAI(prompt: $prompt)
}
` as GeneratedMutation<
  APITypes.BeginGenerateRecipeAIMutationVariables,
  APITypes.BeginGenerateRecipeAIMutation
>;
export const checkGenerateRecipeAI = /* GraphQL */ `mutation CheckGenerateRecipeAI($jobID: ID!) {
  checkGenerateRecipeAI(jobID: $jobID) {
    jobID
    jobStatus
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
  }
}
` as GeneratedMutation<
  APITypes.CheckGenerateRecipeAIMutationVariables,
  APITypes.CheckGenerateRecipeAIMutation
>;
export const beginModerateRecipeAI = /* GraphQL */ `mutation BeginModerateRecipeAI(
  $ingredients: [String!]
  $preparations: [String!]!
) {
  beginModerateRecipeAI(ingredients: $ingredients, preparations: $preparations)
}
` as GeneratedMutation<
  APITypes.BeginModerateRecipeAIMutationVariables,
  APITypes.BeginModerateRecipeAIMutation
>;
export const checkModerateRecipeAI = /* GraphQL */ `mutation CheckModerateRecipeAI($jobID: ID!) {
  checkModerateRecipeAI(jobID: $jobID) {
    jobID
    jobStatus
    moderatedIngredients
    moderatedPreparations
  }
}
` as GeneratedMutation<
  APITypes.CheckModerateRecipeAIMutationVariables,
  APITypes.CheckModerateRecipeAIMutation
>;
export const pingRecipesService = /* GraphQL */ `mutation PingRecipesService {
  pingRecipesService {
    overallStatus
    publicIp
  }
}
` as GeneratedMutation<
  APITypes.PingRecipesServiceMutationVariables,
  APITypes.PingRecipesServiceMutation
>;
export const reportAccountActivitySignal = /* GraphQL */ `mutation ReportAccountActivitySignal(
  $accountActivitySignal: AccountActivitySignalReport!
) {
  reportAccountActivitySignal(accountActivitySignal: $accountActivitySignal) {
    overallStatus
    publicIp
  }
}
` as GeneratedMutation<
  APITypes.ReportAccountActivitySignalMutationVariables,
  APITypes.ReportAccountActivitySignalMutation
>;
export const createMySpaceMembership = /* GraphQL */ `mutation CreateMySpaceMembership(
  $spaceID: ID!
  $isPreactivated: Boolean
  $onboardingAnswers: [OnboardingAnswerInput!]
  $onboardingAnswersLastUpdatedAt: AWSDateTime
  $referrer: SpaceMembershipReferrer
) {
  createMySpaceMembership(
    spaceID: $spaceID
    isPreactivated: $isPreactivated
    onboardingAnswers: $onboardingAnswers
    onboardingAnswersLastUpdatedAt: $onboardingAnswersLastUpdatedAt
    referrer: $referrer
  ) {
    spaceID
    memberID
    state
    correlatedUserID
    expiresAt
    visibleProgrammeIDsOverride
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
    groups
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMySpaceMembershipMutationVariables,
  APITypes.CreateMySpaceMembershipMutation
>;
export const updateMembershipWithAppleIAP = /* GraphQL */ `mutation UpdateMembershipWithAppleIAP(
  $spaceMembershipID: ID!
  $appStoreTransactionReceipt: String!
) {
  updateMembershipWithAppleIAP(
    spaceMembershipID: $spaceMembershipID
    appStoreTransactionReceipt: $appStoreTransactionReceipt
  ) {
    spaceMembershipID
    appStoreTransactionReceipt
    result
    membershipExpiresAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipWithAppleIAPMutationVariables,
  APITypes.UpdateMembershipWithAppleIAPMutation
>;
export const updateMembershipWithGooglePlayPurchase = /* GraphQL */ `mutation UpdateMembershipWithGooglePlayPurchase(
  $spaceMembershipID: ID!
  $googlePlayTransactionReceipt: String!
) {
  updateMembershipWithGooglePlayPurchase(
    spaceMembershipID: $spaceMembershipID
    googlePlayTransactionReceipt: $googlePlayTransactionReceipt
  ) {
    spaceMembershipID
    appStoreTransactionReceipt
    result
    membershipExpiresAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipWithGooglePlayPurchaseMutationVariables,
  APITypes.UpdateMembershipWithGooglePlayPurchaseMutation
>;
export const updateMembershipNoTierAvailable = /* GraphQL */ `mutation UpdateMembershipNoTierAvailable($spaceMembershipID: ID!) {
  updateMembershipNoTierAvailable(spaceMembershipID: $spaceMembershipID) {
    spaceMembershipID
    appStoreTransactionReceipt
    result
    membershipExpiresAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipNoTierAvailableMutationVariables,
  APITypes.UpdateMembershipNoTierAvailableMutation
>;
export const syncSpaceMembership = /* GraphQL */ `mutation SyncSpaceMembership(
  $spaceMembershipID: ID!
  $onboardingAnswers: [OnboardingAnswerInput!]!
  $onboardingAnswersLastUpdatedAt: AWSDateTime!
  $customTargetCalories: Float
  $welcomeVideoWatched: Boolean
  $initialProgrammeSelectionLastOfferedAt: AWSDateTime
  $chatNickname: String
) {
  syncSpaceMembership(
    spaceMembershipID: $spaceMembershipID
    onboardingAnswers: $onboardingAnswers
    onboardingAnswersLastUpdatedAt: $onboardingAnswersLastUpdatedAt
    customTargetCalories: $customTargetCalories
    welcomeVideoWatched: $welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt: $initialProgrammeSelectionLastOfferedAt
    chatNickname: $chatNickname
  ) {
    spaceID
    memberID
    state
    correlatedUserID
    expiresAt
    visibleProgrammeIDsOverride
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
    groups
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.SyncSpaceMembershipMutationVariables,
  APITypes.SyncSpaceMembershipMutation
>;
export const recommendMeals = /* GraphQL */ `mutation RecommendMeals(
  $menuRecommendationRequest: MenuRecommendationRequest!
) {
  recommendMeals(menuRecommendationRequest: $menuRecommendationRequest) {
    days {
      dayIndex
      entries {
        entryType
        mealType
        meals {
          mealSource
          id
          servings
          categoryTags
          scaleFactor
          scaledIngredientsFullText
          scaledNutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          scaledStructuredIngredients {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          scaledDerivedIngredientNutrition {
            ingredientID
            nutrition {
              calories
              protein
              carbohydrate
              fat
              fibre
              sugar
              saturates
              salt
              veg_weight
              cholesterol
              calcium
              iron
              magnesium
              potassium
              vitamin_a
              vitamin_b1
              vitamin_b2
              vitamin_b3
              vitamin_b5
              vitamin_b6
              vitamin_b12
              vitamin_c
              vitamin_d
              vitamin_e
              vitamin_k
              phosphorous
              zinc
              copper
              manganese
              selenium
              folate
              omega3_dha
              omega3_dpa
              omega3_epa
              sucrose
              glucose
              fructose
              lactose
              maltose
            }
            resolvedNutrition
            error
            resolvedIngredientWeight
          }
        }
      }
    }
    errorMessage
  }
}
` as GeneratedMutation<
  APITypes.RecommendMealsMutationVariables,
  APITypes.RecommendMealsMutation
>;
export const expandIngredientsForExclusion = /* GraphQL */ `mutation ExpandIngredientsForExclusion(
  $rootIngredientNames: [String!]!
  $locales: [String!]
) {
  expandIngredientsForExclusion(
    rootIngredientNames: $rootIngredientNames
    locales: $locales
  )
}
` as GeneratedMutation<
  APITypes.ExpandIngredientsForExclusionMutationVariables,
  APITypes.ExpandIngredientsForExclusionMutation
>;
export const getNewStripeConfig = /* GraphQL */ `mutation GetNewStripeConfig($authorizationCode: String!) {
  getNewStripeConfig(authorizationCode: $authorizationCode) {
    environment
    connectedAccountID
    chargesEnabled
    stripeProductID
    billingPortalConfigurationID
  }
}
` as GeneratedMutation<
  APITypes.GetNewStripeConfigMutationVariables,
  APITypes.GetNewStripeConfigMutation
>;
export const createStripeCheckoutSession = /* GraphQL */ `mutation CreateStripeCheckoutSession(
  $membershipTierID: ID!
  $spaceMembershipID: ID!
  $returnUrl: String!
  $duration: SubscriptionDuration
) {
  createStripeCheckoutSession(
    membershipTierID: $membershipTierID
    spaceMembershipID: $spaceMembershipID
    returnUrl: $returnUrl
    duration: $duration
  ) {
    url
  }
}
` as GeneratedMutation<
  APITypes.CreateStripeCheckoutSessionMutationVariables,
  APITypes.CreateStripeCheckoutSessionMutation
>;
export const checkStripeCheckoutSession = /* GraphQL */ `mutation CheckStripeCheckoutSession($spaceMembershipID: ID!) {
  checkStripeCheckoutSession(spaceMembershipID: $spaceMembershipID)
}
` as GeneratedMutation<
  APITypes.CheckStripeCheckoutSessionMutationVariables,
  APITypes.CheckStripeCheckoutSessionMutation
>;
export const cancelSubscription = /* GraphQL */ `mutation CancelSubscription($spaceMembershipID: ID!) {
  cancelSubscription(spaceMembershipID: $spaceMembershipID)
}
` as GeneratedMutation<
  APITypes.CancelSubscriptionMutationVariables,
  APITypes.CancelSubscriptionMutation
>;
export const activateMembershipTier = /* GraphQL */ `mutation ActivateMembershipTier($membershipTierID: ID!) {
  activateMembershipTier(membershipTierID: $membershipTierID) {
    spaceID
    title
    shortDescription
    monthlyPrice
    quarterlyPrice
    annualPrice
    freeTrialDurationDays
    rank
    currencyCode
    state
    stripeProductID
    stripePriceID
    stripePriceIDQuarterly
    stripePriceIDAnnual
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.ActivateMembershipTierMutationVariables,
  APITypes.ActivateMembershipTierMutation
>;
export const listYoutubePlaylistItems = /* GraphQL */ `mutation ListYoutubePlaylistItems($playlistID: ID!) {
  listYoutubePlaylistItems(playlistID: $playlistID) {
    videoID
    title
    description
    thumbnailImageUrl
  }
}
` as GeneratedMutation<
  APITypes.ListYoutubePlaylistItemsMutationVariables,
  APITypes.ListYoutubePlaylistItemsMutation
>;
export const enrollUserIntoChat = /* GraphQL */ `mutation EnrollUserIntoChat($spaceMembershipID: ID!, $userName: String!) {
  enrollUserIntoChat(
    spaceMembershipID: $spaceMembershipID
    userName: $userName
  ) {
    status
    authToken
  }
}
` as GeneratedMutation<
  APITypes.EnrollUserIntoChatMutationVariables,
  APITypes.EnrollUserIntoChatMutation
>;
export const logInUserToChat = /* GraphQL */ `mutation LogInUserToChat($spaceMembershipID: ID!) {
  logInUserToChat(spaceMembershipID: $spaceMembershipID) {
    authToken
  }
}
` as GeneratedMutation<
  APITypes.LogInUserToChatMutationVariables,
  APITypes.LogInUserToChatMutation
>;
export const adminListChatGroups = /* GraphQL */ `mutation AdminListChatGroups {
  adminListChatGroups {
    guid
    name
  }
}
` as GeneratedMutation<
  APITypes.AdminListChatGroupsMutationVariables,
  APITypes.AdminListChatGroupsMutation
>;
export const listSmorgStudioMemberships = /* GraphQL */ `mutation ListSmorgStudioMemberships {
  listSmorgStudioMemberships {
    id
    createdAt
    spaceID
    memberID
    email
    givenName
    familyName
    state
    correlatedUserID
    expiresAt
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
  }
}
` as GeneratedMutation<
  APITypes.ListSmorgStudioMembershipsMutationVariables,
  APITypes.ListSmorgStudioMembershipsMutation
>;
export const smorgStudioMembershipAdminSetExpiresAt = /* GraphQL */ `mutation SmorgStudioMembershipAdminSetExpiresAt(
  $spaceMembershipID: ID!
  $expiresAt: AWSDateTime!
) {
  smorgStudioMembershipAdminSetExpiresAt(
    spaceMembershipID: $spaceMembershipID
    expiresAt: $expiresAt
  ) {
    id
    createdAt
    spaceID
    memberID
    email
    givenName
    familyName
    state
    correlatedUserID
    expiresAt
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
  }
}
` as GeneratedMutation<
  APITypes.SmorgStudioMembershipAdminSetExpiresAtMutationVariables,
  APITypes.SmorgStudioMembershipAdminSetExpiresAtMutation
>;
export const smorgStudioMembershipAdminSetPassword = /* GraphQL */ `mutation SmorgStudioMembershipAdminSetPassword(
  $spaceMembershipID: ID!
  $cleartextPassword: String!
) {
  smorgStudioMembershipAdminSetPassword(
    spaceMembershipID: $spaceMembershipID
    cleartextPassword: $cleartextPassword
  ) {
    success
    message
  }
}
` as GeneratedMutation<
  APITypes.SmorgStudioMembershipAdminSetPasswordMutationVariables,
  APITypes.SmorgStudioMembershipAdminSetPasswordMutation
>;
export const smorgStudioMembershipAdminDeleteUser = /* GraphQL */ `mutation SmorgStudioMembershipAdminDeleteUser($spaceMembershipID: ID!) {
  smorgStudioMembershipAdminDeleteUser(spaceMembershipID: $spaceMembershipID) {
    success
    message
  }
}
` as GeneratedMutation<
  APITypes.SmorgStudioMembershipAdminDeleteUserMutationVariables,
  APITypes.SmorgStudioMembershipAdminDeleteUserMutation
>;
export const createStripeBillingPortalSession = /* GraphQL */ `mutation CreateStripeBillingPortalSession(
  $spaceMembershipID: ID!
  $returnUrl: String!
) {
  createStripeBillingPortalSession(
    spaceMembershipID: $spaceMembershipID
    returnUrl: $returnUrl
  ) {
    url
  }
}
` as GeneratedMutation<
  APITypes.CreateStripeBillingPortalSessionMutationVariables,
  APITypes.CreateStripeBillingPortalSessionMutation
>;
export const createZapierRestHook = /* GraphQL */ `mutation CreateZapierRestHook(
  $input: CreateZapierRestHookInput!
  $condition: ModelZapierRestHookConditionInput
) {
  createZapierRestHook(input: $input, condition: $condition) {
    spaceID
    hookUrl
    hookKey
    enabled
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateZapierRestHookMutationVariables,
  APITypes.CreateZapierRestHookMutation
>;
export const updateZapierRestHook = /* GraphQL */ `mutation UpdateZapierRestHook(
  $input: UpdateZapierRestHookInput!
  $condition: ModelZapierRestHookConditionInput
) {
  updateZapierRestHook(input: $input, condition: $condition) {
    spaceID
    hookUrl
    hookKey
    enabled
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateZapierRestHookMutationVariables,
  APITypes.UpdateZapierRestHookMutation
>;
export const deleteZapierRestHook = /* GraphQL */ `mutation DeleteZapierRestHook(
  $input: DeleteZapierRestHookInput!
  $condition: ModelZapierRestHookConditionInput
) {
  deleteZapierRestHook(input: $input, condition: $condition) {
    spaceID
    hookUrl
    hookKey
    enabled
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteZapierRestHookMutationVariables,
  APITypes.DeleteZapierRestHookMutation
>;
export const createUserProfile = /* GraphQL */ `mutation CreateUserProfile(
  $input: CreateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  createUserProfile(input: $input, condition: $condition) {
    id
    userID
    showOnboarding
    consentToProductComms
    plannerViewWeekStartDay
    geoRegion
    countryCode
    mealPlanReminderSchedule
    showMealDetailGuide
    recentlyUsedTags
    activitySummary {
      numMealsViewed
      numMealsViewedMobile
      numShoppingListsViewed
      numShoppingListsViewedMobile
      numMealsEdited
      numNutritionClicks
      numMealsPlanned
      numMealsAdded
      numMealsImported
      numMealsAddedManually
      numPlansViewed
      numShoppingListsCopiedToClipboard
      numShoppingListsEmailed
      numShoppingListsCreated
      numShops
      numNotesAdded
      numNotesViewed
      numNotesViewedMobile
      numRecipesBoardsCreated
      numRecipesBoardsShared
    }
    enabledFeatures
    requestedFeatures
    seenMessages
    recentlyUsedRecommenderMealTypes
    recentlyUsedRecommenderCalorieSplits {
      mealType
      fraction
    }
    recentlyUsedSearchCriteria {
      searchString
      mealTypes
      cookTimeMin
      cookTimeMax
      excludeIngredients
      includeIngredients
      dietaryPreferences
      perMealNutritionConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      tags
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProfileMutationVariables,
  APITypes.CreateUserProfileMutation
>;
export const updateUserProfile = /* GraphQL */ `mutation UpdateUserProfile(
  $input: UpdateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  updateUserProfile(input: $input, condition: $condition) {
    id
    userID
    showOnboarding
    consentToProductComms
    plannerViewWeekStartDay
    geoRegion
    countryCode
    mealPlanReminderSchedule
    showMealDetailGuide
    recentlyUsedTags
    activitySummary {
      numMealsViewed
      numMealsViewedMobile
      numShoppingListsViewed
      numShoppingListsViewedMobile
      numMealsEdited
      numNutritionClicks
      numMealsPlanned
      numMealsAdded
      numMealsImported
      numMealsAddedManually
      numPlansViewed
      numShoppingListsCopiedToClipboard
      numShoppingListsEmailed
      numShoppingListsCreated
      numShops
      numNotesAdded
      numNotesViewed
      numNotesViewedMobile
      numRecipesBoardsCreated
      numRecipesBoardsShared
    }
    enabledFeatures
    requestedFeatures
    seenMessages
    recentlyUsedRecommenderMealTypes
    recentlyUsedRecommenderCalorieSplits {
      mealType
      fraction
    }
    recentlyUsedSearchCriteria {
      searchString
      mealTypes
      cookTimeMin
      cookTimeMax
      excludeIngredients
      includeIngredients
      dietaryPreferences
      perMealNutritionConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      tags
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfileMutationVariables,
  APITypes.UpdateUserProfileMutation
>;
export const deleteUserProfile = /* GraphQL */ `mutation DeleteUserProfile(
  $input: DeleteUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  deleteUserProfile(input: $input, condition: $condition) {
    id
    userID
    showOnboarding
    consentToProductComms
    plannerViewWeekStartDay
    geoRegion
    countryCode
    mealPlanReminderSchedule
    showMealDetailGuide
    recentlyUsedTags
    activitySummary {
      numMealsViewed
      numMealsViewedMobile
      numShoppingListsViewed
      numShoppingListsViewedMobile
      numMealsEdited
      numNutritionClicks
      numMealsPlanned
      numMealsAdded
      numMealsImported
      numMealsAddedManually
      numPlansViewed
      numShoppingListsCopiedToClipboard
      numShoppingListsEmailed
      numShoppingListsCreated
      numShops
      numNotesAdded
      numNotesViewed
      numNotesViewedMobile
      numRecipesBoardsCreated
      numRecipesBoardsShared
    }
    enabledFeatures
    requestedFeatures
    seenMessages
    recentlyUsedRecommenderMealTypes
    recentlyUsedRecommenderCalorieSplits {
      mealType
      fraction
    }
    recentlyUsedSearchCriteria {
      searchString
      mealTypes
      cookTimeMin
      cookTimeMax
      excludeIngredients
      includeIngredients
      dietaryPreferences
      perMealNutritionConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      tags
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProfileMutationVariables,
  APITypes.DeleteUserProfileMutation
>;
export const createObjectEditEvent = /* GraphQL */ `mutation CreateObjectEditEvent(
  $input: CreateObjectEditEventInput!
  $condition: ModelObjectEditEventConditionInput
) {
  createObjectEditEvent(input: $input, condition: $condition) {
    id
    objectID
    objectType
    section
    itemID
    oldValue
    createdAt
    groups
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateObjectEditEventMutationVariables,
  APITypes.CreateObjectEditEventMutation
>;
export const updateObjectEditEvent = /* GraphQL */ `mutation UpdateObjectEditEvent(
  $input: UpdateObjectEditEventInput!
  $condition: ModelObjectEditEventConditionInput
) {
  updateObjectEditEvent(input: $input, condition: $condition) {
    id
    objectID
    objectType
    section
    itemID
    oldValue
    createdAt
    groups
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateObjectEditEventMutationVariables,
  APITypes.UpdateObjectEditEventMutation
>;
export const deleteObjectEditEvent = /* GraphQL */ `mutation DeleteObjectEditEvent(
  $input: DeleteObjectEditEventInput!
  $condition: ModelObjectEditEventConditionInput
) {
  deleteObjectEditEvent(input: $input, condition: $condition) {
    id
    objectID
    objectType
    section
    itemID
    oldValue
    createdAt
    groups
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteObjectEditEventMutationVariables,
  APITypes.DeleteObjectEditEventMutation
>;
export const createMeal = /* GraphQL */ `mutation CreateMeal(
  $input: CreateMealInput!
  $condition: ModelMealConditionInput
) {
  createMeal(input: $input, condition: $condition) {
    id
    schemaVersion
    smorgBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    addons {
      name
    }
    additionallyReferredToBy {
      sourceType
      sourceID
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateMealMutationVariables,
  APITypes.CreateMealMutation
>;
export const updateMeal = /* GraphQL */ `mutation UpdateMeal(
  $input: UpdateMealInput!
  $condition: ModelMealConditionInput
) {
  updateMeal(input: $input, condition: $condition) {
    id
    schemaVersion
    smorgBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    addons {
      name
    }
    additionallyReferredToBy {
      sourceType
      sourceID
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealMutationVariables,
  APITypes.UpdateMealMutation
>;
export const deleteMeal = /* GraphQL */ `mutation DeleteMeal(
  $input: DeleteMealInput!
  $condition: ModelMealConditionInput
) {
  deleteMeal(input: $input, condition: $condition) {
    id
    schemaVersion
    smorgBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    addons {
      name
    }
    additionallyReferredToBy {
      sourceType
      sourceID
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealMutationVariables,
  APITypes.DeleteMealMutation
>;
export const createSmorgBoard = /* GraphQL */ `mutation CreateSmorgBoard(
  $input: CreateSmorgBoardInput!
  $condition: ModelSmorgBoardConditionInput
) {
  createSmorgBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      mealIDs
    }
    shareRecords {
      sharedBoardID
      version
      updatedOn
    }
    spaceMembershipID
    embeddedInContainerType
    embeddedInContainerID
    groups
    availableInMembershipTierIDs
    isMyFavouritesBoard
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateSmorgBoardMutationVariables,
  APITypes.CreateSmorgBoardMutation
>;
export const updateSmorgBoard = /* GraphQL */ `mutation UpdateSmorgBoard(
  $input: UpdateSmorgBoardInput!
  $condition: ModelSmorgBoardConditionInput
) {
  updateSmorgBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      mealIDs
    }
    shareRecords {
      sharedBoardID
      version
      updatedOn
    }
    spaceMembershipID
    embeddedInContainerType
    embeddedInContainerID
    groups
    availableInMembershipTierIDs
    isMyFavouritesBoard
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateSmorgBoardMutationVariables,
  APITypes.UpdateSmorgBoardMutation
>;
export const deleteSmorgBoard = /* GraphQL */ `mutation DeleteSmorgBoard(
  $input: DeleteSmorgBoardInput!
  $condition: ModelSmorgBoardConditionInput
) {
  deleteSmorgBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      mealIDs
    }
    shareRecords {
      sharedBoardID
      version
      updatedOn
    }
    spaceMembershipID
    embeddedInContainerType
    embeddedInContainerID
    groups
    availableInMembershipTierIDs
    isMyFavouritesBoard
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteSmorgBoardMutationVariables,
  APITypes.DeleteSmorgBoardMutation
>;
export const createSharedMeal = /* GraphQL */ `mutation CreateSharedMeal(
  $input: CreateSharedMealInput!
  $condition: ModelSharedMealConditionInput
) {
  createSharedMeal(input: $input, condition: $condition) {
    id
    schemaVersion
    sharedBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSharedMealMutationVariables,
  APITypes.CreateSharedMealMutation
>;
export const updateSharedMeal = /* GraphQL */ `mutation UpdateSharedMeal(
  $input: UpdateSharedMealInput!
  $condition: ModelSharedMealConditionInput
) {
  updateSharedMeal(input: $input, condition: $condition) {
    id
    schemaVersion
    sharedBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSharedMealMutationVariables,
  APITypes.UpdateSharedMealMutation
>;
export const deleteSharedMeal = /* GraphQL */ `mutation DeleteSharedMeal(
  $input: DeleteSharedMealInput!
  $condition: ModelSharedMealConditionInput
) {
  deleteSharedMeal(input: $input, condition: $condition) {
    id
    schemaVersion
    sharedBoardID
    recipes {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    origin {
      originObjectType
      originObjectID
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSharedMealMutationVariables,
  APITypes.DeleteSharedMealMutation
>;
export const createSharedBoard = /* GraphQL */ `mutation CreateSharedBoard(
  $input: CreateSharedBoardInput!
  $condition: ModelSharedBoardConditionInput
) {
  createSharedBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    version
    accessLevel
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      sharedMealIDs
    }
    availableInMembershipTierIDs
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSharedBoardMutationVariables,
  APITypes.CreateSharedBoardMutation
>;
export const updateSharedBoard = /* GraphQL */ `mutation UpdateSharedBoard(
  $input: UpdateSharedBoardInput!
  $condition: ModelSharedBoardConditionInput
) {
  updateSharedBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    version
    accessLevel
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      sharedMealIDs
    }
    availableInMembershipTierIDs
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSharedBoardMutationVariables,
  APITypes.UpdateSharedBoardMutation
>;
export const deleteSharedBoard = /* GraphQL */ `mutation DeleteSharedBoard(
  $input: DeleteSharedBoardInput!
  $condition: ModelSharedBoardConditionInput
) {
  deleteSharedBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    version
    accessLevel
    title
    shortDescription
    coverImageUrl
    menus {
      id
      title
      sharedMealIDs
    }
    availableInMembershipTierIDs
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSharedBoardMutationVariables,
  APITypes.DeleteSharedBoardMutation
>;
export const createNote = /* GraphQL */ `mutation CreateNote(
  $input: CreateNoteInput!
  $condition: ModelNoteConditionInput
) {
  createNote(input: $input, condition: $condition) {
    id
    parentID
    title
    description
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteMutationVariables,
  APITypes.CreateNoteMutation
>;
export const updateNote = /* GraphQL */ `mutation UpdateNote(
  $input: UpdateNoteInput!
  $condition: ModelNoteConditionInput
) {
  updateNote(input: $input, condition: $condition) {
    id
    parentID
    title
    description
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteMutationVariables,
  APITypes.UpdateNoteMutation
>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote(
  $input: DeleteNoteInput!
  $condition: ModelNoteConditionInput
) {
  deleteNote(input: $input, condition: $condition) {
    id
    parentID
    title
    description
    spaceMembershipID
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteMutationVariables,
  APITypes.DeleteNoteMutation
>;
export const createContentEntry = /* GraphQL */ `mutation CreateContentEntry(
  $input: CreateContentEntryInput!
  $condition: ModelContentEntryConditionInput
) {
  createContentEntry(input: $input, condition: $condition) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateContentEntryMutationVariables,
  APITypes.CreateContentEntryMutation
>;
export const updateContentEntry = /* GraphQL */ `mutation UpdateContentEntry(
  $input: UpdateContentEntryInput!
  $condition: ModelContentEntryConditionInput
) {
  updateContentEntry(input: $input, condition: $condition) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateContentEntryMutationVariables,
  APITypes.UpdateContentEntryMutation
>;
export const deleteContentEntry = /* GraphQL */ `mutation DeleteContentEntry(
  $input: DeleteContentEntryInput!
  $condition: ModelContentEntryConditionInput
) {
  deleteContentEntry(input: $input, condition: $condition) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteContentEntryMutationVariables,
  APITypes.DeleteContentEntryMutation
>;
export const createSharedContentEntry = /* GraphQL */ `mutation CreateSharedContentEntry(
  $input: CreateSharedContentEntryInput!
  $condition: ModelSharedContentEntryConditionInput
) {
  createSharedContentEntry(input: $input, condition: $condition) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSharedContentEntryMutationVariables,
  APITypes.CreateSharedContentEntryMutation
>;
export const updateSharedContentEntry = /* GraphQL */ `mutation UpdateSharedContentEntry(
  $input: UpdateSharedContentEntryInput!
  $condition: ModelSharedContentEntryConditionInput
) {
  updateSharedContentEntry(input: $input, condition: $condition) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSharedContentEntryMutationVariables,
  APITypes.UpdateSharedContentEntryMutation
>;
export const deleteSharedContentEntry = /* GraphQL */ `mutation DeleteSharedContentEntry(
  $input: DeleteSharedContentEntryInput!
  $condition: ModelSharedContentEntryConditionInput
) {
  deleteSharedContentEntry(input: $input, condition: $condition) {
    id
    parentID
    title
    body
    coverImageUrl
    isCoverImageShownInDetailView
    tags
    origin {
      originObjectType
      originObjectID
    }
    appLink {
      appLinkType
      appLinkScreen
      url
      objectID
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSharedContentEntryMutationVariables,
  APITypes.DeleteSharedContentEntryMutation
>;
export const createPlannerWeek = /* GraphQL */ `mutation CreatePlannerWeek(
  $input: CreatePlannerWeekInput!
  $condition: ModelPlannerWeekConditionInput
) {
  createPlannerWeek(input: $input, condition: $condition) {
    id
    schemaVersion
    spaceMembershipID
    startDate
    days {
      entries {
        plannerEntryType
        mealID
        objectID
      }
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreatePlannerWeekMutationVariables,
  APITypes.CreatePlannerWeekMutation
>;
export const updatePlannerWeek = /* GraphQL */ `mutation UpdatePlannerWeek(
  $input: UpdatePlannerWeekInput!
  $condition: ModelPlannerWeekConditionInput
) {
  updatePlannerWeek(input: $input, condition: $condition) {
    id
    schemaVersion
    spaceMembershipID
    startDate
    days {
      entries {
        plannerEntryType
        mealID
        objectID
      }
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlannerWeekMutationVariables,
  APITypes.UpdatePlannerWeekMutation
>;
export const deletePlannerWeek = /* GraphQL */ `mutation DeletePlannerWeek(
  $input: DeletePlannerWeekInput!
  $condition: ModelPlannerWeekConditionInput
) {
  deletePlannerWeek(input: $input, condition: $condition) {
    id
    schemaVersion
    spaceMembershipID
    startDate
    days {
      entries {
        plannerEntryType
        mealID
        objectID
      }
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeletePlannerWeekMutationVariables,
  APITypes.DeletePlannerWeekMutation
>;
export const createShoppingList = /* GraphQL */ `mutation CreateShoppingList(
  $input: CreateShoppingListInput!
  $condition: ModelShoppingListConditionInput
) {
  createShoppingList(input: $input, condition: $condition) {
    id
    schemaVersion
    spaceMembershipID
    productsBoardID
    title
    shortDescription
    items {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    mealIDs
    mealImageUrls
    meals {
      mealID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
    }
    activityRecords {
      activityType
      occurredOn
      vendorName
      transactionID
      shoppingListItemIDs
    }
    userItems {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateShoppingListMutationVariables,
  APITypes.CreateShoppingListMutation
>;
export const updateShoppingList = /* GraphQL */ `mutation UpdateShoppingList(
  $input: UpdateShoppingListInput!
  $condition: ModelShoppingListConditionInput
) {
  updateShoppingList(input: $input, condition: $condition) {
    id
    schemaVersion
    spaceMembershipID
    productsBoardID
    title
    shortDescription
    items {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    mealIDs
    mealImageUrls
    meals {
      mealID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
    }
    activityRecords {
      activityType
      occurredOn
      vendorName
      transactionID
      shoppingListItemIDs
    }
    userItems {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateShoppingListMutationVariables,
  APITypes.UpdateShoppingListMutation
>;
export const deleteShoppingList = /* GraphQL */ `mutation DeleteShoppingList(
  $input: DeleteShoppingListInput!
  $condition: ModelShoppingListConditionInput
) {
  deleteShoppingList(input: $input, condition: $condition) {
    id
    schemaVersion
    spaceMembershipID
    productsBoardID
    title
    shortDescription
    items {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    mealIDs
    mealImageUrls
    meals {
      mealID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
    }
    activityRecords {
      activityType
      occurredOn
      vendorName
      transactionID
      shoppingListItemIDs
    }
    userItems {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteShoppingListMutationVariables,
  APITypes.DeleteShoppingListMutation
>;
export const createProductsBoard = /* GraphQL */ `mutation CreateProductsBoard(
  $input: CreateProductsBoardInput!
  $condition: ModelProductsBoardConditionInput
) {
  createProductsBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    title
    spaceMembershipID
    shoppingListGroups {
      id
      title
      shoppingListIDs
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateProductsBoardMutationVariables,
  APITypes.CreateProductsBoardMutation
>;
export const updateProductsBoard = /* GraphQL */ `mutation UpdateProductsBoard(
  $input: UpdateProductsBoardInput!
  $condition: ModelProductsBoardConditionInput
) {
  updateProductsBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    title
    spaceMembershipID
    shoppingListGroups {
      id
      title
      shoppingListIDs
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductsBoardMutationVariables,
  APITypes.UpdateProductsBoardMutation
>;
export const deleteProductsBoard = /* GraphQL */ `mutation DeleteProductsBoard(
  $input: DeleteProductsBoardInput!
  $condition: ModelProductsBoardConditionInput
) {
  deleteProductsBoard(input: $input, condition: $condition) {
    id
    schemaVersion
    title
    spaceMembershipID
    shoppingListGroups {
      id
      title
      shoppingListIDs
    }
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductsBoardMutationVariables,
  APITypes.DeleteProductsBoardMutation
>;
export const createGlobalTag = /* GraphQL */ `mutation CreateGlobalTag(
  $input: CreateGlobalTagInput!
  $condition: ModelGlobalTagConditionInput
) {
  createGlobalTag(input: $input, condition: $condition) {
    id
    tag
    count
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateGlobalTagMutationVariables,
  APITypes.CreateGlobalTagMutation
>;
export const updateGlobalTag = /* GraphQL */ `mutation UpdateGlobalTag(
  $input: UpdateGlobalTagInput!
  $condition: ModelGlobalTagConditionInput
) {
  updateGlobalTag(input: $input, condition: $condition) {
    id
    tag
    count
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateGlobalTagMutationVariables,
  APITypes.UpdateGlobalTagMutation
>;
export const deleteGlobalTag = /* GraphQL */ `mutation DeleteGlobalTag(
  $input: DeleteGlobalTagInput!
  $condition: ModelGlobalTagConditionInput
) {
  deleteGlobalTag(input: $input, condition: $condition) {
    id
    tag
    count
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteGlobalTagMutationVariables,
  APITypes.DeleteGlobalTagMutation
>;
export const createIngredientEditEvent = /* GraphQL */ `mutation CreateIngredientEditEvent(
  $input: CreateIngredientEditEventInput!
  $condition: ModelIngredientEditEventConditionInput
) {
  createIngredientEditEvent(input: $input, condition: $condition) {
    mealID
    recipeID
    editMethod
    ingredientID
    fullTextBefore
    fullTextAfter
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateIngredientEditEventMutationVariables,
  APITypes.CreateIngredientEditEventMutation
>;
export const updateIngredientEditEvent = /* GraphQL */ `mutation UpdateIngredientEditEvent(
  $input: UpdateIngredientEditEventInput!
  $condition: ModelIngredientEditEventConditionInput
) {
  updateIngredientEditEvent(input: $input, condition: $condition) {
    mealID
    recipeID
    editMethod
    ingredientID
    fullTextBefore
    fullTextAfter
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateIngredientEditEventMutationVariables,
  APITypes.UpdateIngredientEditEventMutation
>;
export const deleteIngredientEditEvent = /* GraphQL */ `mutation DeleteIngredientEditEvent(
  $input: DeleteIngredientEditEventInput!
  $condition: ModelIngredientEditEventConditionInput
) {
  deleteIngredientEditEvent(input: $input, condition: $condition) {
    mealID
    recipeID
    editMethod
    ingredientID
    fullTextBefore
    fullTextAfter
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteIngredientEditEventMutationVariables,
  APITypes.DeleteIngredientEditEventMutation
>;
export const createIngredientEditSuggestion = /* GraphQL */ `mutation CreateIngredientEditSuggestion(
  $input: CreateIngredientEditSuggestionInput!
  $condition: ModelIngredientEditSuggestionConditionInput
) {
  createIngredientEditSuggestion(input: $input, condition: $condition) {
    id
    inputFullText
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateIngredientEditSuggestionMutationVariables,
  APITypes.CreateIngredientEditSuggestionMutation
>;
export const updateIngredientEditSuggestion = /* GraphQL */ `mutation UpdateIngredientEditSuggestion(
  $input: UpdateIngredientEditSuggestionInput!
  $condition: ModelIngredientEditSuggestionConditionInput
) {
  updateIngredientEditSuggestion(input: $input, condition: $condition) {
    id
    inputFullText
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateIngredientEditSuggestionMutationVariables,
  APITypes.UpdateIngredientEditSuggestionMutation
>;
export const deleteIngredientEditSuggestion = /* GraphQL */ `mutation DeleteIngredientEditSuggestion(
  $input: DeleteIngredientEditSuggestionInput!
  $condition: ModelIngredientEditSuggestionConditionInput
) {
  deleteIngredientEditSuggestion(input: $input, condition: $condition) {
    id
    inputFullText
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteIngredientEditSuggestionMutationVariables,
  APITypes.DeleteIngredientEditSuggestionMutation
>;
export const createIngredientEditSuggestionByIngredientID = /* GraphQL */ `mutation CreateIngredientEditSuggestionByIngredientID(
  $input: CreateIngredientEditSuggestionByIngredientIDInput!
  $condition: ModelIngredientEditSuggestionByIngredientIDConditionInput
) {
  createIngredientEditSuggestionByIngredientID(
    input: $input
    condition: $condition
  ) {
    id
    ingredientID
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateIngredientEditSuggestionByIngredientIDMutationVariables,
  APITypes.CreateIngredientEditSuggestionByIngredientIDMutation
>;
export const updateIngredientEditSuggestionByIngredientID = /* GraphQL */ `mutation UpdateIngredientEditSuggestionByIngredientID(
  $input: UpdateIngredientEditSuggestionByIngredientIDInput!
  $condition: ModelIngredientEditSuggestionByIngredientIDConditionInput
) {
  updateIngredientEditSuggestionByIngredientID(
    input: $input
    condition: $condition
  ) {
    id
    ingredientID
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateIngredientEditSuggestionByIngredientIDMutationVariables,
  APITypes.UpdateIngredientEditSuggestionByIngredientIDMutation
>;
export const deleteIngredientEditSuggestionByIngredientID = /* GraphQL */ `mutation DeleteIngredientEditSuggestionByIngredientID(
  $input: DeleteIngredientEditSuggestionByIngredientIDInput!
  $condition: ModelIngredientEditSuggestionByIngredientIDConditionInput
) {
  deleteIngredientEditSuggestionByIngredientID(
    input: $input
    condition: $condition
  ) {
    id
    ingredientID
    suggestion
    weight
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteIngredientEditSuggestionByIngredientIDMutationVariables,
  APITypes.DeleteIngredientEditSuggestionByIngredientIDMutation
>;
export const createProgramme = /* GraphQL */ `mutation CreateProgramme(
  $input: CreateProgrammeInput!
  $condition: ModelProgrammeConditionInput
) {
  createProgramme(input: $input, condition: $condition) {
    id
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    recipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    shareRecords {
      sharedProgrammeID
      version
      updatedOn
    }
    availableInMembershipTierIDs
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateProgrammeMutationVariables,
  APITypes.CreateProgrammeMutation
>;
export const updateProgramme = /* GraphQL */ `mutation UpdateProgramme(
  $input: UpdateProgrammeInput!
  $condition: ModelProgrammeConditionInput
) {
  updateProgramme(input: $input, condition: $condition) {
    id
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    recipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    shareRecords {
      sharedProgrammeID
      version
      updatedOn
    }
    availableInMembershipTierIDs
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateProgrammeMutationVariables,
  APITypes.UpdateProgrammeMutation
>;
export const deleteProgramme = /* GraphQL */ `mutation DeleteProgramme(
  $input: DeleteProgrammeInput!
  $condition: ModelProgrammeConditionInput
) {
  deleteProgramme(input: $input, condition: $condition) {
    id
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    recipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    shareRecords {
      sharedProgrammeID
      version
      updatedOn
    }
    availableInMembershipTierIDs
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteProgrammeMutationVariables,
  APITypes.DeleteProgrammeMutation
>;
export const createSharedProgramme = /* GraphQL */ `mutation CreateSharedProgramme(
  $input: CreateSharedProgrammeInput!
  $condition: ModelSharedProgrammeConditionInput
) {
  createSharedProgramme(input: $input, condition: $condition) {
    spaceID
    programmeID
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    sharedRecipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    version
    availableInMembershipTierIDs
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateSharedProgrammeMutationVariables,
  APITypes.CreateSharedProgrammeMutation
>;
export const updateSharedProgramme = /* GraphQL */ `mutation UpdateSharedProgramme(
  $input: UpdateSharedProgrammeInput!
  $condition: ModelSharedProgrammeConditionInput
) {
  updateSharedProgramme(input: $input, condition: $condition) {
    spaceID
    programmeID
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    sharedRecipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    version
    availableInMembershipTierIDs
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateSharedProgrammeMutationVariables,
  APITypes.UpdateSharedProgrammeMutation
>;
export const deleteSharedProgramme = /* GraphQL */ `mutation DeleteSharedProgramme(
  $input: DeleteSharedProgrammeInput!
  $condition: ModelSharedProgrammeConditionInput
) {
  deleteSharedProgramme(input: $input, condition: $condition) {
    spaceID
    programmeID
    title
    shortDescription
    description
    coverImageUrl
    plans {
      id
      title
      shortDescription
      description
      coverImageUrl
      days {
        id
        title
        entries {
          id
          programmeEntryType
          objectID
        }
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
    }
    locales
    mealTypes
    calorieSplits {
      mealType
      fraction
    }
    nutritionConstraints {
      nutritionMetricConstraints {
        id
        nutritionMetric
        operator
        value
        units
      }
      excludeIngredients
      foodPreferences
    }
    showNutritionToUsers
    copyMealsExactly
    personalisedMealScaling
    recipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    databaseRecipesBoard {
      id
      title
      menus {
        id
        title
        mealIDs
      }
      embeddedInContainerType
      embeddedInContainerID
    }
    sharedRecipesBoardIDs
    onboardingConfiguration {
      questionIDs
    }
    categoryTags
    version
    availableInMembershipTierIDs
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteSharedProgrammeMutationVariables,
  APITypes.DeleteSharedProgrammeMutation
>;
export const createUserProgrammeEnrollment = /* GraphQL */ `mutation CreateUserProgrammeEnrollment(
  $input: CreateUserProgrammeEnrollmentInput!
  $condition: ModelUserProgrammeEnrollmentConditionInput
) {
  createUserProgrammeEnrollment(input: $input, condition: $condition) {
    spaceMembershipID
    sharedProgrammeID
    enrolledAt
    onboardingAnswers {
      questionID
      answer
    }
    endedAt
    eventRecords {
      calendarDay
      eventType
      updatedOn
    }
    customTargetCalories
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProgrammeEnrollmentMutationVariables,
  APITypes.CreateUserProgrammeEnrollmentMutation
>;
export const updateUserProgrammeEnrollment = /* GraphQL */ `mutation UpdateUserProgrammeEnrollment(
  $input: UpdateUserProgrammeEnrollmentInput!
  $condition: ModelUserProgrammeEnrollmentConditionInput
) {
  updateUserProgrammeEnrollment(input: $input, condition: $condition) {
    spaceMembershipID
    sharedProgrammeID
    enrolledAt
    onboardingAnswers {
      questionID
      answer
    }
    endedAt
    eventRecords {
      calendarDay
      eventType
      updatedOn
    }
    customTargetCalories
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProgrammeEnrollmentMutationVariables,
  APITypes.UpdateUserProgrammeEnrollmentMutation
>;
export const deleteUserProgrammeEnrollment = /* GraphQL */ `mutation DeleteUserProgrammeEnrollment(
  $input: DeleteUserProgrammeEnrollmentInput!
  $condition: ModelUserProgrammeEnrollmentConditionInput
) {
  deleteUserProgrammeEnrollment(input: $input, condition: $condition) {
    spaceMembershipID
    sharedProgrammeID
    enrolledAt
    onboardingAnswers {
      questionID
      answer
    }
    endedAt
    eventRecords {
      calendarDay
      eventType
      updatedOn
    }
    customTargetCalories
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProgrammeEnrollmentMutationVariables,
  APITypes.DeleteUserProgrammeEnrollmentMutation
>;
export const createGRCRecipe = /* GraphQL */ `mutation CreateGRCRecipe(
  $input: CreateGRCRecipeInput!
  $condition: ModelGRCRecipeConditionInput
) {
  createGRCRecipe(input: $input, condition: $condition) {
    grcRecipeID
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateGRCRecipeMutationVariables,
  APITypes.CreateGRCRecipeMutation
>;
export const updateGRCRecipe = /* GraphQL */ `mutation UpdateGRCRecipe(
  $input: UpdateGRCRecipeInput!
  $condition: ModelGRCRecipeConditionInput
) {
  updateGRCRecipe(input: $input, condition: $condition) {
    grcRecipeID
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateGRCRecipeMutationVariables,
  APITypes.UpdateGRCRecipeMutation
>;
export const deleteGRCRecipe = /* GraphQL */ `mutation DeleteGRCRecipe(
  $input: DeleteGRCRecipeInput!
  $condition: ModelGRCRecipeConditionInput
) {
  deleteGRCRecipe(input: $input, condition: $condition) {
    grcRecipeID
    recipe {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
    derivedNutrition {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteGRCRecipeMutationVariables,
  APITypes.DeleteGRCRecipeMutation
>;
export const createSharedContentBoard = /* GraphQL */ `mutation CreateSharedContentBoard(
  $input: CreateSharedContentBoardInput!
  $condition: ModelSharedContentBoardConditionInput
) {
  createSharedContentBoard(input: $input, condition: $condition) {
    id
    title
    lanes {
      id
      title
      entries {
        id
        entryType
        objectID
      }
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateSharedContentBoardMutationVariables,
  APITypes.CreateSharedContentBoardMutation
>;
export const updateSharedContentBoard = /* GraphQL */ `mutation UpdateSharedContentBoard(
  $input: UpdateSharedContentBoardInput!
  $condition: ModelSharedContentBoardConditionInput
) {
  updateSharedContentBoard(input: $input, condition: $condition) {
    id
    title
    lanes {
      id
      title
      entries {
        id
        entryType
        objectID
      }
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateSharedContentBoardMutationVariables,
  APITypes.UpdateSharedContentBoardMutation
>;
export const deleteSharedContentBoard = /* GraphQL */ `mutation DeleteSharedContentBoard(
  $input: DeleteSharedContentBoardInput!
  $condition: ModelSharedContentBoardConditionInput
) {
  deleteSharedContentBoard(input: $input, condition: $condition) {
    id
    title
    lanes {
      id
      title
      entries {
        id
        entryType
        objectID
      }
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteSharedContentBoardMutationVariables,
  APITypes.DeleteSharedContentBoardMutation
>;
export const createSpaceIAPSubscription = /* GraphQL */ `mutation CreateSpaceIAPSubscription(
  $input: CreateSpaceIAPSubscriptionInput!
  $condition: ModelSpaceIAPSubscriptionConditionInput
) {
  createSpaceIAPSubscription(input: $input, condition: $condition) {
    spaceID
    productID
    title
    shortDescription
    currencyCode
    priceStr
    bundleID
    duration
    signUpButtonLabelOverride
    appStoreID
    rank
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateSpaceIAPSubscriptionMutationVariables,
  APITypes.CreateSpaceIAPSubscriptionMutation
>;
export const updateSpaceIAPSubscription = /* GraphQL */ `mutation UpdateSpaceIAPSubscription(
  $input: UpdateSpaceIAPSubscriptionInput!
  $condition: ModelSpaceIAPSubscriptionConditionInput
) {
  updateSpaceIAPSubscription(input: $input, condition: $condition) {
    spaceID
    productID
    title
    shortDescription
    currencyCode
    priceStr
    bundleID
    duration
    signUpButtonLabelOverride
    appStoreID
    rank
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpaceIAPSubscriptionMutationVariables,
  APITypes.UpdateSpaceIAPSubscriptionMutation
>;
export const deleteSpaceIAPSubscription = /* GraphQL */ `mutation DeleteSpaceIAPSubscription(
  $input: DeleteSpaceIAPSubscriptionInput!
  $condition: ModelSpaceIAPSubscriptionConditionInput
) {
  deleteSpaceIAPSubscription(input: $input, condition: $condition) {
    spaceID
    productID
    title
    shortDescription
    currencyCode
    priceStr
    bundleID
    duration
    signUpButtonLabelOverride
    appStoreID
    rank
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpaceIAPSubscriptionMutationVariables,
  APITypes.DeleteSpaceIAPSubscriptionMutation
>;
export const createSpace = /* GraphQL */ `mutation CreateSpace(
  $input: CreateSpaceInput!
  $condition: ModelSpaceConditionInput
) {
  createSpace(input: $input, condition: $condition) {
    title
    shortDescription
    whiteLabelElements {
      primaryMainColor
      backgroundStyle
      backgroundColor
      backgroundGradientColor
      cardBackgroundColor
      iconUrl
      splashImageUrl
      myDayBackgroundImageUrl
      desktopBannerImageUrl
      termsUrl
      privacyPolicyUrl
      primaryFontColor
      navBarFontColor
    }
    adminUserIDs
    landingPageContent
    landingPageStyle {
      backgroundColor
    }
    landingPageCoverImageUrl
    isDefaultSpace
    sharedProgrammeConfiguration {
      sharedProgrammeCategories {
        categoryTag
        orderedProgrammeIDs
      }
    }
    productPreviewSections {
      title
      productPreviews {
        productType
        productObjectID
        title
        shortDescription
        coverImageUrl
        availableInMembershipTierIDs
      }
    }
    videoPlaylists {
      title
      playlistID
      items {
        videoID
        title
        description
        thumbnailImageUrl
      }
    }
    spaceContentBoard {
      id
      title
      lanes {
        id
        title
        entries {
          id
          entryType
          objectID
        }
      }
    }
    spaceSharedContentBoardIDs
    welcomeVideo {
      videoID
      title
      description
      thumbnailImageUrl
    }
    myDayScreenSections {
      publishedSectionType
      spaceContentLaneID
    }
    subscriptionBenefitsContent
    subscriptionPageStyle {
      backgroundColor
    }
    groups
    sharedRecipesBoardIDs
    defaultSharedProgrammeIDForNewUsers
    appStoreLinks {
      appStoreID
      appUrl
    }
    chatConfig {
      appID
      appRegion
    }
    publishedSearchTags {
      id
      tag
    }
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateSpaceMutationVariables,
  APITypes.CreateSpaceMutation
>;
export const updateSpace = /* GraphQL */ `mutation UpdateSpace(
  $input: UpdateSpaceInput!
  $condition: ModelSpaceConditionInput
) {
  updateSpace(input: $input, condition: $condition) {
    title
    shortDescription
    whiteLabelElements {
      primaryMainColor
      backgroundStyle
      backgroundColor
      backgroundGradientColor
      cardBackgroundColor
      iconUrl
      splashImageUrl
      myDayBackgroundImageUrl
      desktopBannerImageUrl
      termsUrl
      privacyPolicyUrl
      primaryFontColor
      navBarFontColor
    }
    adminUserIDs
    landingPageContent
    landingPageStyle {
      backgroundColor
    }
    landingPageCoverImageUrl
    isDefaultSpace
    sharedProgrammeConfiguration {
      sharedProgrammeCategories {
        categoryTag
        orderedProgrammeIDs
      }
    }
    productPreviewSections {
      title
      productPreviews {
        productType
        productObjectID
        title
        shortDescription
        coverImageUrl
        availableInMembershipTierIDs
      }
    }
    videoPlaylists {
      title
      playlistID
      items {
        videoID
        title
        description
        thumbnailImageUrl
      }
    }
    spaceContentBoard {
      id
      title
      lanes {
        id
        title
        entries {
          id
          entryType
          objectID
        }
      }
    }
    spaceSharedContentBoardIDs
    welcomeVideo {
      videoID
      title
      description
      thumbnailImageUrl
    }
    myDayScreenSections {
      publishedSectionType
      spaceContentLaneID
    }
    subscriptionBenefitsContent
    subscriptionPageStyle {
      backgroundColor
    }
    groups
    sharedRecipesBoardIDs
    defaultSharedProgrammeIDForNewUsers
    appStoreLinks {
      appStoreID
      appUrl
    }
    chatConfig {
      appID
      appRegion
    }
    publishedSearchTags {
      id
      tag
    }
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpaceMutationVariables,
  APITypes.UpdateSpaceMutation
>;
export const deleteSpace = /* GraphQL */ `mutation DeleteSpace(
  $input: DeleteSpaceInput!
  $condition: ModelSpaceConditionInput
) {
  deleteSpace(input: $input, condition: $condition) {
    title
    shortDescription
    whiteLabelElements {
      primaryMainColor
      backgroundStyle
      backgroundColor
      backgroundGradientColor
      cardBackgroundColor
      iconUrl
      splashImageUrl
      myDayBackgroundImageUrl
      desktopBannerImageUrl
      termsUrl
      privacyPolicyUrl
      primaryFontColor
      navBarFontColor
    }
    adminUserIDs
    landingPageContent
    landingPageStyle {
      backgroundColor
    }
    landingPageCoverImageUrl
    isDefaultSpace
    sharedProgrammeConfiguration {
      sharedProgrammeCategories {
        categoryTag
        orderedProgrammeIDs
      }
    }
    productPreviewSections {
      title
      productPreviews {
        productType
        productObjectID
        title
        shortDescription
        coverImageUrl
        availableInMembershipTierIDs
      }
    }
    videoPlaylists {
      title
      playlistID
      items {
        videoID
        title
        description
        thumbnailImageUrl
      }
    }
    spaceContentBoard {
      id
      title
      lanes {
        id
        title
        entries {
          id
          entryType
          objectID
        }
      }
    }
    spaceSharedContentBoardIDs
    welcomeVideo {
      videoID
      title
      description
      thumbnailImageUrl
    }
    myDayScreenSections {
      publishedSectionType
      spaceContentLaneID
    }
    subscriptionBenefitsContent
    subscriptionPageStyle {
      backgroundColor
    }
    groups
    sharedRecipesBoardIDs
    defaultSharedProgrammeIDForNewUsers
    appStoreLinks {
      appStoreID
      appUrl
    }
    chatConfig {
      appID
      appRegion
    }
    publishedSearchTags {
      id
      tag
    }
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpaceMutationVariables,
  APITypes.DeleteSpaceMutation
>;
export const createSpaceMembership = /* GraphQL */ `mutation CreateSpaceMembership(
  $input: CreateSpaceMembershipInput!
  $condition: ModelSpaceMembershipConditionInput
) {
  createSpaceMembership(input: $input, condition: $condition) {
    spaceID
    memberID
    state
    correlatedUserID
    expiresAt
    visibleProgrammeIDsOverride
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
    groups
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSpaceMembershipMutationVariables,
  APITypes.CreateSpaceMembershipMutation
>;
export const updateSpaceMembership = /* GraphQL */ `mutation UpdateSpaceMembership(
  $input: UpdateSpaceMembershipInput!
  $condition: ModelSpaceMembershipConditionInput
) {
  updateSpaceMembership(input: $input, condition: $condition) {
    spaceID
    memberID
    state
    correlatedUserID
    expiresAt
    visibleProgrammeIDsOverride
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
    groups
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpaceMembershipMutationVariables,
  APITypes.UpdateSpaceMembershipMutation
>;
export const deleteSpaceMembership = /* GraphQL */ `mutation DeleteSpaceMembership(
  $input: DeleteSpaceMembershipInput!
  $condition: ModelSpaceMembershipConditionInput
) {
  deleteSpaceMembership(input: $input, condition: $condition) {
    spaceID
    memberID
    state
    correlatedUserID
    expiresAt
    visibleProgrammeIDsOverride
    membershipTierID
    stripeCustomerID
    stripeSubscriptionID
    onboardingAnswers {
      questionID
      answer
    }
    onboardingAnswersLastUpdatedAt
    customTargetCalories
    welcomeVideoWatched
    initialProgrammeSelectionLastOfferedAt
    chatNickname
    referrer
    appStoreSubscriptionID
    groups
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpaceMembershipMutationVariables,
  APITypes.DeleteSpaceMembershipMutation
>;
export const createSpaceMembershipEvent = /* GraphQL */ `mutation CreateSpaceMembershipEvent(
  $input: CreateSpaceMembershipEventInput!
  $condition: ModelSpaceMembershipEventConditionInput
) {
  createSpaceMembershipEvent(input: $input, condition: $condition) {
    spaceMembershipID
    memberID
    eventType
    transactionSource
    transactionID
    details
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSpaceMembershipEventMutationVariables,
  APITypes.CreateSpaceMembershipEventMutation
>;
export const updateSpaceMembershipEvent = /* GraphQL */ `mutation UpdateSpaceMembershipEvent(
  $input: UpdateSpaceMembershipEventInput!
  $condition: ModelSpaceMembershipEventConditionInput
) {
  updateSpaceMembershipEvent(input: $input, condition: $condition) {
    spaceMembershipID
    memberID
    eventType
    transactionSource
    transactionID
    details
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpaceMembershipEventMutationVariables,
  APITypes.UpdateSpaceMembershipEventMutation
>;
export const deleteSpaceMembershipEvent = /* GraphQL */ `mutation DeleteSpaceMembershipEvent(
  $input: DeleteSpaceMembershipEventInput!
  $condition: ModelSpaceMembershipEventConditionInput
) {
  deleteSpaceMembershipEvent(input: $input, condition: $condition) {
    spaceMembershipID
    memberID
    eventType
    transactionSource
    transactionID
    details
    createdAt
    id
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpaceMembershipEventMutationVariables,
  APITypes.DeleteSpaceMembershipEventMutation
>;
export const createUserMyDayActionRecord = /* GraphQL */ `mutation CreateUserMyDayActionRecord(
  $input: CreateUserMyDayActionRecordInput!
  $condition: ModelUserMyDayActionRecordConditionInput
) {
  createUserMyDayActionRecord(input: $input, condition: $condition) {
    userProgrammeEnrollmentID
    programmeEntryType
    objectID
    parentID
    calendarDay
    actionType
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMyDayActionRecordMutationVariables,
  APITypes.CreateUserMyDayActionRecordMutation
>;
export const updateUserMyDayActionRecord = /* GraphQL */ `mutation UpdateUserMyDayActionRecord(
  $input: UpdateUserMyDayActionRecordInput!
  $condition: ModelUserMyDayActionRecordConditionInput
) {
  updateUserMyDayActionRecord(input: $input, condition: $condition) {
    userProgrammeEnrollmentID
    programmeEntryType
    objectID
    parentID
    calendarDay
    actionType
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMyDayActionRecordMutationVariables,
  APITypes.UpdateUserMyDayActionRecordMutation
>;
export const deleteUserMyDayActionRecord = /* GraphQL */ `mutation DeleteUserMyDayActionRecord(
  $input: DeleteUserMyDayActionRecordInput!
  $condition: ModelUserMyDayActionRecordConditionInput
) {
  deleteUserMyDayActionRecord(input: $input, condition: $condition) {
    userProgrammeEnrollmentID
    programmeEntryType
    objectID
    parentID
    calendarDay
    actionType
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMyDayActionRecordMutationVariables,
  APITypes.DeleteUserMyDayActionRecordMutation
>;
export const createMembershipTier = /* GraphQL */ `mutation CreateMembershipTier(
  $input: CreateMembershipTierInput!
  $condition: ModelMembershipTierConditionInput
) {
  createMembershipTier(input: $input, condition: $condition) {
    spaceID
    title
    shortDescription
    monthlyPrice
    quarterlyPrice
    annualPrice
    freeTrialDurationDays
    rank
    currencyCode
    state
    stripeProductID
    stripePriceID
    stripePriceIDQuarterly
    stripePriceIDAnnual
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateMembershipTierMutationVariables,
  APITypes.CreateMembershipTierMutation
>;
export const updateMembershipTier = /* GraphQL */ `mutation UpdateMembershipTier(
  $input: UpdateMembershipTierInput!
  $condition: ModelMembershipTierConditionInput
) {
  updateMembershipTier(input: $input, condition: $condition) {
    spaceID
    title
    shortDescription
    monthlyPrice
    quarterlyPrice
    annualPrice
    freeTrialDurationDays
    rank
    currencyCode
    state
    stripeProductID
    stripePriceID
    stripePriceIDQuarterly
    stripePriceIDAnnual
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipTierMutationVariables,
  APITypes.UpdateMembershipTierMutation
>;
export const deleteMembershipTier = /* GraphQL */ `mutation DeleteMembershipTier(
  $input: DeleteMembershipTierInput!
  $condition: ModelMembershipTierConditionInput
) {
  deleteMembershipTier(input: $input, condition: $condition) {
    spaceID
    title
    shortDescription
    monthlyPrice
    quarterlyPrice
    annualPrice
    freeTrialDurationDays
    rank
    currencyCode
    state
    stripeProductID
    stripePriceID
    stripePriceIDQuarterly
    stripePriceIDAnnual
    groups
    id
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteMembershipTierMutationVariables,
  APITypes.DeleteMembershipTierMutation
>;
export const createSpacePrivateConfig = /* GraphQL */ `mutation CreateSpacePrivateConfig(
  $input: CreateSpacePrivateConfigInput!
  $condition: ModelSpacePrivateConfigConditionInput
) {
  createSpacePrivateConfig(input: $input, condition: $condition) {
    id
    spaceID
    stripeConfig {
      environment
      connectedAccountID
      chargesEnabled
      stripeProductID
      billingPortalConfigurationID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateSpacePrivateConfigMutationVariables,
  APITypes.CreateSpacePrivateConfigMutation
>;
export const updateSpacePrivateConfig = /* GraphQL */ `mutation UpdateSpacePrivateConfig(
  $input: UpdateSpacePrivateConfigInput!
  $condition: ModelSpacePrivateConfigConditionInput
) {
  updateSpacePrivateConfig(input: $input, condition: $condition) {
    id
    spaceID
    stripeConfig {
      environment
      connectedAccountID
      chargesEnabled
      stripeProductID
      billingPortalConfigurationID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpacePrivateConfigMutationVariables,
  APITypes.UpdateSpacePrivateConfigMutation
>;
export const deleteSpacePrivateConfig = /* GraphQL */ `mutation DeleteSpacePrivateConfig(
  $input: DeleteSpacePrivateConfigInput!
  $condition: ModelSpacePrivateConfigConditionInput
) {
  deleteSpacePrivateConfig(input: $input, condition: $condition) {
    id
    spaceID
    stripeConfig {
      environment
      connectedAccountID
      chargesEnabled
      stripeProductID
      billingPortalConfigurationID
    }
    groups
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpacePrivateConfigMutationVariables,
  APITypes.DeleteSpacePrivateConfigMutation
>;
export const createAccountActivitySignal = /* GraphQL */ `mutation CreateAccountActivitySignal(
  $input: CreateAccountActivitySignalInput!
  $condition: ModelAccountActivitySignalConditionInput
) {
  createAccountActivitySignal(input: $input, condition: $condition) {
    id
    spaceID
    groups
    lastActivityAt
    friendlyUsername
    lastActivityObjectType
    lastActivityObjectID
    sourceIp
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountActivitySignalMutationVariables,
  APITypes.CreateAccountActivitySignalMutation
>;
export const updateAccountActivitySignal = /* GraphQL */ `mutation UpdateAccountActivitySignal(
  $input: UpdateAccountActivitySignalInput!
  $condition: ModelAccountActivitySignalConditionInput
) {
  updateAccountActivitySignal(input: $input, condition: $condition) {
    id
    spaceID
    groups
    lastActivityAt
    friendlyUsername
    lastActivityObjectType
    lastActivityObjectID
    sourceIp
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountActivitySignalMutationVariables,
  APITypes.UpdateAccountActivitySignalMutation
>;
export const deleteAccountActivitySignal = /* GraphQL */ `mutation DeleteAccountActivitySignal(
  $input: DeleteAccountActivitySignalInput!
  $condition: ModelAccountActivitySignalConditionInput
) {
  deleteAccountActivitySignal(input: $input, condition: $condition) {
    id
    spaceID
    groups
    lastActivityAt
    friendlyUsername
    lastActivityObjectType
    lastActivityObjectID
    sourceIp
    createdAt
    updatedAt
    owner
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountActivitySignalMutationVariables,
  APITypes.DeleteAccountActivitySignalMutation
>;
export const createContact = /* GraphQL */ `mutation CreateContact(
  $email: AWSEmail!
  $givenName: String
  $familyName: String
) {
  createContact(email: $email, givenName: $givenName, familyName: $familyName)
}
` as GeneratedMutation<
  APITypes.CreateContactMutationVariables,
  APITypes.CreateContactMutation
>;
export const emailWelcomeMessage = /* GraphQL */ `mutation EmailWelcomeMessage(
  $recipient: AWSEmail!
  $givenName: String
  $familyName: String
) {
  emailWelcomeMessage(
    recipient: $recipient
    givenName: $givenName
    familyName: $familyName
  )
}
` as GeneratedMutation<
  APITypes.EmailWelcomeMessageMutationVariables,
  APITypes.EmailWelcomeMessageMutation
>;
export const emailHealthProWelcomeMessage = /* GraphQL */ `mutation EmailHealthProWelcomeMessage(
  $recipient: AWSEmail!
  $givenName: String
  $familyName: String
) {
  emailHealthProWelcomeMessage(
    recipient: $recipient
    givenName: $givenName
    familyName: $familyName
  )
}
` as GeneratedMutation<
  APITypes.EmailHealthProWelcomeMessageMutationVariables,
  APITypes.EmailHealthProWelcomeMessageMutation
>;
export const emailWelcomeToSpaceMessage = /* GraphQL */ `mutation EmailWelcomeToSpaceMessage(
  $recipient: AWSEmail!
  $givenName: String
  $familyName: String
  $spaceTitle: String!
  $spaceSubscribeUrl: String!
) {
  emailWelcomeToSpaceMessage(
    recipient: $recipient
    givenName: $givenName
    familyName: $familyName
    spaceTitle: $spaceTitle
    spaceSubscribeUrl: $spaceSubscribeUrl
  )
}
` as GeneratedMutation<
  APITypes.EmailWelcomeToSpaceMessageMutationVariables,
  APITypes.EmailWelcomeToSpaceMessageMutation
>;
export const getIngredientTreeNodes = /* GraphQL */ `mutation GetIngredientTreeNodes($locale: String) {
  getIngredientTreeNodes(locale: $locale) {
    ingredientName
    parentIngredientName
  }
}
` as GeneratedMutation<
  APITypes.GetIngredientTreeNodesMutationVariables,
  APITypes.GetIngredientTreeNodesMutation
>;
