import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nutrition from '../meal/nutrition';

const ProgrammePreviewNavigationBar = ({
  selectedProgrammeId,
  selectedPlanId,
  calorieTarget,
}) => {
  const navigate = useNavigate();
  const selectedProgrammeTitle = useSelector(
    (state) =>
      state.programmes.find((p) => p.id === selectedProgrammeId)?.title,
  );
  const selectedPlanTitle = useSelector(
    (state) =>
      (
        state.programmes.find((p) => p.id === selectedProgrammeId)?.plans || []
      ).find((pl) => pl.id === selectedPlanId)?.title,
  );

  const onReturn = () => {
    navigate(`/programmes/${selectedProgrammeId}/plans/${selectedPlanId}`);
  };

  return (
    <Box
      display="flex"
      className="plannerNavigationBar"
      justifyContent="center"
      style={{
        color: 'var(--app-primary-font-color)',
        position: 'relative',
        height: 40,
      }}>
      <div
        style={{
          padding: '0 10px',
          display: 'flex',
          position: 'absolute',
          left: 0,
          top: 0,
          height: 40,
        }}>
        <div style={{ margin: 'auto 0' }}>
          Preview of {selectedProgrammeTitle} - {selectedPlanTitle};{' '}
          <Nutrition metric="calories" quantity={calorieTarget} /> daily target
        </div>
      </div>{' '}
      <Button
        onClick={onReturn}
        variant="text"
        size="small"
        color="primary"
        style={{
          fontSize: '14px',
          color: 'var(--app-primary-font-color)',
          border: '1px solid var(--app-primary-font-color)',
          padding: '0 5px',
        }}>
        Back to plan
      </Button>
    </Box>
  );
};

ProgrammePreviewNavigationBar.propTypes = {
  selectedProgrammeId: PropTypes.string.isRequired,
  selectedPlanId: PropTypes.string.isRequired,
  calorieTarget: PropTypes.number.isRequired,
};

export default ProgrammePreviewNavigationBar;
