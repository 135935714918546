import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import AppContentPreview from './app_content_preview';

const ProgrammeCardPreview = ({
  title,
  coverImageUrl,
  categoryTitle,
  shortDescription,
}) => {
  const previewInnerContent = (
    <>
      <div className="screen-title" style={{ flexGrow: 1, paddingTop: 64 }}>
        Programs
      </div>

      <Grid container style={{ padding: 10, paddingLeft: 23 }}>
        <Grid item xs={12} style={{ color: 'var(--app-primary-font-color)' }}>
          <h2>{categoryTitle}</h2>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <div style={{ display: 'flex', overflowX: 'auto' }}>
                <Card
                  sx={{
                    width: 166,
                    height: 242,
                    minWidth: 166,
                    borderRadius: '15px',
                    cursor: 'pointer',
                  }}>
                  <CardMedia sx={{ height: 140 }} image={coverImageUrl || ''} />
                  <CardContent sx={{ padding: '8px' }}>
                    <Typography
                      variant="h4"
                      sx={{ padding: 0 }}
                      style={{ fontSize: '14px' }}>
                      {title}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        lineClamp: 2,
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                      }}>
                      {shortDescription}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return <AppContentPreview innerContent={previewInnerContent} />;
};

ProgrammeCardPreview.propTypes = {
  title: PropTypes.string,
  coverImageUrl: PropTypes.string,
  categoryTitle: PropTypes.string,
  shortDescription: PropTypes.string,
};

ProgrammeCardPreview.defaultProps = {
  title: '',
  coverImageUrl: '',
  categoryTitle: '',
  shortDescription: '',
};

export default ProgrammeCardPreview;
