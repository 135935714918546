import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ContentEntryDetail from './content_entry_detail';
import {
  contentEntryAppLinkUpdatedAction,
  contentEntryCoverImageShownInDetailViewChangedAction,
  contentEntryCoverImageUpdatedAction,
  contentEntrySectionUpdatedAction,
  sharedContentEntryAppLinkUpdatedAction,
  sharedContentEntryCoverImageShownInDetailViewChangedAction,
  sharedContentEntryCoverImageUpdatedAction,
  sharedContentEntrySectionUpdatedAction,
} from '../../action_creators/content_entry_action_creators';
import { uploadUserImage } from '../../services/user_generated_content';

const ContentEntryDetailContainer = ({
  contentEntryID,
  appLinkEnabled,
  visible,
  dismiss,
}) => {
  // Must not access state from within callbacks passed to ContentEditable.
  // The issue is described at:
  // https://github.com/lovasoa/react-contenteditable/issues/161
  const currentTextRef = useRef();
  const currentContentEntryRef = useRef();

  const [currentlyEditingInline, setCurrentlyEditingInline] = useState({
    section: null,
    itemId: null,
  });

  const dispatch = useDispatch();

  const contentEntry = useSelector(
    (state) =>
      state.contentEntries[contentEntryID] ||
      state.sharedContentEntries[contentEntryID],
  );

  const isSharedContentEntry = useSelector(
    (state) => !!state.sharedContentEntries[contentEntryID],
  );

  useEffect(() => {
    currentContentEntryRef.current = contentEntry;
  }, [contentEntry]);

  const dismissEditingInline = () => {
    currentTextRef.current = null;
    setCurrentlyEditingInline({ section: null, itemId: null });
  };

  if (!visible || !contentEntry) {
    return null;
  }

  console.log(`ContentEntryDetail contentEntryID=${contentEntryID}`);

  const onInlineEditBlur = (section, itemId, reason) => {
    console.log(`Blur ${section} ${itemId} ${reason}`);
    if (['title', 'body'].includes(section)) {
      const contentChanged =
        currentTextRef.current !== currentContentEntryRef.current[section];
      if (contentChanged) {
        const newContent = currentTextRef.current;
        if (section === 'body' && !newContent) {
          console.warn('newContent is blank! doing nothing.');
        } else if (isSharedContentEntry) {
          dispatch(
            sharedContentEntrySectionUpdatedAction(
              contentEntryID,
              section,
              newContent,
            ),
          );
        } else {
          dispatch(
            contentEntrySectionUpdatedAction(
              contentEntryID,
              section,
              newContent,
            ),
          );
        }
      }
    }

    dismissEditingInline();
  };

  const onInlineEditFocus = (section) => {
    console.log(`Focus ${section}`);
    const value = currentContentEntryRef.current[section] || '';
    currentTextRef.current = value.toString();
    setCurrentlyEditingInline({ section });
  };

  const onInlineEditChange = (section, itemId, value) => {
    console.log(`Change ${section}`);
    currentTextRef.current = value;
  };

  const onDialogClose = (ev, reason) => {
    if (reason === 'escapeKeyDown') {
      console.log('Dialog escape key down');
      // Prevent dialog from closing, let the contenteditable handle the esc key
      return null;
    }
    console.log(`Dialog close other reason: ${reason}`);
    dismiss();
    return true;
  };

  const onNewCoverImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(contentEntryID, fileList);
    if (isSharedContentEntry) {
      dispatch(
        sharedContentEntryCoverImageUpdatedAction(contentEntryID, newImageUrl),
      );
    } else {
      dispatch(
        contentEntryCoverImageUpdatedAction(contentEntryID, newImageUrl),
      );
    }
  };

  const onAppLinkChange = (newAppLink) => {
    if (isSharedContentEntry) {
      dispatch(
        sharedContentEntryAppLinkUpdatedAction(contentEntryID, newAppLink),
      );
    } else {
      dispatch(contentEntryAppLinkUpdatedAction(contentEntryID, newAppLink));
    }
  };

  const onCoverImageShownInDetailViewChange = (
    newCoverImageShownInDetailView,
  ) => {
    if (isSharedContentEntry) {
      dispatch(
        sharedContentEntryCoverImageShownInDetailViewChangedAction(
          contentEntryID,
          newCoverImageShownInDetailView,
        ),
      );
    } else {
      dispatch(
        contentEntryCoverImageShownInDetailViewChangedAction(
          contentEntryID,
          newCoverImageShownInDetailView,
        ),
      );
    }
  };

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      // Prevents TinyMCE issue - disabled inputs in Image dialogs invoked from toolbar
      disableEnforceFocus
      onClose={onDialogClose}>
      <ContentEntryDetail
        contentEntry={contentEntry}
        currentlyEditingInline={currentlyEditingInline}
        currentTextRef={currentTextRef}
        onInlineEditFocus={onInlineEditFocus}
        onInlineEditChange={onInlineEditChange}
        onInlineEditBlur={onInlineEditBlur}
        onNewCoverImageChosen={onNewCoverImageChosen}
        onDialogClose={onDialogClose}
        appLinkEnabled={appLinkEnabled}
        onAppLinkChange={onAppLinkChange}
        onCoverImageShownInDetailViewChange={
          onCoverImageShownInDetailViewChange
        }
      />
    </Dialog>
  );
};

ContentEntryDetailContainer.propTypes = {
  contentEntryID: PropTypes.string,
  appLinkEnabled: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  dismiss: PropTypes.func,
};

ContentEntryDetailContainer.defaultProps = {
  contentEntryID: null,
  appLinkEnabled: false,
  dismiss: () => {},
};

export default ContentEntryDetailContainer;
