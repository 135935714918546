import React, { useState } from 'react';
import { Fab, Zoom } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector } from 'react-redux';
import MealBasketPopover from './meal_basket_popover';
import { mealsExistInBasketSelector } from '../../reducers/meal_reducer';

const MealBasketWidget = () => {
  const mealsExistInBasket = useSelector(mealsExistInBasketSelector);

  const [anchorEl, setAnchorEl] = useState(null);

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  // const theme = useTheme();

  return (
    <>
      <div className="mealBasketWidget">
        <Zoom in={mealsExistInBasket}>
          <Fab
            color="warning"
            size="small"
            style={{
              position: 'fixed',
              bottom: '67px',
              right: '10px',
              color: 'var(--app-primary-font-color)',
            }}
            onClick={(ev) => setAnchorEl(ev.target)}>
            <ShoppingCartIcon />
          </Fab>
        </Zoom>
      </div>
      <MealBasketPopover
        anchorEl={anchorEl}
        isOpen={mealsExistInBasket}
        onClose={onClosePopover}
      />
    </>
  );
};

export default MealBasketWidget;
