import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { v4 as uuidv4 } from 'uuid';
import { uploadUserImage } from '../../services/user_generated_content';
import { DEFAULT_SMORG_BACKGROUND_COLOR } from '../../services/spaces';
import { tinymceVideoPluginEntryPoint } from '../../services/tinymce_video_plugin';
import { tinymceEmbedPluginEntryPoint } from '../../services/tinymce_embed_plugin';

const HtmlEditor = ({
  initialHtml,
  height,
  width,
  editorRef,
  onChange,
  onBlur,
  backgroundColor,
}) => {
  const imageUploadHandler = async (blobInfo, progress) => {
    const fileList = [blobInfo.blob()];
    const imageObjectId = uuidv4();
    const newImageUrl = uploadUserImage(imageObjectId, fileList, true);
    return newImageUrl;
  };

  useEffect(() => {
    console.log(`Background color changed to ${backgroundColor}`);
    // console.log(editorRef.current);
    document.documentElement.style.setProperty(
      '--tinymce-background-color',
      backgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR,
    );

    return () => {
      document.documentElement.style.setProperty(
        '--tinymce-background-color',
        DEFAULT_SMORG_BACKGROUND_COLOR,
      );
    };
  }, [backgroundColor]);

  return (
    <Editor
      tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
      onInit={(evt, tinymceEditor) => {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        editorRef.current = tinymceEditor;
      }}
      initialValue={initialHtml}
      onEditorChange={(newValue, editor) => {
        onChange(newValue);
      }}
      onBlur={() => onBlur && onBlur()}
      init={{
        height,
        width,
        promotion: false,
        menubar: 'edit insert format',
        menu: {
          edit: {
            title: 'Edit',
            items:
              'undo redo | cut copy paste pastetext | selectall | searchreplace',
          },
          insert: {
            title: 'Insert',
            items:
              'image link smorgvideo smorgembed addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime',
          },
          format: {
            title: 'Format',
            items:
              'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat',
          },
        },
        plugins: [
          'image',
          'media',
          'smorgvideo',
          'smorgembed',
          'lists',
          'link',
        ],
        media_alt_source: false,
        media_dimensions: false,
        media_poster: false,
        media_strict: false,
        extended_valid_elements:
          'iframe[width|height|srcdoc|frameborder|title|src|style|allowfullscreen]',
        images_upload_handler: imageUploadHandler,
        // formats: {
        //   // Changes the default format for h1 to have a class of heading
        //   h1: { block: 'h1', classes: 'heading' },
        // },
        // style_formats: [
        //   // Adds the h1 format defined above to style_formats
        //   { title: 'Heading 1', format: 'h1' },
        // ],
        toolbar:
          'undo redo | styles | bold italic | forecolor backcolor | numlist bullist | image smorgvideo link smorgembed | alignleft aligncenter alignright alignjustify | outdent indent',
        // style_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
        // toolbar:
        //   'undo redo | styleselect formatselect | ' +
        //   'bold italic backcolor | alignleft aligncenter ' +
        //   'alignright alignjustify | bullist numlist outdent indent | ' +
        //   'removeformat | help',
        statusbar: false,
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        setup: (tinymceEditor) => {
          const newColor = document.createElement('style');
          document.head.appendChild(newColor);
          console.log(`In tinymce setup ${tinymceEditor.id}`);
          // console.log(editor);
          const iframeId = `${tinymceEditor.id}_ifr`;
          newColor.sheet.insertRule(
            `#${iframeId} {background-color: var(--tinymce-background-color);}`,
          );
          // console.log({
          //   PluginManager: tinymceEditor.editorManager.PluginManager,
          // });
          tinymceEditor.editorManager.PluginManager.add(
            'smorgvideo',
            tinymceVideoPluginEntryPoint,
          );
          tinymceEditor.editorManager.PluginManager.add(
            'smorgembed',
            tinymceEmbedPluginEntryPoint,
          );
          tinymceEditor.on('init', (args) => {
            const tgt = args.target;
            tgt.on('NodeChange', (e) => {
              // console.log('node change');
              if (e && e.element.nodeName.toLowerCase() === 'img') {
                let { width: imgWidth, height: imgHeight } = e.element;
                console.log({ imgWidth, imgHeight });
                if (imgWidth > 384) {
                  imgHeight /= imgWidth / 384;
                  imgWidth = 384;
                  console.log({ imgWidth, imgHeight });
                  tgt.dom.setAttribs(e.element, {
                    width: imgWidth,
                    height: imgHeight,
                  });
                }
              }
            });
          });
        },
      }}
    />
  );
};

HtmlEditor.propTypes = {
  initialHtml: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  editorRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
};

HtmlEditor.defaultProps = {
  backgroundColor: null,
};

export default HtmlEditor;
