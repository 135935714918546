import React from 'react';
import PropTypes from 'prop-types';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import Icon from '@mdi/react';
import {
  mdiBowlMixOutline,
  mdiCalendarWeekOutline,
  mdiCheckOutline,
  mdiDotsHorizontal,
  mdiSchool,
} from '@mdi/js';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { BackgroundStyle } from '../../API';

const AppPreview = ({
  primaryMainColor,
  backgroundStyle,
  backgroundColor,
  backgroundGradientColor,
  primaryFontColor,
  navBarFontColor,
  innerContent,
  hideBottomNavigation,
}) => {
  const phoneHeight = 812;
  const zoomFactor = 0.75;

  const backgroundCss =
    backgroundStyle === BackgroundStyle.LINEAR_GRADIENT
      ? {
          backgroundImage: `linear-gradient(180deg, ${backgroundColor}, ${backgroundGradientColor})`,
        }
      : { backgroundColor: backgroundColor || '#427a46' };

  return (
    <div
      className="appPreview"
      style={{
        marginTop: `calc(-${phoneHeight / 2}px * (1 - ${zoomFactor}))`,
        height: `${phoneHeight * zoomFactor}px`,
      }}>
      <DeviceFrameset device="iPhone X" color="black" zoom={zoomFactor}>
        <div
          style={{
            height: '100%',
            ...backgroundCss,
          }}>
          {innerContent}

          {!hideBottomNavigation && (
            <Paper
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                padding: '0 8px',
              }}
              style={{
                backgroundColor: primaryMainColor || '#427a46',
              }}
              elevation={3}>
              <BottomNavigation
                showLabels
                style={{
                  backgroundColor: primaryMainColor || '#427a46',
                  textAlign: 'center',
                  height: '72px',
                  fontSize: '14px',
                }}>
                <BottomNavigationAction
                  label="My Day"
                  href="#"
                  disabled
                  variant="appPreview"
                  style={{ color: navBarFontColor }}
                  icon={
                    <Icon
                      path={mdiCheckOutline}
                      style={{
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  }
                />
                <BottomNavigationAction
                  label="Recipes"
                  href="#"
                  disabled
                  variant="appPreview"
                  style={{ color: navBarFontColor }}
                  icon={
                    <Icon
                      path={mdiBowlMixOutline}
                      style={{
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  }
                />
                <BottomNavigationAction
                  label="Planner"
                  href="#"
                  disabled
                  variant="appPreview"
                  style={{ color: navBarFontColor }}
                  icon={
                    <Icon
                      path={mdiCalendarWeekOutline}
                      style={{
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  }
                />
                <BottomNavigationAction
                  label="Programs"
                  href="#"
                  disabled
                  variant="appPreview"
                  style={{ color: navBarFontColor }}
                  icon={
                    <Icon
                      path={mdiSchool}
                      style={{
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  }
                />
                <BottomNavigationAction
                  label="More"
                  href="#"
                  disabled
                  variant="appPreview"
                  style={{ color: navBarFontColor }}
                  icon={
                    <Icon
                      path={mdiDotsHorizontal}
                      style={{ width: '32px', height: '32px' }}
                    />
                  }
                />
              </BottomNavigation>
            </Paper>
          )}
        </div>
      </DeviceFrameset>
    </div>
  );
};

AppPreview.propTypes = {
  primaryMainColor: PropTypes.string,
  backgroundStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundGradientColor: PropTypes.string,
  primaryFontColor: PropTypes.string,
  navBarFontColor: PropTypes.string,
  innerContent: PropTypes.element.isRequired,
  hideBottomNavigation: PropTypes.bool,
};

AppPreview.defaultProps = {
  primaryMainColor: null,
  backgroundStyle: 'SOLID',
  backgroundColor: null,
  backgroundGradientColor: null,
  primaryFontColor: null,
  navBarFontColor: null,
  hideBottomNavigation: false,
};

export default AppPreview;
