import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import MealBasketWidget from '../meal/meal_basket_widget';
import { sharedRecipesBoardByIDSelector } from '../../reducers/recipes_reducer';
import RecipeCollectionAddNewMealButton from './recipe_collection_add_new_meal_button';
import RecipeCollectionFullWidthMealCard from './recipe_collection_full_width_meal_card';
import RecipeCollectionMenuActionsMenu from './recipe_collection_menu_actions_menu';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const RecipeSingleCollectionMenuView = () => {
  const navigate = useNavigate();

  const { boardID, menuID } = useParams();

  const ownRecipesBoard = useSelector((state) =>
    state.recipesBoards.find((b) => b.id === boardID),
  );

  const sharedRecipesBoard = useSelector((state) =>
    sharedRecipesBoardByIDSelector(state, boardID),
  );

  const [menuActionsMenuAnchorEl, setMenuActionsMenuAnchorEl] = useState(null);

  const onClickMenuActionsMenu = (ev) => {
    setMenuActionsMenuAnchorEl(ev.target);
  };

  const onDismissMenuActionsMenu = () => {
    setMenuActionsMenuAnchorEl(null);
  };

  const recipesBoard = ownRecipesBoard || sharedRecipesBoard;

  const isReadOnly = !ownRecipesBoard;

  if (!recipesBoard) {
    return null;
  }

  const theMenu = recipesBoard.menus.find((m) => m.id === menuID);

  if (!theMenu) {
    return null;
  }

  return (
    <>
      <MealBasketWidget />
      <div className="collections-view">
        <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
          <Box style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div className="popup-title-back-button">
              <IconButton aria-label="back" onClick={() => navigate(-1)}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
            </div>
            <div className="popup-title-close-button">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                size="small"
                onClick={onClickMenuActionsMenu}>
                <Icon
                  path={mdiDotsHorizontal}
                  style={{
                    width: '24px',
                    height: '24px',
                    color: 'var(--app-primary-font-color)',
                  }}
                />
              </IconButton>
            </div>
            <div
              style={{
                padding: '8px',
                textAlign: 'center',
              }}>
              <Typography variant="popupTitle">{recipesBoard.title}</Typography>
            </div>
          </Box>
        </div>
        <div
          style={{
            marginTop: '20px',
          }}>
          <h1 style={{ marginLeft: '23px' }}>{recipesBoard.title}</h1>
          <h2 style={{ marginLeft: '23px' }}>{theMenu.title}</h2>

          {!isReadOnly && (
            <RecipeCollectionAddNewMealButton
              boardID={boardID}
              menuID={menuID}
            />
          )}
          <div
            style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '15px' }}>
            {(theMenu.mealIDs || theMenu.sharedMealIDs).map((mealID) => (
              <div
                key={mealID}
                id={recipeCollectionsCardDOMID(mealID)}
                style={{ flexShrink: 0 }}>
                <RecipeCollectionFullWidthMealCard
                  mealID={mealID}
                  parentID={boardID}
                  menuID={menuID}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {menuActionsMenuAnchorEl && (
        <RecipeCollectionMenuActionsMenu
          boardID={boardID}
          menuID={menuID}
          anchorEl={menuActionsMenuAnchorEl}
          onDismiss={onDismissMenuActionsMenu}
        />
      )}
    </>
  );
};

export default RecipeSingleCollectionMenuView;
