import {
  contentEntriesWithContentEntriesUpdated,
  contentEntryWithSectionUpdated,
  sharedContentEntriesWithSharedContentEntriesUpdated,
  sharedContentEntryWithSectionUpdated,
} from '../services/content_entries';

// eslint-disable-next-line import/prefer-default-export
export const contentEntryReducer = (state, action) => {
  switch (action.type) {
    case 'CONTENT_ENTRY_SECTION_UPDATED': {
      const { objectID, section, text } = action;
      const contentEntry = state.contentEntries[objectID];
      const updatedContentEntry = contentEntryWithSectionUpdated(
        contentEntry,
        section,
        text,
      );
      return {
        ...state,
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [updatedContentEntry],
        ),
      };
    }

    case 'CONTENT_ENTRY_COVER_IMAGE_UPDATED': {
      const { objectID, newImageUrl } = action;
      const contentEntry = state.contentEntries[objectID];
      const updatedContentEntry = {
        ...contentEntry,
        coverImageUrl: newImageUrl,
      };
      return {
        ...state,
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [updatedContentEntry],
        ),
      };
    }

    case 'CONTENT_ENTRY_APP_LINK_UPDATED': {
      const { objectID, newAppLink } = action;
      const contentEntry = state.contentEntries[objectID];
      const updatedContentEntry = {
        ...contentEntry,
        appLink: newAppLink,
      };
      return {
        ...state,
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [updatedContentEntry],
        ),
      };
    }

    case 'CONTENT_ENTRY_COVER_IMAGE_SHOWN_IN_DETAIL_VIEW_CHANGED': {
      const { objectID, newCoverImageShownInDetailView } = action;
      const contentEntry = state.contentEntries[objectID];
      const updatedContentEntry = {
        ...contentEntry,
        isCoverImageShownInDetailView: newCoverImageShownInDetailView,
      };
      return {
        ...state,
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [updatedContentEntry],
        ),
      };
    }

    case 'CONTENT_ENTRY_UPDATED_FROM_BACKEND': {
      const { contentEntry } = action;
      return {
        ...state,
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [contentEntry],
        ),
      };
    }

    case 'SHARED_CONTENT_ENTRY_SECTION_UPDATED': {
      const { objectID, section, text } = action;
      const sharedContentEntry = state.sharedContentEntries[objectID];
      const updatedSharedContentEntry = sharedContentEntryWithSectionUpdated(
        sharedContentEntry,
        section,
        text,
      );
      return {
        ...state,
        sharedContentEntries:
          sharedContentEntriesWithSharedContentEntriesUpdated(
            state.sharedContentEntries,
            [updatedSharedContentEntry],
          ),
      };
    }

    case 'SHARED_CONTENT_ENTRY_COVER_IMAGE_UPDATED': {
      const { objectID, newImageUrl } = action;
      const sharedContentEntry = state.sharedContentEntries[objectID];
      const updatedSharedContentEntry = {
        ...sharedContentEntry,
        coverImageUrl: newImageUrl,
      };
      return {
        ...state,
        sharedContentEntries:
          sharedContentEntriesWithSharedContentEntriesUpdated(
            state.sharedContentEntries,
            [updatedSharedContentEntry],
          ),
      };
    }

    case 'SHARED_CONTENT_ENTRY_APP_LINK_UPDATED': {
      const { objectID, newAppLink } = action;
      const sharedContentEntry = state.sharedContentEntries[objectID];
      const updatedSharedContentEntry = {
        ...sharedContentEntry,
        appLink: newAppLink,
      };
      return {
        ...state,
        sharedContentEntries:
          sharedContentEntriesWithSharedContentEntriesUpdated(
            state.sharedContentEntries,
            [updatedSharedContentEntry],
          ),
      };
    }

    case 'SHARED_CONTENT_ENTRY_COVER_IMAGE_SHOWN_IN_DETAIL_VIEW_CHANGED': {
      const { objectID, newCoverImageShownInDetailView } = action;
      const sharedContentEntry = state.sharedContentEntries[objectID];
      const updatedSharedContentEntry = {
        ...sharedContentEntry,
        isCoverImageShownInDetailView: newCoverImageShownInDetailView,
      };
      return {
        ...state,
        sharedContentEntries:
          sharedContentEntriesWithSharedContentEntriesUpdated(
            state.sharedContentEntries,
            [updatedSharedContentEntry],
          ),
      };
    }

    case 'SHARED_CONTENT_ENTRY_UPDATED_FROM_BACKEND': {
      const { sharedContentEntry } = action;
      return {
        ...state,
        sharedContentEntries:
          sharedContentEntriesWithSharedContentEntriesUpdated(
            state.sharedContentEntries,
            [sharedContentEntry],
          ),
      };
    }

    case 'CONTENT_ENTRY_PARENT_CHANGED': {
      const { objectID, parentID } = action;
      const contentEntry = state.contentEntries[objectID];
      const updatedContentEntry = { ...contentEntry, parentID };
      return {
        ...state,
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [updatedContentEntry],
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export const contentEntriesForParentSelector = (state, parentID) => {
  const contentEntriesList = Object.values(state.contentEntries).filter(
    (c) => c.parentID === parentID,
  );
  const contentEntriesMap = {};
  contentEntriesList.forEach((c) => {
    contentEntriesMap[c.id] = c;
  });
  return contentEntriesMap;
};
