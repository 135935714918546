import React from 'react';
import dayjs from 'dayjs';
// import { v4 as uuidv4 } from 'uuid';
import {
  addMealToPlannerOperation,
  addMealToPlannerWithScalingOperation,
  createOrGetPlannerWeek,
  getPlannerWeek,
  updatePlannerWeekOperation,
} from '../operations/planner_operations';
import {
  addMealOperation,
  getMealsBySmorgBoardID,
  newMealFromGrcRecipe,
  removeMeal,
  removeMeals,
  scaleMealIngredientsOperation,
} from '../operations/meal_operations';
import {
  analyzeMissingIngredientsAction,
  scaleMealAction,
  syncMealAction,
  updateFoodBrainDerivedDataAction,
} from './meal_action_creators';
import { trackAction } from './user_action_creators';
import { trackEvents } from '../operations/tracking_operations';
import {
  addNoteOperation,
  getNoteOperation,
  getNotesByParentIDOperation,
  removeNoteOperation,
  removeNotesOperation,
  updateNoteOperation,
} from '../operations/note_operations';
import {
  daysForPlannerBoardSelector,
  findPlannerEntry,
  plannerEntryType,
  plannerEntryTypeSelector,
} from '../reducers/planner_reducer';
import {
  dayIndexForCalendarDate,
  daysArrayIndex,
  dbWeekStartDateForViewWeekAndIndex,
  dbWeekStartDateStr,
  dbWeekStartDatesAndDayIndexesCoveringRecommendationRequest,
  dbWeekStartDatesCoveringCalendarRange,
  dbWeekStartDatesCoveringViewWeek,
  dbWeekStartDatesForDayIndexes,
  plannerEntriesIndexOfObject,
  viewWeekStartDateStr,
} from '../services/planner';
import { deduplicate } from '../services/arrays';
import { cloneObject } from '../operations/utils';
import {
  currentHealthProGroupSelector,
  currentSpaceMembershipIDSelector,
  currentSpaceMembershipSelector,
  userPlannerViewWeekStartDaySelector,
} from '../reducers/user_reducer';
import {
  mealFromSharedMeal,
  mealWithScaledIngredientsReplaced,
} from '../services/meals';
import { recommendMealsOperation } from '../operations/recommender_operations';
import {
  currentProgrammeTargetCaloriesSelector,
  currentProgrammeEnrollmentSelector,
  sharedProgrammeLocalesSelector,
} from '../reducers/programmes_reducer';
import { recommenderPersonalisationDataFromOnboardingAnswers } from '../services/space_onboarding';
import { getGRCRecipeOperation } from '../operations/grc_recipes_operations';
import { PlannerEntryType, RecommendationSource } from '../API';
import { programmeStartCalendarDay } from '../reducers/my_day_reducer';
import { programmeDaysForCalendar } from '../services/programmes';
import {
  DEFAULT_PROGRAMME_CALORIE_SPLITS,
  DEFAULT_PROGRAMME_MEAL_TYPES,
  calorieSplitsAreValid,
} from '../services/meal_types';
import {
  buildExistingDaysFromProgrammePrescribedDays,
  buildExistingMealsFromProgrammePrescribedMeals,
  RECOMMENDER_ENTRY_TYPE_CONTENT_ENTRY,
  RECOMMENDER_ENTRY_TYPE_MEAL,
  RECOMMENDER_MEAL_SOURCE_GRC,
} from '../services/recommender';
import {
  addContentEntryOperation,
  getContentEntriesByParentIDOperation,
  removeContentEntryOperation,
} from '../operations/content_entry_operations';
import { syncContentEntryAction } from './content_entry_action_creators';

const syncPlannerWeekAction = (dbWeekStartDate) => {
  return async (dispatch, getState) => {
    const plannerWeek = getState().plannerWeeks[dbWeekStartDate];
    try {
      const updatedPlannerWeek = await updatePlannerWeekOperation(plannerWeek);
      dispatch({
        type: 'PLANNER_WEEK_UPDATED_FROM_BACKEND',
        plannerWeek: updatedPlannerWeek,
      });
    } catch (e) {
      console.log(e);
      const plannerWeekFromBackend = await getPlannerWeek(dbWeekStartDate);
      dispatch({
        type: 'PLANNER_WEEK_UPDATED_FROM_BACKEND',
        plannerWeek: plannerWeekFromBackend,
      });
    }
  };
};

const loadDbPlannerWeek = async (
  plannerDbWeekStartDate,
  currentSpaceMembershipID,
) => {
  const plannerWeek = await createOrGetPlannerWeek(
    plannerDbWeekStartDate,
    currentSpaceMembershipID,
  );
  const [meals, notes, contentEntries] = await Promise.all([
    getMealsBySmorgBoardID(plannerWeek.id, currentSpaceMembershipID),
    getNotesByParentIDOperation(plannerWeek.id, currentSpaceMembershipID),
    getContentEntriesByParentIDOperation(plannerWeek.id),
  ]);
  return { plannerWeek, meals, notes, contentEntries };
};

const ensureDbPlannerWeekLoaded = async (
  plannerDbWeekStartDate,
  existingPlannerWeeks,
  currentSpaceMembershipID,
  dispatch,
) => {
  if (existingPlannerWeeks[plannerDbWeekStartDate]) {
    return;
  }
  const { plannerWeek, meals, notes, contentEntries } = await loadDbPlannerWeek(
    plannerDbWeekStartDate,
    currentSpaceMembershipID,
  );
  dispatch({
    type: 'PLANNER_WEEK_AVAILABLE',
    plannerWeek,
    meals,
    notes,
    contentEntries,
  });
};

export const ensureTodaysDbPlannerWeekLoadedAction = (today) => {
  return async (dispatch, getState) => {
    const dbWeekStartDate = dbWeekStartDateStr(today);
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    await ensureDbPlannerWeekLoaded(
      dbWeekStartDate,
      getState().plannerWeeks,
      currentSpaceMembershipID,
      dispatch,
    );
  };
};

const ensureDbPlannerWeeksLoadedAction = (plannerDbWeekStartDates) => {
  return async (dispatch, getState) => {
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    const plannerDbWeekStartDatesMissing = plannerDbWeekStartDates.filter(
      (plannerDbWeekStartDate) =>
        !Object.prototype.hasOwnProperty.call(
          getState().plannerWeeks,
          plannerDbWeekStartDate,
        ),
    );
    if (plannerDbWeekStartDatesMissing.length === 0) {
      return;
    }
    dispatch({
      type: 'PLANNER_NETWORK_STATE_CHANGED',
      networkState: { loading: true },
    });

    try {
      const promises = plannerDbWeekStartDatesMissing.map((dbWeekStartDate) =>
        ensureDbPlannerWeekLoaded(
          dbWeekStartDate,
          getState().plannerWeeks,
          currentSpaceMembershipID,
          dispatch,
        ),
      );
      await Promise.all(promises);
    } finally {
      dispatch({
        type: 'PLANNER_NETWORK_STATE_CHANGED',
        networkState: { loading: false },
      });
    }
  };
};

export const ensurePlannerWeekLoadedAction = (plannerViewWeekStartDate) => {
  return async (dispatch) => {
    const plannerDbWeekStartDates = dbWeekStartDatesCoveringViewWeek(
      plannerViewWeekStartDate,
    );
    return dispatch(ensureDbPlannerWeeksLoadedAction(plannerDbWeekStartDates));
  };
};

export const ensureDbPlannerWeekLoadedForRangeAction = (
  fromCalendarDay,
  toCalendarDay,
) => {
  return async (dispatch) => {
    const plannerDbWeekStartDates = dbWeekStartDatesCoveringCalendarRange(
      fromCalendarDay,
      toCalendarDay,
    );
    dispatch(ensureDbPlannerWeeksLoadedAction(plannerDbWeekStartDates));
  };
};

export const mealCopiedToPlannerAction = (
  mealID,
  plannerViewWeekStartDate,
  dayIndexes,
) => {
  const dbWeekRecords = dbWeekStartDatesForDayIndexes(
    plannerViewWeekStartDate,
    dayIndexes,
  );
  const dbWeekStartDates = deduplicate(
    dbWeekRecords.map((dbwr) => dbwr.dbWeekStartDate),
  );
  return async (dispatch, getState) => {
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      await dispatch(ensurePlannerWeekLoadedAction(plannerViewWeekStartDate));
      const { plannerWeeks } = getState();
      const meal = { ...getState().meals[mealID] };
      const insertedMeals = await Promise.all(
        dbWeekRecords.map((dbwr) => {
          const plannerWeek = plannerWeeks[dbwr.dbWeekStartDate];
          return addMealToPlannerOperation(
            plannerWeek.id,
            meal,
            currentSpaceMembershipID,
            currentHealthProGroup,
          );
        }),
      );
      dbWeekRecords.forEach((dbwr, i) => {
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PLANNER',
          plannerDbWeekStartDate: dbwr.dbWeekStartDate,
          dayIndex: dbwr.dayIndex,
          insertedMeal: insertedMeals[i],
        });
      });
      dispatch(trackAction(['Add to planner'], ['numMealsPlanned']));
      await Promise.all(
        insertedMeals.map(async (insertedMeal) => {
          try {
            await dispatch(updateFoodBrainDerivedDataAction(insertedMeal.id));
          } finally {
            await dispatch(syncMealAction(insertedMeal.id));
          }
        }),
      );
    } finally {
      await Promise.all(
        dbWeekStartDates.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};

export const sharedMealCopiedToPlannerAction = (
  sharedMealID,
  plannerViewWeekStartDate,
  dayIndexes,
) => {
  const dbWeekRecords = dbWeekStartDatesForDayIndexes(
    plannerViewWeekStartDate,
    dayIndexes,
  );
  const dbWeekStartDates = deduplicate(
    dbWeekRecords.map((dbwr) => dbwr.dbWeekStartDate),
  );
  return async (dispatch, getState) => {
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      await dispatch(ensurePlannerWeekLoadedAction(plannerViewWeekStartDate));
      const { plannerWeeks } = getState();
      const meal = mealFromSharedMeal(getState().sharedMeals[sharedMealID]);
      const insertedMeals = await Promise.all(
        dbWeekRecords.map((dbwr) => {
          const plannerWeek = plannerWeeks[dbwr.dbWeekStartDate];
          return addMealToPlannerOperation(
            plannerWeek.id,
            meal,
            currentSpaceMembershipID,
            currentHealthProGroup,
          );
        }),
      );
      dbWeekRecords.forEach((dbwr, i) => {
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PLANNER',
          plannerDbWeekStartDate: dbwr.dbWeekStartDate,
          dayIndex: dbwr.dayIndex,
          insertedMeal: insertedMeals[i],
        });
      });
      dispatch(trackAction(['Add to planner'], ['numMealsPlanned']));
      await Promise.all(
        insertedMeals.map(async (insertedMeal) => {
          try {
            await dispatch(updateFoodBrainDerivedDataAction(insertedMeal.id));
          } finally {
            await dispatch(syncMealAction(insertedMeal.id));
          }
        }),
      );
    } finally {
      await Promise.all(
        dbWeekStartDates.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};

export const plannerEntryMovedAction = (
  plannerViewWeekStartDate,
  objectID,
  sourceDayIndexStr,
  toPlannerViewWeekStartDate,
  targetDayIndexStr,
  position,
) => {
  const sourceDayIndex = parseInt(sourceDayIndexStr, 10);
  const targetDayIndex = parseInt(targetDayIndexStr, 10);
  console.log({
    plannerViewWeekStartDate,
    objectID,
    sourceDayIndex,
    toPlannerViewWeekStartDate,
    targetDayIndex,
    position,
  });
  return async (dispatch, getState) => {
    const sourcePlannerWeekDays = daysForPlannerBoardSelector(
      getState(),
      plannerViewWeekStartDate,
    );
    const targetPlannerWeekDays = daysForPlannerBoardSelector(
      getState(),
      toPlannerViewWeekStartDate,
    );
    console.log({ sourcePlannerWeekDays, targetPlannerWeekDays });
    const sourceDbWeekStartDate = sourcePlannerWeekDays.find(
      (d) => d.dayIndex === sourceDayIndex,
    ).plannerDbWeekStartDate;
    const targetDbWeekStartDate = targetPlannerWeekDays.find(
      (d) => d.dayIndex === targetDayIndex,
    ).plannerDbWeekStartDate;
    const dbWeekStartDates = deduplicate([
      sourceDbWeekStartDate,
      targetDbWeekStartDate,
    ]);
    if (sourceDbWeekStartDate === targetDbWeekStartDate) {
      dispatch({
        type: 'PLANNER_ENTRY_MOVED_SAME_DB_WEEK',
        objectID,
        plannerDbWeekStartDate: sourceDbWeekStartDate,
        sourceDayIndex,
        targetDayIndex,
        position,
      });
    } else {
      const entryType = plannerEntryType(
        getState().plannerWeeks,
        plannerViewWeekStartDate,
        objectID,
      );
      if (entryType === PlannerEntryType.NOTE) {
        try {
          dispatch({
            type: 'NOTE_PARENT_CHANGED',
            objectID,
            parentID: getState().plannerWeeks[targetDbWeekStartDate].id,
          });
        } finally {
          await dispatch(syncNoteAction(objectID));
        }
      } else if (entryType === PlannerEntryType.CONTENT_ENTRY) {
        try {
          dispatch({
            type: 'CONTENT_ENTRY_PARENT_CHANGED',
            objectID,
            parentID: getState().plannerWeeks[targetDbWeekStartDate].id,
          });
        } finally {
          await dispatch(syncContentEntryAction(objectID));
        }
      } else {
        try {
          dispatch({
            type: 'MEAL_PARENT_CHANGED',
            objectID,
            parentID: getState().plannerWeeks[targetDbWeekStartDate].id,
          });
        } finally {
          await dispatch(syncMealAction(objectID));
        }
      }
      dispatch({
        type: 'PLANNER_ENTRY_MOVED',
        objectID,
        entryType,
        sourceDbWeekStartDate,
        sourceDayIndex,
        targetDbWeekStartDate,
        targetDayIndex,
        position,
      });
    }
    await Promise.all(
      dbWeekStartDates.map((dbWeekStartDate) =>
        dispatch(syncPlannerWeekAction(dbWeekStartDate)),
      ),
    );
  };
};

export const plannerEntryDeletedAction = (
  plannerViewWeekStartDate,
  objectID,
  dayIndex,
) => {
  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  return async (dispatch, getState) => {
    try {
      dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: [objectID] });
      const entryType = plannerEntryTypeSelector(
        getState(),
        plannerViewWeekStartDate,
        objectID,
      );
      if (entryType === PlannerEntryType.NOTE) {
        await removeNoteOperation(objectID);
      } else if (entryType === PlannerEntryType.CONTENT_ENTRY) {
        await removeContentEntryOperation(objectID);
      } else {
        await removeMeal(objectID);
      }
      // TODO const removeMealResult =
      dispatch({
        type: 'PLANNER_ENTRY_DELETED',
        plannerViewWeekStartDate,
        plannerDbWeekStartDate,
        dayIndex,
        objectID,
      });
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const plannerEntryQuickDuplicateAction = (
  plannerViewWeekStartDate,
  objectID,
) => {
  return async (dispatch, getState) => {
    const { plannerWeeks } = getState();
    console.log({ plannerViewWeekStartDate, objectID });
    const entryType = plannerEntryTypeSelector(
      getState(),
      plannerViewWeekStartDate,
      objectID,
    );
    const dbWeekStartDates = dbWeekStartDatesCoveringViewWeek(
      plannerViewWeekStartDate,
    );
    const { plannerDbWeekStartDate, plannerWeek, dayIndex } = findPlannerEntry(
      plannerWeeks,
      dbWeekStartDates,
      objectID,
    );
    const currentPosition = plannerEntriesIndexOfObject(
      plannerWeek.days[daysArrayIndex(dayIndex)].entries,
      objectID,
    );
    console.log({ objectID, dayIndex, currentPosition });
    if (entryType === PlannerEntryType.NOTE) {
      dispatch(
        plannerEntryNoteCopiedToDayAction(
          plannerDbWeekStartDate,
          objectID,
          dayIndex,
          currentPosition + 1,
        ),
      );
    } else {
      dispatch(
        plannerEntryMealCopiedToDayAction(
          plannerDbWeekStartDate,
          objectID,
          dayIndex,
          currentPosition + 1,
        ),
      );
    }
  };
};

const plannerEntryMealCopiedToDayAction = (
  plannerDbWeekStartDate,
  mealID,
  destinationDayIndex,
  position,
) => {
  return async (dispatch, getState) => {
    try {
      const { plannerWeeks } = getState();
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const meal = { ...getState().meals[mealID] };
      const plannerWeek = plannerWeeks[plannerDbWeekStartDate];
      const insertedMeal = await addMealToPlannerOperation(
        plannerWeek.id,
        meal,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      dispatch({
        type: 'NEW_MEAL_ADDED_TO_PLANNER_AT_POSITION',
        plannerDbWeekStartDate,
        dayIndex: destinationDayIndex,
        position,
        insertedMeal,
      });
      dispatch(trackAction(['Add to planner'], ['numMealsPlanned']));
      try {
        await dispatch(updateFoodBrainDerivedDataAction(insertedMeal.id));
      } finally {
        await dispatch(syncMealAction(insertedMeal.id));
      }
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

const plannerEntryNoteCopiedToDayAction = (
  plannerDbWeekStartDate,
  noteID,
  destinationDayIndex,
  position,
) => {
  return async (dispatch, getState) => {
    try {
      const { plannerWeeks } = getState();
      const clonedNote = cloneObject(getState().notes[noteID]);
      const plannerWeek = plannerWeeks[plannerDbWeekStartDate];
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const insertedNote = await addNoteOperation(
        plannerWeek.id,
        clonedNote,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      dispatch({
        type: 'NEW_NOTE_ADDED_TO_PLANNER_AT_POSITION',
        plannerDbWeekStartDate,
        dayIndex: destinationDayIndex,
        position,
        insertedNote,
      });
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const plannerDayAddedToMealBasketAction = (
  plannerViewWeekStartDate,
  dayIndex,
) => {
  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  return async (dispatch) => {
    trackEvents([
      { name: 'Meals added to basket', args: { source: 'planner' } },
    ]);
    dispatch({
      type: 'PLANNER_DAY_ADDED_TO_MEAL_BASKET',
      plannerDbWeekStartDate,
      dayIndex,
    });
  };
};

export const plannerDayObjectsDeletedAction = (
  plannerViewWeekStartDate,
  dayIndex,
) => {
  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  return async (dispatch, getState) => {
    try {
      const plannerDay =
        getState().plannerWeeks[plannerDbWeekStartDate].days[
          daysArrayIndex(dayIndex)
        ];
      if (!plannerDay) {
        console.warn(
          `Cannot find planner day for DB week start date ${plannerDbWeekStartDate} day index ${dayIndex}`,
        );
        return;
      }
      const mealIDs = plannerDay.entries
        .filter(
          (e) =>
            !e.plannerEntryType || e.plannerEntryType === PlannerEntryType.MEAL,
        )
        .map((e) => e.mealID);
      const noteIDs = plannerDay.entries
        .filter((e) => e.plannerEntryType === PlannerEntryType.NOTE)
        .map((e) => e.objectID);
      const contentEntryIDs = plannerDay.entries
        .filter((e) => e.plannerEntryType === PlannerEntryType.CONTENT_ENTRY)
        .map((e) => e.objectID);
      const removePromises = [];
      if (mealIDs.length > 0) {
        dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: mealIDs });
        removePromises.push(removeMeals(mealIDs));
      }
      if (noteIDs.length > 0) {
        removePromises.push(removeNotesOperation(noteIDs));
      }
      if (contentEntryIDs.length > 0) {
        removePromises.push(contentEntryIDs.map(removeContentEntryOperation));
      }
      if (removePromises.length > 0) {
        try {
          await Promise.allSettled(removePromises);
          // TODO use result
        } catch (e) {
          // Swallow any errors
          console.warn(e);
        }
      }
      dispatch({
        type: 'PLANNER_DAY_OBJECTS_DELETED',
        plannerDbWeekStartDate,
        dayIndex,
      });
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const plannerWeekMealsDeletedAction = (plannerViewWeekStartDate) => {
  return async (dispatch, getState) => {
    const plannerWeekDays = daysForPlannerBoardSelector(
      getState(),
      plannerViewWeekStartDate,
    );
    const plannerDbWeekStartDatesToSync = deduplicate(
      plannerWeekDays.map((day) => day.plannerDbWeekStartDate),
    );
    try {
      const mealIDs = plannerWeekDays
        .flatMap((d) => d.entries)
        .filter(
          (e) =>
            !e.plannerEntryType || e.plannerEntryType === PlannerEntryType.MEAL,
        )
        .map((e) => e.mealID);
      const noteIDs = plannerWeekDays
        .flatMap((d) => d.entries)
        .filter((e) => e.plannerEntryType === PlannerEntryType.NOTE)
        .map((e) => e.objectID);
      const contentEntryIDs = plannerWeekDays
        .flatMap((d) => d.entries)
        .filter((e) => e.plannerEntryType === PlannerEntryType.CONTENT_ENTRY)
        .map((e) => e.objectID);
      const removePromises = [];
      if (mealIDs.length > 0) {
        dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: mealIDs });
        removePromises.push(removeMeals(mealIDs));
      }
      if (noteIDs.length > 0) {
        removePromises.push(removeNotesOperation(noteIDs));
      }
      if (contentEntryIDs.length > 0) {
        removePromises.push(contentEntryIDs.map(removeContentEntryOperation));
      }
      if (removePromises.length > 0) {
        try {
          await Promise.allSettled(removePromises);
          // TODO use result
        } catch (e) {
          // Swallow any errors
          console.warn(e);
        }
      }
      plannerWeekDays.forEach((d) => {
        dispatch({
          type: 'PLANNER_DAY_OBJECTS_DELETED',
          plannerDbWeekStartDate: d.plannerDbWeekStartDate,
          dayIndex: d.dayIndex,
        });
      });
    } finally {
      await Promise.all(
        plannerDbWeekStartDatesToSync.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};

export const plannerWeekAddedToMealBasketAction = (
  plannerViewWeekStartDate,
) => {
  return async (dispatch, getState) => {
    const plannerWeekDays = daysForPlannerBoardSelector(
      getState(),
      plannerViewWeekStartDate,
    );
    trackEvents([
      { name: 'Meals added to basket', args: { source: 'planner' } },
    ]);
    plannerWeekDays.forEach((d) => {
      dispatch({
        type: 'PLANNER_DAY_ADDED_TO_MEAL_BASKET',
        plannerDbWeekStartDate: d.plannerDbWeekStartDate,
        dayIndex: d.dayIndex,
      });
    });
  };
};

export const plannerNewMealAddedAction = (
  plannerViewWeekStartDate,
  dayIndex,
  mealWithoutId,
  cb,
) => {
  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  return async (dispatch, getState) => {
    try {
      const isImported = !!mealWithoutId.recipes[0]?.recipeUrl;
      const plannerWeek = getState().plannerWeeks[plannerDbWeekStartDate];
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const meal = await addMealOperation(
        plannerWeek.id,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      dispatch({
        type: 'NEW_MEAL_ADDED_TO_PLANNER',
        plannerDbWeekStartDate,
        dayIndex,
        insertedMeal: meal,
      });
      if (cb) {
        cb(meal);
      }
      const counters = ['numMealsAdded'];
      if (isImported) {
        counters.push('numMealsImported');
      } else {
        counters.push('numMealsAddedManually');
      }
      dispatch(
        trackAction(
          [
            {
              name: 'Add new meal',
              args: {
                import: isImported,
                url: mealWithoutId.recipes[0]?.recipeUrl,
                section: 'planner',
              },
            },
          ],
          counters,
        ),
      );
      try {
        await dispatch(updateFoodBrainDerivedDataAction(meal.id));
      } finally {
        await dispatch(syncMealAction(meal.id));
      }
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const plannerWeekMealsCopiedAction = (
  plannerViewWeekStartDate,
  fromViewWeekStartDate,
) => {
  const dbWeekStartDatesToSync = dbWeekStartDatesCoveringViewWeek(
    plannerViewWeekStartDate,
  );
  return async (dispatch, getState) => {
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      await dispatch(ensurePlannerWeekLoadedAction(fromViewWeekStartDate));
      const plannerWeekDays = daysForPlannerBoardSelector(
        getState(),
        fromViewWeekStartDate,
      );
      const destinationWeekDays = daysForPlannerBoardSelector(
        getState(),
        plannerViewWeekStartDate,
      );

      const itemCopyRecords = plannerWeekDays.flatMap((day, arrayIndex) => {
        return day.entries.map((plannerEntry) => {
          let sourceObject;
          if (plannerEntry.plannerEntryType === PlannerEntryType.NOTE) {
            sourceObject = getState().notes[plannerEntry.objectID];
          } else if (
            plannerEntry.plannerEntryType === PlannerEntryType.CONTENT_ENTRY
          ) {
            sourceObject = getState().contentEntries[plannerEntry.objectID];
          } else {
            sourceObject = getState().meals[plannerEntry.mealID];
          }
          return {
            dayIndex: day.dayIndex,
            plannerEntryType: plannerEntry.plannerEntryType,
            sourcePlannerDbWeekStartDate: day.plannerDbWeekStartDate,
            sourceMealID: plannerEntry.mealID,
            sourceObjectID: plannerEntry.objectID,
            sourceObject,
            destinationPlannerDbWeekStartDate:
              destinationWeekDays[arrayIndex].plannerDbWeekStartDate,
            destinationBoardId: destinationWeekDays[arrayIndex].parentBoardId,
          };
        });
      });

      const insertedObjects = await Promise.all(
        itemCopyRecords.map((itemCopyRecord) => {
          if (itemCopyRecord.plannerEntryType === PlannerEntryType.NOTE) {
            const clonedNote = cloneObject(itemCopyRecord.sourceObject);
            return addNoteOperation(
              itemCopyRecord.destinationBoardId,
              clonedNote,
              currentSpaceMembershipID,
              currentHealthProGroup,
            );
          }
          if (
            itemCopyRecord.plannerEntryType === PlannerEntryType.CONTENT_ENTRY
          ) {
            const clonedContentEntry = cloneObject(itemCopyRecord.sourceObject);
            return addContentEntryOperation(
              itemCopyRecord.destinationBoardId,
              clonedContentEntry,
              currentSpaceMembershipID,
              currentHealthProGroup,
            );
          }
          return addMealToPlannerOperation(
            itemCopyRecord.destinationBoardId,
            itemCopyRecord.sourceObject,
            currentSpaceMembershipID,
            currentHealthProGroup,
          );
        }),
      );
      itemCopyRecords.forEach((itemCopyRecord, i) => {
        // eslint-disable-next-line no-param-reassign
        itemCopyRecord.destinationObject = insertedObjects[i];
      });
      dispatch({
        type: 'PLANNER_WEEK_OF_ENTRIES_ADDED',
        itemCopyRecords,
      });
      trackEvents(['Copy planner week']);
      await Promise.all(
        itemCopyRecords
          .filter(
            (itemCopyRecord) =>
              !itemCopyRecord.plannerEntryType ||
              itemCopyRecord.plannerEntryType === PlannerEntryType.MEAL,
          )
          .map(async (itemCopyRecord) => {
            try {
              await dispatch(
                updateFoodBrainDerivedDataAction(
                  itemCopyRecord.destinationObject.id,
                ),
              );
            } finally {
              await dispatch(
                syncMealAction(itemCopyRecord.destinationObject.id),
              );
            }
          }),
      );
    } finally {
      await Promise.all(
        dbWeekStartDatesToSync.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};

export const plannerNewNoteAddedAction = (
  plannerViewWeekStartDate,
  dayIndex,
  noteWithoutId,
  cb,
) => {
  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  return async (dispatch, getState) => {
    try {
      const plannerWeek = getState().plannerWeeks[plannerDbWeekStartDate];
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const note = await addNoteOperation(
        plannerWeek.id,
        noteWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      dispatch({
        type: 'NEW_NOTE_ADDED_TO_PLANNER',
        plannerDbWeekStartDate,
        dayIndex,
        insertedNote: note,
      });
      if (cb) {
        cb(note);
      }
      dispatch(
        trackAction(
          [
            {
              name: 'Add new note',
              args: {
                note_title: noteWithoutId.title,
                section: 'planner',
              },
            },
          ],
          ['numNotesAdded'],
        ),
      );
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const noteSectionUpdatedAction = (objectID, section, text) => {
  return async (dispatch) => {
    dispatch({ type: 'NOTE_SECTION_UPDATED', objectID, section, text });
    await dispatch(syncNoteAction(objectID));
  };
};

const syncNoteAction = (objectID) => {
  return async (dispatch, getState) => {
    try {
      const updatedNote = await updateNoteOperation(getState().notes[objectID]);
      dispatch({
        type: 'NOTE_UPDATED_FROM_BACKEND',
        note: updatedNote,
      });
    } catch (e) {
      console.log(e);
      const noteFromBackend = await getNoteOperation(objectID);
      dispatch({
        type: 'NOTE_UPDATED_FROM_BACKEND',
        note: noteFromBackend,
      });
    }
  };
};

export const plannerDayCopiedToPlannerAction = (
  fromPlannerViewWeekStartDate,
  fromDayIndex,
  toPlannerViewWeekStartDate,
  dayIndexes,
) => {
  // console.log({ fromPlannerViewWeekStartDate,
  //   fromDayIndex,
  //   toPlannerViewWeekStartDate,
  //   dayIndexes });
  const fromPlannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    fromPlannerViewWeekStartDate,
    fromDayIndex,
  );
  const dbWeekRecords = dbWeekStartDatesForDayIndexes(
    toPlannerViewWeekStartDate,
    dayIndexes,
  );
  const dbWeekStartDates = deduplicate(
    dbWeekRecords.map((dbwr) => dbwr.dbWeekStartDate),
  );
  // console.log({ dbWeekStartDates });
  return async (dispatch, getState) => {
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());

      const fromPlannerDay =
        getState().plannerWeeks[fromPlannerDbWeekStartDate].days[
          daysArrayIndex(fromDayIndex)
        ];
      const mealIDs = fromPlannerDay.entries
        .filter((e) => !e.plannerEntryType || e.plannerEntryType === 'MEAL')
        .map((e) => e.mealID);

      // console.log({ mealIDs });

      const copyRecords = [];
      dbWeekRecords.forEach((dbwr) => {
        mealIDs.forEach((mealID) => {
          copyRecords.push({ ...dbwr, meal: getState().meals[mealID] });
        });
      });

      // console.log({ copyRecords });

      await dispatch(ensurePlannerWeekLoadedAction(toPlannerViewWeekStartDate));
      const { plannerWeeks } = getState();

      const insertedMeals = await Promise.all(
        copyRecords.map((copyRecord) => {
          const plannerWeek = plannerWeeks[copyRecord.dbWeekStartDate];
          const { meal } = copyRecord;
          return addMealToPlannerOperation(
            plannerWeek.id,
            meal,
            currentSpaceMembershipID,
            currentHealthProGroup,
          );
        }),
      );
      copyRecords.forEach((copyRecord, i) => {
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PLANNER',
          plannerDbWeekStartDate: copyRecord.dbWeekStartDate,
          dayIndex: copyRecord.dayIndex,
          insertedMeal: insertedMeals[i],
        });
      });
      dispatch(trackAction(['Add to planner'], ['numMealsPlanned']));
      await Promise.all(
        insertedMeals.map(async (insertedMeal) => {
          try {
            await dispatch(updateFoodBrainDerivedDataAction(insertedMeal.id));
          } finally {
            await dispatch(syncMealAction(insertedMeal.id));
          }
        }),
      );
    } finally {
      await Promise.all(
        dbWeekStartDates.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};

const importGRCRecipeAsMeal = async (
  grcRecipeID,
  currentSpaceMembershipID,
  plannerDbWeekId,
  currentHealthProGroup,
  scaledServings,
  scaledIngredientsFullText,
  scaledNutrition,
  scaledStructuredIngredients,
  scaledDerivedIngredientNutrition,
) => {
  const grcRecipe = await getGRCRecipeOperation(grcRecipeID);
  const newMeal = newMealFromGrcRecipe(grcRecipe, plannerDbWeekId);
  if (scaledServings) {
    const insertedMeal = addMealToPlannerWithScalingOperation(
      plannerDbWeekId,
      newMeal,
      currentSpaceMembershipID,
      scaledServings,
      scaledIngredientsFullText,
      scaledNutrition,
      scaledStructuredIngredients,
      scaledDerivedIngredientNutrition,
      currentHealthProGroup,
    );
    return insertedMeal;
  }
  const insertedMeal = await addMealOperation(
    plannerDbWeekId,
    newMeal,
    currentSpaceMembershipID,
    currentHealthProGroup,
  );
  return insertedMeal;
};

/**
 * Called only when user is enrolled into a programme,
 * i.e. sharedProgrammeID is a valid ID
 */
export const plannerRecommendMealsAction = (
  plannerViewWeekStartDate,
  startDayIndex,
  sharedProgrammeID,
  numDaysToPlan,
  numServings,
) => {
  return async (dispatch, getState) => {
    const locales = sharedProgrammeLocalesSelector(
      getState(),
      sharedProgrammeID,
    );

    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    const currentHealthProGroup = currentHealthProGroupSelector(getState());

    const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(
      getState(),
    );

    if (!currentProgrammeEnrollment) {
      console.warn('Programme enrollment not found, this should not happen');
      return;
    }

    const programmeStartDay = programmeStartCalendarDay(
      currentProgrammeEnrollment,
    );

    console.log({
      currentSpaceMembershipID,
      currentProgrammeEnrollment,
      plannerViewWeekStartDate,
      startDayIndex,
    });

    const dbWeeksAndIndexes =
      dbWeekStartDatesAndDayIndexesCoveringRecommendationRequest(
        plannerViewWeekStartDate,
        startDayIndex,
        numDaysToPlan,
      );

    const affectedDbWeekStartDates = deduplicate(
      dbWeeksAndIndexes.map((dbwi) => dbwi.dbWeekStartDate),
    );

    console.log({ affectedDbWeekStartDates });

    await Promise.all(
      affectedDbWeekStartDates.map((dbWeekStartDate) =>
        ensureDbPlannerWeekLoaded(
          dbWeekStartDate,
          getState().plannerWeeks,
          currentSpaceMembershipID,
          dispatch,
        ),
      ),
    );

    console.log('Planner weeks loaded');

    const {
      sharedMeals,
      sharedContentEntries,
      recipesBoards,
      sharedProgrammes,
    } = getState();
    const sharedProgramme = sharedProgrammes.find(
      (sp) => sp.id === sharedProgrammeID,
    );
    if (!sharedProgramme) {
      console.warn(
        `Could not find shared programme with ID ${sharedProgrammeID}`,
      );
      return;
    }
    const { personalisedMealScaling } = sharedProgramme;
    const sharedProgrammeEmbeddedRecipesBoardID =
      sharedProgramme.recipesBoard?.id;

    const sharedProgrammeAssociatedRecipesBoardIDs =
      sharedProgramme?.sharedRecipesBoardIDs || [];

    const recipesBoardsIDs = [
      ...recipesBoards.map((board) => board.id),
      ...sharedProgrammeAssociatedRecipesBoardIDs,
    ];

    if (sharedProgrammeEmbeddedRecipesBoardID) {
      recipesBoardsIDs.push(sharedProgrammeEmbeddedRecipesBoardID);
    }

    const databaseRecipesBoardID = sharedProgramme.databaseRecipesBoard?.id;
    if (databaseRecipesBoardID) {
      recipesBoardsIDs.push(databaseRecipesBoardID);
    }

    console.log({ recipesBoardsIDs });

    const programmeDays = programmeDaysForCalendar(
      sharedProgramme.plans,
      programmeStartDay,
      dbWeeksAndIndexes[0].dbWeekStartDate,
      dbWeeksAndIndexes[0].dayIndex,
      numDaysToPlan,
    );

    console.log(JSON.stringify(programmeDays));

    // const existingDays = dbWeeksAndIndexes.map((dbwi, indexZeroBased) => {
    //   const plannerWeekDay =
    //     getState().plannerWeeks[dbwi.dbWeekStartDate].days[
    //       daysArrayIndex(dbwi.dayIndex)
    //     ];
    //   return {
    //     dayIndex: indexZeroBased + 1,
    //     entries: plannerWeekDay.entries
    //       .filter((entry) => ['MEAL'].includes(entry.programmeEntryType))
    //       .filter(
    //         (entry) =>
    //           // Only entries which are linked to valid meals/GRC recipes
    //           entry.programmeEntryType === 'MEAL' &&
    //           !!(meals[entry.objectID]?.recipes || [])[0],
    //       )
    //       .map((entry) => ({
    //         entryType: RECOMMENDER_ENTRY_TYPE_MEAL,
    //         mealType:
    //           entry.programmeEntryType ===
    //           ((meals[entry.objectID]?.recipes || [])[0].mealTypes || [])[0],
    //         meals: [
    //           {
    //             mealSource: RECOMMENDER_MEAL_SOURCE_SMORG,
    //             id: entry.mealID,
    //             servings: meals[entry.mealID].recipes[0]?.servings || 1,
    //             categoryTags: [],
    //             scaleFactor: 1.0,
    //           },
    //         ],
    //       })),
    //   };
    // });

    console.log('Existing days computed');

    const targetCaloriesPerDay = currentProgrammeTargetCaloriesSelector(
      getState(),
    );

    const onboardingAnswers = currentSpaceMembershipSelector(
      getState(),
    )?.onboardingAnswers;

    const programmeHasValidMealTypesAndCalorieSplits =
      (sharedProgramme.mealTypes || []).length > 0 &&
      calorieSplitsAreValid(
        sharedProgramme.mealTypes,
        sharedProgramme.calorieSplits,
      );

    const mealTypes = programmeHasValidMealTypesAndCalorieSplits
      ? sharedProgramme.mealTypes
      : DEFAULT_PROGRAMME_MEAL_TYPES;

    const calorieSplits = programmeHasValidMealTypesAndCalorieSplits
      ? sharedProgramme.calorieSplits
      : DEFAULT_PROGRAMME_CALORIE_SPLITS;

    try {
      let recommendedMenu;
      if (!sharedProgramme.copyMealsExactly) {
        const existingDaysMealsOnly = programmeDays.map(
          (day, indexZeroBased) => ({
            dayIndex: indexZeroBased + 1,
            entries: buildExistingMealsFromProgrammePrescribedMeals(
              day.entries,
              sharedMeals,
            ),
          }),
        );

        recommendedMenu = await recommendMealsOperation(
          mealTypes,
          numDaysToPlan,
          existingDaysMealsOnly,
          1,
          sharedProgramme.nutritionConstraints,
          recommenderPersonalisationDataFromOnboardingAnswers(
            onboardingAnswers,
          ),
          recipesBoardsIDs,
          calorieSplits,
          numServings,
          personalisedMealScaling,
          [RecommendationSource.SMORG],
          targetCaloriesPerDay,
          locales,
        );
      } else {
        console.log('Copying program exactly as defined');

        const existingDaysFullContent = programmeDays.map(
          (day, indexZeroBased) => ({
            dayIndex: indexZeroBased + 1,
            entries: buildExistingDaysFromProgrammePrescribedDays(
              day.entries,
              sharedMeals,
              sharedContentEntries,
            ),
          }),
        );

        recommendedMenu = { days: existingDaysFullContent };
      }

      console.log(JSON.stringify(recommendedMenu));

      if (recommendedMenu.errorMessage) {
        console.warn(recommendedMenu.errorMessage);
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: (
            <p>
              Something went wrong, please try again
              <br />
              <br />
              {recommendedMenu.errorMessage}
            </p>
          ),
        });
        return;
      }

      const flattenedRecommendedEntries = recommendedMenu.days.flatMap(
        (recommendedDay, recommendedDayIndex) => {
          const plannerDbWeekStartDate =
            dbWeeksAndIndexes[recommendedDayIndex].dbWeekStartDate;
          if (!plannerDbWeekStartDate) {
            console.warn(
              `Could not find plannerDbWeekStartDate for recommended day ${recommendedDayIndex}`,
            );
            return null;
          }
          return recommendedDay.entries.map((recommendedEntry) => ({
            recommendedDayIndex,
            recommendedEntry,
            toDbPlannerWeekId:
              getState().plannerWeeks[plannerDbWeekStartDate].id,
            toDayIndex: dbWeeksAndIndexes[recommendedDayIndex].dayIndex,
          }));
        },
      );

      const flattenedRecommendedMeals = recommendedMenu.days.flatMap(
        (recommendedDay, recommendedDayIndex) => {
          const plannerDbWeekStartDate =
            dbWeeksAndIndexes[recommendedDayIndex].dbWeekStartDate;
          if (!plannerDbWeekStartDate) {
            console.warn(
              `Could not find plannerDbWeekStartDate for recommended day ${recommendedDayIndex}`,
            );
            return null;
          }
          return recommendedDay.entries
            .filter((e) => e.entryType === RECOMMENDER_ENTRY_TYPE_MEAL)
            .flatMap((recommendedEntry) => recommendedEntry.meals)
            .map((recommendedMeal) => ({
              recommendedDayIndex,
              recommendedMeal,
              toDbPlannerWeekId:
                getState().plannerWeeks[plannerDbWeekStartDate].id,
              toDayIndex: dbWeeksAndIndexes[recommendedDayIndex].dayIndex,
            }));
        },
      );

      console.log(JSON.stringify(flattenedRecommendedEntries));

      const importedMeals = await Promise.all(
        flattenedRecommendedMeals.map(
          ({ recommendedMeal, toDbPlannerWeekId }) => {
            if (recommendedMeal.mealSource === RECOMMENDER_MEAL_SOURCE_GRC) {
              return importGRCRecipeAsMeal(
                recommendedMeal.id,
                currentSpaceMembershipID,
                toDbPlannerWeekId,
                currentHealthProGroup,
                recommendedMeal.servings,
                recommendedMeal.scaledIngredientsFullText,
                recommendedMeal.scaledNutrition,
                recommendedMeal.scaledStructuredIngredients,
                recommendedMeal.scaledDerivedIngredientNutrition,
              );
            }
            const mealOrSharedMealObj = getState().sharedMeals[
              recommendedMeal.id
            ]
              ? mealFromSharedMeal(getState().sharedMeals[recommendedMeal.id])
              : getState().meals[recommendedMeal.id];
            if (!mealOrSharedMealObj) {
              console.warn(
                `Could not find meal with ID ${recommendedMeal.id} in meals or in shared meals`,
              );
              return null;
            }
            if (
              recommendedMeal.scaledIngredientsFullText &&
              recommendedMeal.scaledNutrition &&
              recommendedMeal.scaledStructuredIngredients &&
              recommendedMeal.scaledDerivedIngredientNutrition
            ) {
              console.log(`Adding meal to planner with scaling`);
              return addMealToPlannerWithScalingOperation(
                toDbPlannerWeekId,
                mealOrSharedMealObj,
                currentSpaceMembershipID,
                recommendedMeal.servings,
                recommendedMeal.scaledIngredientsFullText,
                recommendedMeal.scaledNutrition,
                recommendedMeal.scaledStructuredIngredients,
                recommendedMeal.scaledDerivedIngredientNutrition,
                currentHealthProGroup,
              );
            }
            console.log(`Adding meal to planner`);
            return addMealToPlannerOperation(
              toDbPlannerWeekId,
              mealOrSharedMealObj,
              currentSpaceMembershipID,
              currentHealthProGroup,
            );
          },
        ),
      );

      const flattenedRecommendedContentEntries = recommendedMenu.days.flatMap(
        (recommendedDay, recommendedDayIndex) => {
          const plannerDbWeekStartDate =
            dbWeeksAndIndexes[recommendedDayIndex].dbWeekStartDate;
          if (!plannerDbWeekStartDate) {
            console.warn(
              `Could not find plannerDbWeekStartDate for recommended day ${recommendedDayIndex}`,
            );
            return null;
          }
          return recommendedDay.entries
            .filter((e) => e.entryType === RECOMMENDER_ENTRY_TYPE_CONTENT_ENTRY)
            .map((e) => ({
              recommendedDayIndex,
              objectID: e.objectID,
              toDbPlannerWeekId:
                getState().plannerWeeks[plannerDbWeekStartDate].id,
              toDayIndex: dbWeeksAndIndexes[recommendedDayIndex].dayIndex,
            }));
        },
      );

      const importedContentEntries = await Promise.all(
        flattenedRecommendedContentEntries.map(
          ({ objectID, toDbPlannerWeekId }) => {
            const sharedContentEntryObj =
              getState().sharedContentEntries[objectID];
            if (!sharedContentEntryObj) {
              console.warn(
                `Could not find shared content entry with ID ${objectID}`,
              );
              return null;
            }
            console.log(`Adding content entry`);
            const clonedContentEntry = cloneObject(sharedContentEntryObj);
            return addContentEntryOperation(
              toDbPlannerWeekId,
              clonedContentEntry,
            );
          },
        ),
      );

      const recommendedMenuWithPlannerEntries = {
        ...recommendedMenu,
        days: recommendedMenu.days.map(
          (recommendedDay, recommendedDayIndex) => {
            const dbwi = dbWeeksAndIndexes[recommendedDayIndex];
            if (!dbwi) {
              console.warn(
                `Could not find plannerDbWeekStartDate for recommended day ${recommendedDayIndex}`,
              );
              return null;
            }
            const { dayIndex } = dbWeeksAndIndexes[recommendedDayIndex];
            const newEntries = recommendedDay.entries
              .flatMap((entry) => {
                if (entry.entryType === RECOMMENDER_ENTRY_TYPE_MEAL) {
                  return entry.meals.map((recommendedMeal) => {
                    const importedMealsIndex =
                      flattenedRecommendedMeals.findIndex(
                        (frm) =>
                          frm.recommendedDayIndex === recommendedDayIndex &&
                          frm.recommendedMeal?.id === recommendedMeal.id,
                      );
                    // Here we rely on the assumption that the same meal is not recommended twice in a day
                    return {
                      plannerEntryType: PlannerEntryType.MEAL,
                      mealID: importedMeals[importedMealsIndex].id,
                    };
                  });
                }

                if (entry.entryType === RECOMMENDER_ENTRY_TYPE_CONTENT_ENTRY) {
                  const importedContentEntriesIndex =
                    flattenedRecommendedContentEntries.findIndex(
                      (frm) =>
                        frm.recommendedDayIndex === recommendedDayIndex &&
                        frm.objectID === entry.objectID,
                    );
                  return [
                    {
                      plannerEntryType: PlannerEntryType.CONTENT_ENTRY,
                      objectID:
                        importedContentEntries[importedContentEntriesIndex].id,
                    },
                  ];
                }

                return [];
              })
              .filter((e) => e);

            return {
              ...recommendedDay,
              plannerDbWeekStartDate: dbwi.dbWeekStartDate,
              dayIndex,
              entries: newEntries,
            };
          },
        ),
      };
      dispatch({
        type: 'PLANNER_EXTENDED_WITH_RECOMMENDED_ENTRIES',
        recommendedMenuWithPlannerEntries,
        referencedMeals: importedMeals,
        referencedContentEntries: importedContentEntries,
      });
      if (sharedProgramme.copyMealsExactly) {
        // Scale to the required number of servings. This set of meals
        // has not been through the recommender so they are unscaled.
        importedMeals.forEach((meal) => {
          const scaleToServingsNumber = Number(numServings) || 1;
          if (scaleToServingsNumber !== meal.recipes[0]?.servings) {
            const scaleFactor =
              scaleToServingsNumber / (meal.recipes[0]?.servings || 1);
            dispatch(
              scaleMealAction(meal.id, scaleFactor, scaleToServingsNumber),
            );
          }
        });
      } else {
        // Some meals have scaled ingredient strings, ensure they are tokenized
        importedMeals.map((m) =>
          dispatch(analyzeMissingIngredientsAction(m.id)).finally(() =>
            dispatch(syncMealAction(m.id)),
          ),
        );
      }
    } catch (e) {
      console.warn(e);
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Something went wrong, please try again',
      });
    } finally {
      await Promise.all(
        affectedDbWeekStartDates.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};

export const plannerReplaceMealWithMealAction = (
  calendarDay,
  originalMealID,
  newMealID,
  targetCaloriesForScaling,
) => {
  console.log(`plannerReplaceMealWithMealAction with newMealID = ${newMealID}`);
  const plannerDbWeekStartDate = dbWeekStartDateStr(calendarDay);
  const dayIndex = dayIndexForCalendarDate(calendarDay);
  return async (dispatch, getState) => {
    try {
      const plannerWeek = getState().plannerWeeks[plannerDbWeekStartDate];
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      let mealWithoutId;
      const mealToInsert = getState().meals[newMealID];
      if (mealToInsert) {
        mealWithoutId = cloneObject(mealToInsert);
      } else {
        const sharedMealToInsert = getState().sharedMeals[newMealID];
        if (sharedMealToInsert) {
          mealWithoutId = mealFromSharedMeal(sharedMealToInsert);
        } else {
          console.warn(
            `Asked to insert meal ${newMealID} but I could not find this meal`,
          );
          return;
        }
      }
      const originalCalories =
        mealWithoutId?.derivedNutrition?.totalNutritionPerServing?.calories ||
        (mealWithoutId?.recipes || [])[0]?.nutrition?.calories;
      const shouldScale =
        targetCaloriesForScaling &&
        originalCalories &&
        originalCalories > 0 &&
        targetCaloriesForScaling > 0;
      if (shouldScale) {
        const scaleFactor = targetCaloriesForScaling / originalCalories;
        console.log({ scaleFactor });
        const scaledIngredients = await scaleMealIngredientsOperation(
          mealWithoutId,
          scaleFactor,
        );
        const { servings } = mealWithoutId.recipes[0];
        mealWithoutId = mealWithScaledIngredientsReplaced(
          mealWithoutId,
          servings,
          scaledIngredients,
        );
      }
      const meal = await addMealOperation(
        plannerWeek.id,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      if (originalMealID) {
        await removeMeal(originalMealID);
      }
      dispatch({
        type: 'PLANNER_MEAL_REPLACED',
        plannerDbWeekStartDate,
        dayIndex,
        originalMealID,
        newMeal: meal,
      });
      try {
        await dispatch(updateFoodBrainDerivedDataAction(meal.id));
      } finally {
        await dispatch(syncMealAction(meal.id));
      }
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const plannerReplaceMealWithGRCRecipeAction = (
  calendarDay,
  originalMealID,
  grcRecipeID,
  targetCaloriesForScaling,
) => {
  console.log(
    `plannerReplaceMealWithGRCRecipeAction with grcRecipeID = ${grcRecipeID}`,
  );
  const plannerDbWeekStartDate = dbWeekStartDateStr(calendarDay);
  const dayIndex = dayIndexForCalendarDate(calendarDay);
  return async (dispatch, getState) => {
    try {
      const plannerWeek = getState().plannerWeeks[plannerDbWeekStartDate];
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const grcRecipe = getState().grcRecipes[grcRecipeID];
      let mealWithoutId = newMealFromGrcRecipe(grcRecipe);
      const originalCalories =
        mealWithoutId?.derivedNutrition?.totalNutritionPerServing?.calories ||
        (mealWithoutId?.recipes || [])[0]?.nutrition?.calories;
      const shouldScale =
        targetCaloriesForScaling &&
        originalCalories &&
        originalCalories > 0 &&
        targetCaloriesForScaling > 0;
      if (shouldScale) {
        const scaleFactor = targetCaloriesForScaling / originalCalories;
        console.log({ scaleFactor });
        const scaledIngredients = await scaleMealIngredientsOperation(
          mealWithoutId,
          scaleFactor,
        );
        const { servings } = mealWithoutId.recipes[0];
        mealWithoutId = mealWithScaledIngredientsReplaced(
          mealWithoutId,
          servings,
          scaledIngredients,
        );
      }
      const meal = await addMealOperation(
        plannerWeek.id,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      if (originalMealID) {
        await removeMeal(originalMealID);
      }
      dispatch({
        type: 'PLANNER_MEAL_REPLACED',
        plannerDbWeekStartDate,
        dayIndex,
        originalMealID,
        newMeal: meal,
      });
      try {
        await dispatch(updateFoodBrainDerivedDataAction(meal.id));
      } finally {
        await dispatch(syncMealAction(meal.id));
      }
    } finally {
      await dispatch(syncPlannerWeekAction(plannerDbWeekStartDate));
    }
  };
};

export const plannerClearedAction = (fromCalendarDay, toCalendarDay) => {
  return async (dispatch, getState) => {
    const plannerViewWeekStartDay = userPlannerViewWeekStartDaySelector(
      getState(),
    );

    await dispatch(
      ensureDbPlannerWeekLoadedForRangeAction(fromCalendarDay, toCalendarDay),
    );
    for (
      let currentCalendarDay = fromCalendarDay;
      dayjs(currentCalendarDay).isBefore(dayjs(toCalendarDay));
      currentCalendarDay = dayjs(currentCalendarDay)
        .add(1, 'day')
        .format('YYYY-MM-DD')
    ) {
      console.log(currentCalendarDay);
      const plannerViewWeekStartDate = viewWeekStartDateStr(
        currentCalendarDay,
        plannerViewWeekStartDay,
      );
      const dayIndex = dayIndexForCalendarDate(currentCalendarDay);
      console.log(
        `Deleting objects, plannerViewWeekStartDate ${plannerViewWeekStartDate}, dayIndex ${dayIndex}`,
      );
      // eslint-disable-next-line no-await-in-loop
      await dispatch(
        plannerDayObjectsDeletedAction(plannerViewWeekStartDate, dayIndex),
      );
    }
  };
};

// export const exportPlannerBasedProgrammeAction = (plannerBasedProgrammeID) => {
//   return async (dispatch, getState) => {
//     const programmeTemplate =
//       PLANNER_BASED_PROGRAMME_TEMPLATES[plannerBasedProgrammeID];
//     const programme = {
//       ...programmeTemplate,
//       plans: programmeTemplate.plans.map((pl) => ({ ...pl })),
//     };
//     programme.id = uuidv4();
//     delete programme.SmorgRecipesBoardID;
//     programme.recipesBoard = {
//       id: uuidv4(),
//       title: `Programme: ${programme.title}`,
//       menus: [{ id: uuidv4(), title: 'Meals', mealIDs: [] }],
//     };
//     console.log(JSON.stringify(programmeTemplate));
//     const dbPlannerWeekStartDates = programmeTemplate.plans.map(
//       (pl) => pl.SmorgPlannerWeekStartDate,
//     );
//     console.log({ dbPlannerWeekStartDates });
//     await Promise.all(
//       dbPlannerWeekStartDates.map((dbWeekStartDate) =>
//         ensureDbPlannerWeekLoaded(
//           dbWeekStartDate,
//           getState().plannerWeeks,
//           null,
//           dispatch,
//         ),
//       ),
//     );
//     const referencedMealIDs = dbPlannerWeekStartDates.flatMap(
//       (dbWeekStartDate) =>
//         plannerEntryObjectIDsOfType(
//           getState().plannerWeeks[dbWeekStartDate],
//           PlannerEntryType.MEAL,
//         ),
//     );
//     const referencedNoteIDs = dbPlannerWeekStartDates.flatMap(
//       (dbWeekStartDate) =>
//         plannerEntryObjectIDsOfType(
//           getState().plannerWeeks[dbWeekStartDate],
//           PlannerEntryType.NOTE,
//         ),
//     );
//     const referencedMeals = referencedMealIDs
//       .map((mealID) => getState().meals[mealID])
//       .filter((meal) => !!meal);
//     const referencedNotes = referencedNoteIDs
//       .map((noteID) => getState().notes[noteID])
//       .filter((note) => !!note);
//     const contentEntriesFromNotes = referencedNotes.map((note) => ({
//       id: note.id,
//       title: note.title,
//       body: note.body,
//     }));
//     // eslint-disable-next-line no-restricted-syntax
//     for (const plan of programme.plans) {
//       const { SmorgPlannerWeekStartDate: startDate } = plan;
//       delete plan.SmorgPlannerWeekStartDate;
//       plan.id = uuidv4();
//       const plannerWeek = getState().plannerWeeks[startDate];
//       plan.days = emptyProgrammePlan();
//       plannerWeek.days.forEach((plannerDay, i) => {
//         plan.days[i].entries = plannerDay.entries.map((plannerEntry) => {
//           if (
//             !plannerEntry.plannerEntryType ||
//             plannerEntry.plannerEntryType === PlannerEntryType.MEAL
//           ) {
//             programme.recipesBoard.menus[0].mealIDs.push(plannerEntry.mealID);
//             return {
//               id: uuidv4(),
//               programmeEntryType: EntryType.MEAL,
//               objectID: plannerEntry.mealID,
//             };
//           }
//           if (plannerEntry.plannerEntryType === PlannerEntryType.NOTE) {
//             return {
//               id: uuidv4(),
//               programmeEntryType: EntryType.CONTENT_ENTRY,
//               objectID: plannerEntry.objectID,
//             };
//           }
//           return null;
//         });
//       });
//     }
//     await downloadProgrammeAndObjects(
//       programme,
//       referencedMeals,
//       contentEntriesFromNotes,
//     );
//   };
// };

export const grcRecipeImportedIntoPlannerAction = (
  grcRecipeID,
  plannerViewWeekStartDate,
  dayIndexes,
) => {
  return async (dispatch, getState) => {
    const grcRecipe = getState().grcRecipes[grcRecipeID];
    const newMeal = newMealFromGrcRecipe(grcRecipe);
    const dbWeekRecords = dbWeekStartDatesForDayIndexes(
      plannerViewWeekStartDate,
      dayIndexes,
    );
    const dbWeekStartDates = deduplicate(
      dbWeekRecords.map((dbwr) => dbwr.dbWeekStartDate),
    );
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      await dispatch(ensurePlannerWeekLoadedAction(plannerViewWeekStartDate));
      const { plannerWeeks } = getState();
      const insertedMeals = await Promise.all(
        dbWeekRecords.map((dbwr) => {
          const plannerWeek = plannerWeeks[dbwr.dbWeekStartDate];
          return addMealToPlannerOperation(
            plannerWeek.id,
            newMeal,
            currentSpaceMembershipID,
            currentHealthProGroup,
          );
        }),
      );
      dbWeekRecords.forEach((dbwr, i) => {
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PLANNER',
          plannerDbWeekStartDate: dbwr.dbWeekStartDate,
          dayIndex: dbwr.dayIndex,
          insertedMeal: insertedMeals[i],
        });
      });
      dispatch(trackAction(['Add to planner'], ['numMealsPlanned']));
      await Promise.all(
        insertedMeals.map(async (insertedMeal) => {
          try {
            await dispatch(updateFoodBrainDerivedDataAction(insertedMeal.id));
          } finally {
            await dispatch(syncMealAction(insertedMeal.id));
          }
        }),
      );
    } finally {
      await Promise.all(
        dbWeekStartDates.map((dbWeekStartDate) =>
          dispatch(syncPlannerWeekAction(dbWeekStartDate)),
        ),
      );
    }
  };
};
