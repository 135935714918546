import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../action_creators/user_action_creators';
import { LOGIN_ROUTE } from '../../services/routes';
import {
  currentEndUserSpaceIDSelector,
  currentSpaceMembershipIDSelector,
} from '../../reducers/user_reducer';
import SmorgCompanionSubscribeContent from './smorg_companion_subscribe_content';
import { linkWithQueryParams } from '../../services/urls';
import { trackEvents } from '../../operations/tracking_operations';
import SignUpButtonLabel from './signup_button_label';
import {
  matchesAppStoreID,
  useNativeAppStoreID,
} from '../../services/app_stores';
import { AppStoreID } from '../../API';
import { updateMembershipNoTierAvailableOperation } from '../../operations/user_profile_operations';

const PSEUDO_PRODUCT_ID_RESTORING_PURCHASES = 'RestoringPurchases';

const SmorgCompanionSubscribeScreen = ({ postMessageToSmorgCompanion }) => {
  const [
    iapSubscriptionProductIDPendingPurchase,
    setIapSubscriptionProductIDPendingPurchase,
  ] = useState(null);

  const spaceMembershipID = useSelector(currentSpaceMembershipIDSelector);

  const isLoading = !!iapSubscriptionProductIDPendingPurchase;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nativeAppStoreID = useNativeAppStoreID();

  console.log({ nativeAppStoreID });

  const [searchParams] = useSearchParams();
  const urlSpaceID = searchParams.get('intoSpace');
  const accountSpaceID = useSelector(currentEndUserSpaceIDSelector);
  const spaceID = urlSpaceID || accountSpaceID;

  const spaceSubscriptionPageBackgroundColor = useSelector(
    (state) =>
      state.spaces.find((s) => s.id === spaceID)?.subscriptionPageStyle
        ?.backgroundColor,
  );

  const iapSubscriptions = useSelector((state) =>
    state.iapSubscriptions
      .filter((s) => s.spaceID === spaceID)
      .filter((s) => matchesAppStoreID(s.appStoreID, nativeAppStoreID)),
  );

  const anyIAPSubscriptionsAvailable = iapSubscriptions.length > 0;

  const onPurchase = (iapSubscriptionProductID, iapSubscriptionTitle) => {
    if (isLoading) {
      return;
    }
    setIapSubscriptionProductIDPendingPurchase(iapSubscriptionProductID);
    trackEvents([
      {
        name: 'Clicked to purchase subscription',
        args: {
          subscription_name: iapSubscriptionTitle,
        },
      },
    ]);
    postMessageToSmorgCompanion({
      type: 'subscribe',
      productID: iapSubscriptionProductID,
    });
  };

  const onRestorePurchases = () => {
    if (isLoading) {
      return;
    }
    setIapSubscriptionProductIDPendingPurchase(
      PSEUDO_PRODUCT_ID_RESTORING_PURCHASES,
    );
    postMessageToSmorgCompanion({
      type: 'restorePurchases',
    });
  };

  const onSignOut = () => {
    dispatch(signOutAction());
    navigate(
      linkWithQueryParams(LOGIN_ROUTE, {
        intoSpace: spaceID,
      }),
    );
  };

  /**
   * Handles message from Smorg Companion native wrapper
   */
  const handleDOMMessage = useCallback((message) => {
    // console.log(`DOM message: ${JSON.stringify(message.data)}`);
    const messageDataObj =
      typeof message.data === 'string'
        ? JSON.parse(message.data)
        : message.data;

    if (messageDataObj.type === 'cancelIAPJourney') {
      console.log('Received cancelIAPJourney');
      setIapSubscriptionProductIDPendingPurchase(null);
    } else if (messageDataObj.type === 'purchasesRestored') {
      // Native code is expected to send 'updateMembershipWithAppleIAP' too,
      // in order to activate the subscription
      console.log('Received purchasesRestored');
      setIapSubscriptionProductIDPendingPurchase(null);
    }
  }, []);

  const theme = useTheme();

  useEffect(() => {
    window.addEventListener('message', handleDOMMessage);
    return () => {
      window.removeEventListener('message', handleDOMMessage);
    };
  }, [handleDOMMessage]);

  useEffect(() => {
    if (!anyIAPSubscriptionsAvailable) {
      updateMembershipNoTierAvailableOperation(spaceMembershipID).then(
        (updateResult) => {
          console.log(updateResult);
          const { membershipExpiresAt } = updateResult;
          dispatch({
            type: 'MEMBERSHIP_UPDATED_WITH_TRANSACTION_RECEIPT',
            spaceMembershipID,
            membershipExpiresAt,
          });
        },
      );
    }
  }, [anyIAPSubscriptionsAvailable, dispatch, spaceMembershipID]);

  const styleOverride = spaceSubscriptionPageBackgroundColor
    ? { backgroundColor: spaceSubscriptionPageBackgroundColor }
    : undefined;

  return (
    <div
      className="subscription-screen smorg-companion-subscribe-screen"
      style={styleOverride}>
      <div className="screen-title">Select your subscription</div>
      <SmorgCompanionSubscribeContent spaceID={spaceID} />
      {iapSubscriptions.map((iapSub) => (
        <div
          className="available-subscription"
          style={{
            marginTop: '20px',
            backgroundColor: theme.palette.primary.cardBackground,
          }}
          key={iapSub.productID}>
          <div className="subscription-name" style={{ margin: '10px 0' }}>
            {iapSub.title}
          </div>
          {iapSub.shortDescription && (
            <div className="subscription-short-description">
              {iapSub.shortDescription}
            </div>
          )}
          {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={classNames([
              'subscribe-action',
              { 'is-loading': isLoading },
            ])}
            onClick={() => onPurchase(iapSub.productID, iapSub.title)}
            style={{ marginTop: '20px' }}>
            <div className="subscribe-action-text">
              <SignUpButtonLabel
                isFree={false}
                actionText={
                  <>
                    Sign up for
                    <br />
                  </>
                }
                price={iapSub.priceStr}
                billingPeriodText="/ month"
                currencyCode={iapSub.currencyCode}
                override={iapSub.signUpButtonLabelOverride}
              />
            </div>
          </div>
          <div className="loading-text">
            {isLoading &&
              iapSubscriptionProductIDPendingPurchase === iapSub.productID &&
              'Requesting subscription, please wait...'}
          </div>
        </div>
      ))}
      {nativeAppStoreID === AppStoreID.APPLE_APP_STORE && (
        //  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className="available-subscription"
          onClick={onRestorePurchases}
          style={{ marginTop: '20px' }}>
          <div
            className={classNames([
              'subscribe-action',
              { 'is-loading': isLoading },
            ])}
            style={{ marginTop: '20px' }}>
            <div className="subscribe-action-text">Restore purchases</div>
          </div>
        </div>
      )}

      <div className="spacer">&nbsp;</div>
      <div className="footer">
        <div>
          <Button onClick={onSignOut} style={{ color: 'var(--app-primary-font-color)' }}>
            Log out
          </Button>
        </div>
        <div>
          <Button
            onClick={() => navigate('/user?hideMobileNavigation=true')}
            style={{ color: 'var(--app-primary-font-color)' }}>
            My Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

SmorgCompanionSubscribeScreen.propTypes = {
  postMessageToSmorgCompanion: PropTypes.func.isRequired,
};

export default SmorgCompanionSubscribeScreen;
